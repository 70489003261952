import { PostRemoteLog } from './api-remote-log'
import { BACKEND_URL } from '../../api/constants'
import { useAuthenticationHook } from '../hooks/authentication-hook'

import { canUseGetNearbyLoadsV4 } from '../helpers/permissions'

let GET_NEARBY_LOADS
const GET_LOAD_DETAILS = "/api/carrier/getLoadDetails"
const GET_LOAD_DETAILS_BY_LOAD_NUMBER = "/api/carrier/getLoadDetailsByLoadNumber"

export const FetchNearByLoads = (params) => {

    const myAuthService = useAuthenticationHook()
    //Trigger
    GET_NEARBY_LOADS = canUseGetNearbyLoadsV4() ? '/api/carrier/getNearbyLoadsV4' : '/api/carrier/getNearByLoadsV2'

    const url = BACKEND_URL + GET_NEARBY_LOADS

    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(params)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + GET_NEARBY_LOADS,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data: JSON.stringify(params)
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + GET_NEARBY_LOADS,
            message: {
                error: JSON.stringify(error)
            },
            data: JSON.stringify(params)
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}

export const GetLoadDetails = (params) => {

    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + GET_LOAD_DETAILS
    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + GET_LOAD_DETAILS,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data: JSON.stringify(params)
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + GET_LOAD_DETAILS,
            message: {
                error: JSON.stringify(error)
            },
            data: JSON.stringify(params)
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}

export const GetLoadDetailsByLoadNumber = (loadNumber, advantageId) => {

    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + GET_LOAD_DETAILS_BY_LOAD_NUMBER + "/" + loadNumber + "/" + advantageId
    try {
        let requestObject = {
            method: 'GET'
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + GET_LOAD_DETAILS_BY_LOAD_NUMBER,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data: JSON.stringify({ loadNumber: loadNumber })
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + GET_LOAD_DETAILS_BY_LOAD_NUMBER,
            message: {
                error: JSON.stringify(error)
            },
            data: JSON.stringify({ loadNumber: loadNumber })
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}