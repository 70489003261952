import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';

import isMobile from '../../../HOC/Mobile.js'
import RoleSelect from "./role-selector"

import * as UserManagementApi from '../../apis/api-user-management';


const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        // fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '11px',
        lineHeight: '22px',
        color: 'grey'
    }
}));

export function InviteUserButton(props) {

    const { muiTheme, isMobile, customerAccountId, successCallback } = props

    const classes = useStyles();

    function getModalStyle() {

        return {
            textAlign: isMobile ? 'center' : 'unset',
            width: isMobile ? '100%' : 'unset',
            top: `40%`,
            left: `50%`,
            transform: `translate(-50%, -50%)`,
        };
    }

    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [role, setRole] = useState(null);


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFirstName("")
        setLastName("")
        setEmail("")
        setPhone("")
        setRole(null)
    };

    const handleSubmit = () => {
        // validation
        if (!email || email === "") {
            toast.error("The email field is required for user invitations.")
            return
        } else if (role === null || typeof role === "undefined") {
            toast.error("Please select a user role.")
            return
        }

        let params = {
            advantageId: muiTheme.customerUniqueId,
            customerAccountId: customerAccountId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            role: role
        }
        if (phone !== "") {
            params.phone = phone
        }
        // console.log("params: ", params)
        UserManagementApi.SendCarrierInvite(params).then(response => {
            // console.log("sendCarrierInvite: ", response)
            if (response.status) {
                // refresh the list and show success message
                toast.success("Invite sent successfully.")
                handleClose()
                successCallback()
            } else {
                toast.error("Error: " + response.message)
            }
        }).catch((error) => {
            let errorMessage = "Error: There was a problem making your request."
            toast.error("Error: " + errorMessage)
        })
    }

    useEffect(() => {
        if (props &&
            props.history &&
            props.history.location &&
            props.history.location.state &&
            props.history.location.state.inviteUser &&
            props.history.location.state.inviteUser === true) {
            handleOpen()
        }
    }, [])

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h3>Invite New User</h3>
            {/* <div style={{ alignSelf: 'center', paddingBottom: 24 }}>
                <p style={{ fontSize: 14, fontWeight: 600, paddingLeft: 10, paddingRight: 10 }}>
                    Complete this form to invite a new user to join your carrier network.
                </p>
            </div> */}
            <div style={{ marginTop: 15, display: "flex", alignItems: "flex-end" }}>
                <p className={classes.mainText} style={{ marginRight: 10, marginBottom: 5 }}>First Name: </p>
                <TextField
                    required
                    id="filled-required"
                    // label="Required"
                    variant="filled"
                    value={firstName}
                    onChange={(event) => { setFirstName(event.target.value) }}
                    style={{ flex: 1 }}
                    inputProps={{ style: { fontSize: 14 } }}
                />
            </div>
            <div style={{ marginTop: 10, display: "flex", alignItems: "flex-end" }}>
                <p className={classes.mainText} style={{ marginRight: 10, marginBottom: 5 }}>Last Name: </p>
                <TextField
                    required
                    id="filled-required"
                    // label="Required"
                    variant="filled"
                    value={lastName}
                    onChange={(event) => { setLastName(event.target.value) }}
                    style={{ flex: 1 }}
                    inputProps={{ style: { fontSize: 14 } }}
                />
            </div>
            <div style={{ marginTop: 10, display: "flex", alignItems: "flex-end" }}>
                <p className={classes.mainText} style={{ marginRight: 10, marginBottom: 5 }}>Email: </p>
                <TextField
                    required
                    id="filled-required"
                    // label="Required"
                    variant="filled"
                    value={email}
                    onChange={(event) => { setEmail(event.target.value) }}
                    style={{ flex: 1 }}
                    inputProps={{ style: { fontSize: 14 } }}
                />
            </div>
            <div style={{ marginTop: 10, display: "flex", alignItems: "flex-end" }}>
                <p className={classes.mainText} style={{ marginRight: 10, marginBottom: 5 }}>Phone: </p>
                <TextField
                    required
                    id="filled-required"
                    // label="Required"
                    variant="filled"
                    value={phone}
                    onChange={(event) => { setPhone(event.target.value) }}
                    style={{ flex: 1 }}
                    inputProps={{ style: { fontSize: 14 } }}
                />
            </div>
            <div style={{ marginTop: 10, marginBottom: 30, display: "flex", alignItems: "flex-end" }}>
                <p className={classes.mainText} style={{ marginRight: 10, marginBottom: 5 }}>Role: </p>
                <RoleSelect handleSetRole={(selected) => setRole(selected)} />
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Button
                    size="small"
                    style={{ fontSize: 14, color: muiTheme.actionColor, backgroundColor: "transparent", maxHeight: '40px' }}
                    onClick={() => { handleClose() }}
                >
                    CANCEL
                </Button>
                <Button
                    variant="contained"
                    size="small"
                    style={{ fontSize: 14, color: "white", backgroundColor: muiTheme.actionColor, maxHeight: '40px' }}
                    onClick={() => { handleSubmit() }}
                >
                    SEND INVITE
                </Button>
            </div>
        </div>
    );

    return (
        <div style={{ margin: 10, marginBottom: 0 }}>
            <Button
                variant="contained"
                size="small"
                style={{ fontSize: 14, color: "white", backgroundColor: muiTheme.actionColor, margin: 5, maxHeight: '40px' }}
                onClick={handleOpen}
            >
                INVITE NEW USER
            </Button>
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}

export default compose(
    muiThemeable(),
    isMobile()
)(InviteUserButton)