import React, { useState, useEffect } from 'react';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';

import FA from 'react-fontawesome';
import moment from 'moment'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { dontUseTimeSearch, canUseGetNearbyLoadsV4, canUsePickupEndDate } from '../../helpers/permissions';
import LcDataWrap from '../load-list/desktop/LoadCardDataWrapper.js'
/**
 * This component is paired with the searchBarDesktop component along with MUI Collapse lib component
 *  Needed for collapse controlls and collapse preview 
 */
const LoadSearchBarDesktopCollapseView = (props) => {

    const useStyles = makeStyles({
        labelText: {
            fontSize: 12,
            color: 'rgba(0, 0, 0, 0.54)'
        },
        valueText: {
            fontSize: 14,
            color: 'black',
            maxHeight:60,
            maxWidth:250,
            overflow:'hidden',
            textOverflow:'ellipsis',
            wordBreak: 'break-word',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient:'vertical'

        },
        inputLabelClass: {
            '& .MuiTypography-body1': {
                fontSize: 14,
                fontWeight:'bold'
            }
        },
        carrotHoverClassExtension: {
            
        }
    });

    const classes = useStyles()

    const [hoverBar, setHoverBar] = useState(false)

    const {
        isSearchBarCollapsed,
        collapseSearchBar,
        searchBarContents,
        muiTheme
    } = props

    const getStateCodesArray = (statesObject) => {
        return Array.prototype.map.call(statesObject, (state) => state.abbr ).join(", ")
    }

    const getZoneCodesArray = (zonesObject) => {
        return Array.prototype.map.call(zonesObject, (zone) => zone.id ).join(", ")
    }

    const getMarketNamesArray = (marketsObject) => {
        return Array.prototype.map.call(marketsObject, (market) => market.name ).join(", ")
    }

    const getOriginSearchBarText = () => {
        switch (searchBarContents.originSearchType){
            case "default":
                return searchBarContents?.formattedAddress ?? null
            case "state": case "anywhere":
                if(searchBarContents.lane_origins == false || searchBarContents.lane_origins.length == 0){
                    return 'All Locations'
                }else{
                    return getStateCodesArray(searchBarContents.lane_origins)
                }
            case "zone":
                return getZoneCodesArray(searchBarContents.lane_origins)
            case "market":
                return getMarketNamesArray(searchBarContents.lane_origins)             
        }
    }

    const getDestinationSearchBarText = () => {
        switch (searchBarContents.destinationSearchType) {
            case "default":
                return searchBarContents?.formattedAddressDestination ?? null
            case "state": case "anywhere":
                if (searchBarContents.lane_preferences == false || searchBarContents.lane_preferences.length == 0) {
                    return 'All Locations'
                } else {
                    return getStateCodesArray(searchBarContents.lane_preferences)
                }
            case "zone":
                return getZoneCodesArray(searchBarContents.lane_preferences)
            case "market":
                return getMarketNamesArray(searchBarContents.lane_preferences)
        }
    }

    const truckTypeText = () => {
        let truckTypesText =  Array.prototype.map.call(searchBarContents.truckTypes, (truckObject) => truckObject.code ).join(", ")
        if (truckTypesText.length != 0) {
            return truckTypesText
        } else {
            return 'N/A'
        }
    }

    const pickupStartDateText = () => {
        return dontUseTimeSearch() ?  
            moment(searchBarContents.available_at_start).format('MM/DD/YYYY')
            :
            moment(searchBarContents.available_at_start).format('MM/DD/YYYY hh:mm a')
            
    }

    const pickupEndDateText = () => {
        if(searchBarContents.available_at_end && searchBarContents.available_at_end != null){
            return dontUseTimeSearch() ? 
                moment(searchBarContents.available_at_end).format('MM/DD/YYYY')
                :
                moment(searchBarContents.available_at_end).format('MM/DD/YYYY hh:mm a')
        }else{
            return 'N/A'
        }
    }

    const locationDisplayText = (location) => {
        if (location == 'origin'){
            let originText = getOriginSearchBarText()
            if(originText!=false && originText!=null && originText.length !=0 ){
                return originText
            }else{
                return (searchBarContents.originSearchType == "default" || 
                        searchBarContents.originSearchType == "zone" || 
                        searchBarContents.originSearchType == "market") ? 
                <span style={{color:'red'}}>N\A</span> : <span>N\A</span>
            }
        }else if(location='destination'){
            let destinationText = getDestinationSearchBarText()
            if(destinationText!=false && destinationText!=null && destinationText.length !=0 ){
                return destinationText
            }else{
                return (searchBarContents.destinationSearchType == "default" || 
                        searchBarContents.destinationSearchType == "zone" || 
                        searchBarContents.destinationSearchType == "market") ? 
                <span style={{color:'red'}}>N\A</span> : <span>N\A</span>
            }
        }
        
    }

    return (
        <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 50, minHeight: 45, maxHeight: 70, cursor: 'pointer' }}
            onMouseDown={(e) => collapseSearchBar(e, 'clickEvent')}
            onMouseEnter={function () { setHoverBar(true) }}
            onMouseLeave={function () { setHoverBar(false) }}
        >
            <p
                style={{ color: 'black', fontWeight: 'bold', fontSize: 16, cursor: 'pointer' }}
                onMouseDown={(e) => collapseSearchBar(e, 'clickEvent')}
            >
                FIND & BOOK LOADS
            </p>
            <IconButton
                key={{isSearchBarCollapsed} + { hoverBar }}
                onMouseDown={(e) => collapseSearchBar(e, 'clickEvent')}
                style={{ fontSize: 12, padding: 24, height: 12, width: 12, backgroundColor: hoverBar ? 'rgba(0, 0, 0, 0.04)' : 'unset' }}
            >
                <div key={isSearchBarCollapsed}><FA name={isSearchBarCollapsed ? 'chevron-right' : 'chevron-down'} size='2x' /></div>
            </IconButton>
            <div style={{ flex: 1 }}>
                {isSearchBarCollapsed ?
                    <div style={{ display: 'flex', marginLeft: 36, marginRight: 36, gap: 16 }}>
                        <LcDataWrap>
                            <div>
                                <div className={classes.labelText}>Origin</div>
                                <div className={classes.valueText}>{locationDisplayText('origin')}</div>
                            </div>
                            <div>
                                <div className={classes.labelText}>Pickup Start Date</div>
                                <div className={classes.valueText}>{pickupStartDateText()}</div>
                            </div>
                            {canUseGetNearbyLoadsV4() && canUsePickupEndDate() &&
                                <div>
                                    <div className={classes.labelText}>Pickup End Date</div>
                                    <div className={classes.valueText}>{pickupEndDateText()}</div>
                                </div>
                            }
                            <div>
                                <div className={classes.labelText}>Equipment</div>
                                <div className={classes.valueText}>{truckTypeText()}</div>
                            </div>
                            <div>
                                <div className={classes.labelText}>Destination</div>
                                <div className={classes.valueText}>{locationDisplayText('destination')}</div>
                            </div>
                            <Button
                                style={{ fontSize: 16,color: muiTheme.actionColor, backgroundColor: hoverBar ? 'rgba(0, 0, 0, 0.04)' : 'unset' }}
                                variant="standard"
                                startIcon={<FA name={'edit'} size='2x' />}
                                onMouseDown={(e) => collapseSearchBar(e, 'clickEvent')}
                            >
                                Edit
                            </Button>
                        </LcDataWrap>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default compose(
    muiThemeable()
)(LoadSearchBarDesktopCollapseView)
