import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import Pagination from '@material-ui/lab/Pagination';
import { compose } from 'recompose';

const ListPagination = (props) => {

    const {
        isMobile=false,
        pageNumber = 1,
        setPageNumber,
        numberOfPages,
        muiTheme,
        screenWidth
    } = props

    const useStyles = makeStyles({
      root: {
        "& > *": {
          justifyContent:isMobile? 'center' : 'left',
          display:'flex'
        },
        '& .MuiPaginationItem-page' : {
          marginRight: isMobile ? '0px' : '16px',
          fontSize: '16px',
          height: '36px',
          width:'36px',
          textDecoration: 'underline',
          color: muiTheme.actionColor
        },
        '& .MuiPaginationItem-icon' : {
          fontSize: '26px',
          height: '36px',
          width: '36px'
        },
        '& .MuiPaginationItem-page.Mui-selected' : {
          backgroundColor: process.env.REACT_APP_ADVANTAGE_ID==="a98db973" ? muiTheme.actionColor : muiTheme.secondary,
          color: 'white',
          textDecoration: 'none'
        },
        '& .MuiPagination-ul' :{
              '& li:first-child':{
                  paddingRight: isMobile? '5px' : '10px',
                  '& button':  !isMobile ? {
                    width: '70px',
                    paddingRight: 10,
                    marginRight: 0,
                    '&::after': {
                      fontSize: '16px',
                      content: '"Back"',
                    }
                  } 
                  : {
                    width:45,
                    height:45
                  }  
              },
              '& li:last-child':{        
                paddingLeft: isMobile? '5px' : '10px',
                '& button': !isMobile ? {
                  width: '70px',
                  paddingLeft:10,
                  marginLeft: -10,
                  '&::before': {
                    fontSize: '16px',
                    content: '"Next"',
                  }
                }
                : {
                  width:45,
                  height:45
                }  
            } 
        },
        '& .MuiPaginationItem-ellipsis' : {
          fontSize: '30px',
          position: 'relative',
          top: '-8px'
        },
        '& .MuiPaginationItem-page.Mui-disabled' : {
          opacity: 0.3
        }
      }
    });

    const classes = useStyles();

    const adjustMobileSiblingCount = () => {
      if(screenWidth < 500){
        return 0
      } else if (screenWidth > 500 && screenWidth < 700){
        return 1
      } else {
        return 2
      }
    }

    const handleChange = (event, value) => {
        event.preventDefault();
        setPageNumber(value);
    };

    return (
        <div>            
          <Pagination 
            // , marginLeft: page == 1 ? 45.972 : 0 extra rule to deal with shift after hiding prev,next buttons
            // style={{position:'absolute', bottom: 40, paddingLeft:12}}
            count={numberOfPages}
            page={pageNumber}
            // hidePrevButton={page == 1}
            // hideNextButton={page == numberOfPages}
            onChange={handleChange}
            boundaryCount={1}
            siblingCount={isMobile ? adjustMobileSiblingCount() : 2}
            defaultPage={1}
            shape='rounded'
            className={classes.root}       
          />     
        </div>
    )
}

export default compose(
  muiThemeable()
)(ListPagination)