
import { API_PROD_URL, API_STAGING_URL } from "../../../api/constants"
import {useAuthenticationHook} from "../../hooks/authentication-hook"
export function WernerServices(){
    const myAuthService = useAuthenticationHook()
    return {
        async getLocations() {
            let promise = new Promise((resolve, reject) => {
                try {
                    let user = myAuthService.getUser()
                    let externalId = user.carrier.externalId
                    // console.log("externalId: ", externalId) // 100010828

                    const GET_LOCATIONS_URL = "/account/getLocations?advantagId=" + process.env.REACT_APP_ADVANTAGE_ID + "&carrierExternalId=" + externalId
                    let url = API_STAGING_URL + GET_LOCATIONS_URL
                    let requestObject = {
                        method: 'GET',
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LOCATIONS: ", jsondata)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LOCATIONS ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LOCATIONS E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async getAvailableCapacity(addressId) {
            let promise = new Promise((resolve, reject) => {
                try {
                    const GET_AVAILABLE_CAPACITY_URL = "/carrier/getAvailableCapacity?advantagId=" + process.env.REACT_APP_ADVANTAGE_ID + "&addressId=" + addressId
                    let url = API_STAGING_URL + GET_AVAILABLE_CAPACITY_URL
                    let requestObject = {
                        method: 'GET',
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("AVAILABLE_CAPACITY: ", jsondata)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("AVAILABLE_CAPACITY ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("AVAILABLE_CAPACITY E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async addAvailableCapacity(params) {
            let promise = new Promise((resolve, reject) => {
                try {
                    params.advantageId = process.env.REACT_APP_ADVANTAGE_ID

                    const ADD_AVAILABLE_CAPACITY_URL = "/carrier/addAvailableCapacity"
                    let url = API_STAGING_URL + ADD_AVAILABLE_CAPACITY_URL
                    let requestObject = {
                        method: 'POST',
                        body: JSON.stringify(params)
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("AVAILABLE_CAPACITY: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("AVAILABLE_CAPACITY ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("AVAILABLE_CAPACITY E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async editAvailableCapacity(params) {
            let promise = new Promise((resolve, reject) => {
                try {
                    params.advantageId = process.env.REACT_APP_ADVANTAGE_ID

                    const ADD_AVAILABLE_CAPACITY_URL = "/carrier/editAvailableCapacity"
                    let url = API_STAGING_URL + ADD_AVAILABLE_CAPACITY_URL
                    let requestObject = {
                        method: 'PUT',
                        body: JSON.stringify(params)
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("AVAILABLE_CAPACITY: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("AVAILABLE_CAPACITY ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("AVAILABLE_CAPACITY E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async getLanePreferences(addressId) {
            let promise = new Promise((resolve, reject) => {
                try {
                    const GET_LANE_PREFERENCES_URL = "/carrier/getLanePreferences?advantagId=" + process.env.REACT_APP_ADVANTAGE_ID + "&addressId=" + addressId
                    let url = API_STAGING_URL + GET_LANE_PREFERENCES_URL
                    let requestObject = {
                        method: 'GET',
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCES: ", jsondata)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCES ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCES E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async addLanePreference(params) {
            let promise = new Promise((resolve, reject) => {
                try {
                    params.advantageId = process.env.REACT_APP_ADVANTAGE_ID

                    const ADD_AVAILABLE_CAPACITY_URL = "/carrier/addLanePreference"
                    let url = API_STAGING_URL + ADD_AVAILABLE_CAPACITY_URL
                    let requestObject = {
                        method: 'POST',
                        body: JSON.stringify(params)
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCE: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCE ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCE E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async editLanePreference(params) {
            let promise = new Promise((resolve, reject) => {
                try {
                    params.advantageId = process.env.REACT_APP_ADVANTAGE_ID

                    const ADD_AVAILABLE_CAPACITY_URL = "/carrier/editLanePreference"
                    let url = API_STAGING_URL + ADD_AVAILABLE_CAPACITY_URL
                    let requestObject = {
                        method: 'PUT',
                        body: JSON.stringify(params)
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCE: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCE ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCE E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async deleteLanePreference(params) {
            let promise = new Promise((resolve, reject) => {
                try {
                    params.advantageId = process.env.REACT_APP_ADVANTAGE_ID

                    const ADD_AVAILABLE_CAPACITY_URL = "/carrier/deleteLanePreference"
                    let url = API_STAGING_URL + ADD_AVAILABLE_CAPACITY_URL
                    let requestObject = {
                        method: 'POST',
                        body: JSON.stringify(params)
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCE: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCE ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCE E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async getEquipments(addressId) {
            // return mockEquipments
            let promise = new Promise((resolve, reject) => {
                try {
                    const GET_EQUIPMENTS_URL = "/carrier/getEquipments?advantagId=" + process.env.REACT_APP_ADVANTAGE_ID + "&addressId=" + addressId
                    let url = API_PROD_URL + GET_EQUIPMENTS_URL
                    let requestObject = {
                        method: 'GET',
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("EQUIPMENTS: ", jsondata)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("EQUIPMENTS ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("EQUIPMENTS E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async getContacts(addressId) {
            let promise = new Promise((resolve, reject) => {
                try {
                    let user = myAuthService.getUser()
                    let externalId = user.carrier.externalId

                    const GET_CONTACTS_URL = "/carrier/getContacts?advantagId=" + process.env.REACT_APP_ADVANTAGE_ID + "&carrierExternalId=" + externalId + "&addressId=" + addressId
                    let url = API_STAGING_URL + GET_CONTACTS_URL
                    let requestObject = {
                        method: 'GET',
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("CONTACTS: ", jsondata)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("CONTACTS ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("CONTACTS E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async getAvailableLoads() {
            let promise = new Promise((resolve, reject) => {
                try {
                    let user = myAuthService.getUser()
                    let externalId = user.carrier.externalId
                    // console.log("externalId: ", externalId) // 100010828

                    const GET_AVAILABLE_LOADS_URL = "/carrier/getAvailableLoads?advantagId=" + process.env.REACT_APP_ADVANTAGE_ID + "&carrierExternalId=" + externalId
                    let url = API_STAGING_URL + GET_AVAILABLE_LOADS_URL
                    let requestObject = {
                        method: 'GET',
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("AVAILABLE LOADS: ", jsondata)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("AVAILABLE LOADS ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("AVAILABLE LOADS E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async getHistoricalLoads(params) {
            let promise = new Promise((resolve, reject) => {
                try {
                    let user = myAuthService.getUser()
                    let externalId = user.carrier.externalId
                    // console.log("externalId: ", externalId) // 100010828

                    const GET_AVAILABLE_LOADS_URL = "/carrier/getHistoricalLoads?advantagId=" + process.env.REACT_APP_ADVANTAGE_ID + "&carrierExternalId=" + externalId + "&daysBack=" + params.daysBack
                    let url = API_STAGING_URL + GET_AVAILABLE_LOADS_URL
                    let requestObject = {
                        method: 'GET',
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("AVAILABLE LOADS: ", jsondata)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("AVAILABLE LOADS ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("AVAILABLE LOADS E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async getCommunications() {
            let promise = new Promise((resolve, reject) => {
                try {
                    let user = myAuthService.getUser()
                    let externalId = user.carrier.externalId
                    // console.log("externalId: ", externalId) // 100010828

                    const GET_AVAILABLE_LOADS_URL = "/carrier/getCommunications?advantagId=" + process.env.REACT_APP_ADVANTAGE_ID + "&carrierExternalId=" + externalId
                    let url = API_STAGING_URL + GET_AVAILABLE_LOADS_URL
                    let requestObject = {
                        method: 'GET',
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("AVAILABLE LOADS: ", jsondata)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("AVAILABLE LOADS ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("AVAILABLE LOADS E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },

        async addLocation(params) {
            let payload=  params
            let promise = new Promise((resolve, reject) => {
                try {
                    let user = myAuthService.getUser()
                    let externalId = user.carrier.externalId
                    // console.log("externalId: ", externalId) // 100010828
                    payload.advantageId = process.env.REACT_APP_ADVANTAGE_ID
                    payload.carrierExternalId=externalId
                    payload.source = "TT"
                    payload.user = user.fullName

                    //console.log('call api payload',payload,JSON.stringify(payload))
                    const POST_LOCATIONS_URL = "/account/addLocation";
                    let url = API_STAGING_URL + POST_LOCATIONS_URL
                    let requestObject = {
                        method: 'POST',
                        body:JSON.stringify(payload)
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => {
                            resolve(response)
                        })
                        .catch(error => {
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LOCATIONS E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },

        async editLocation(params) {
            let payload=  params
            let promise = new Promise((resolve, reject) => {
                try {
                    let user = myAuthService.getUser()
                    let externalId = user.carrier.externalId
                    // console.log("externalId: ", externalId) // 100010828
                    payload.advantageId = process.env.REACT_APP_ADVANTAGE_ID
                    payload.carrierExternalId=externalId
                    payload.source = "TT"
                    payload.user = user.fullName

                    //console.log('call api payload',payload,JSON.stringify(payload))
                    const POST_LOCATIONS_URL = "/account/editLocation";
                    let url = API_STAGING_URL + POST_LOCATIONS_URL
                    let requestObject = {
                        method: 'POST',
                        body:JSON.stringify(payload)
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => {
                            resolve(response)
                        })
                        .catch(error => {
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LOCATIONS E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },

        async deleteLocation(params) {
            let payload=  params
            let promise = new Promise((resolve, reject) => {
                try {
                    let user = myAuthService.getUser()
                    let externalId = user.carrier.externalId
                    // console.log("externalId: ", externalId) // 100010828
                    payload.advantageId = process.env.REACT_APP_ADVANTAGE_ID
                    payload.carrierExternalId=externalId
                    payload.source = "TT"
                    payload.user = user.fullName

                    //console.log('call api payload',payload,JSON.stringify(payload))
                    const POST_LOCATIONS_URL = "/account/deleteLocation";
                    let url = API_STAGING_URL + POST_LOCATIONS_URL
                    let requestObject = {
                        method: 'POST',
                        body:JSON.stringify(payload)
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => {
                            resolve(response)
                        })
                        .catch(error => {
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LOCATIONS E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },

        async addContact(params) {
            let payload=  params
            let promise = new Promise((resolve, reject) => {
                try {
                    let user = myAuthService.getUser()
                    let externalId = user.carrier.externalId
                    // console.log("externalId: ", externalId) // 100010828
                    payload.advantageId = process.env.REACT_APP_ADVANTAGE_ID
                    payload.carrierExternalId=externalId
                    payload.source = "TT"
                    payload.user = user.fullName

                    //console.log('call api payload',payload,JSON.stringify(payload))
                    const POST_LOCATIONS_URL = "/carrier/addContact";
                    let url = API_STAGING_URL + POST_LOCATIONS_URL
                    let requestObject = {
                        method: 'POST',
                        body:JSON.stringify(payload)
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => {
                            //console.log("AVAILABLE_CAPACITY: ", response)
                            resolve(response)
                        })
                        .catch(error => {
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LOCATIONS E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },

        async editContact(params) {
            let payload=  params
            let promise = new Promise((resolve, reject) => {
                try {
                    let user = myAuthService.getUser()
                    let externalId = user.carrier.externalId
                    // console.log("externalId: ", externalId) // 100010828
                    payload.advantageId = process.env.REACT_APP_ADVANTAGE_ID
                    payload.carrierExternalId=externalId
                    payload.source = "TT"
                    payload.user = user.fullName

                    //console.log('call api payload',payload,JSON.stringify(payload))
                    const POST_LOCATIONS_URL = "/carrier/editContact";
                    let url = API_STAGING_URL + POST_LOCATIONS_URL
                    let requestObject = {
                        method: 'POST',
                        body:JSON.stringify(payload)
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => {
                            //console.log("AVAILABLE_CAPACITY: ", response)
                            resolve(response)
                        })
                        .catch(error => {
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LOCATIONS E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },


        async deleteContact(params) {
            let payload=  params
            let promise = new Promise((resolve, reject) => {
                try {
                    let user = myAuthService.getUser()
                    let externalId = user.carrier.externalId
                    // console.log("externalId: ", externalId) // 100010828
                    payload.advantageId = process.env.REACT_APP_ADVANTAGE_ID
                    payload.carrierExternalId=externalId
                    payload.source = "TT"
                    payload.user = user.fullName

                    const POST_LOCATIONS_URL = "/carrier/deleteContact";
                    let url = API_STAGING_URL + POST_LOCATIONS_URL
                    let requestObject = {
                        method: 'POST',
                        body:JSON.stringify(payload)
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => {
                            resolve(response)
                        })
                        .catch(error => {
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LOCATIONS E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
    }
}