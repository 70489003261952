import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';

import CarrierLoadList from './werner/load-list'
import FilterMenu from "../../components/filter-menu/index"
import DaysBackSelector from "../../components/my-loads/werner-days-back-selector"

import LoadingOverlay from '../../../components/LoadingOverlay'
import useGtagHook from '../../../gtag-hook';
import isMobile from '../../../HOC/Mobile.js'

import ListPagination from '../ListPagination';
import { WernerServices } from '../../customers/a98db973/services';
// import { mockAvailableLoads } from '../../customers/a98db973/mock-available-loads'

const MyLoadScreen = ({ loads, availability, muiTheme, isMobile }) => {

    const useStyles = makeStyles({
        container: {
            padding: 5,
            height: '100%',
            width: '100%'
        },
        badge: {
            height: 20,
            width: 20,
            borderRadius: 50,
            display: "flex",
            justifyContent: "center",
            backgroundColor: muiTheme.secondary
        },
        breadcrumbLink: {
            cursor: 'pointer',
            marginRight: 15,
            color: "black", // TODO: change??
            "&:hover": {
                color: muiTheme.actionColor,
                textDecoration: "underline"
            }
        },
        activeBreadcrumbLink: {
            color: muiTheme.primary,
            fontWeight: 'bold',
            textDecoration: "underline",
            "&:hover": {
                color: muiTheme.primary,
            }
        },
        secondaryText: {
            fontFamily: 'Arial !important',
            fontSize: '14px',
            color: 'grey'
        },
        header: {
            width: "50%",
            alignItems: "center",
            justifyContent: "center",
            borderBottomWidth: 2,
            borderBottomStyle: "solid",
            borderRadius: 0,
            textTransform: "none",
            "&hover": {
                cursor: "grab",
                color: muiTheme.actionColor
            }
        },
        buttonHeaderText: {
            textAlign: "center",
            fontSize: 16,
            fontWeight: 'bold',
            "&hover": {
                cursor: "grab",
                color: muiTheme.actionColor
            }
        },
        headerText: {
            textAlign: "center",
            fontSize: 16,
            "&hover": {
                cursor: "grab",
                color: muiTheme.actionColor
            }
        },
        formControl: {
            minWidth: isMobile ? 'unset' : 200,
        },
        menuItem: {
            fontSize: '16px',
            textAlign: 'center'
        },
        removePadding: {
            paddingBottom: 0
        },
        selectfieldStyleOutline: {
            backgroundColor: 'white',
            // border: '1px solid #ccc',
            height: '45px',
            width: '180px',
            maxWidth: '180px',
            // margin: '0px',
            // backgroundColor: 'white',
            paddingLeft: '10px',
            paddingRight: '0px',
            paddingBottom: '0px',
            fontSize: '16px',
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textAlign: 'center'
        }
    });

    const classes = useStyles()
    const [sendGtagEvent] = useGtagHook()

    const PAGES = {
        activeLoadsTab: 0,
        quotedLoadsTab: 1,
        historyTab: 2,
    }

    const [renderedTab, setRenderedTab] = useState( () => {
        if (localStorage.getItem("tab")){
            return localStorage.getItem("tab")
        } else {
            return PAGES["activeLoadsTab"]   
        }
    })

    const [loadsStack, setLoadsStack] = useState([])
    const [currentLoadList, setCurrentLoadList] = useState([])
    const [currentActiveLoadList, setCurrentActiveLoadList] = useState([])
    const [currentHistoricalLoadList, setCurrentHistoricalLoadList] = useState([])
    const [reloadCrumbs, setReloadCrumbs] = useState([])
    const [reloadsOpen, setReloadsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [historicalDaysBack, setHistoricalDaysBack] = useState(45)

    const [activeLoadPageNumber, setActiveLoadPageNumber] = useState(1)
    const [historicalLoadPageNumber, setHistoricalLoadPageNumber] = useState(1)

    const [paginationPageCount, setPaginationPageCount] = useState(1)

    const itemsPerPage = 10

    const FILTER_PADDING_TOP = 83

    useEffect(() => {
        fetchActiveLoads()
    }, [])

    useEffect(() => {
        fetchHistoricalLoads()
    }, [historicalDaysBack])

    useEffect(() => {
        setLoadsStack([])
        setReloadCrumbs([])
        setReloadsOpen(false)
        localStorage.setItem('tab', renderedTab)
        if (renderedTab == PAGES["activeLoadsTab"]) { //todo: in future save list with a timer 
            setCurrentLoadList(currentActiveLoadList)
        } else if (renderedTab == PAGES["historyTab"]) {
            setCurrentLoadList(currentHistoricalLoadList)
        }
        setIsLoading(false)
    }, [renderedTab])

    useEffect(() => {
        if (renderedTab == PAGES["activeLoadsTab"]) { //todo: in future save list with a timer 
            setPaginationPageCount(Math.ceil(currentActiveLoadList.length / itemsPerPage))
        } else if (renderedTab == PAGES["historyTab"]) {
            setPaginationPageCount(Math.ceil(currentHistoricalLoadList.length / itemsPerPage))
        }
    }, [currentLoadList])

    useEffect(() => {
        if (reloadCrumbs.length <= 0)
            setReloadsOpen(false)
        else
            setReloadsOpen(true)
    }, [reloadCrumbs])

    useEffect(() => {
        if (loadsStack[loadsStack.length - 1] != null)
            setCurrentLoadList(loadsStack[loadsStack.length - 1])
    }, [loadsStack])

    const sortWernerAvailableLoads = (loadList = []) => {
        loadList.sort((a, b) => {
            let aTime = moment(a.originStop.latestDate).valueOf()
            let bTime = moment(b.originStop.latestDate).valueOf()
            if (aTime < bTime)
                return 1
            else if (aTime > bTime)
                return -1
            else
                return 0
        })
        return loadList
    }

    const fetchActiveLoads = () => {
        setIsLoading(true)
        const apiServices = new WernerServices()

        apiServices.getAvailableLoads()
            .then(response => {
                if (response) {
                    if (Array.isArray(response) && response.length > 0) {
                        let loadList = sortWernerAvailableLoads(response)
                        setLoadsStack([loadList])
                        setCurrentActiveLoadList(loadList)
                    } else {
                        let loadList = []
                        setLoadsStack([loadList])
                        setCurrentActiveLoadList(loadList)
                    }
                    setIsLoading(false)
                    sendGtagEvent(
                        "search_results_returned",
                        {
                            screen: "booked_loads",
                            listLength: response.length,
                            type: "loads",
                        }
                    )
                } else {
                    // console.log("ERROR 1: ", response)
                    let errorMessage = "Error: There was a problem fetching your booked loads."
                    setIsLoading(false)
                    toast.error(response.message ? response.message : errorMessage)
                }
            })
            .catch(error => {
                // console.log("ERROR 2: ", error)
                let errorMessage = "Error: There was a problem fetching your booked loads."
                setIsLoading(false)
                toast.error(errorMessage)
            })
    }

    const fetchHistoricalLoads = (daysBack) => {
        setIsLoading(true)
        let params = {
            advantageId: muiTheme.customerUniqueId
        }

        params['daysBack'] = historicalDaysBack 

        const apiServices = new WernerServices()

        apiServices.getHistoricalLoads(params)
            .then(response => {
            // })
                if (response) {
                    if (Array.isArray(response) && response.length > 0) {
                        setLoadsStack([response])
                        setCurrentHistoricalLoadList(response)
                    } else {
                        let loadList = []
                        setLoadsStack([loadList])
                        setCurrentHistoricalLoadList(loadList)
                        // toast.error("There are no historical transactions.")
                    }
                    setIsLoading(false)
                    // sendGtagEvent(
                    //     "search_results_returned",
                    //     {
                    //         screen: "booked_loads",
                    //         listLength: response.length,
                    //         type: "loads",
                    //     }
                    // )
                } else {
                    // console.log("ERROR 1: ", response)
                    let errorMessage = "Error: There was a problem fetching your booked loads."
                    setIsLoading(false)
                    toast.error(response.message ? response.message : errorMessage)
                }
            })
            .catch(error => {
                // console.log("ERROR 2: ", error)
                let errorMessage = "Error: There was a problem fetching your booked loads."
                setIsLoading(false)
                toast.error(errorMessage)
            })

    }
    

    const pushReloads = (parentLoad, reloads) => {
        //todo: push another param for date besides relying on scheduledAt to be in stops
        let tmpLoadOrigin = {
            ...parentLoad.stops[0],
            date: parentLoad.pickupDateTimeLatest ? parentLoad.pickupDateTimeLatest : parentLoad.pickupDateTime
        }
        let tmpLoadDestination = {
            ...parentLoad.stops[parentLoad.stops.length - 1],
            date: parentLoad.deliveryDateTime
        }
        if (reloadCrumbs.length <= 0) {
            setReloadCrumbs([
                tmpLoadOrigin,
                tmpLoadDestination
            ])
        } else {
            setReloadCrumbs([
                ...reloadCrumbs,
                tmpLoadDestination
            ])
        }
        setLoadsStack([
            ...loadsStack,
            reloads
        ])
        sendGtagEvent(
            "search_results_returned",
            {
                screen: "booked_loads",
                listLength: reloads.length,
                type: "reloads"
            }
        )
    }

    const popToIndex = (index) => {
        let newLoadsStack = loadsStack.slice(0, index + 1)
        let newReloadCrumbs = reloadCrumbs.slice(0, index + 1)
        setLoadsStack(newLoadsStack)
        if (index === 0)
            setReloadCrumbs([])
        else
            setReloadCrumbs(newReloadCrumbs)
    }

    const sortMostRecentBooked = (loadList = []) => {
        loadList.sort((a, b) => {
            let aTime = moment(a.activities[0].created_at).valueOf()
            let bTime = moment(b.activities[0].created_at).valueOf()
            if (aTime < bTime)
                return 1
            else if (aTime > bTime)
                return -1
            else
                return 0
        })
        return loadList
    }

    const getHeaderText = () => {
        switch (renderedTab) {
            case PAGES["activeLoadsTab"]:
                return "Active Loads"
            case PAGES["quotedLoadsTab"]:
                return "Quoted Loads"
            case PAGES["historyTab"]:
                return "History"
        }
    }

    return (
        <div style={{ height: '100%' }}>
            {isLoading && <LoadingOverlay />}
            { !isMobile &&
                <div className={classes.container}>
                    <Row style={{ height: '8%', width: "100%", marginBottom: 0, display: "flex", justifyContent: "center" }}>
                        <Col style={{ width: "50%", display: "flex", justifyContent: "center", paddingTop: 25 }}>
                            <Button
                                className={classes.header}
                                style={{ borderBottomColor: renderedTab == PAGES["activeLoadsTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                onClick={() => {
                                    if(renderedTab != PAGES["activeLoadsTab"]){
                                     setIsLoading(true)
                                     setRenderedTab(PAGES["activeLoadsTab"])   
                                    }
                                }}
                            >
                                <p className={classes.buttonHeaderText} style={{ marginBottom: -15, color: renderedTab == PAGES["activeLoadsTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>ACTIVE LOADS</p>
                            </Button>
                            <Button
                                className={classes.header}
                                style={{ borderBottomColor: renderedTab == PAGES["historyTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                onClick={() => {
                                    if(renderedTab != PAGES["historyTab"]){ 
                                        setIsLoading(true)
                                        setRenderedTab(PAGES["historyTab"])             
                                    }
                                }}
                            >
                                <p className={classes.buttonHeaderText} style={{ marginBottom: -15, color: renderedTab == PAGES["historyTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>HISTORY</p>
                            </Button>
                        </Col>
                    </Row>

                    <Row style={{ height: '82%' }}>
                        {reloadsOpen &&
                            <Col md={2} style={{ paddingTop: FILTER_PADDING_TOP, backgroundColor: 'white', height: '100%' }}>
                                <FilterMenu loadList={currentLoadList} paddingTop={FILTER_PADDING_TOP} />
                            </Col>
                        }
                        <Col md={reloadsOpen ? 10 : 12} style={{height: '100%'}}>
                                <Row style={{ height: 60, display: "flex", width: "100%", marginTop: 15, marginBottom: 15 }}>
                                    <Col style={{ display: 'flex', alignItems: 'flex-end', width: '25%' }}>
                                        <p style={{ color: 'black', fontSize: 32, marginLeft: 15, marginTop: '15%', fontWeight: 'bold', whiteSpace: 'nowrap' }}>{getHeaderText()}</p>
                                    </Col>
                                    <Col style={{ width: '50%', display: "flex", justifyContent: "center"}}>
                                        {renderedTab == PAGES["historyTab"] &&
                                            <DaysBackSelector historicalDaysBack={historicalDaysBack} setHistoricalDaysBack={setHistoricalDaysBack} />
                                        }
                                    </Col>
                                    <Col style={{ width: '25%' }} />
                                </Row>
                            {/* } */}

                            {/* {reloadsOpen &&
                                <Row>
                                    <div style={{ paddingLeft: 15, paddingTop: 25 }}>
                                        {
                                            reloadCrumbs.map((crumb, index) => {
                                                return (
                                                    <span>

                                                        { (index < reloadCrumbs.length - 1) &&
                                                            <span>
                                                                <div style={{ display: 'inline-block' }}>
                                                                    <span className={classes.breadcrumbLink} onClick={() => popToIndex(index)}>
                                                                        {crumb.city + ', ' + crumb.state}
                                                                    </span>
                                                                    <p className={classes.secondaryText}>
                                                                        {moment(crumb.date).format('MM/DD')}
                                                                    </p>
                                                                </div>
                                                                <FA name='chevron-right' size="lg" color={"black"} style={{ marginRight: 15, verticalAlign: 'top' }} />

                                                            </span>
                                                        }

                                                        { index === reloadCrumbs.length - 1 &&

                                                            <div style={{ display: 'inline-block' }}>
                                                                <span className={classes.activeBreadcrumbLink} onClick={() => popToIndex(index)}>
                                                                    {crumb.city + ', ' + crumb.state}
                                                                </span>
                                                                <p className={classes.secondaryText}>
                                                                    {moment(crumb.date).format('MM/DD')}
                                                                </p>
                                                            </div>


                                                        }
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                </Row>
                            } */}

                            {!isLoading && currentLoadList && currentLoadList.length > 0 ?
                                <Row style={{height: `calc(${paginationPageCount > 1 ? '95%' : '100%'} - 60px)`}}>
                                    <CarrierLoadList
                                        unfilterLoadList={
                                            renderedTab == PAGES["activeLoadsTab"] ? 
                                                currentActiveLoadList : currentHistoricalLoadList
                                        }
                                        availability={availability}
                                        pushReloads={pushReloads}
                                        reloadsOpen={reloadsOpen}
                                        isMyLoadsScreen={true}
                                        myLoadsRenderedTab={renderedTab}
                                        pageNumber={renderedTab == PAGES["activeLoadsTab"] ? activeLoadPageNumber : historicalLoadPageNumber}
                                        setPageNumber={renderedTab == PAGES["activeLoadsTab"] ? setActiveLoadPageNumber : setHistoricalLoadPageNumber}
                                        itemsPerPage={itemsPerPage}
                                    />
                                </Row>
                                :
                                <div style={{ paddingTop: 50 }}>
                                    <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                        No {getHeaderText()} Found
                                    </p>
                                </div>
                            }
                            {!isLoading && currentLoadList && currentLoadList.length > 0 && paginationPageCount > 1 && (
                                <Row style={{ height: '5%'}}>
                                    <ListPagination
                                        numberOfPages={paginationPageCount}
                                        pageNumber={renderedTab == PAGES["activeLoadsTab"] ? activeLoadPageNumber : historicalLoadPageNumber}
                                        setPageNumber={renderedTab == PAGES["activeLoadsTab"] ? setActiveLoadPageNumber : setHistoricalLoadPageNumber}
                                    />
                                </Row>
                            )}
                        </Col>
                    </Row>
                </div>
            }


            {isMobile &&
                <div style={{height: '100%'}}>
                        <Row style={{ height: 50, width: "100%", marginBottom: 0, display: "flex", justifyContent: "center", visibility:isLoading ? 'hidden' : 'visible'}}>
                            <Col style={{ width: "90%", display: "flex", justifyContent: "center", paddingTop: 15 }}>
                                <Button
                                    className={classes.header}
                                    style={{ borderBottomColor: renderedTab == PAGES["activeLoadsTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                    onClick={() => {
                                        if(renderedTab != PAGES["activeLoadsTab"]){
                                            setIsLoading(true)
                                            setRenderedTab(PAGES["activeLoadsTab"])   
                                        }
                                    }}
                                >
                                    <p className={classes.headerText} style={{ color: renderedTab == PAGES["activeLoadsTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>Active Loads</p>
                                </Button>
                                {/* <Button
                                className={classes.header}
                                style={{ borderBottomColor: renderedTab == PAGES["quotedLoadsTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                onClick={() => setRenderedTab(PAGES["quotedLoadsTab"])}
                            >
                                <p className={classes.headerText} style={{ color: renderedTab == PAGES["quotedLoadsTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>Quoted Loads</p>
                            </Button> */}
                                <Button
                                    className={classes.header}
                                    style={{ borderBottomColor: renderedTab == PAGES["historyTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}
                                    onClick={() => {
                                        if(renderedTab != PAGES["historyTab"]){
                                            setIsLoading(true)
                                            setRenderedTab(PAGES["historyTab"])   
                                        }
                                    }}
                                >
                                    <p className={classes.headerText} style={{ color: renderedTab == PAGES["historyTab"] ? muiTheme.actionColor : muiTheme.ttUnselectGrey }}>History</p>
                                </Button>
                            </Col>
                        </Row>

                    {renderedTab == PAGES["historyTab"] &&
                        <Row style={{ height: 50, width: "100%", marginBottom: 0, display: "flex", justifyContent: "center", marginTop: 10, marginBottom: 20, visibility:isLoading ? 'hidden' : 'visible'}}>
                            <Col>
                                <DaysBackSelector historicalDaysBack={historicalDaysBack} setHistoricalDaysBack={setHistoricalDaysBack} />
                            </Col>
                        </Row>
                    }


                    {!isLoading && (currentLoadList && currentLoadList.length > 0) ?
                        <Row style={{height: `calc(${paginationPageCount > 1 ? '85%' : '100%'} - ${ renderedTab == PAGES["historyTab"] ? '100px' : '50px'})`}}>
                        <CarrierLoadList
                            isMobile
                            unfilterLoadList={
                                renderedTab == PAGES["activeLoadsTab"] ? 
                                    currentActiveLoadList : currentHistoricalLoadList
                            }
                            availability={availability}
                            pushReloads={pushReloads}
                            reloadsOpen={reloadsOpen}
                            isMyLoadsScreen={true}
                            myLoadsRenderedTab={renderedTab}
                            pageNumber={renderedTab == PAGES["activeLoadsTab"] ? activeLoadPageNumber : historicalLoadPageNumber}
                            setPageNumber={renderedTab == PAGES["activeLoadsTab"] ? setActiveLoadPageNumber : setHistoricalLoadPageNumber}
                            itemsPerPage={itemsPerPage}
                        />
                        </Row>
                        :
                        <div style={{ paddingTop: 50 }}>
                            <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                No {getHeaderText()} Found
                            </p>
                        </div>
                    }
                    {!isLoading && currentLoadList && currentLoadList.length > 0 && paginationPageCount > 1 && (
                        <Row style={{ height: '15%', paddingTop: 15}}>
                            <ListPagination
                                isMobile
                                numberOfPages={paginationPageCount}
                                pageNumber={renderedTab == PAGES["activeLoadsTab"] ? activeLoadPageNumber : historicalLoadPageNumber}
                                setPageNumber={renderedTab == PAGES["activeLoadsTab"] ? setActiveLoadPageNumber : setHistoricalLoadPageNumber}
                            />
                        </Row>
                    )}
                </div>
            }

        </div>
    )
}

const mapStateToProps = state => ({
    loads: state.AvailabilityLoads.payload.loads,
    availability: state.AvailabilityLoads.payload.availability,
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
    isMobile(),
    withProps(props => {

        return ({
            ...{

            },
            ...props
        })
    }),
)(MyLoadScreen)