import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withState, compose } from 'recompose'
import moment from 'moment'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';

import './style.css'
import isMobile from '../../../HOC/Mobile.js'
import FA from 'react-fontawesome'
//COMPONENTS//
import CarrierLoadList from '../../components/load-list'
import { Col, Row } from "react-bootstrap"
import FilterMenu from "../../components/filter-menu/index"
import LoadSearchBar from '../../components/LoadSearchBar';
import LoadingOverlay from '../../../components/LoadingOverlay'
import LoadSearchMobileWrapper from '../../components/LoadSearchBar/LoadSearchMobileWrapper'
import { getNearbyLoads, setSearchLoading, resetLoadList } from '../../actions';
import { modifyLoadObject } from '../../../lib/modify-load-object';
import { toast } from 'react-toastify';

import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useAuthenticationHook } from '../../hooks/authentication-hook';

import ListPagination from '../../components/ListPagination';

import { canUseGetNearbyLoadsV4, isDemoAdvantageId } from '../../helpers/permissions';

//ACTIONS//
import { sendInterestEmail, resetEmailSendReducer } from './actions'
//FUNCTIONS//
import useGtagHook from '../../../gtag-hook';

import { validateSearchBarContents } from '../../helpers/validateSearchBarContents';
import { createLocationsPayloadV4 } from '../../helpers/createLocationsPayloadV4'
import { LogNotificationClick } from '../../../api/rateNegotiations';


const COMPONENT_NAME = ({
  //PROPS FROM PARENT//
  //required
  //optional
  hideDashLink = false, containerStyle, showEditAvailabilityButton = false, onEditButtonClick = false, isVisible = 'not passed',
  //REDUX//
  loads, loadList, availability, loading, loadingPortal, error, emailSendSuccess, emailSending,userProfile,
  sendInterestEmail, resetEmailSendReducer, pickupLocation, getNearbyLoads, setSearchLoading, lastSearchPayload,
  message, resetLoadList, values,
  //STATE
  selectedLoads, updateSelectedLoads,
  messageOpen, updateMessageOpen,
  isPaginationEnabled = false,
  //OTHER
  isMobile, muiTheme, ...props
}) => {

  const { height, width } = useWindowDimensions();

  const useStyles = makeStyles({
    container: {
      padding: "0px 5px 5px 5px",
      height: height,
      width: '100%',
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    flexItem: {
      paddingTop: 5,
      paddingBottom: 5
    },
    badge: {
      height: 20,
      width: 20,
      borderRadius: 50,
      display: "flex",
      justifyContent: "center",
      backgroundColor: muiTheme.secondary
    },
    breadcrumbLink: {
      cursor: 'pointer',
      marginRight: 15,
      color: "black", // TODO: change??
      "&:hover": {
        color: muiTheme.actionColor,
        textDecoration: "underline"
      }
    },
    activeBreadcrumbLink: {
      color: muiTheme.primary,
      fontWeight: 'bold',
      textDecoration: "underline",
      "&:hover": {
        color: muiTheme.primary,
      }
    },
    secondaryText: {
      fontFamily: 'Arial !important',
      fontSize: '14px',
      color: 'grey'
    },
    sortSelectItem: {
      border: '1px solid #ccc',
      backgroundColor: 'white',
      fontSize: 14,
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.1,
      whiteSpace: 'nowrap',
      paddingLeft: '10px',
    },
    icon: {
      position: 'absolute',
      fontSize: '2.5rem',
      textAlign: 'right'
    },
    refreshButton: {
      fontSize: 16,
      color: "black",
      marginLeft: 5,
      paddingLeft: 5,
      paddingRight: 5,
      cursor: 'pointer',
      borderRadius: 10,
      '&:hover': {
        boxShadow: '0px 2px 10px #999999',
      }
    }
  });
  let bannerLinks = [
    {
      image: 'https://assets.truckertools.com/website/banners/england-logistics/TT-Factoring-Banner-BLUE_936_120.gif',
      href: 'https://www.englandlogistics.com/factoring/',
      alt: 'England Logistics Factoring Banner'
    },
    {
      image: 'https://assets.truckertools.com/website/banners/england-logistics/Free-Balancing-TTT-Banner_936_120.gif',
      href: 'https://www.englandlogistics.com/tires/',
      alt: 'England Logistics Tire Banner'
    }
  ]
  const classes = useStyles()
  const myAuthService = useAuthenticationHook()
  const isAuthenticated = myAuthService.isAuthenticated()

  const [originalLoadList, setOriginalLoadList] = useState([])
  const [loadsStack, setLoadsStack] = useState([])
  const [currentLoadList, setCurrentLoadList] = useState([])
  const [currentLoadPageNumber, setCurrentLoadPageNumber] = useState(1)
  const [currentLoadListCount, setCurrentLoadListCount] = useState(0) //this will be updated by the load list when a filter is applied
  const [reloadCrumbs, setReloadCrumbs] = useState([])
  const [reloadsOpen, setReloadsOpen] = useState(false)
  const [listMessage, setListMessage] = useState(null)

  const [displayTime, setDisplayTime] = useState(null)
  const [listLastFetch, setListLastFetched] = useState()

  const sortSelection = { "BestMatch": 0, "Deadhead": 1, "Pickup": 2 }
  const [selectedSort, setSelectedSort] = useState(isAuthenticated && muiTheme.customerUniqueId !== "a98db973" ? 0 : 1)
  const [refreshList, setRefreshList] = useState(false)

  const [sendGtagEvent] = useGtagHook()

  const [bannerAd, setBannerAd] = useState(bannerLinks[0])
  const FILTER_PADDING_TOP = 167
  const BANNER_TIMINGS = 18000

  const [numOfPaginationPages, setNumOfPaginationPages] = useState(1)
  const itemsPerPage = 20

  const handleBannerClick = () => {
    let type = ""
    switch (bannerAd.alt) {
      case "England Logistics Factoring Banner":
        type = "factoring"
        break;
      case "England Logistics Tire Banner":
        type = "tireNationalAccount"
        break;
      default:
        break;
    }
    sendGtagEvent("banner_click", {
      bannerType: type
    })
  }

  useEffect(() => {
    let url = props.location.search;
    let params = queryString.parse(url);

    if(Object.keys(params).length > 0)
      LogNotificationClick(params)

    setTimeout(
      () => { showSlides(1) },
      BANNER_TIMINGS
    );

    /* Signout and signin causes a refresh and it'll trigger this useEffect refetchList */
    if (loadList === false || (lastSearchPayload && lastSearchPayload.authenticated !== isAuthenticated)) {
      handleRefetchList()
    }

    window.addEventListener('login', function (event) {
      setSelectedSort(muiTheme.customerUniqueId !== "a98db973" ? 0 : 1)
      // handleRefetchList()
    })
    window.addEventListener('logout', function (event) {
      setSelectedSort(1)
      // resetLoadList()
    })

    return () => {
      window.removeEventListener('login', () => { })
      window.removeEventListener('logout', () => { })
    }

  }, [])

  const searchWithLastSearchbarContents = () => {
    let searchPayload

    if (canUseGetNearbyLoadsV4()) {
      searchPayload = {
        pickupDateFrom: moment(values.available_at_start).format('MM/DD/YYYY'),
        pickupTimeFrom: moment(values.available_at_start).format('HH:mm'),
        truckTypes: values.truckTypes ? values.truckTypes : [],
        advantageId: isDemoAdvantageId() ? "32m9lbe4" : muiTheme.customerUniqueId,
        origins: createLocationsPayloadV4(values, 'origin'),
        destinations: createLocationsPayloadV4(values, 'destination'),
        authenticated: isAuthenticated
      }

      if (values.available_at_end && values.available_at_end != 'Invalid date') {
        searchPayload['pickupDateTo'] = moment(values.available_at_end).format('MM/DD/YYYY');
        searchPayload['pickupTimeTo'] = moment(values.available_at_end).format('HH:mm');

        let timeVerificationStartDate = muiTheme.customerUniqueId === "a98db973" ? moment(values.available_at_start).format('MM/DD/YYYY') : moment(values.available_at_start).format('MM/DD/YYYYHH:mm')
        let timeVerificationEndDate = muiTheme.customerUniqueId === "a98db973" ? moment(values.available_at_end).format('MM/DD/YYYY') : moment(values.available_at_end).format('MM/DD/YYYYHH:mm')

        if (timeVerificationStartDate.valueOf() > timeVerificationEndDate.valueOf()) {
          toast.error('The starting pickup date must come before the ending pickup date.')
          return
        }
      }
    } else {
      searchPayload = {
        lat: values.lat ? values.lat : 37.09024,
        lon: values.lon ? values.lon : -95.712891,
        pickupDate: moment(values.available_at_start).format('MM/DD/YYYY'),
        pickupTime: moment(values.available_at_start).format('HH:mm'),
        destinations: values.lane_preferences.length === 0 || (values.lane_preferences.length === 1 && values.lane_preferences[0].abbr === 'All') ? null : values.lane_preferences,
        truckTypes: values.truckTypes ? values.truckTypes : [],
        advantageId: isDemoAdvantageId() ? "32m9lbe4" : muiTheme.customerUniqueId,
        state: values.state ? values.state : false,
        zipCode: values.zipCode ? values.zipCode : false,
        city: values.city ? values.city : false,
        country: values.country ? values.country : "United States",
        visibility: 0,
        authenticated: isAuthenticated
        //createSavedSearch: createSavedSearch
      }
    }
    getNearbyLoads(searchPayload);
  }

  const searchByUnitedStates = () => {
    if (canUseGetNearbyLoadsV4()) {
      let searchPayload = {
        pickupDateFrom: moment().format('MM/DD/YYYY'),
        pickupTimeFrom: moment().format('HH:mm'),
        truckTypes: [],
        advantageId: isDemoAdvantageId() ? "32m9lbe4" : muiTheme.customerUniqueId,
        origins: createLocationsPayloadV4(values, 'origin', true),
        destinations: createLocationsPayloadV4(values, 'destination', true),
        authenticated: isAuthenticated
      }
      getNearbyLoads(searchPayload);
    } else {
      getNearbyLoads({
        advantageId: isDemoAdvantageId() ? "32m9lbe4" : muiTheme.customerUniqueId,
        lat: 37.09024,
        lon: -95.712891,
        pickupDate: moment().format('MM/DD/YYYY'),
        pickupTime: moment().format('HH:mm'),
        destinations: false,
        truckTypes: false,
        state: false,
        zipCode: false,
        city: false,
        country: "United States",
        visibility: 0,
        createSavedSearch: false,
        authenticated: isAuthenticated
      })
    }
  }

  const onMountGeolocationSearch = () => {
    // setSearchLoading(true)
    navigator.geolocation.getCurrentPosition((position) => {
      if (canUseGetNearbyLoadsV4()) {
        const newOriginValues = {
          lat: position.coords.latitude,
          lon: position.coords.longitude,
          state: '',
          zipCode: '',
          city: '',
          country: "United States",
          originSearchType: "default",
          destinationSearchType: "anywhere"
        }
        let searchPayload = {
          pickupDateFrom: moment().format('MM/DD/YYYY'),
          pickupTimeFrom: moment().format('HH:mm'),
          truckTypes: [],
          advantageId: isDemoAdvantageId() ? "32m9lbe4" : muiTheme.customerUniqueId,
          origins: createLocationsPayloadV4(newOriginValues, 'origin'),
          destinations: createLocationsPayloadV4(values, 'destination', true),
          authenticated: isAuthenticated
        }
        getNearbyLoads(searchPayload);
      } else {
        getNearbyLoads({
          advantageId: isDemoAdvantageId() ? "32m9lbe4" : muiTheme.customerUniqueId,
          lat: position.coords.latitude,
          lon: position.coords.longitude,
          pickupDate: moment().format('MM/DD/YYYY'),
          pickupTime: moment().format('HH:mm'),
          destinations: false,
          truckTypes: false,
          state: false,
          zipCode: false,
          city: false,
          country: false,
          visibility: 0,
          createSavedSearch: false,
          authenticated: isAuthenticated
        })
      }
    }, (error) => { // If allow location disabled, do a generic united states search 
      searchByUnitedStates();
    },
    )
  }

  useEffect(() => {
    setDisplayTime(moment().fromNow())
    const timer = setInterval(() => {
      setDisplayTime(moment(listLastFetch).fromNow())
    }, 60000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, [listLastFetch]);

  useEffect(() => {
    if (loadList) {
      loadList = loadList.map((load) => modifyLoadObject(load))
      setListLastFetched(moment())
      setLoadsStack([loadList])
      setCurrentLoadList(loadList)
      setListMessage(message)
      setReloadCrumbs([])
      setCurrentLoadListCount(loadList.length)

      if (selectedSort !== null && selectedSort !== 0) {
        onSelectSort(selectedSort, loadList)
      }
      setOriginalLoadList(JSON.parse(JSON.stringify(loadList)))
      sendGtagEvent(
        "search_results_returned",
        {
          screen: "find_and_book_loads",
          listLength: loadList.length,
          type: "loads",
        }
      )

      //wait for loads to be returned before opening the tour
      // if(process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" && !loadingPortal){
      //   if(localStorage.getItem('2zr5ys19_Tutorial_Seen') != 'true' ){
      //     setTimeout(() => {
      //       localStorage.setItem('2zr5ys19_Tutorial_Seen', 'true')
      //       setIsOpen(true)
      //     }, 1000)
      //   }
      // }

    }

  }, [loadList])

  useEffect(() => {
    setNumOfPaginationPages(Math.ceil(currentLoadListCount / itemsPerPage))
  }, [currentLoadListCount])

  useEffect(() => {
    if (loadsStack.length > 0) {
      setCurrentLoadList(loadsStack[loadsStack.length - 1])
      setCurrentLoadListCount(loadsStack[loadsStack.length - 1].length)
      setOriginalLoadList(JSON.parse(JSON.stringify((loadsStack[loadsStack.length - 1]))))
      if (selectedSort !== null && selectedSort !== 0) {
        onSelectSort(selectedSort, loadsStack[loadsStack.length - 1])
      }
    }

  }, [loadsStack])

  useEffect(() => {
    if (reloadCrumbs.length <= 0)
      setReloadsOpen(false)
    else
      setReloadsOpen(true)
  }, [reloadCrumbs])


  const pushReloads = (parentLoad, reloads) => {
    let tmpLoadOrigin = {
      ...parentLoad.stops[0],
      date: parentLoad.pickupDateTimeLatest ? parentLoad.pickupDateTimeLatest : parentLoad.pickupDateTime
    }
    let tmpLoadDestination = {
      ...parentLoad.stops[parentLoad.stops.length - 1],
      date: parentLoad.deliveryDateTime
    }
    if (reloadCrumbs.length <= 0) {
      setReloadCrumbs([
        tmpLoadOrigin,
        tmpLoadDestination
      ])
    } else {
      setReloadCrumbs([
        ...reloadCrumbs,
        tmpLoadDestination
      ])
    }
    setLoadsStack([
      ...loadsStack,
      reloads
    ])


    sendGtagEvent(
      "search_results_returned",
      {
        screen: "find_and_book_loads",
        listLength: reloads.length,
        type: "reloads"
      }
    )
  }

  const popToIndex = (index) => {
    let newLoadsStack = loadsStack.slice(0, index + 1)
    let newReloadCrumbs = reloadCrumbs.slice(0, index + 1)
    setLoadsStack(newLoadsStack)
    if (index === 0)
      setReloadCrumbs([])
    else
      setReloadCrumbs(newReloadCrumbs)
  }

  const onSelectSort = (value, loadList) => {
    let tempLoads
    if (value === sortSelection.BestMatch) {
      setSelectedSort(sortSelection.BestMatch)
      tempLoads = JSON.parse(JSON.stringify(originalLoadList))
      sendGtagEvent(
        "sort_loads_best_match",
        {
          listLength: loadList.length
        }
      )
    } else if (value === sortSelection.Deadhead) {
      setSelectedSort(sortSelection.Deadhead)
      tempLoads = loadList.sort((a, b) => {
        return (
          (a.originCity === b.originCity) &&
          (a.originState === b.originState) &&
          (a.destinationCity === b.destinationCity) &&
          (a.destinationState === b.destinationState)
        ) ?
          moment(a.pickupDateTime).valueOf() - moment(b.pickupDateTime).valueOf()
          :
          a.deadHead - b.deadHead
      })
      sendGtagEvent(
        "sort_loads_deadhead",
        {
          listLength: loadList.length
        }
      )
    } else if (value === sortSelection.Pickup) {
      setSelectedSort(sortSelection.Pickup)
      tempLoads = loadList.sort((a, b) => {
        return moment(a.pickupDateTime).valueOf() - moment(b.pickupDateTime).valueOf()
      })
      sendGtagEvent(
        "sort_loads_pickup_datetime",
        {
          listLength: loadList.length
        }
      )
    }

    setCurrentLoadList(tempLoads)
    setTimeout(() => setRefreshList(!refreshList), 0)

  }

  const getCurrentPickupLocation = () => {

    if (!reloadsOpen) {
      return (!pickupLocation || pickupLocation.indexOf('false') > -1) ? "" : "near " + pickupLocation
    } else if (reloadCrumbs[reloadCrumbs.length - 2]) {
      let parentLoad = reloadCrumbs[reloadCrumbs.length - 2]
      return "near " + parentLoad.city + ", " + parentLoad.state
    }
  }

  const handleRefetchList = () => {
    if (validateSearchBarContents(values)) { // Use contents from searchbar
      searchWithLastSearchbarContents()
    } else if (lastSearchPayload) { // Use contents from last successful search call
      lastSearchPayload.authenticated = isAuthenticated
      getNearbyLoads(lastSearchPayload)
    } else if (navigator.geolocation && process.env.REACT_APP_ADVANTAGE_ID !== "a98db973") {
      onMountGeolocationSearch()
    } else {
      searchByUnitedStates() // Use generic United States Call
    }
  }


  const bannersDesktop = () => {
    if (process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" && bannerAd) {

      return (
        <div style={{ paddingRight: 4, paddingBottom: FILTER_PADDING_TOP - 57, paddingTop: 15 }}>
          <a class="mySlides" href={bannerAd.href} target="_blank" onClick={() => handleBannerClick()}>
            <img src={bannerAd.image} alt={bannerAd.alt} style={{ width: '100%', marginBottom: 5 }} />
          </a>
        </div>
      )
    } else {
      return (<div style={{ paddingTop: FILTER_PADDING_TOP - 1 }} >

      </div>)
    }
  }

  const bannersMobile = () => {
    if (process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" && bannerAd) {
      return (
        <div >
          <a class="mySlides" href={bannerAd.href} target="_blank">
            <img src={bannerAd.image} alt={bannerAd.alt} style={{ width: '100%', marginBottom: 5 }} />
          </a>
        </div>
      )
    } else {
      return null
    }
  }

  const showSlides = (type = 0) => {
    setBannerAd(bannerLinks[type])
    let typeNext = 1;
    if (type == 1) {
      typeNext = 0;
    }


    setTimeout(
      () => { showSlides(typeNext) },
      BANNER_TIMINGS
    );
  }

  return (
    <div style={{ height: '100%' }}>

      <div style={{ height: '100%' }}>
        {loadingPortal && <LoadingOverlay />}
        {!isMobile &&
          <div className={classes.container}>

            <Row style={{ height: '100%' }}>
              {currentLoadList.length > 0 &&
                <Col
                  md={2}
                  style={{
                    backgroundColor: 'white',
                    height: '100%'
                  }}
                >
                  {bannersDesktop()}
                  <FilterMenu loadList={currentLoadList} paddingTop={FILTER_PADDING_TOP} />
                </Col>
              }

              <Col md={currentLoadList.length > 0 ? 10 : 12} className={classes.flexContainer} style={{ height: '100%' }}>
                <div className={classes.flexItem} style={{ paddingLeft: 10, paddingBottom: 40 }}>
                  <div data-tut="reactour_LoadSearchBar">
                    <LoadSearchBar />
                  </div>
                </div>
                {currentLoadList && currentLoadList.length > 0 &&
                  <div className={classes.flexItem}>

                    <Col style={{ height: "100%", width: "75%", display: "inline-flex", paddingLeft: 15, alignItems: 'flex-end', margin: 0 }}>
                      <p style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginRight: 15, marginBottom: 0, paddingBottom: 0, whiteSpace: 'nowrap', color: 'black', fontWeight: 'bold', fontSize: 25, lineHeight: '25px' }}>
                        {process.env.REACT_APP_ADVANTAGE_ID !== "a98db973" ? currentLoadListCount : null} Premium load{currentLoadListCount > 1 ? "s" : ""} for you {getCurrentPickupLocation()}
                      </p>
                      {!reloadsOpen &&
                        <div style={{ display: 'flex' }}>
                          <p style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', fontSize: 16, lineHeight: '20px' }}>
                            List last refreshed {displayTime}
                          </p>
                          <a className={classes.refreshButton} onClick={() => handleRefetchList()}>
                            <FA name='sync' style={{ fontSize: 16, color: muiTheme.actionColor, padding: 0 }} />
                          </a>
                        </div>
                      }
                    </Col>

                    <Col style={{ height: "100%", width: "25%", display: "inline-block", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end", paddingRight: 35, position: 'relative' }}>
                      <div style={{ position: 'absolute', bottom: 0, right: 15 }}>
                        {/* <p style={{ display: 'inline-block', paddingRight: 15, paddingBottom: 7, verticalAlign: 'bottom', color: 'black', fontWeight: 'bold' }}>Sort By:</p> */}
                        <FormControl>
                          {/* <InputLabel id="sort-by-label">Sort By</InputLabel> */}
                          <FormHelperText style={{ fontSize: 12 }}>{selectedSort == 1 ? "lowest to highest" : selectedSort == 2 ? "earliest to latest" : "sorted by"}</FormHelperText>
                          <Select
                            className={classes.sortSelectItem}
                            onChange={(event) => { onSelectSort(event.target.value, currentLoadList) }}
                            displayEmpty
                            disableUnderline={true}
                            style={{ minWidth: 215 }}
                            value={selectedSort}
                            defaultValue={sortSelection.BestMatch}
                            inputProps={{
                              classes: {
                                icon: classes.icon
                              },
                              ariaLabel: selectedSort == 1 ? "lowest to highest" : selectedSort == 2 ? "earliest to latest" : "sorted by"
                            }}
                          >
                            <MenuItem value={0} style={{ fontSize: 14 }}>Best Match</MenuItem>
                            <MenuItem value={1} style={{ fontSize: 14 }}>Distance from search location</MenuItem>
                            <MenuItem value={2} style={{ fontSize: 14 }}>Pickup date {"&"} time</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </Col>
                  </div>
                }
                {reloadsOpen &&
                  <div dataComponent='ReloadsBreadCrumbs' className={classes.flexItem} style={{ paddingBottom: 0 }}>
                    <div style={{ paddingLeft: 15 }}>
                      {
                        reloadCrumbs.map((crumb, index) => {
                          return (
                            <span key={'reloads_crumb_' + index}>

                              {(index < reloadCrumbs.length - 1) &&
                                <span>
                                  <div style={{ display: 'inline-block' }}>
                                    <span className={classes.breadcrumbLink} onClick={() => popToIndex(index)}>
                                      {crumb.city + ', ' + crumb.state}
                                    </span>
                                    <p className={classes.secondaryText}>
                                      {moment(crumb.date).format('MM/DD')}
                                    </p>
                                  </div>
                                  <FA name='chevron-right' size="lg" color={"black"} style={{ marginRight: 15, verticalAlign: 'top' }} />

                                </span>
                              }

                              {index === reloadCrumbs.length - 1 &&

                                <div style={{ display: 'inline-block' }}>
                                  <span className={classes.activeBreadcrumbLink} onClick={() => popToIndex(index)}>
                                    {crumb.city + ', ' + crumb.state}
                                  </span>
                                  <p className={classes.secondaryText}>
                                    {moment(crumb.date).format('MM/DD')}
                                  </p>
                                </div>


                              }
                            </span>
                          )
                        })
                      }
                    </div>
                  </div>
                }
                {(currentLoadList && currentLoadList.length > 0) ?
                  <div className={classes.flexItem} style={{ maxHeight: '100%', overflow: 'hidden', paddingBottom: numOfPaginationPages > 1 ? 5 : 45 }}>
                    <CarrierLoadList
                      {...props}
                      key={refreshList}
                      unfilterLoadList={currentLoadList}
                      availability={availability} //todo: may not need this
                      pushReloads={pushReloads}
                      reloadsOpen={reloadsOpen}
                      setCurrentLoadListCount={setCurrentLoadListCount} //used for when a filter is applied
                      autoHeight
                      isPaginationEnabled={isPaginationEnabled}
                      setPageNumber={setCurrentLoadPageNumber}
                      pageNumber={currentLoadPageNumber}
                      itemsPerPage={itemsPerPage}
                    />
                  </div>
                  :
                  <div className={classes.flexItem}>
                    <p data-component="find&book-no-loads-found" style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                      {listMessage ? listMessage : "No Loads Found"}
                    </p>
                  </div>
                }
                {(isPaginationEnabled && currentLoadList && currentLoadList.length > 0 && numOfPaginationPages > 1) && (
                  <div className={classes.flexItem} style={{ paddingBottom: 10, paddingTop: 10, paddingLeft: 5 }}>
                    <ListPagination
                      numberOfPages={numOfPaginationPages}
                      pageNumber={currentLoadPageNumber}
                      setPageNumber={setCurrentLoadPageNumber}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </div>
        }
        {isMobile &&
          <div style={{ height: '100%' }}>
            {bannersMobile()}
            {currentLoadList.length > 0 &&
              <Row style={{ height: 120, marginLeft: 10, marginRight: 10 }}>
                <Row>
                  <Col style={{ display: 'inline-block', float: 'left' }}>
                    <div data-tut-mobile="reactour_mobile_LoadSearchBar">
                      <LoadSearchMobileWrapper />
                    </div>
                  </Col>
                  <Col style={{ display: 'inline-block', float: 'right' }}>
                    <FilterMenu loadList={currentLoadList} paddingTop={FILTER_PADDING_TOP} mobileView={true} />
                  </Col>
                </Row>
                <Row style={{ marginBottom: 8 }}>
                  <Col style={{ width: "35%", height: "100%", position: 'relative', display: "inline-block", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-start", paddingLeft: 0 }}>
                    <p style={{ position: 'absolute', top: 10, marginBottom: 0, paddingBottom: 0, color: 'black', fontSize: 14, fontWeight: 'bold' }}>{currentLoadListCount} loads {pickupLocation ? "near " + pickupLocation : null}</p>
                  </Col>
                  <Col style={{ display: 'inline-block', float: 'right', justifyContent: "flex-end" }}>
                    <div style={{ flex: 1, flexDirection: 'column', alignSelf: 'center', marginTop: 25 }}>
                      <p style={{ textAlign: 'right', paddingRight: 5 }}>
                        <p style={{ fontSize: 12, color: 'grey' }}>{selectedSort == 1 ? "lowest to highest" : selectedSort == 2 ? "earliest to latest" : "sorted by"} </p>
                      </p>
                      <div style={{ flex: 1, flexDirection: 'row', alignSelf: 'flex-end', display: 'inline-block' }}>
                        <p
                          onClick={() => onSelectSort(0, currentLoadList)}
                          style={{
                            fontSize: 16,
                            padding: 5,
                            color: (selectedSort === 0 || selectedSort === null) ? muiTheme.primary : muiTheme.actionColor,
                            textDecorationLine: (selectedSort === 0 || selectedSort === null) ? "underline" : "none",
                            display: 'inline-block'
                          }}>
                          Best
                        </p>
                        <p style={{ fontSize: 16, paddingTop: 5, paddingBottom: 5, display: 'inline-block' }}>|</p>
                        <p
                          onClick={() => onSelectSort(1, currentLoadList)}
                          style={{
                            fontSize: 16,
                            padding: 5,
                            color: selectedSort === 1 ? muiTheme.primary : muiTheme.actionColor,
                            textDecorationLine: selectedSort === 1 ? "underline" : "none",
                            display: 'inline-block'
                          }}>
                          Deadhead
                        </p>
                        <p style={{ fontSize: 16, paddingTop: 5, paddingBottom: 5, display: 'inline-block' }}>|</p>
                        <p
                          onClick={() => onSelectSort(2, currentLoadList)}
                          style={{
                            fontSize: 16,
                            padding: 5,
                            color: selectedSort === 2 ? muiTheme.primary : muiTheme.actionColor,
                            textDecorationLine: selectedSort === 2 ? "underline" : "none",
                            display: 'inline-block'
                          }}>
                          Pickup
                        </p>
                      </div>

                    </div>

                  </Col>
                </Row>
              </Row>
            }
            {(currentLoadList && currentLoadList.length > 0) ?
              <Row style={{ height: `calc(${numOfPaginationPages > 1 ? 'calc(100% - 70px)' : '100%'} - ${process.env.REACT_APP_ADVANTAGE_ID == "dd4udt1b" ? '170px' : '120px'})` }}>
                <CarrierLoadList
                  {...props}
                  key={refreshList}
                  unfilterLoadList={currentLoadList}
                  availability={availability} //todo: may not need this
                  pushReloads={pushReloads}
                  reloadsOpen={reloadsOpen}
                  setCurrentLoadListCount={setCurrentLoadListCount} //used for when a filter is applied
                  autoHeight
                  isPaginationEnabled={isPaginationEnabled}
                  setPageNumber={setCurrentLoadPageNumber}
                  pageNumber={currentLoadPageNumber}
                  itemsPerPage={itemsPerPage}
                  isMobile
                />
              </Row>
              :
              <div style={{ paddingTop: 50 }}>
                <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                  {listMessage ? listMessage : "No Loads Found"}
                </p>
                <Col style={{ textAlign: 'center' }}>
                  <div data-tut-mobile="reactour_mobile_LoadSearchBar">
                    <LoadSearchMobileWrapper buttonColor={muiTheme.actionColor} />
                  </div>
                </Col>
              </div>
            }
            {(isPaginationEnabled && currentLoadList && currentLoadList.length > 0 && numOfPaginationPages > 1) && (
              <Row style={{ height: '50px', paddingTop: 10, paddingBottom: 10 }}>
                <ListPagination
                  numberOfPages={numOfPaginationPages}
                  pageNumber={currentLoadPageNumber}
                  setPageNumber={setCurrentLoadPageNumber}
                  isMobile
                  screenWidth={width}
                />
              </Row>
            )}
          </div>
        }
      </div>
    </div>
  )
}


const mapStateToProps = state => ({
  loads: state.AvailabilityLoads.payload.loads,
  loadList: state.CarrierPortal.search.loadList,
  message: state.CarrierPortal.search.message,
  lastSearchPayload: state.CarrierPortal.search.lastSearchPayload,
  pickupLocation: state.CarrierPortal.search.pickupLocation,
  loadingPortal: state.CarrierPortal.search.requesting,
  availability: state.AvailabilityLoads.payload.availability,
  loading: state.AvailabilityLoads.requesting,
  error: !state.AvailabilityLoads.successful,
  emailSendSuccess: state.AvailabilityLoads.emailSend.successful,
  emailSending: state.AvailabilityLoads.emailSend.requesting,
  values: state.CarrierPortal.components.loadSearchBar,
  userProfile: state.Profile.profileData,

})

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    sendInterestEmail: sendInterestEmail,
    resetEmailSendReducer: resetEmailSendReducer,
    getNearbyLoads: getNearbyLoads,
    resetLoadList: resetLoadList,
    setSearchLoading: setSearchLoading
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, matchDispatchToProps),
  withState('selectedLoads', 'updateSelectedLoads', false),
  withState('messageOpen', 'updateMessageOpen', false),
  muiThemeable(),
  isMobile()
  //withState('showSearchbox','toggleSearchbox',true)
)(COMPONENT_NAME)

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}