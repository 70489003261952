import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import { Fade } from "@material-ui/core";
import ContentCopy from 'material-ui/svg-icons/content/content-copy'
import Check from 'material-ui/svg-icons/navigation/check'


const CopyContentsIconButton = (props) => {

    const {
        content = '',
        isMobile = false,
        muiTheme
    } = props

    const [isCopied, setIsCopied] = useState(false)

    const useStyles = makeStyles({

    });

    const classes = useStyles();


    const handleClick = () => {
        if (!isCopied) {
            setIsCopied(true)
            setTimeout(() => setIsCopied(false), 10000);
        }
    }

    // {<ContentCopy color={muiTheme.actionColor} size={'small'} />}

    return (
        <Button
            size="small"
            endIcon={
                isCopied ?
                    <Check color={muiTheme.actionColor} size="small" />
                    :
                    <Fade in={!isCopied}>{<ContentCopy color={muiTheme.actionColor} size="small" />}</Fade>
            }
            variant="text"
            style={{
                color: muiTheme.actionColor,
                borderColor: muiTheme.actionColor,
                padding: 0
            }}
            onClick={() => { navigator.clipboard.writeText(content); handleClick() }}
        />
    )
}

export default compose(
    muiThemeable()
)(CopyContentsIconButton)