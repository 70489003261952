import React, { useEffect, useState, useRef } from 'react';
import { compose } from 'recompose'
import isMobile from '../HOC/Mobile.js'
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { useAuthenticationHook } from '../CarrierPortal/hooks/authentication-hook';
import { decodeToken } from "react-jwt";
import moment from 'moment'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth0 } from "@auth0/auth0-react";
import LoadingOverlay from '../components/LoadingOverlay.js';
import { PostRemoteLog } from '../CarrierPortal/apis/api-remote-log.js';
import { isAuthenticated } from '../lib/check-auth.js';

import {
    isTablet
} from "react-device-detect"

const AuthenticationWatcher = (props) => {

    const {
        children,
        muiTheme,
        isMobile
    } = props

    const useStyles = makeStyles((theme) => ({
        paper: {
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: (isTablet == false && isMobile == true) ? '100%' : 400,
            maxHeight: 240,
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #b2b2b2',
            boxShadow: theme.shadows[5],
            padding: '24px 16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            gap: 16,
            '&:focus-visible': {
                outline: 'unset'
              },
        },
        title: {
            fontSize: 22,
            fontWeight: 'bold'
        },
        text: {
            fontSize: 16,
            textAlign: 'center'
        },
        closeIconButton: {
            position: 'absolute',
            top: 5,
            right: 5,
            float: 'right',
            '& .MuiSvgIcon-root': {
                fontSize: '2.5rem'
            }
        },
        modal: {
            backdropFilter: 'unset',
            backgroundColor: 'rgba(0, 0, 0, 0.5);'
        },
    }))

    const classes = useStyles();
    const myAuthService = useAuthenticationHook()
    const TestEnv = false; // set to true to demo the modals to popup soon after logging in
    const INTERVAL_TIME =  5000 // Inteval auth checker every 5 seconds
    const { getAccessTokenSilently } = useAuth0(); // Used for non-werner authentication handling

    const history = useHistory();
    const [open, setOpen] = useState(localStorage.getItem('userTimedOut') ? true : false);
    const [userState, setUserState] = useState(localStorage.getItem('userTimedOut') ? "timedOut" : "warning")
    const [modalSeen, setModalSeen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [ms, setMs] = useState(null)
    const msRef = useRef(null)

    const handleClose = (refreshTriggered = false) => {
        setOpen(false)
        if (userState == "warning") {
            setModalSeen(refreshTriggered ? false : true)
        } else if (userState == "timedOut") {
            setUserState("warning")
            setModalSeen(false)
            localStorage.removeItem('userTimedOut')
        }
        setIsLoading(false)
    };

    const updateLocalTokenParameters = (newToken, newTokenObject, localTokenObject, tokenType = 'Access') => {
        //Refresh token is different than the access token and Id token
        if (tokenType == 'Refresh') {
            localTokenObject.value = newToken
            localTokenObject.refreshToken = newToken
        } else {
            localTokenObject.value = newToken
            if (tokenType == "Access") {
                localTokenObject.accessToken = newToken
            } else {
                localTokenObject.idToken = newToken
            }
            localTokenObject.claims = newTokenObject
            localTokenObject.expiresAt = newTokenObject.exp
        }
    }

    const auth0RefreshToken = async () => {
        const RefreshAccessToken = await getAccessTokenSilently({
            ignoreCache: true
        });
        localStorage.setItem('token', JSON.stringify(RefreshAccessToken))
        setIsLoading(false)
        window.location.reload(false);
    }

    const wernerRefreshToken = (urlEncodedPayload) => {
        const url = process.env.REACT_APP_OKTA_ISSUER + '/v1/token'
        try {
            let requestObject = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: urlEncodedPayload
            }

            return fetch(url, requestObject)
                .then(response => {
                    if (response.status !== 200) {
                        const error = new Error(response.status);
                        error.response = response;
                        throw error;
                    }
                    return response.json();
                })
                .catch(error => {
                    let payload = {
                        source: "Refreshing Okta tokens",
                        message: {
                            error: JSON.stringify(error)
                        },
                    }
                    console.log("wernerRefreshToken fetch Error: ", error)
                    toast.error("There was an issue with your log in session. Please log out and log back in. ", error)
                    setIsLoading(false)
                    PostRemoteLog(payload).then(response => {
                    }).catch((error) => {
                    })
                })
        } catch (error) {
            let payload = {
                source: "wernerRefreshToken Refreshing Okta tokens",
                message: {
                    error: JSON.stringify(error)
                },
            }
            console.log(" wernerRefreshToken Error: ", error)
            toast.error("There was an issue with your log in session. Please log out and log back in. ", error)
            setIsLoading(false)
            PostRemoteLog(payload).then(response => {
            }).catch((error) => {

            })
        }

    }

    const handleRefreshToken = () => {
        setIsLoading(true)
        if (process.env.REACT_APP_ADVANTAGE_ID === "a98db973") {
            let wernerLocalTokens = localStorage.getItem('werner_tokens')
            let wernerLocalTokensParsed = JSON.parse(wernerLocalTokens)
            let localToken = localStorage.getItem('token')
            let localTokenParsed = JSON.parse(localToken)
            if (wernerLocalTokensParsed && wernerLocalTokensParsed.refreshToken && wernerLocalTokensParsed.refreshToken.value) {
                let refreshToken = wernerLocalTokensParsed.refreshToken.value
                //put together the x-www-form-urlencoded payload
                let payload = {
                    'refresh_token': refreshToken,
                    'grant_type': 'refresh_token',
                    'scope': 'openid offline_access',
                    'client_id': process.env.REACT_APP_OKTA_CLIENT_ID
                }

                let urlEncodeFormatPayload = []
                for (let key in payload) {
                    let encodedKey = encodeURIComponent(key)
                    let encodedValue = encodeURIComponent(payload[key])
                    urlEncodeFormatPayload.push(encodedKey + "=" + encodedValue);
                }
                urlEncodeFormatPayload = urlEncodeFormatPayload.join("&");
                wernerRefreshToken(urlEncodeFormatPayload)
                    .then(response => {

                        if (response) {
                            // Update existing token values within our local token object

                            /* Update Local Werner Token*/
                            let decodedAccessToken = decodeToken(response.access_token)
                            let decodedIdToken = decodeToken(response.id_token)

                            updateLocalTokenParameters(response.access_token, decodedAccessToken, wernerLocalTokensParsed.accessToken, 'Access')
                            updateLocalTokenParameters(response.id_token, decodedIdToken, wernerLocalTokensParsed.idToken, 'Id')
                            updateLocalTokenParameters(response.refresh_token, null, wernerLocalTokensParsed.refreshToken, 'Refresh') // refresh token is not decoded
                            localStorage.setItem('werner_tokens', JSON.stringify(wernerLocalTokensParsed));

                            let payload = {
                                tokens: wernerLocalTokensParsed,
                                selectedCarrier: {
                                    carrierIdent: localTokenParsed.CarrierExternalId,
                                    carrierName: localTokenParsed.companyName,
                                    isApproved: localTokenParsed.isApproved,
                                    roles: [localTokenParsed.role],
                                    contactName: localTokenParsed.name,
                                    mcOrDot: (localTokenParsed.mcNumber & localTokenParsed.mcNumber != "") ? localTokenParsed.mcNumber : localTokenParsed.dotNumber,
                                    email: localTokenParsed.email
                                }
                            }

                            /* Update Local token id */
                            myAuthService.returnInternalOktaJwtToken(payload)
                                .then(response => {
                                    if (response.id) {
                                        localTokenParsed.id = response.id
                                        localStorage.setItem('token', JSON.stringify(localTokenParsed));
                                        let refreshTriggered = true
                                        handleClose(refreshTriggered)
                                    } else {
                                        toast.error("There was an issue with your log in session. Please log out and log back in: ", response.message)
                                        console.log(response.message)
                                        setIsLoading(false)
                                    }
                                })
                                .catch(error => {
                                    console.log("handleRefreshToken Error: ", error)
                                    toast.error("There was an issue with your log in session. Please log out and log back in: ", error)
                                    setIsLoading(false)
                                })
                        } else {
                            toast.error("There was an issue with your log in session. Please log out and log back in. ")
                            setIsLoading(false)
                        }

                    }).catch((error) => {
                        console.log("handleRefreshToken Error: ", error)
                        toast.error("There was an issue with your log in session. Please log out and log back in: ", error)
                        setIsLoading(false)
                    })
            }else{
                handleClose()
                console.log("Error: Invalid token parameters")
                toast.error("There was an issue with your log in session. Please log out and log back in.")
                setIsLoading(false)
            }
        } else {
            setIsLoading(false)
            auth0RefreshToken()
        }
    }

    const handleLogout = () => {
        if(open){
            handleClose()
        }
        msRef.current = null
        history.push("/loads");
        localStorage.setItem('userTimedOut', true)
        myAuthService.logout()
        let signoutWatcher
        signoutWatcher = setInterval(() => {
            if (myAuthService.isAuthenticated() !== true) {
                clearInterval(signoutWatcher)
                window.location.reload(false)
            }
        }, 1000);
    }

    useEffect(() => {
        const authenticationIntervalTimer = setInterval(() => {
            if (myAuthService.isAuthenticated()) {
                let expireDateTime;
                if (process.env.REACT_APP_ADVANTAGE_ID === "a98db973") {
                    const wernerLocalTokens = localStorage.getItem('werner_tokens')
                    const wernerLocalTokensParsed = JSON.parse(wernerLocalTokens)
                    if (wernerLocalTokensParsed && wernerLocalTokensParsed.accessToken && wernerLocalTokensParsed.accessToken.value) {
                        const access_token = wernerLocalTokensParsed.accessToken.value
                        let local_token = decodeToken(access_token)
                        expireDateTime = local_token.exp
                    }
                } else {
                    const generalLocalToken = localStorage.getItem('token')
                    let local_token = decodeToken(generalLocalToken)
                    expireDateTime = local_token.exp
                }

                let expirationMoment = moment(expireDateTime * 1000) //Needs to be milliseconds to work with moment library
                let currentMoment = moment()

                setMs(moment(expirationMoment, "DD/MM/YYYY HH:mm:ss").diff(moment(currentMoment, "DD/MM/YYYY HH:mm:ss")) - 300000)
            }else{
                if( (process.env.REACT_APP_ADVANTAGE_ID === "a98db973") ? (msRef.current && msRef.current <= (TestEnv ? 3280000 : 300000  )) : (msRef.current && msRef.current <= (TestEnv ? 86070000  : 300000))){ // Means user got logged out from another tab, need to reload page
                    history.push("/loads");
                    window.location.reload(false)
                }
            }
        }, INTERVAL_TIME); // Check every 5 seconds

        return () => {
            clearInterval(authenticationIntervalTimer)
        }
    }, [])

    useEffect(() => {
        if (ms && myAuthService.isAuthenticated()) {
            msRef.current = ms
            //The D and S variables are just used for display. 
            let d = moment.duration(ms) // D to display hours more than 24
            let s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss"); // display remaining minutes and seconds
            if (process.env.REACT_APP_ADVANTAGE_ID === "a98db973" ? (ms <= (TestEnv ? 3260000 : 0)) :  (ms <= (TestEnv ? 86050000 : 0))) { // if past ending time, auto-logout. Werner we change to trigger sooner for testing
                handleLogout()
            }else if (process.env.REACT_APP_ADVANTAGE_ID === "a98db973" ? (ms <= (TestEnv ? 3280000 : 300000  )) : (ms <= (TestEnv ? 86070000  : 300000))) { // If less than 5 minutes, display warning. Werner we change to trigger sooner for tesing
                if (modalSeen != true) {
                    setOpen(true)
                }
            }
        }
    }, [ms])

    const warningContent = (
        <div className={classes.paper}>
            <IconButton
                data-component="warning-modal-x"
                className={classes.closeIconButton}
                aria-label="close"
                onClick={() => handleClose()
                }>
                <CloseIcon />
            </IconButton>
            <p className={classes.title}> Log In Verification </p>
            <p className={classes.text}>Due to security purposes, your log in session will expire in 5 minutes. Click the button below to continue seeing rates, making quotes, and booking loads.</p>
            <Button
                data-component="warning-modal-close"
                size="small"
                variant="contained"
                style={{
                    backgroundColor: muiTheme.actionColor,
                    borderColor: muiTheme.actionColor,
                    color: "white",
                    fontSize: 14,
                    margin: 5
                }}
                onClick={() => handleRefreshToken()}
            >
                Stay Logged In
            </Button>
        </div>
    )

    const loggedOutContent = (
        <div className={classes.paper}>
            <IconButton
                data-component="logout-modal-x"
                className={classes.closeIconButton}
                aria-label="close"
                onClick={() => handleClose()
                }>
                <CloseIcon />
            </IconButton>
            <p className={classes.title}> Logged Out </p>
            <p className={classes.text}>Your log in session has expired due to security purposes. Please log back in to see rates, make quotes, and book loads.</p>
            <Button
                data-component="logout-modal-close" 
                size="small"
                variant="contained"
                style={{
                    backgroundColor: muiTheme.actionColor,
                    borderColor: muiTheme.actionColor,
                    color: "white",
                    fontSize: 14,
                    margin: 5
                }}
                onClick={() => handleClose()}
            >
                Close
            </Button>
        </div>
    )

    return (
        <div style={{ height: '100%' }}>
            {isLoading && <LoadingOverlay />}
            {children.map((child) => child)}
            <Modal
                open={open && !modalSeen}
                onClose={() => handleClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropComponent={Backdrop}
                className={classes.modal}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
                disableBackdropClick
            >
                {userState === "warning" ? warningContent : loggedOutContent}
            </Modal>
        </div>
    )

}

export default compose(
    muiThemeable(),
    isMobile()
)(AuthenticationWatcher)