import React, { useState, useCallback, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "recompose";
import TextField from "@material-ui/core/TextField";
import FA from "react-fontawesome";

//ACTIONS//
import { updateLoadSearchBar } from "../../../CarrierPortal/ActionReducers/components";

//COMPONENTS
import AddressAutocomplete from "./AddressAutocompleteV2LoadSearch";
import DateTimePicker from "./DateTimePickerV2LoadSearch";
import DateTimePickerNoTime from "./DateTimePickerV2LoadSearchNoTime";
import SearchTypeSelector from "./SearchTypeSelector";
import StateSelect from "./locationSelectTypes/StateSelectV2LoadSearch.js";
import ZoneSelect from "./locationSelectTypes/ZoneSelect";
import MarketSelect from "./locationSelectTypes/MarketSelect";
import TruckTypeSelect from "./TruckTypeSelectV2LoadSearch";
import TruckTypeSelectCondensed from "./TruckTypeSelectCondensed";
import { Form, Row, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import muiThemeable from "material-ui/styles/muiThemeable";
import { makeStyles } from "@material-ui/core/styles";
import { useAuthenticationHook } from "../../hooks/authentication-hook";
import {
  canSearchByLoadNumber,
  showCondensedTruckList,
  canUseGetNearbyLoadsV4,
  dontUseTimeSearch,
} from "../../helpers/permissions";

const useStyles = makeStyles({
  loadSearchButton: {
    backgroundColor: "white",
    // primaryColor:primary.main,
    justifyContent: "center",
    // outline: '1px solid black',
    minHeight: "45px",
    maxHeight: "45px",
    minWidth: "45px",
    maxWidth: "45px",
    left: "10%",
    top: "10%",
    border: "1px solid #ccc",
    shadowRadius: 5,
    shadowColor: "black",
    shadowOpacity: 0.1,
  },
  margin: {
    margin: 5,
    maxHeight: "40px",
  },
});

const LoadSearchBarMobile = ({
  values,
  pickupLocation,
  dropoffLocation,
  destinations,
  setDestinations,
  origins,
  setOrigins,
  pickupDateStart,
  pickupDateEnd,
  truckTypes,
  clearData,
  muiTheme,
  handleSearch,
  originSearchType,
  destinationSearchType,
  marketOptions,
  handleSearchByLoadNumber,
  clearSearch,
  updateLoadSearchBar,
  onHomePage,
}) => {
  const classes = useStyles();

  const myAuthService = useAuthenticationHook();

  const isAuthenticated = myAuthService.isAuthenticated();

  const [searchByLoadNumberForm, setSearchByLoadNumberForm] = useState(false);
  const [loadNumber, setLoadNumber] = useState(null);

  const originSearchInput = (type, existingValues) => {
    switch (type) {
      case "default":
        return (
          <AddressAutocomplete
            onChange={updateLoadSearchBar}
            value={pickupLocation}
            clearData={clearData}
            existingLocation={pickupLocation}
            searchComponentType={"origin"}
            mobileView
          />
        );
      case "state":
        return (
          <StateSelect
            allowSelectAll={true}
            handleSetSelectedStates={(value) => {
              updateLoadSearchBar(value, "lane_origins");
              setOrigins(value);
            }}
            existingStates={existingValues}
            clearData={clearData}
            searchComponentType={"origin"}
            needsRedux={false}
            mobileView
          />
        );
      case "zone":
        return (
          <ZoneSelect
            handleSetSelectedZone={(value) => {
              updateLoadSearchBar(value, "lane_origins");
              setOrigins(value);
            }}
            existingZones={existingValues}
            clearData={clearData}
            searchComponentType={"origin"}
            inputTransparentStyle={false}
            mobileView
          />
        );
      case "market":
        return (
          <MarketSelect
            handleSetSelectedMarkets={(value) => {
              updateLoadSearchBar(value, "lane_origins");
              setOrigins(value);
            }}
            existingMarkets={existingValues}
            clearData={clearData}
            searchComponentType={"origin"}
            marketOptions={marketOptions}
            mobileView
          />
        );
      case "anywhere":
        return (
          <StateSelect
            allowSelectAll={true}
            anywhereSearchType
            handleSetSelectedStates={(value) => {
              updateLoadSearchBar(value, "lane_origins");
              setOrigins(value);
            }}
            existingStates={existingValues}
            clearData={clearData}
            searchComponentType={"origin"}
            needsRedux={false}
            mobileView
          />
        );
      default:
        return null;
    }
  };

  const destinationSearchInput = (type, existingValues) => {
    switch (type) {
      case "default":
        return (
          <AddressAutocomplete
            onChange={updateLoadSearchBar}
            value={dropoffLocation}
            clearData={clearData}
            existingLocation={dropoffLocation}
            searchComponentType={"destination"}
            mobileView
          />
        );
      case "state":
        return (
          <StateSelect
            allowSelectAll={true}
            handleSetSelectedStates={(value) => {
              updateLoadSearchBar(value, "lane_preferences");
              setDestinations(value);
            }}
            existingStates={existingValues}
            clearData={clearData}
            searchComponentType={"destination"}
            needsRedux={false}
            mobileView
          />
        );
      case "zone":
        return (
          <ZoneSelect
            handleSetSelectedZone={(value) => {
              updateLoadSearchBar(value, "lane_preferences");
              setDestinations(value);
            }}
            existingZones={existingValues}
            clearData={clearData}
            searchComponentType={"destination"}
            inputTransparentStyle={false}
            mobileView
          />
        );
      case "market":
        return (
          <MarketSelect
            handleSetSelectedMarkets={(value) => {
              updateLoadSearchBar(value, "lane_preferences");
              setDestinations(value);
            }}
            existingMarkets={existingValues}
            clearData={clearData}
            searchComponentType={"destination"}
            marketOptions={marketOptions}
            mobileView
          />
        );
      case "anywhere":
        return (
          <StateSelect
            allowSelectAll={true}
            anywhereSearchType
            handleSetSelectedStates={(value) => {
              updateLoadSearchBar(value, "lane_preferences");
              setDestinations(value);
            }}
            existingStates={existingValues}
            clearData={clearData}
            searchComponentType={"destination"}
            needsRedux={false}
            mobileView
          />
        );
      default:
        return null;
    }
  };

  return (
    <Form>
      {!searchByLoadNumberForm ? (
        canUseGetNearbyLoadsV4() ? ( //Check whether or not to use old searchbar for bookaload
          <Col style={{ padding: 10 }}>
            <Row style={{ paddingBottom: "32px" }}>
              <SearchTypeSelector
                clearData={clearData}
                existingSearchType={originSearchType}
                searchComponentType={"origin"}
              />
              <FormControl style={{ width: "95%", margin: 0 }}>
                {originSearchInput(originSearchType, origins)}
              </FormControl>
            </Row>
            <Row>
              <FormControl style={{ width: "95%", paddingBottom: "32px" }}>
                {dontUseTimeSearch() != true ? (
                  <DateTimePicker
                    pendoInputClass="pendo-dateTimePicker-StartDate"
                    helperText={
                      "Enter the date and time you are available to pick up a load."
                    }
                    labelText={"Pickup Start Date"}
                    onChange={(dateTime) => {
                      updateLoadSearchBar(dateTime, "available_at_start");
                    }}
                    value={pickupDateStart}
                    clearData={clearData}
                    existingDate={pickupDateStart}
                    allowNullDate={false}
                    mobileView={true}
                  />
                ) : (
                  <DateTimePickerNoTime
                    pendoInputClass="pendo-dateTimePicker-StartDate"
                    helperText={
                      "Enter the date and time you are available to pick up a load."
                    }
                    labelText={"Pickup Start Date"}
                    onChange={(dateTime) => {
                      updateLoadSearchBar(dateTime, "available_at_start");
                    }}
                    value={pickupDateStart}
                    clearData={clearData}
                    existingDate={pickupDateStart}
                    allowNullDate={false}
                    mobileView={true}
                  />
                )}
              </FormControl>
            </Row>
            <Row>
              <FormControl style={{ width: "95%", paddingBottom: "32px" }}>
                {dontUseTimeSearch() != true ? (
                  <DateTimePicker
                    pendoInputClass="pendo-dateTimePicker-EndDate"
                    helperText={
                      "Enter the date and time you are available to pick up a load."
                    }
                    labelText={"Pickup End Date"}
                    onChange={(dateTime) => {
                      updateLoadSearchBar(dateTime, "available_at_end");
                    }}
                    value={pickupDateEnd}
                    clearData={clearData}
                    existingDate={pickupDateEnd}
                    allowNullDate={true}
                    mobileView={true}
                  />
                ) : (
                  <DateTimePickerNoTime
                    pendoInputClass="pendo-dateTimePicker-EndDate"
                    helperText={
                      "Enter the date and time you are available to pick up a load."
                    }
                    labelText={"Pickup End Date"}
                    onChange={(dateTime) => {
                      updateLoadSearchBar(dateTime, "available_at_end");
                    }}
                    value={pickupDateEnd}
                    clearData={clearData}
                    existingDate={pickupDateEnd}
                    allowNullDate={true}
                    mobileView={true}
                  />
                )}
              </FormControl>
            </Row>
            <Row>
              <FormControl style={{ width: "95%", paddingBottom: "32px" }}>
                {showCondensedTruckList() ? (
                  <TruckTypeSelectCondensed
                    clearData={clearData}
                    existingTruckTypes={truckTypes}
                    mobileView={true}
                  />
                ) : (
                  <TruckTypeSelect
                    clearData={clearData}
                    existingTruckTypes={truckTypes}
                    mobileView={true}
                  />
                )}
              </FormControl>
            </Row>

            <Row style={{ paddingBottom: "32px" }}>
              <SearchTypeSelector
                clearData={clearData}
                existingSearchType={destinationSearchType}
                searchComponentType={"destination"}
              />
              <FormControl style={{ width: "95%" }}>
                {destinationSearchInput(destinationSearchType, destinations)}
              </FormControl>
            </Row>

            <Row>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  data-cy="clear-search-button"
                  className={classes.margin}
                  style={{
                    fontSize: 14,
                    color: muiTheme.actionColor,
                    width: "max-content",
                  }}
                  onClick={() => clearSearch()}
                >
                  {" "}
                  Clear Search
                </Button>
              </Col>
            </Row>

            <Row style={{ display: "flex", justifyContent: "space-around" }}>
              {isAuthenticated &&
                process.env.REACT_APP_ADVANTAGE_ID !== "a98db973" &&
                canUseGetNearbyLoadsV4() == false && ( //BG - TO DO FIX FOR CRST!
                  <Button
                    onClick={() => handleSearch(true)}
                    variant={"outlined"}
                    className={classes.margin}
                    style={{
                      fontSize: 14,
                      color: muiTheme.actionColor,
                      backgroundColor: "white",
                      borderColor: muiTheme.actionColor,
                    }}
                  >
                    Save Preference
                  </Button>
                )}
            </Row>

            <Row>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  paddingTop: 5,
                }}
              >
                {isAuthenticated && !onHomePage && canSearchByLoadNumber() && (
                  <Button
                    onClick={() => setSearchByLoadNumberForm(true)}
                    variant={"outlined"}
                    size={"small"}
                    className={classes.margin}
                    style={{
                      fontSize: 14,
                      color: "white",
                      backgroundColor: muiTheme.actionColor,
                      borderColor: muiTheme.actionColor,
                    }}
                  >
                    Search By Load #
                  </Button>
                )}
                <Button
                  variant={"contained"}
                  size={"small"}
                  className={classes.margin}
                  style={{
                    fontSize: 14,
                    color: "white",
                    backgroundColor: muiTheme.actionColor,
                    marginRight: 15,
                  }}
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Col>
        ) : (
          <Col style={{ padding: 10 }}>
            <Row>
              <FormControl
                style={{
                  width: "95%",
                  paddingTop: "10px",
                  paddingBottom: "32px",
                }}
              >
                <AddressAutocomplete
                  onChange={updateLoadSearchBar}
                  value={pickupLocation}
                  clearData={clearData}
                  existingLocation={pickupLocation}
                  mobileView={true}
                />
              </FormControl>
            </Row>
            <Row>
              <FormControl style={{ width: "95%", paddingBottom: "32px" }}>
                {dontUseTimeSearch() != true ? (
                  <DateTimePicker
                    pendoInputClass="pendo-dateTimePicker-StartDate"
                    helperText={
                      "Enter the date and time you are available to pick up a load."
                    }
                    labelText={"Pickup Start Date"}
                    onChange={(dateTime) => {
                      updateLoadSearchBar(dateTime, "available_at_start");
                    }}
                    value={pickupDateStart}
                    clearData={clearData}
                    existingDate={pickupDateStart}
                    allowNullDate={false}
                    mobileView={true}
                  />
                ) : (
                  <DateTimePickerNoTime
                    pendoInputClass="pendo-dateTimePicker-StartDate"
                    helperText={
                      "Enter the date and time you are available to pick up a load."
                    }
                    labelText={"Pickup Start Date"}
                    onChange={(dateTime) => {
                      updateLoadSearchBar(dateTime, "available_at_start");
                    }}
                    value={pickupDateStart}
                    clearData={clearData}
                    existingDate={pickupDateStart}
                    allowNullDate={false}
                    mobileView={true}
                  />
                )}
              </FormControl>
            </Row>

            <Row>
              <FormControl style={{ width: "95%", paddingBottom: "32px" }}>
                {dontUseTimeSearch() != true ? (
                  <DateTimePicker
                    pendoInputClass="pendo-dateTimePicker-EndDate"
                    helperText={
                      "Enter the date and time you are available to pick up a load."
                    }
                    labelText={"Pickup End Date"}
                    onChange={(dateTime) => {
                      updateLoadSearchBar(dateTime, "available_at_end");
                    }}
                    value={pickupDateEnd}
                    clearData={clearData}
                    existingDate={pickupDateEnd}
                    allowNullDate={true}
                    mobileView={true}
                  />
                ) : (
                  <DateTimePickerNoTime
                    pendoInputClass="pendo-dateTimePicker-EndDate"
                    helperText={
                      "Enter the date and time you are available to pick up a load."
                    }
                    labelText={"Pickup End Date"}
                    onChange={(dateTime) => {
                      updateLoadSearchBar(dateTime, "available_at_end");
                    }}
                    value={pickupDateEnd}
                    clearData={clearData}
                    existingDate={pickupDateEnd}
                    allowNullDate={true}
                    mobileView={true}
                  />
                )}
              </FormControl>
            </Row>

            <Row>
              <FormControl style={{ width: "95%", paddingBottom: "32px" }}>
                {showCondensedTruckList() ? (
                  <TruckTypeSelectCondensed
                    clearData={clearData}
                    existingTruckTypes={truckTypes}
                    mobileView={true}
                  />
                ) : (
                  <TruckTypeSelect
                    clearData={clearData}
                    existingTruckTypes={truckTypes}
                    mobileView={true}
                  />
                )}
              </FormControl>
            </Row>

            <Row>
              <FormControl style={{ width: "95%", paddingBottom: "32px" }}>
                <StateSelect
                  onChange={(value) => {
                    updateLoadSearchBar(value, "lane_preferences");
                    setDestinations(value);
                  }}
                  destinations={destinations}
                  inputTransparentStyle={false}
                  mobileView={true}
                />
              </FormControl>
            </Row>

            <Row>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  data-cy="clear-search-button"
                  className={classes.margin}
                  style={{
                    fontSize: 14,
                    color: muiTheme.actionColor,
                    width: "max-content",
                  }}
                  onClick={() => clearSearch()}
                >
                  {" "}
                  Clear Search
                </Button>
              </Col>
            </Row>

            <Row style={{ display: "flex", justifyContent: "space-around" }}>
              {isAuthenticated &&
                process.env.REACT_APP_ADVANTAGE_ID !== "a98db973" && (
                  <Button
                    onClick={() => handleSearch(true)}
                    variant={"outlined"}
                    className={classes.margin}
                    style={{
                      fontSize: 14,
                      color: muiTheme.actionColor,
                      backgroundColor: "white",
                      borderColor: muiTheme.actionColor,
                    }}
                  >
                    Save Preference
                  </Button>
                )}
            </Row>

            <Row>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  paddingTop: 5,
                }}
              >
                {isAuthenticated && !onHomePage && canSearchByLoadNumber() && (
                  <Button
                    onClick={() => setSearchByLoadNumberForm(true)}
                    variant={"outlined"}
                    size={"small"}
                    className={classes.margin}
                    style={{
                      fontSize: 14,
                      color: "white",
                      backgroundColor: muiTheme.actionColor,
                      borderColor: muiTheme.actionColor,
                    }}
                  >
                    Search By Load #
                  </Button>
                )}
                <Button
                  variant={"contained"}
                  size={"small"}
                  className={classes.margin}
                  style={{
                    fontSize: 14,
                    color: "white",
                    backgroundColor: muiTheme.actionColor,
                    marginRight: 15,
                  }}
                  onClick={() => handleSearch()}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Col>
        )
      ) : (
        <Col
          style={{
            padding: 25,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => setSearchByLoadNumberForm(false)}
            style={{
              position: "absolute",
              top: 8,
              left: 15,
              zIndex: 10,
              backgroundColor: muiTheme.actionColor,
              minWidth: "auto",
              padding: "6px 16px",
            }}
            disableRipple={true}
          >
            <FA name="caret-left" size="2x" style={{ color: "white" }} />
          </Button>
          <TextField
            id="filled-basic"
            label={<span style={{ fontSize: 16 }}>Load Number *</span>}
            variant="filled"
            value={loadNumber}
            onChange={(event) => setLoadNumber(event.target.value)}
            InputProps={{
              style: {
                fontSize: 16,
              },
            }}
            style={{ marginTop: 50 }}
          />
          <Button
            onClick={() => handleSearchByLoadNumber(loadNumber)}
            variant={"outlined"}
            size={"small"}
            className={classes.margin}
            style={{
              fontSize: 14,
              color: "white",
              backgroundColor: muiTheme.actionColor,
              borderColor: muiTheme.actionColor,
              marginTop: 15,
            }}
          >
            Search
          </Button>
        </Col>
      )}
    </Form>
  );
};

const mapStateToProps = (state) => ({
  values: state.CarrierPortal.components.loadSearchBar,
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateLoadSearchBar: updateLoadSearchBar,
    },
    dispatch
  );
}
export default compose(
  muiThemeable(),
  connect(mapStateToProps, matchDispatchToProps)
)(LoadSearchBarMobile);
