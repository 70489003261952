
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import React from 'react';
import { Col } from "react-bootstrap";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getLocalDateTime, joinOfferDateTime } from '../../../../../../lib/date-functions';
import { numberWithCommas } from '../../../../../../lib/number-with-commas';


const RateNegotiationsListItemDesktop = (props) => {

    const {
        quote,
        getStatusIcon,
        getStatusText,
        useShowInterest
    } = props
    const classes = useStyles();
 
    return (
        <div style={{ display: 'flex', width: '100%', marginBottom: 4 }}>

            <Col style={{ flex: 1.5, display: 'inline-flex', paddingRight: 16 }}>
                <Col>
                    {quote.loadOffer.amount ?
                        <p className={classes.mainText}>${numberWithCommas(parseFloat(quote.loadOffer.amount).toFixed(2))}</p>
                        :
                        <p className={classes.mainText}><i>{useShowInterest && quote.loadOffer.amount == null ? "Shown Interest" : "No Offer Amount Given"}</i></p>
                    }
                    <p className={classes.secondaryText}>
                        {getLocalDateTime(joinOfferDateTime(quote.loadOffer), 'YYYY-MM-DD hh:mm A').format('MMM D HH:mm')}
                    </p>
                </Col>
                <Col style={{ justifyContent: 'flex-start', flexDirection: 'column', display: 'flex', marginLeft: 16 }}>
                    {getStatusIcon(quote.loadOffer)}
                </Col>
            </Col>
            <Col style={{ flex: 2, wordBreak: 'break-word', paddingRight: 16 }}>
                <p>
                    {getStatusText(quote.loadOffer)}
                </p>
            </Col>
            <Col style={{ flex: 2, wordBreak: 'break-word', paddingRight: 16 }}>
                <p>
                    {quote.loadOffer.message}
                </p>
            </Col>
        </div>
    );
}

export default compose(
    connect(),
    muiThemeable()
)(RateNegotiationsListItemDesktop)

const useStyles = makeStyles({
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '13px',
        lineHeight: '20px',
        color: 'grey',
        wordBreak: 'break-word'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        justifyContent: 'center'
    },
    button: {
        fontSize: 14,
        color: "white",
        margin: 5
    }
});