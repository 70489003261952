import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap';
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../../HOC/Mobile.js'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import FA from 'react-fontawesome'
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ResizeObserver } from 'resize-observer';
import TextField from '@material-ui/core/TextField';

import StopInformationDesktop from './stop-information-desktop';
import LineItemCharges from './line-item-charges'
import PaymentInformation from './payment-information-desktop'


const InvoiceFormDesktop = (props) => {
    const useStyles = makeStyles((theme) => ({
        mainText: {
            fontSize: '14px',
            lineHeight: '22px',
            color: 'black',
            fontWeight: 'bold',
            margin: 0
        },
        secondaryText: {
            fontSize: '14px',
            lineHeight: '22px',
            color: 'black',
            margin: 0,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            marginBottom: -5,
        },
        actionContainer: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            position: 'sticky',
            width: '100%',
            bottom: 0,
            backgroundColor: 'white',
            textAlign: 'center',
            margin: 0,
            marginTop: 50
        },
        MuiAccordionroot: {
            "&.MuiAccordion-root:before": {
                backgroundColor: "white"
            }
        },
        inputRoot: {
            fontSize: 16,
        },
        labelRoot: {
            fontSize: 16,
            // color: props.muiTheme.actionColor,
            "&$labelFocused": {
                //   color: props.muiTheme.actionColor
            }
        },
        labelFocused: {},

    }));
    const classes = useStyles();
    const {
        allowEdit, //tpdo
        allowSubmit,//tpdo
        load,
        invoice,
        arrivalTime,
        departureTime,
        updateDepartureTime,
        updateArrivalTime,
        lineItems,
        addLineItem,
        updateLineItem,
        removeLineItem,
        onFileUpload,
        removeLineItemDocument,
        submitInvoice,//tpdo
        muiTheme,
        editModeFlag,
        setEditModeFlag,
        hasDocuments = false,
        currentStatus,
        contactEmail,
        contactPhone,
        setContactEmail,
        setContactPhone,
        refresh,
    } = props

    const scrollContainer = useRef(null)

    const [showBoxShadow, setShowBoxShadow] = useState(true)

    const ro = new ResizeObserver(resizeWatcher);

    const [showContactInfo, setShowContactInfo] = useState(false)


    useEffect(() => {
        ro.observe(scrollContainer.current);

        scrollContainer.current.addEventListener('scroll', scrollWatcher)

        return () => window.removeEventListener("scroll", scrollWatcher)

    }, [])

    function scrollWatcher(event) {
        var element = event.target;

        if (element.scrollHeight - element.scrollTop === element.clientHeight)
            setShowBoxShadow(false)
        else
            setShowBoxShadow(true)

    }

    function resizeWatcher() {
        var element = scrollContainer.current

        if (element && element.scrollHeight > element.clientHeight)
            setShowBoxShadow(true)
        else
            setShowBoxShadow(false)

    }

    const formatWord = (param) => {
        if (param && typeof param === "string") {
            param = param.replace("_", " ")

            let words = param.split(" ");

            param = words.map((word) => {
                return word[0].toUpperCase() + word.substring(1);
            }).join(" ");

            return param
        }
        return null
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const getIcon = (market, type) => {
        const SQUARE_SIZE = 6
        let icon = null
        let key = "originSignal"
        if (type == "origin") {
            icon = <div
                style={{
                    width: SQUARE_SIZE + 3,
                    height: SQUARE_SIZE + 3,
                    borderRadius: (SQUARE_SIZE + 6) / 2,
                    backgroundColor: '#A4A4AC',
                    width: 12,
                    height: 12,
                }}
            />
        } else {
            key = "destinationSignal"
            icon = <div
                style={{
                    width: SQUARE_SIZE + 3,
                    height: SQUARE_SIZE + 3,
                    backgroundColor: muiTheme.ttUnselectGrey,
                    width: 12,
                    height: 12,
                }}
            />
        }

        return icon
    }

    const hasValidDocs = (docs) => {
        let returnVal = false
        docs.forEach(doc => {
            if (!doc.isDeleted && !returnVal)
                returnVal = true
        })
        return returnVal
    }

    const header = (
        <Row style={{ width: '100%', textAlign: 'right', display: 'inline-flex' }}>
            {load.originCity && load.destinationCity &&
                <Col style={{ width: "100%" }}>
                    <Row style={{ display: 'inline-flex', width: '100%', textAlign: 'center', paddingLeft: 35, paddingRight: 35, justifyContent: 'center' }}>

                        <Col style={{ alignItems: 'left', maxWidth: 175 }}>
                            <Row>
                                <p className={classes.mainText} style={{ textAlign: 'left' }}>
                                    {load.originCity + ', ' + load.originState}
                                </p>
                            </Row>
                        </Col>

                        <Col style={{ padding: '5px', alignItems: 'center', width: 25 }}>
                            <Row style={{ marginTop: 0 }}>
                                {getIcon(load, "origin")}
                            </Row>
                        </Col>

                        <Col style={{ alignItems: 'center', padding: "5px", width: '30%' }}>

                            <hr style={{ marginTop: 6, marginBottom: 10, width: '100%', border: '1px solid black' }} />

                        </Col>

                        <Col style={{ padding: '5px', alignItems: 'center', width: 25 }}>
                            <Row style={{ marginTop: 0 }}>
                                {getIcon(load, "destination")}
                            </Row>
                        </Col>

                        <Col style={{ alignItems: 'left', maxWidth: 175 }}>
                            <Row>
                                <p className={classes.mainText} style={{ textAlign: 'left' }}>
                                    {load.destinationCity + ', ' + load.destinationState}
                                </p>
                            </Row>
                        </Col>

                    </Row>

                </Col>
            }
        </Row>
    );

    return (
        <div ref={scrollContainer} style={{ maxHeight: 600, overflowY: 'auto', overflowX: 'hidden' }}>

            {showContactInfo ?
                <div style={{ width: '100%', padding: 25 }}>
                    <h3>Contact Info</h3>
                    <Row style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Col>
                            <TextField
                                id="standard-basic"
                                label="Contact Email"
                                InputProps={{ classes: { root: classes.inputRoot } }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelRoot,
                                        focused: classes.labelFocused
                                    }
                                }}
                                margin="normal"
                                fullWidth
                                variant="outlined"
                                value={contactEmail}
                                onChange={(e) => setContactEmail(e.target.value)}
                            />
                        </Col>
                        <Col>
                            <TextField
                                id="standard-basic"
                                label="Contact Phone"
                                InputProps={{ classes: { root: classes.inputRoot } }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelRoot,
                                        focused: classes.labelFocused
                                    }
                                }}
                                margin="normal"
                                fullWidth
                                variant="outlined"
                                value={contactPhone}
                                onChange={(e) => setContactPhone(e.target.value)}
                            />
                        </Col>
                    </Row>
                </div>
                :
                <div style={{ display: 'contents' }}>
                    {load.stops && load.stops.length > 0 &&
                        <div style={{ display: 'contents' }}>
                            <Accordion elevation={0} classes={{ root: classes.MuiAccordionroot }} style={{ boxShadow: "none" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ fontSize: 28 }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    {header}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <StopInformationDesktop
                                        load={load}
                                        arrivalTime={arrivalTime}
                                        updateArrivalTime={updateArrivalTime}
                                        departureTime={departureTime}
                                        updateDepartureTime={updateDepartureTime}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            <hr />
                        </div>
                    }



                    <LineItemCharges
                        stops={load.stops}
                        lineItems={lineItems}
                        addLineItem={addLineItem}
                        updateLineItem={updateLineItem}
                        removeLineItem={removeLineItem}
                        onFileUpload={onFileUpload}
                        refresh={refresh}
                        editModeFlag={editModeFlag}
                        setEditModeFlag={setEditModeFlag}
                        allowEdit={allowEdit}
                    />




                    <hr />

                    <Row key={"line_item_docs_" + refresh} style={{ paddingLeft: 25 }}>
                        <Row style={{ display: 'flex' }}>
                            <h3>Documents</h3>
                        </Row>
                        {lineItems && lineItems.length > 0 && hasDocuments ?
                            <Row>
                                {lineItems.map((item, itemIndex) =>
                                    <Row style={{ display: 'flex', flexDirection: 'column' }}>
                                        {item.documents && item.documents.length > 0 && hasValidDocs(item.documents) &&
                                            <p className={classes.mainText} style={{ fontSize: 14 }}>{formatWord(item.line_item_type)}
                                                <span> {item.stop && item.stop.sequence ? `(Stop ${item.stop.sequence})` : null} </span>
                                            </p>
                                        }
                                        {item.documents && item.documents.length > 0 && hasValidDocs(item.documents) &&
                                            <div>
                                                <ul style={{ display: 'inline-block' }}>

                                                    {item.documents && item.documents.length > 0 && item.documents.map((doc, docIndex) => {
                                                        if (doc.isDeleted)
                                                            return null
                                                        else {
                                                            return (
                                                                <li key={doc.name + docIndex}>

                                                                    <Row>
                                                                        <Col style={{ width: 200, overflow: 'hidden', display: 'inline-flex', whiteSpace: 'nowrap' }}>
                                                                            {/* <p className={classes.secondaryText} style={{ width: 'min-content', whiteSpace: 'nowrap', overflow: 'visible', display: 'inline-block' }}>
                                                                                {formatWord(doc.document_name)}
                                                                            </p> */}
                                                                            <a
                                                                                className={classes.secondaryText}
                                                                                style={{ width: 'min-content', whiteSpace: 'nowrap', overflow: 'visible', display: 'inline-block', color: doc.document_url ? muiTheme.actionColor : 'black' }}
                                                                                href={doc.document_url ? doc.document_url : doc.path}
                                                                                target="_blank">
                                                                                {formatWord(doc.document_name ? doc.document_name : doc.name)}
                                                                            </a>
                                                                        </Col>
                                                                        <Col style={{ display: 'inline-block' }}>

                                                                            <p className={classes.secondaryText} style={{ display: 'inline-block', width: 160, paddingLeft: 30 }}>
                                                                                {moment(doc.updated_at ? doc.updated_at : doc.timestamp).format('ll HH:mm')}
                                                                            </p>
                                                                            {allowEdit &&
                                                                                <span onClick={() => removeLineItemDocument(itemIndex, docIndex)} style={{ paddingLeft: 15, display: 'inline-block' }}><FA name="trash" style={{ fontSize: 18, cursor: 'pointer', color: muiTheme.ttRed }} /></span>
                                                                            }

                                                                        </Col>
                                                                    </Row>
                                                                </li>
                                                            )
                                                        }
                                                    }

                                                    )}
                                                </ul>
                                            </div>
                                        }
                                    </Row>
                                )}
                            </Row>
                            :
                            <div>
                                <p style={{ width: '100%', textAlign: 'left', fontSize: 14, color: 'grey', fontStyle: 'italic' }}>
                                    Document images can be submitted in the Charges section above.
                                </p>
                            </div>
                        }
                    </Row>

                    {invoice && invoice.payments && invoice.payments.length > 0 &&
                        <div>
                            <hr />
                            <PaymentInformation paymentHistory={invoice.payments} />
                        </div>
                    }
                </div>
            }
            <Row className={classes.actionContainer} style={{ boxShadow: showBoxShadow ? '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)' : 'none' }}>
                <Col style={{ width: '60%', display: 'inline-flex', justifyContent: 'flex-end' }}>
                    <Button
                        aria-describedby={"invoice_save"}
                        variant={"outlined"}
                        onClick={() => { showContactInfo ? setShowContactInfo(false) : submitInvoice("save") }}
                        style={{
                            display: allowSubmit && allowEdit ? "block" : "none",
                            fontSize: 14,
                            color: muiTheme.actionColor,
                            backgroundColor: "white",
                            borderColor: muiTheme.actionColor,
                            border: '1px solid',
                            margin: 5
                        }}
                        size={"medium"}
                        disableRipple={false}
                        disabled={!allowEdit || props.isLoading}
                    >

                        {showContactInfo ? "Go Back" : "Save for Later"}
                    </Button>
                    <Button
                        aria-describedby={"invoice_submit"}
                        variant={"contained"}
                        onClick={() => { showContactInfo ? submitInvoice("submitted") : setShowContactInfo(true) }}
                        style={{
                            display: allowSubmit && allowEdit ? "block" : "none",
                            fontSize: 14,
                            color: "white",
                            backgroundColor: muiTheme.actionColor,
                            borderColor: muiTheme.actionColor,
                            margin: 5
                        }}
                        size={"medium"}
                        disableRipple={false}
                        disabled={!allowSubmit || props.isLoading}
                    >

                        {props.isLoading ? <FA name='spinner' spin /> : showContactInfo ? "Submit" : "Next"}
                    </Button>
                </Col>

                <Col style={{ width: '40%', display: 'inline-flex', justifyContent: 'flex-end', paddingRight: 25, visibility: invoice.amount && parseFloat(invoice.amount) > 0 ? 'visible' : 'hidden' }}>
                    <p style={{ fontSize: 24 }}>{currentStatus === "paid" ? "Amount Paid: " : "Total: "}<span style={{ fontWeight: 'bold', color: 'green' }}>${numberWithCommas(parseFloat(invoice.amount).toFixed(2))}</span></p>
                </Col>

            </Row>

        </div>
    )
}

export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(InvoiceFormDesktop)

