import React from 'react'
import { setUserProfileRedux, updateProfile } from './actions.js'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
//import 'react-toastify/dist/ReactToastify.min.css';
import ProfileUpdateForm from './profileUpdateForm'
import EditCarrierProfile from './edit-carrier-profile.js';
import EditMyProfile from './edit-my-profile.js'
import CopyContentsButton from './copy-contents-button.js';
import Button from '@material-ui/core/Button';
import DefaultSignupModalContent from "../CarrierPortal/authentication/signup/signup-modal-content"
import Modal from '@material-ui/core/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

// import {useAuthenticationHook} from '../CarrierPortal/hooks/authentication-hook';

import PropTypes from 'prop-types';

import {
	Redirect
} from 'react-router-dom';
import OutOfNetworkBroker from '../outOfNetworkBrokerFooter'

import LoadingOverlay from '../components/LoadingOverlay'

import {
	signout
} from '../lib/check-auth'

import {
	isTablet
} from "react-device-detect"

import Preferences from '../preferences/indexV2';//chnage to indexv2 here for new one

//HOC//
import Mobile from '../HOC/Mobile'


const styles = theme => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		gap: 30,
		flexWrap: 'wrap',
		overflow: 'auto',
		maxHeight: '100%',
		maxWidth: 1400,
		padding: 15
	},
	cardStyle: {
		backgroundColor: "white",
		padding: '24px 32px',
		maxHeight: 560,
		width: 550,
		boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		borderRadius: 2,
		overflow: 'auto'
	},
	cardheader: {
		fontSize: 16,
		fontWeight: 600
	},
	cardConent: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 20
	},
	contentBlock: {
		width: 225
	},
	contentHeader: {
		fontSize: 12,
		color: '#7f7f7f'
	},
	contentValue: {
		fontSize: 16,
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	disabledTextFieldFont: {
		"& .MuiInputBase-root.Mui-disabled": {
			color: "black"
		}
	},
	adminLabel: {
		fontWeight: 'bold'
	}
});

class profile extends React.Component {

	static propTypes = {
		profile: PropTypes.shape({
			// profileData: PropTypes.obj,
			updateResponse: PropTypes.array,
			requesting: PropTypes.bool,
			successful: PropTypes.bool,
			messages: PropTypes.array,
			errors: PropTypes.array,
		}).isRequired,
		setUserProfileRedux: PropTypes.func.isRequired, // <-- new
	}

	constructor(props) {
		super(props);
		this.calHeaderHeight = this.calHeaderHeight.bind(this);
		this.changeFooterHeight = this.changeFooterHeight.bind(this);
		this.setShowSignupForm = this.setShowSignupForm.bind(this);
		this.setEditProfileForm = this.setShowSignupForm.bind(this);
		this.hidingBrokerId = 0;
		this.state = {
			data: {},
			footerDynamicHeight: 0,
			headerDynamicHeight: 0,
			editField: false,
			showSignupForm: false,
			showUserEditForm: false,
			showCarrierEditForm: false
		};
	}

	clientHeight() {

	}
	componentDidMount() {

		this.fetchProfileData();

	}

	componentDidUpdate() {

	}

	fetchProfileData = () => {

		// if (isAuthenticated()) {
		this.props.setUserProfileRedux();
		// 	return true;
		// }
		// else {
		// 	signout(() => this.props.history.push('/login'));
		// }
		// return false;
	}

	setShowSignupForm = (val) => {

		this.setState({ showSignupForm: val })

	}

	setShowUserEditForm = (val) => {

		this.setState({ showUserEditForm: val })

	}

	setShowCarrierEditForm = (val) => {

		this.setState({ showCarrierEditForm: val })

	}

	didTokenTimeOut = (errors) => {
		var didTimeOut = false;
		errors.map(error => {
			if (error.body && error.body.indexOf('Unauthorized') !== -1) {
				didTimeOut = true;
				signout(() => { });
			}
		});
		return didTimeOut;
	}

	isMobileNotTablet = () => {
		return (!isTablet && this.props.isMobile)
	}

	render() {
		// const myAuthService = useAuthenticationHook()
		// const user = myAuthService.getUser()
		// console.log('render_start');

		const {
			profile: {
				profileData,
				updateResponse,
				requesting,
				successful,
				messages,
				errors,
			},
			preferences,
			classes,
			user = null,
			muiTheme
		} = this.props

		const BrandedLinearProgress = withStyles({
			colorPrimary: {
				backgroundColor: 'grey'
			},
			bar: {
				backgroundColor: muiTheme.actionColor,
			},
		})(LinearProgress);

		const getNameData = (firstOrLast) => {
			if (process.env.REACT_APP_ADVANTAGE_ID === "a98db973") {
				let nameArray = user.fullName.split(' ');
				if (firstOrLast === 'first') {
					return nameArray[0]
				} else {
					return nameArray[1]
				}
			} else {
				if (firstOrLast === 'first') {
					return user.firstName
				} else {
					return user.lastName
				}
			}
		}

		if (this.props.profile.profileData) {
			this.state.data = this.props.profile.profileData;
		}

		return (
			<div style={{ height: '100%' }}>
				{requesting && <LoadingOverlay />}
				<Modal
					disablePortal
					disableEnforceFocus
					disableAutoFocus
					open={this.state.showUserEditForm}
					onClose={() => this.setShowUserEditForm(false)}
					aria-labelledby="server-modal-title"
					aria-describedby="server-modal-description"
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<EditMyProfile onBack={() => { this.setShowUserEditForm(false) }} userEmail={this.state.data.username} onProfileScreen={true} existingData={user} isMobile={this.isMobileNotTablet()} />
				</Modal>
				<Modal
					disablePortal
					disableEnforceFocus
					disableAutoFocus
					open={this.state.showCarrierEditForm}
					onClose={() => this.setShowCarrierEditForm(false)}
					aria-labelledby="server-modal-title"
					aria-describedby="server-modal-description"
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<EditCarrierProfile
						onBack={() => { this.setShowCarrierEditForm(false) }}
						onProfileScreen={true}
						isMobile={this.isMobileNotTablet()}
						existingData={this.state.data}
					/>
				</Modal>
				<Modal
					disablePortal
					disableEnforceFocus
					disableAutoFocus
					open={this.state.showSignupForm}
					onClose={() => this.setShowSignupForm(false)}
					aria-labelledby="server-modal-title"
					aria-describedby="server-modal-description"
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<DefaultSignupModalContent onBack={() => { this.setShowSignupForm(false) }} userEmail={this.state.data.username} onProfileScreen={true} existingData={this.state.data} isMobile={this.isMobileNotTablet()} />
				</Modal>
				<div className=" panel-default" style={{ height: '100%' }}>
					{/* {!this.props.isMobile &&(
			        		<div className="panel-heading" id="header_container" ref={this.calHeaderHeight}>
					            <Row>
					              <Col md={2}>
					                <h4 style={hBottomMargin}>Profile</h4>
					              </Col>
					              
					            </Row>
					        </div>
					    )} */}
					<div className="custom-panel-body" style={{ maxHeight: '100%', display: 'flex', justifyContent: 'center' }} >
						<div className={classes.container}>

							<div className={classes.cardStyle}>
								<h3>My Profile</h3>
								<div className={classes.cardConent}>
									<div class={classes.contentBlock}>
										<p className={classes.contentHeader}>First Name</p>
										<p className={classes.contentValue}>
											{user && getNameData('first') ? getNameData('first') : 'N/A'}
										</p>
									</div>
									<div class={classes.contentBlock}>
										<p className={classes.contentHeader}>Last Name</p>
										<p className={classes.contentValue}>
											{user && getNameData('last') ? getNameData('last') : 'N/A'}
										</p>
									</div>
									<div class={classes.contentBlock}>
										<p className={classes.contentHeader}>Phone</p>
										<p className={classes.contentValue}>
											{user && user.phoneNumber ? user.phoneNumber : 'N/A'}
										</p>
									</div>
									<div class={classes.contentBlock}>
										<p className={classes.contentHeader}>Email</p>
										<p className={classes.contentValue}>
											{user && user.username ? user.username : 'N/A'}
										</p>
									</div>
									<div class={classes.contentBlock} style={{ width: '100%' }}>
										<div style={{ display: 'flex' }}>
											<p className={classes.contentHeader}>Role</p>
											<Tooltip enterTouchDelay={0} leaveTouchDelay={10000} title={
												<React.Fragment>
													<Typography style={{ color: '#fff', fontSize: '12px' }} color="inherit">
														<p> <span className={classes.adminLabel}>Admin</span>: Search & view loads, make offers, book loads, manage users</p><br />
														<p> <span className={classes.adminLabel}>Dispatcher:</span> Search & view loads, make offers, book loads</p><br />
														<p> <span className={classes.adminLabel}>Load Viewer:</span> Search & view loads</p><br />
														<p> <span className={classes.adminLabel}>Fincance:</span> Search & view loads, submit invoices</p><br />
														<p style={{ fontStyle: 'italic' }}>*If you need to update your role, reach out to a carrier admin to update this information.</p>
													</Typography>
												</React.Fragment>} placement={this.props.isMobile ? "top" : "right"} arrow>
												<img src="/icon-help-circled.png" className="help-circled" />
											</Tooltip>
										</div>
										<p className={classes.contentValue}>
											{user && user.role ? user.role : 'N/A'}
										</p>
									</div>
									<div class={classes.contentBlock} style={{ width: '100%' }}>
										<Button
											id='saveButton'
											size="small"
											variant="contained"
											style={{
												backgroundColor: this.props.muiTheme.actionColor,
												borderColor: this.props.muiTheme.actionColor,
												color: "white",
												fontSize: 14,
												margin: 5
											}}
											onClick={() => { this.setShowUserEditForm(true) }}
										>
											Edit My Profile
										</Button>
									</div>
								</div>
							</div>
							<div className={classes.cardStyle}>
								<h3>Carrier Profile</h3>
								<div className={classes.cardConent} style={{ paddingBottom: 10 }}>
									<div class={classes.contentBlock}>
										<p className={classes.contentHeader}>Company Name</p>
										<p className={classes.contentValue}>
											{(this.state.data && this.state.data.companyName) ? this.state.data.companyName : 'N/A'}
										</p>
									</div>
									<div class={classes.contentBlock}>
										<p className={classes.contentHeader}>SCAC Number</p>
										<p className={classes.contentValue}>
											{(this.state.data && this.state.data.scac) ? this.state.data.scac : 'N/A'}
										</p>
									</div>
									<div class={classes.contentBlock}>
										<p className={classes.contentHeader}>MC Number</p>
										<p className={classes.contentValue}>
											{(this.state.data && this.state.data.mc) ? this.state.data.mc : 'N/A'}
										</p>
									</div>
									<div class={classes.contentBlock}>
										<p className={classes.contentHeader}>DOT Number</p>
										<p className={classes.contentValue}>
											{(this.state.data && this.state.data.dotNumber) ? this.state.data.dotNumber : 'N/A'}
										</p>
									</div>
									<div class={classes.contentBlock}>
										<p className={classes.contentHeader}>Address</p>
										<p className={classes.contentValue}>
											{this.state.data && this.state.data.address ? this.state.data.address : 'N/A'}
										</p>
										<p className={classes.contentValue}>
											{this.state.data && this.state.data.city && this.state.data.state ? `${this.state.data.city}, ${this.state.data.state}` : 'N/A'}
										</p>
										<p className={classes.contentValue}>
											{this.state.data && this.state.data.zip ? this.state.data.zip : 'N/A'}
										</p>
									</div>
									<div class={classes.contentBlock} style={{ width: '100%' }}>
										{this.props.userRole && this.props.userRole.toLowerCase() == "admin" && (
											<Button
												id='saveButton'
												size="small"
												variant="contained"
												style={{
													backgroundColor: this.props.muiTheme.actionColor,
													borderColor: this.props.muiTheme.actionColor,
													color: "white",
													fontSize: 14,
													margin: 5
												}}
												onClick={() => { this.setShowCarrierEditForm(true) }}
											>
												Edit Carrier Profile
											</Button>
										)}
										{process.env.REACT_APP_USE_AUTH0 == "true" && (
											<Button
												id='saveButton'
												size="small"
												variant="contained"
												style={{
													backgroundColor: this.props.muiTheme.actionColor,
													borderColor: this.props.muiTheme.actionColor,
													color: "white",
													fontSize: 14,
													margin: 5
												}}
												onClick={() => { this.setShowSignupForm(true) }}
											>
												Signup as New Carrier
											</Button>
										)}
									</div>
								</div>
							</div>
							<div className={classes.cardStyle} style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
								<div style={{ marginBottom: 24, paddingLeft: 36, paddingRight: 36 }}>
									<h3>Current Preferences</h3>
									<Preferences {...this.props} userEmail={user && user.username ? user.username : ""} />
								</div>
								{preferences.requesting ? <BrandedLinearProgress style={{ width: '100%' }} /> : null}
							</div>
							<div className={classes.cardStyle}>
								<h3>Carrier Admin Contacts</h3>
								<div className={classes.cardConent} style={{ flexDirection: 'column' }}>
									{
										this.state.data && this.state.data.admins && this.state.data.admins.map((admin, index) => {
											// dummyAdminList.admins.map((admin, index) => {
											return (
												<div key={index} style={{ maxWidth: 480, display: 'flex', justifyContent: 'space-between' }}>
													<div style={{ display: 'flex', flexDirection: 'column' }}>
														<p className={classes.contentHeader}> {admin.first_name} {admin.last_name}</p>
														<a href={"mailto:" + admin} target="_blank" style={{ color: muiTheme.actionColor, width: this.isMobileNotTablet() ? 146 : 'unset', overflow: 'hidden', textOverflow: 'ellipsis' }}>
															{admin.email}
														</a>
													</div>
													<CopyContentsButton isMobile={this.isMobileNotTablet()} content={admin.email} />
												</div>
											)
										})
									}
								</div>
							</div>
							{/* {!this.props.isMobile && this.props.muiTheme.customerUniqueId == "2zr5ys19" && (
							<div style={{ ...footerStyle, ...{ backgroundColor: "white", marginTop: 10, marginBottom: 10 } }}>
								<OutOfNetworkBroker clientHeight={this.changeFooterHeight} />
							</div>
						)} */}
						</div>
						{/* {this.props.isMobile && (
						<div className="custom-panel-footer  " >
							<div style={footerStyle}>
								<OutOfNetworkBroker clientHeight={this.changeFooterHeight} />
							</div>
						</div>
					)} */}
					</div>
				</div >
			</div>
		)
	}


	calHeaderHeight(node) {
		if (node) {
			// console.log('offsetHeight' + node.offsetHeight + 68);
			this.setState({
				headerDynamicHeight: node.offsetHeight + 68
			});
		}
	}


	changeFooterHeight(calculatedHeight) {
		// console.log('calculatedHeight ' + calculatedHeight)
		this.setState({ footerDynamicHeight: calculatedHeight });
	}
}


const mapStateToProps = state => ({
	profile: state.Profile,
	client: state.client,
	preferences: state.preferencesReducer

})

const connected = connect(
	mapStateToProps,
	{ setUserProfileRedux, updateProfile, }
)(profile)

export default withStyles(styles)(Mobile()(muiThemeable()(connected))) // TODO: check if this is okay
