// CRST THEME FILE

import getMuiTheme from 'material-ui/styles/getMuiTheme';

const theme = getMuiTheme({
    primary: '#af986e', // gold
    secondary: '#297b9a', // teal
    actionColor: "#910000", // red
    lightContrastText: '#fff',
    darkContrastText: '#000',
    useDarkContrastText: false,
    menuHighlightColor: "rgba(0, 0, 0, 0.3)",
    useWhiteBackgroundBehindLogo: false,
    tagLine:'Deliverying Promises & Driving Success',
    customerUniqueId: "c0419cm1",
    ttRed: "#AD2228",
    ttUnselectGrey: "#53565A",
    ttSecondary: "#EAEAEA",
    forceDrawerAlwaysOpen: false,
    primaryFontFamily: "Sans-Serif",
    loginMsg: "You've selected a feature that requires a secure login. Please login.",
    source: "crst_advantage_portal"
});

export default theme
