import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import isMobile from '../../HOC/Mobile.js'
import { toast } from 'react-toastify';
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import "react-datepicker/dist/react-datepicker.css";

import { Row, Col, Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Card } from 'material-ui/Card';
import DatePicker from "react-datepicker";

import ConditionalWrapper from '../components/conditionalWrapper.js';

import { useAuthenticationHook } from '../hooks/authentication-hook'
import useGtagHook from '../../gtag-hook';
import { sendEmail } from '../apis/api-email-service';


const MaintenanceScreen = (props) => {

    const { height, width } = useWindowDimensions();
    const { muiTheme, isMobile } = props

    const useStyles = makeStyles((theme) => ({
        paper: {
            backgroundColor: theme.palette.background.paper,
            height: 'unset',
            outline: 'none',
            padding: theme.spacing(2, 4, 3),
        },
        container: {
            height: height,
            width: '100%',
            backgroundColor: isMobile ? "#fff" : "transparent"
        },
        headerText: {
            margin: 0,
            marginTop: 15
        },
        dataRow: {
            marginTop: 5
        },
        colLeft: {
            width: "45%",
            marginRight: "5%"
        },
        colRight: {
            width: "45%",
        },
        splitRow: {
            display: "flex"
        },
        dateTimePickers: {
            borderWidth: 1,
            paddingTop: 6,
            paddingBottom: 7,
            fontSize: 16,
            borderRadius: 0,
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            '&:hover': {
                borderWidth: 2
            }
        },
        divCalendarContainer:{
            '& .react-datepicker': {
                fontSize: 16
            },
            '& .react-datepicker__current-month': {
                fontSize: 16
            },
            '& .react-datepicker-time__header': {
                fontSize: 16
            },
            '& .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name': {
                width:25,
                height: 25,
                lineHeight: 'unset'
            },
            '& .react-datepicker__day-name': {
                margin: '5px !important',
                padding: 2
            },
            '& .react-datepicker__day': {
                margin: '5px !important',
            },
            '& .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range': {
                backgroundColor: muiTheme.actionColor
            },
            '& .react-datepicker__time-container': {
                width: 130
            },
            '& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box': {
                width: 130
            },
            '& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected': {
                backgroundColor: muiTheme.actionColor
            }
        }
    }));

    const classes = useStyles()

    const [sendGtagEvent] = useGtagHook()

    const myAuthService = useAuthenticationHook()
    const user = myAuthService.getUser()


    const recipientEmail = "ecsmaintenance@englandlogistics.com"
    const recipientCcEmail = "elatlasnow@englandlogistics.com"
    const logoUrl = "https://s3.amazonaws.com/assets.truckertools.com/carrierPortal/dd4udt1b/logo.png"

    const [driverName, setDriverName] = useState((user && user.firstName && user.lastName) ? user.firstName + " " + user.lastName : "");
    const [companyName, setCompanyName] = useState((user && user.carrier && user.carrier.companyName) ? user.carrier.companyName : "")
    const [dot, setDot] = useState((user && user.carrier && user.carrier.dot) ? user.carrier.dot : "");
    const [mc, setMc] = useState((user && user.carrier && user.carrier.mc) ? user.carrier.mc : "");
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState((user && user.username) ? user.username : "");

    const [shopName, setShopName] = useState("");
    const [shopCity, setShopCity] = useState("");
    const [shopState, setShopState] = useState("")

    const [etaDate, setEtaDate] = useState(null)
    const [etaTime, setEtaTime] = useState(null)

    const [serviceReqType, setServiceReqType] = useState("")
    const [serviceType, setServiceType] = useState("")
    const [unitType, setUnitType] = useState("")
    const [truckNum, setTruckNum] = useState("")
    const [trailerNum, setTrailerNum] = useState("")

    const [additionalInfo, setAdditionalInfo] = useState("")

    const [isError, setIsError] = useState(false)
    const [isMakingApiCall, setIsMakingApiCall] = useState(false)

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const submitForm = () => {
        if ((driverName == null || driverName === "" || (driverName.split(" ").join("") === "")) ||
            (companyName == null || companyName === "" || (companyName.split(" ").join("") === ""))
        ) {
            toast.error("Driver Name and Company Name are required.")
            setIsError(true)
            return
        }
        else if (((mc == null || mc === "" || (mc.split(" ").join("") === "")) || isNaN(mc)) && (dot == null || dot === "" || (dot.split(" ").join("") === "")) || isNaN(dot)) {
            toast.error("A Valid MC or DOT Number is required.")
            setIsError(true)
            return
        }
        else if (phone == null || phone === "") {
            toast.error("A valid contact phone number is required.")
            setIsError(true)
            return
        }
        else if (email == null || email === "" || (email.split(" ").join("") === "") || !validateEmail(email)) {
            toast.error("A valid contact email address is required.")
            setIsError(true)
            return
        }
        else if ((shopName == null || shopName === "" || (shopName.split(" ").join("") === "")) ||
            (shopCity == null || shopCity === "" || (shopCity.split(" ").join("") === "")) ||
            (shopState == null || shopState === "" || (shopState.split(" ").join("") === ""))
        ) {
            toast.error("Shop information fields are required.")
            setIsError(true)
            return
        }
        else if (etaDate == null || etaDate === "" || etaTime == null || etaTime === "") {
            toast.error("A valid ETA date and time is required.")
            setIsError(true)
            return
        }
        else if ((serviceReqType == null || serviceReqType === "" || (serviceReqType.split(" ").join("") === "")) ||
            (serviceType == null || serviceType === "" || (serviceType.split(" ").join("") === "")) ||
            (unitType == null || unitType === "" || (unitType.split(" ").join("") === "")) ||
            (truckNum == null || truckNum === "" || (truckNum.split(" ").join("") === "")) ||
            (trailerNum == null || trailerNum === "" || (trailerNum.split(" ").join("") === ""))
        ) {
            toast.error("All Service Request data is required.")
            setIsError(true)
            return
        }

        let msg = "Contact Information <br/>" +
            "Driver Name: " + driverName + "<br/>" +
            "Company Name: " + companyName + "<br/>" +
            "Driver Name: " + driverName + "<br/>" +
            (mc !== "" ? "MC: " + mc + "<br/>" : "") +
            (dot !== "" ? "DOT: " + dot + "<br/>" : "") +
            "Contact Email: " + email + "<br/>" +
            "Contact Phone: " + phone + "<br/><br/>" +
            "Shop Information <br/>" +
            "Shop Name: " + shopName + "<br/>" +
            "City: " + shopCity + "<br/>" +
            "State: " + shopState + "<br/><br/>" +
            "ETA <br/>" +
            "Date: " + moment(etaDate).format('MM/DD/YYYY') + "<br/>" +
            "Time: " + moment(etaTime).format('hh:mm a') + "<br/>" +
            "Time Zone: " + (new Date).toString().match(/\(([^\)]+)\)$/)[1] + "<br/><br/>" +
            "Service Request <br/>" +
            "Service Request Type: " + serviceReqType + "<br/>" +
            "Service Type: " + serviceType + "<br/>" +
            "Unit Type: " + unitType + "<br/>" +
            "Truck #: " + truckNum + "<br/>" +
            "Trailer #: " + trailerNum + "<br/><br/>" +
            "Additional Information <br/>" +
            (additionalInfo !== "" ? additionalInfo : "No additional information.")

        let params = {
            subject: companyName + " has requested maintenance service",
            reply_to: email,
            message_body: msg,
            // mc: 555,
            // company_name: "Test Company Name",
            recipient_email: recipientEmail,
            recipient_cc: [recipientCcEmail],
            customer_logo_url: logoUrl
        }

        // console.log("message: ", msg)
        setIsMakingApiCall(true)
        sendEmail(params).then(response => {
            sendGtagEvent("maintenance_service_request", {
                "driverName": driverName,
                "companyName": companyName,
                "mcNumber": mc,
                "dotNumber": dot,
                "phoneNumber": phone,
                "emailAddress": email,
                "shopName": shopName,
                "shopCity": shopCity,
                "shopState": shopState,
                "etaDate": moment(etaDate).format('MM/DD/YYYY'),
                "etaTime": moment(etaTime).format('hh:mm a'),
                "etaTimeZone": (new Date).toString().match(/\(([^\)]+)\)$/)[1],
                "serviceReqType": serviceReqType,
                "serviceType": serviceType,
                "unitType": unitType,
                "truckNum": truckNum,
                "trailerNum": trailerNum,
                "apiDidError": !response.status
            })
            if (response && response.status) {
                // close form
                // toast that email has been successfully sent
                toast.success("Email seccuessfully sent")
                setIsMakingApiCall(false)
            } else {
                // display error as toast
                // do not clear or close form
                toast.error(response.message ? response.message : "There was a problem making your request. Please check your internet connection and try again")
                setIsMakingApiCall(false)
            }
        }).catch((e) => {
            // display error as toast
            // do not clear or close form
            toast.error("There was a problem making your request. Please check your internet connection and try again")
            setIsMakingApiCall(false)
        })
    }

    // const handleBannerClick = () => {
    //     sendGtagEvent("banner_click", {
    //         bannerType: "maintenance"
    //     })
    // }

    return (
        <div className={classes.container}>
            <div style={{ maxHeight: "90%", width: "100%", overflowX: "hidden", overflowY: "auto", paddingBottom: 60 }}>
                <div style={{ width: "100%", paddingTop: 10, paddingLeft: 15, paddingRight: 15, display: isMobile ? "block" : "flex", justifyContent: "center" }}>
                    <ConditionalWrapper
                        condition={!isMobile}
                        wrapper={children =>
                            <Card className={classes.paper} style={{ width: isMobile ? "100%" : "60%", marginBottom: 30, display: "flex", justifyContent: "center" }}>{children}</Card>
                        }
                    >
                        <Row style={{ marginBottom: 10 }}>
                            <h3 style={{ margin: 0 }}>Submit form to have a team member coordinate and schedule repairs or call us direct at <a href={'tel:8008487810'} style={{ color: muiTheme.actionColor }}>800-848-7810</a>. For support after 4PM MTN, call us at <a href={'tel:8663519255'} style={{ color: muiTheme.actionColor }}>866-351-9255</a>.</h3>
                        </Row>
                        <Row style={{ display: "flex", marginBottom: 10 }}>
                            <Col style={{ width: "100%" }}>
                                <form className={classes.root}>
                                    <p className={classes.secondaryText} style={{ color: "grey" }}>Asterisks ( <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span> ) indicate required fields</p>
                                    <Row className={classes.row}>
                                        <h3 className={classes.headerText}>Contact Information</h3>
                                    </Row>
                                    <Row className={classes.dataRow}>
                                        <div>
                                            <p className={classes.secondaryText} style={{ color: driverName == "" ? muiTheme.ttRed : "grey" }}>DRIVER NAME <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                            <TextField
                                                inputProps={{ style: { fontSize: 16 } }}
                                                fullWidth
                                                value={driverName}
                                                onChange={(e) => setDriverName(e.target.value)}
                                                error={isError && driverName == ""}
                                            />
                                        </div>
                                    </Row>
                                    <Row className={classes.dataRow}>
                                        <div>
                                            <p className={classes.secondaryText} style={{ color: companyName == "" ? muiTheme.ttRed : "grey" }}>COMPANY NAME <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                            <TextField
                                                inputProps={{ style: { fontSize: 16 } }}
                                                fullWidth
                                                value={companyName}
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                error={isError && companyName == ""}
                                            />
                                        </div>
                                    </Row>
                                    <Row className={[classes.dataRow, classes.splitRow]}>
                                        <div className={classes.colLeft}>
                                            <p className={classes.secondaryText} style={{ color: dot == "" && mc == "" ? muiTheme.ttRed : "grey" }}>DOT NUMBER <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                            <TextField
                                                inputProps={{ style: { fontSize: 16 } }}
                                                fullWidth
                                                value={dot}
                                                onChange={(e) => setDot(e.target.value)}
                                                error={isError && dot == "" && mc == ""}
                                            />
                                        </div>
                                        <div className={classes.colRight}>
                                            <p className={classes.secondaryText} style={{ color: dot == "" && mc == "" ? muiTheme.ttRed : "grey" }}>MC NUMBER <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                            <TextField
                                                inputProps={{ style: { fontSize: 16 } }}
                                                fullWidth
                                                value={mc}
                                                onChange={(e) => setMc(e.target.value)}
                                                error={isError && dot == "" && mc == ""}
                                            />
                                        </div>
                                    </Row>
                                    <Row className={classes.dataRow}>
                                        <div>
                                            <p className={classes.secondaryText} style={{ color: phone == "" ? muiTheme.ttRed : "grey" }}>PHONE NUMBER <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                            <TextField
                                                inputProps={{ style: { fontSize: 16 } }}
                                                fullWidth
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                error={isError && (phone == "" || !Number.isInteger(Number(phone)))}
                                            />
                                        </div>
                                    </Row>
                                    <Row className={classes.dataRow}>
                                        <div>
                                            <p className={classes.secondaryText} style={{ color: email == "" ? muiTheme.ttRed : "grey" }}>EMAIL <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                            <TextField
                                                inputProps={{ style: { fontSize: 16 } }}
                                                fullWidth
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                error={isError && email == "" && validateEmail(email)}
                                            />
                                        </div>
                                    </Row>
                                    <Row>
                                        <h3 className={classes.headerText}>Shop Information</h3>
                                    </Row>
                                    <Row className={classes.dataRow}>
                                        <div>
                                            <p className={classes.secondaryText} style={{ color: shopName == "" ? muiTheme.ttRed : "grey" }}>--SHOP NAME-- <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                            <NativeSelect
                                                value={shopName}
                                                onChange={(e) => setShopName(e.target.value)}
                                                error={isError && shopName == ""}
                                                inputProps={{
                                                    style: { fontSize: 16 }
                                                }}
                                                style={{ fontSize: 16 }}
                                            // inputProps={{
                                            //     name: 'age',
                                            //     id: 'age-native-helper',
                                            // }}
                                            >
                                                {/* <option value={""}></option> */}
                                                <option aria-label="--SHOP NAME--" value={""} />
                                                <option value={"loves"}>LOVES</option>
                                                <option value={"speedco"}>SPEEDCO</option>
                                            </NativeSelect>
                                        </div>
                                    </Row>
                                    <Row className={[classes.dataRow, classes.splitRow]}>
                                        <div className={classes.colLeft}>
                                            <p className={classes.secondaryText} style={{ color: (shopCity == "") ? muiTheme.ttRed : "grey" }}>CITY <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                            <TextField
                                                inputProps={{ style: { fontSize: 16 } }}
                                                fullWidth
                                                value={shopCity}
                                                // placeholder={"MC11223344"}
                                                onChange={(e) => setShopCity(e.target.value)}
                                                error={isError && shopCity == ""}
                                            />
                                        </div>
                                        <div className={classes.colRight}>
                                            <p className={classes.secondaryText} style={{ color: (shopState == "") ? muiTheme.ttRed : "grey" }}>STATE <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                            <TextField
                                                inputProps={{ style: { fontSize: 16 } }}
                                                fullWidth
                                                value={shopState}
                                                // placeholder={"MC11223344"}
                                                onChange={(e) => setShopState(e.target.value)}
                                                error={isError && shopState == ""}
                                            />
                                        </div>
                                    </Row>
                                    <Row>
                                        <h3 className={classes.headerText}>ETA</h3>
                                    </Row>
                                    <Row className={classes.dataRow}>
                                        <div className={classes.divCalendarContainer}>
                                            <p className={classes.secondaryText} style={{ color: (etaDate == "") ? muiTheme.ttRed : "grey" }}>DATE <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                            <DatePicker className={classes.dateTimePickers} onFocus={e => e.target.blur()} selected={etaDate} onChange={(date) => setEtaDate(date)} />
                                        </div>
                                    </Row>
                                    <Row className={classes.dataRow}>
                                        <div className={classes.divCalendarContainer}>
                                            <p className={classes.secondaryText} style={{ color: (etaTime == "") ? muiTheme.ttRed : "grey" }}>TIME <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                            <DatePicker
                                                className={classes.dateTimePickers}
                                                onFocus={e => e.target.blur()}
                                                selected={etaTime}
                                                onChange={(date) => setEtaTime(date)}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={30}
                                                timeCaption="Time"
                                                dateFormat="hh:mm a"
                                            />
                                        </div>
                                    </Row>
                                    <Row className={classes.dataRow}>
                                        <h3 className={classes.headerText}>Service Request</h3>
                                    </Row>
                                    <Row className={classes.dataRow}>
                                        <div>
                                            <p className={classes.secondaryText} style={{ color: serviceReqType == "" ? muiTheme.ttRed : "grey" }}>--SERVICE REQUEST TYPE-- <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                            <NativeSelect
                                                value={serviceReqType}
                                                onChange={(e) => setServiceReqType(e.target.value)}
                                                inputProps={{
                                                    style: { fontSize: 16 }
                                                }}
                                                style={{ fontSize: 16 }}
                                                error={isError && serviceReqType == ""}
                                            // inputProps={{
                                            //     name: 'age',
                                            //     id: 'age-native-helper',
                                            // }}
                                            >
                                                {/* <option value={""}></option> */}
                                                <option aria-label="--SERVICE REQUEST TYPE--" value={""} />
                                                <option value={"in_bay"}>IN BAY</option>
                                                <option value={"road_call"}>ROAD CALL</option>
                                            </NativeSelect>
                                        </div>
                                    </Row>
                                    <Row className={classes.dataRow}>
                                        <div>
                                            <p className={classes.secondaryText} style={{ color: serviceType == "" ? muiTheme.ttRed : "grey" }}>--SERVICE TYPE-- <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                            <NativeSelect
                                                value={serviceType}
                                                onChange={(e) => setServiceType(e.target.value)}
                                                inputProps={{
                                                    style: { fontSize: 16 }
                                                }}
                                                style={{ fontSize: 16 }}
                                                error={isError && serviceType == ""}
                                            // inputProps={{
                                            //     name: 'age',
                                            //     id: 'age-native-helper',
                                            // }}
                                            >
                                                {/* <option value={""}></option> */}
                                                <option aria-label="--SERVICE TYPE--" value={""} />
                                                <option value={"mechanical"}>MECHANICAL</option>
                                                <option value={"pm_service"}>PM SERVICE</option>
                                                <option value={"dot_trailer"}>DOT-TRAILER</option>
                                                <option value={"dot_tractor"}>DOT-TRACTOR</option>
                                            </NativeSelect>
                                        </div>
                                    </Row>
                                    <Row className={classes.dataRow}>
                                        <div>
                                            <p className={classes.secondaryText} style={{ color: unitType == "" ? muiTheme.ttRed : "grey" }}>--UNIT TYPE-- <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                            <NativeSelect
                                                value={unitType}
                                                onChange={(e) => setUnitType(e.target.value)}
                                                inputProps={{
                                                    style: { fontSize: 16 }
                                                }}
                                                style={{ fontSize: 16 }}
                                                error={isError && unitType == ""}
                                            // inputProps={{
                                            //     name: 'age',
                                            //     id: 'age-native-helper',
                                            // }}
                                            >
                                                {/* <option value={""}></option> */}
                                                <option aria-label="--SERVICE TYPE--" value={""} />
                                                <option value={"truck"}>TRUCK</option>
                                                <option value={"trailer"}>TRAILER</option>
                                                <option value={"both"}>BOTH</option>
                                            </NativeSelect>
                                        </div>
                                    </Row>
                                    <Row className={[classes.dataRow, classes.splitRow]}>
                                        <div className={classes.colLeft}>
                                            <p className={classes.secondaryText} style={{ color: (truckNum == "") ? muiTheme.ttRed : "grey" }}>TRUCK # <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                            <TextField
                                                inputProps={{ style: { fontSize: 16 } }}
                                                fullWidth
                                                value={truckNum}
                                                // placeholder={"MC11223344"}
                                                onChange={(e) => setTruckNum(e.target.value)}
                                                error={isError && truckNum == ""}
                                            />
                                        </div>
                                        <div className={classes.colRight}>
                                            <p className={classes.secondaryText} style={{ color: (trailerNum == "") ? muiTheme.ttRed : "grey" }}>TRAILER # <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>
                                            <TextField
                                                inputProps={{ style: { fontSize: 16 } }}
                                                fullWidth
                                                value={trailerNum}
                                                // placeholder={"MC11223344"}
                                                onChange={(e) => setTrailerNum(e.target.value)}
                                                error={isError && trailerNum == ""}
                                            />
                                        </div>
                                    </Row>
                                    <Row>
                                        <h3 className={classes.headerText}>Additional Information</h3>
                                    </Row>
                                    <Row className={classes.dataRow}>
                                        <div>
                                            <TextareaAutosize
                                                inputProps={{ style: { fontSize: 16 } }}
                                                style={{ width: '100%' }}
                                                placeholder='ADD ANY NOTES, SPECIAL INSTRUCTIONS, DRIVER LOCATION, DIRECTIONS, ETC. HERE FOR OUR ACCOUNT MANAGER TO REVIEW'
                                                rowsMin={3}
                                                value={additionalInfo}
                                                onChange={(e) => setAdditionalInfo(e.target.value)}
                                            />
                                        </div>
                                    </Row>
                                </form>
                            </Col>
                        </Row>
                        <Row style={{ display: "flex", justifyContent: "center" }}>
                            <div style={{ display: 'inline-block', marginLeft: 10 }}>
                                <Button
                                    variant={"contained"}
                                    size={"small"}
                                    style={{ fontSize: 14, color: "white", backgroundColor: muiTheme.actionColor, borderColor: muiTheme.actionColor }}
                                    onClick={() => submitForm()}
                                    disabled={isMakingApiCall}
                                >
                                    SUBMIT
                                </Button>
                            </div>
                        </Row>
                    </ConditionalWrapper>
                </div>
            </div>
        </div>
    )
}


export default compose(
    muiThemeable(),
    isMobile(),
)(MaintenanceScreen)

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}