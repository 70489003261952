
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import numeral from 'numeral'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import * as FeatherIcon from 'react-feather';
import Popover from '@material-ui/core/Popover';

import GoogleMapReact from 'google-map-react';
import { Col, Row } from "react-bootstrap"

import MapRoute from '../maproute.js'
import * as GeoLocationApi from '../../../.././../apis/api-geo-location';

import { checkValueExists } from "../../../../../../lib/check-value-exists"
import {useAuthenticationHook} from '../../../../../hooks/authentication-hook.js';

import { titleCaseFormatter } from "../../../../../../lib/title-case-formatter"

import moment from 'moment'
import { formatPhoneNumberWithHyphens } from '../../../../../../components/formatPhoneNumber.js';

const LoadCardExpandedContent = (props) => {

    const { load, muiTheme } = props
    const classes = useStyles();

    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()

    const [mapLoaded, setMapLoaded] = useState(false)
    const [map, updateMap] = useState(null)
    const [maps, updateMaps] = useState(null)
    const [processOnProgress, updateProcessOnProgress] = useState(false)
    const [notRendered, updateNotRendered] = useState(true)
    const [delay, updateForceDelay] = useState(false)
    const [googleMapsDuration, updateGoogleMapsDuration] = useState('')
    const [googleMapsDistance, updateGoogleMapsDistance] = useState('')
    const [showMapRouteError, setShowMapRouteError] = useState(false)

    const [stops, setStops] = useState(null)

    const [anchorEl, setAnchorEl] = useState(null);
    const openRateBreakdown = Boolean(anchorEl);
    const id = openRateBreakdown ? 'rate-info-popover' : undefined;

    const handleTrackingHelperOpen = (event, tag) => {
        setAnchorEl(event.currentTarget)
    };

    const handleTrackingHelperClose = () => {
        if (anchorEl !== null)
            setAnchorEl(null);
    };

    const getTrackingHelperPopover = (id, openRateBreakdown, anchorEl) => {
        return (
            <Popover
                onClick={(e)=>e.stopPropagation()}
                id={id}
                open={openRateBreakdown}
                anchorEl={anchorEl}
                PaperProps={{onMouseLeave: handleTrackingHelperClose}}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                // style={{ pointerEvents: 'none' }}
                disableRestoreFocus
            >
                <div style={{ padding: 15, width: 220, maxHeight: 300 }}>
                    <p className={classes.valueText}> Contact <a href={"tel:1-800-000-0000"}style={{ color: muiTheme.actionColor }}>1-800-000-0000</a><br/>to update tracking details.</p> 
                </div>    
            </Popover>
        )
    }

    useEffect(() => {
        let originLatLon = null
        let destLatLon = null
        getLatitudeLongitudes(load.originStop).then(resp => {
            originLatLon = resp

            getLatitudeLongitudes(load.destStop).then(resp => {
                destLatLon = resp
            }).then(() => {
                if (originLatLon && destLatLon) {
                    let tmpStops = [
                        {
                            lat: parseFloat(originLatLon.latitude),
                            lon: parseFloat(originLatLon.longitude),
                            city: load.originStop.city,
                            state: load.originStop.state,
                            label: 'stop0',
                            latestDate: load.originStop.latestDate
                        },
                        {
                            lat: parseFloat(destLatLon.latitude),
                            lon: parseFloat(destLatLon.longitude),
                            city: load.destStop.city,
                            state: load.destStop.state,
                            label: 'stop1',
                            latestDate: load.destStop.latestDate
                        }
                    ]
                    setStops(tmpStops)
                }
            })


        })
    }, [])

    const getLatitudeLongitudes = (stop) => {
        let params = {
            city: stop.city,
            state: stop.state,
            zipcode: stop.zip
        }
        let resp = GeoLocationApi.GetLatitudeLongitudes(params).then(response => {
            if (response.status) {
                if (response.latitude && response.longitude) {
                    return response
                }
            }
            return null
        })
        return resp
    }

    const removeImportedFont = () => {
        var elements = document.querySelectorAll('link[href*="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Google+Sans:400,500,700"]')

        if (elements.length > 0)
            elements[0].parentNode.removeChild(elements[0]);
    }

    function contains(selector, text) {
        var elements = document.querySelectorAll(selector);
        return [].filter.call(elements, function (element) {
            return RegExp(text).test(element.innerHTML);
        });
    }

    const removeUnwantedSpan = () => {
        setTimeout(
            () => {
                let spanArray = contains('span', 'BESbswy')
                if (spanArray && spanArray.length > 0)
                    spanArray[0].parentNode.removeChild(spanArray[0]);
            }
            , 3000)
    }

    const showGoogleMapsInfo = (totalDistance, totalDuration) => {
        totalDistance = totalDistance * 0.000621371192;
        totalDistance = Math.round(totalDistance * 100) / 100
        var d = totalDuration
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        totalDuration = hDisplay + mDisplay //+ sDisplay;

        /*console.log('Directions totalDistance',totalDistance);
        console.log('Directions totalDuration',totalDuration);*/

        updateGoogleMapsDistance(totalDistance)
        updateGoogleMapsDuration(totalDuration)
        updateNotRendered(false)
        updateForceDelay(false)
        setShowMapRouteError(false)
    }


    const checkWhiteSpace = (textInput) => {
        let status = true;
        if(textInput){
            if(textInput.trim().length === 0)
                status = false
        };
        return status
    }

    const checkEmptyString = (textInput) => {
        let status = true;
        if(typeof(textInput)=== 'string'){
            if(textInput.length === 0)
                status = false
        };
        return status
    }

    return (
        <Col>
            <Row style={{ display: 'flex', paddingTop: 40, }}>
                <Col style={{ display: 'flex', width: '4.3%' }}></Col>
                <Col style={{ width: '27.13%' }}>
                    <Row style={{ paddingRight: 15 }}>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <p className={classes.mainText} style={{ marginTop: 5, marginBottom: 5 }}>Tracking Details</p>
                                </Col>
                            </Row>
                            <dl className={classes.descriptionList} style={{marginBottom:32}}>
                                {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "driverName") && checkEmptyString(load.carrierTrackingInfo.driverName) && (
                                    <div>
                                        <dt className={classes.descriptorTagOne}>
                                            <p className={classes.labelText}>Driver Name</p>
                                        </dt>
                                        <dd className={classes.descriptorDetailOne}>
                                            <p className={classes.valueText}>{load.carrierTrackingInfo.driverName}</p>
                                        </dd>
                                    </div>
                                )}
                                {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "driverPhoneNumber") && checkEmptyString(load.carrierTrackingInfo.driverPhoneNumber) && (
                                    <div>
                                        <dt className={classes.descriptorTagOne}>
                                            <p className={classes.labelText}>Driver Phone</p>
                                        </dt>
                                        <dd className={classes.descriptorDetailOne}>
                                            <p className={classes.valueText}>{formatPhoneNumberWithHyphens(load.carrierTrackingInfo.driverPhoneNumber)}</p>
                                        </dd>
                                    </div>
                                )}
                                    <div>
                                        <dt className={classes.descriptorTagOne}>
                                            <p className={classes.labelText} style={{whiteSpace:'nowrap'}}>Tractor #</p>
                                        </dt>
                                        <dd className={classes.descriptorDetailOne}>
                                            <p className={classes.valueText} style={{position:'relative'}}>
                                                {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "tractor") && checkWhiteSpace(load.carrierTrackingInfo.tractor) && load.carrierTrackingInfo.tractor != "" ? load.carrierTrackingInfo.tractor 
                                                : <span className={classes.missing}> MISSING </span>}
                                                <FeatherIcon.Info
                                                    onClick={(e)=> {e.stopPropagation()}}
                                                    style={{position:'absolute', left:65, top:7}}
                                                    color={"black"}
                                                    size={20}
                                                    onMouseEnter={handleTrackingHelperOpen}
                                                    // onMouseLeave={handleTrackingHelperClose}
                                                />
                                                {getTrackingHelperPopover(id, openRateBreakdown, anchorEl)}
                                            </p>
                                        </dd>
                                        <dt className={classes.descriptorTagOne}>
                                            <p className={classes.labelText} style={{whiteSpace:'nowrap'}}>Trailer &nbsp;#</p>
                                        </dt>
                                        <dd className={classes.descriptorDetailOne}>
                                            <p className={classes.valueText}>{checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "trailer") && checkWhiteSpace(load.carrierTrackingInfo.trailer) && load.carrierTrackingInfo.trailer != "" ? load.carrierTrackingInfo.trailer 
                                            : <span className={classes.missing}> MISSING </span>}</p>
                                        </dd>
                                    </div>
                            </dl>
                            <Row>
                                <Col md={4}>
                                    <p className={classes.mainText} style={{ marginTop: 5, marginBottom: 5 }}>Contact Werner</p>
                                </Col>
                            </Row>
                            <dl className={classes.descriptionList}>
                                {checkValueExists(load, "tourId") && (
                                    <div>
                                        <dt className={classes.descriptorTagOne}>
                                            <p className={classes.labelText}>Load ID</p>
                                        </dt>
                                        <dd className={classes.descriptorDetailOne}>
                                            <p className={classes.valueText}>{load.tourId}</p>
                                        </dd>
                                    </div>
                                )}
                                    <div>
                                        <dt className={classes.descriptorTagOne}>
                                            <p className={classes.labelText}>Email</p>
                                        </dt>
                                        <dd className={classes.descriptorDetailOne}>
                                            <a 
                                                onClick={(e)=> {e.stopPropagation()}}
                                                href={"mailto:TruckerToolsCapacity@Werner.com"} 
                                                target="_blank"
                                                className={classes.valueText} 
                                                style={{ color: muiTheme.actionColor }}>
                                                    {"TruckerToolsCapacity@Werner.com" + "\n"}
                                            </a>
                                        </dd>
                                    </div>
                                    <div>
                                        <dt className={classes.descriptorTagOne}>
                                            <p className={classes.labelText}>Phone</p>
                                        </dt>
                                        <dd className={classes.descriptorDetailOne}>
                                            <a 
                                                onClick={(e)=> {e.stopPropagation()}}
                                                href={'tel:8003462820'} 
                                                className={classes.valueText} 
                                                style={{ color: muiTheme.actionColor, display: 'block' }}>
                                                    800-346-2820
                                            </a>
                                        </dd>
                                    </div>                   
                            </dl>
                        </Col>
                    </Row>
                </Col>

                {stops && stops.length > 0 &&

                    <Col style={{ width: "68.57%" }}>
                        <Row style={{ display: "flex" }}>
                            <div onClick={(e)=> {e.stopPropagation()}} style={{ position: 'relative', height: '200px', width: "50%", border: '1px solid grey' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API }}
                                    center={{ lat: stops[0].lat, lng: stops[0].lon }}
                                    defaultZoom={8}
                                    onGoogleApiLoaded={({ map, maps }) => {
                                        updateMap(map);
                                        updateMaps(maps);
                                        setMapLoaded(true);
                                    }}
                                    yesIWantToUseGoogleMapApiInternals={true}
                                    options={{ fullscreenControl: false }}
                                >
                                </GoogleMapReact>

                                {mapLoaded && !processOnProgress && notRendered &&
                                    <MapRoute
                                        delay={delay ? delay : false}
                                        processStart={() => updateProcessOnProgress(true)}
                                        processEnd={() => { updateProcessOnProgress(false); updateForceDelay(false); removeImportedFont(); removeUnwantedSpan(); }}
                                        showMapsInfo={(distance, duration) => showGoogleMapsInfo(distance, duration)}
                                        map={map}
                                        maps={maps}
                                        origin={stops[0]}
                                        destination={stops[stops.length - 1]}
                                        stops={stops}
                                        setShowMapRouteError={setShowMapRouteError}
                                    />
                                }
                                <div style={{ position: 'absolute', top: 1, right: 1, fontWeight: 'bold', textAlign: 'center', backgroundColor: '#FFF', opacity: '75%', color: 'red' }} >
                                    {showMapRouteError && (<div style={{ padding: 1 }} >Failed to find the route for this load</div>)}
                                </div>
                            </div>
                            <div style={{ height: '200px', width: "50%", marginLeft: 30, padding: 5 }}>
                                <p className={classes.mainText} style={{ marginBottom: 5 }}>Trip Details</p>
                                <div style={{ height: '160px', width: '100%', overflowY: 'auto', overflowX: "auto", padding: 5 }}>
                                    {stops.map((stop, index) => (        
                                        <div key={stop.stopId} style={{ marginBottom: 10 }}>
                                            {/* <Row>
                                                <p className={classes.secondaryMainText}>
                                                    { index === 0 ? 'Origin' : ( index === stops.length - 1 ? 'Destination' :  'Stop ' + (index + 1)) }
                                                </p>
                                            </Row> */}
                                            <Row>
                                                <p className={classes.secondaryMainText}>
                                                    {titleCaseFormatter(stop.city)}, {stop.state}
                                                </p>
                                            </Row>
                                            <dl className={classes.descriptionList} style={{marginBottom:24, width:400}}>
                                                <dt className={classes.descriptorTagTwo}>
                                                    <p className={classes.labelText}>Appointment:</p>
                                                </dt>
                                                <dd className={classes.descriptorDetailTwo}>
                                                    <p className={classes.valueText}>
                                                        {moment(stop.latestDate).format('ll  HH:mm')} 
                                                    </p>
                                                </dd>
                                            </dl>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* <div style={{ paddingLeft: '10%', height: '20px', marginTop: 20, width: "25%" }}>
                                <p className={classes.secondaryText}>Distance</p>
                                <p className={classes.thirdText}>{googleMapsDistance} miles</p>
                                <br/>
                                <p className={classes.secondaryText}>Travel Time</p>
                                <p className={classes.thirdText}>{googleMapsDuration}</p>                 
                            </div> */}
                        </Row>
                    </Col>
                }

            </Row>
        </Col>
    );
}

export default compose(
    connect(),
    muiThemeable()
)(LoadCardExpandedContent)

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    mainText: {
        fontSize: '16px',
        lineHeight: '20px',
        color: 'black',
        wordBreak: 'break-word',
        fontWeight: "bold"
    },
    secondaryMainText: {
        fontSize: '14px',
        lineHeight: '20px',
        color: 'black',
        wordBreak: 'break-word',
        fontWeight: "bold"
    },
    secondaryText: {
        fontSize: '14px',
        lineHeight: '20px',
        color: 'grey',
        fontWeight: "bold",
        wordBreak: 'break-word'
    },
    thirdText: {
        fontSize: '14px',
        lineHeight: '20px',
        color: 'grey',
        wordBreak: 'break-word'
    },
    labelText: {
        fontSize: '14px',
        color: 'grey'
    },
    valueText: {
        wordBreak: 'break-word',
        fontSize: '14px',
        color: 'black'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    missing: {
        wordBreak: 'break-word',
        fontSize: '14px',
        color: 'grey'
    },
    descriptionList: {
        width: '450px',
        overflow: 'hidden',
        padding: 0,
        margin: 0
    },
    descriptorTagOne: {
        float: 'left',
        width: '18%;',
        padding: 0,
        margin: 0,
    },
    descriptorDetailOne: {
        float: 'left',
        width: '82%;',
        padding: 0,
        margin: 0,
        display:'inline-block'
    },
    descriptorTagTwo: {
        float: 'left',
        width: '25%;',
        padding: 0,
        margin: 0,
    },
    descriptorDetailTwo: {
        float: 'left',
        width: '75%;',
        padding: 0,
        margin: 0,
        display:'inline-block'
    }
});