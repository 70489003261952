
import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import FA from 'react-fontawesome'
import { Col, Row } from "react-bootstrap"
import Button from '@material-ui/core/Button';
import * as FeatherIcon from 'react-feather';
import Popover from '@material-ui/core/Popover';
import { toast } from 'react-toastify'
import IconButton from '@material-ui/core/IconButton';
import SimpleLineIcon from 'react-simple-line-icons';
import Box from '@material-ui/core/Box';

import moment from 'moment'
import { checkValueExists } from '../../../../../lib/check-value-exists'

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBiohazard } from '@fortawesome/free-solid-svg-icons';
// import useAuthenticationHook from '../../../../hooks/authentication-hook';
import ReloadsLink from '../reloadsLink';
import LoadCardExpandedContent from './LoadCardExpandedContent'

import { titleCaseFormatter } from "../../../../../lib/title-case-formatter"
import LcDataWrap from "../LoadCardDataWrapper"
import { formatPhoneNumberWithHyphens } from '../../../../../components/formatPhoneNumber';


library.add(
    faBiohazard
);

const LoadCard = (props) => {
    const classes = useStyles();
    const {
        load,
        index,
        pickup,
        delivery,
        availability,
        isExpanded,
        dropDownIcon,
        muiTheme,
        key,
        pushReloads,
        reloadsOpen,
        carrierCommunications,
        
    } = props

    const [isHazmat, setIsHazmat] = useState(false)


    // const myAuthService = useAuthenticationHook()
    // const isAuthenticated = myAuthService.isAuthenticated()
    // const user = myAuthService.getUser()

    const [anchorEl, setAnchorEl] = useState(null);
    const openRateBreakdown = Boolean(anchorEl);
    const open = Boolean(anchorEl);
    const id = openRateBreakdown ? 'rate-info-popover' : undefined;

    const handleRateOpen = (event, tag) => {
        setAnchorEl(event.currentTarget)
    };

    const handleRateClose = () => {
        if (anchorEl !== null)
            setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTrackingHelperOpen = (event, tag) => {
        setAnchorEl(event.currentTarget)
    };

    const handleTrackingHelperClose = () => {
        if (anchorEl !== null)
            setAnchorEl(null);
    };

    const getIcon = (type) => {
        const SQUARE_SIZE = 6
        let icon = null
        let key = "originSignal"
        if (type == "origin") {
            icon = <div
                style={{
                    width: SQUARE_SIZE + 3,
                    height: SQUARE_SIZE + 3,
                    borderRadius: (SQUARE_SIZE + 6) / 2,
                    backgroundColor: '#A4A4AC',
                    width: 12,
                    height: 12,
                }}
            />
        } else {
            key = "destinationSignal"
            icon = <div
                style={{
                    width: SQUARE_SIZE + 3,
                    height: SQUARE_SIZE + 3,
                    backgroundColor: muiTheme.ttUnselectGrey,
                    width: 12,
                    height: 12,
                }}
            />
        }

        return icon
    }

    useEffect(() => {

        if (load.truckTypes) {
            if (load.truckTypes.toUpperCase().indexOf('HAZMAT') > -1)
                setIsHazmat(true)
        }
    }, [])

    const getDropDownIcon = () => {
        return <FA name={dropDownIcon} size='2x' />
    }

    const getTrackingHelperPopover = (id, openRateBreakdown, anchorEl) => {
        return (
            <Popover
                onClick={(e) => e.stopPropagation()}
                id={id}
                open={openRateBreakdown}
                anchorEl={anchorEl}
                PaperProps={{ onMouseLeave: handleTrackingHelperClose }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                // style={{ pointerEvents: 'none' }}
                disableRestoreFocus
            >
                <div style={{ padding: 15, width: 220, maxHeight: 300 }}>
                    <p className={classes.valueText}> Contact <a href={"tel:1-800-000-0000"} style={{ color: muiTheme.actionColor }}>1-800-000-0000</a><br />to update tracking details.</p>
                </div>
            </Popover>
        )
    }


    const checkWhiteSpace = (textInput) => {
        let status = true;
        if (textInput) {
            if (textInput.trim().length === 0)
                status = false
        };
        return status
    }

    return (

        <div>

            <Row style={{ display: 'flex' }}>

                <Col style={{ width: '5%', display: 'flex' }}>
                    <Button
                        key={key + dropDownIcon + index}
                        style={{ backgroundColor: 'transparent', justifyContent: 'flex-start' }}
                        disableRipple={true}
                    >
                        {getDropDownIcon()}
                    </Button>
                </Col>

                <Col style={{ width: "13%", display: 'flex' }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                    <LcDataWrap>
                        <p className={classes.mainText}> {titleCaseFormatter(load.originCity) + ', ' + titleCaseFormatter(load.originState)} </p>
                        <p className={classes.secondaryText}>
                            {moment(load.pickupDateTime).format('ll')} {moment(load.pickupDateTime).format('HH:mm')} 
                        </p>
                        <p className={classes.secondaryText}>
                            {checkValueExists(load, 'pickupDateTimeLatest') && 
                                `- ${moment(load.pickupDateTimeLatest).format('ll')} ${moment(load.pickupDateTimeLatest).format('HH:mm')}`
                            }
                        </p>
                    </LcDataWrap>
                    </Col>
                </Col>

                <Col style={{ width: "10%" }}>
                    <div>
                        <Col md={1} className={classes.centerColContent} style={{ paddingRight: '10px', paddingTop: '5px', alignItems: 'center' }}>

                            {getIcon("origin")}


                        </Col>
                        <Col md={7} style={{ alignItems: 'center', padding: "5px" }}>
                            <hr style={{ marginTop: 6, marginBottom: 10, width: '100%', border: '1px dashed grey' }} />

                            {checkValueExists(load, "tourStops") && load.tourStops > 0 &&
                                (<p className={classes.secondaryText} style={{ textAlign: 'center' }}>
                                    {load.tourStops} stops
                                </p>)
                            }

                        </Col>
                        <Col md={1} className={classes.centerColContent} style={{ paddingLeft: '10px', paddingTop: '5px', alignItems: 'center' }}>

                            {getIcon("destination")}


                        </Col>
                    </div>
                </Col>

                <Col style={{ width: "14%" }}>
                    <div>
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <LcDataWrap>
                                <p className={classes.mainText}> {titleCaseFormatter(load.destinationCity) + ', ' + titleCaseFormatter(load.destinationState)}</p>
                                <p className={classes.secondaryText}>
                                    {moment(load.deliveryDateTime).format('ll')} {moment(load.deliveryDateTime).format('HH:mm')}
                                </p>
                                {/* <p className={classes.secondaryText}>
                                {moment(load.pickupDateTimeLatest).format('ll')} {moment(load.pickupDateTimeLatest).format('HH:mm')}
                            </p> */}
                            </LcDataWrap>
                        </Col>
                    </div>
                </Col>
 
                <Col style={{ width: "5%" }}>
                    {/* <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        <LcDataWrap>
                            <p className={classes.headerText}>
                                {checkValueExists(load,'carrierTotal') ? 
                                    `$${load.carrierTotal}`
                                    :
                                    `N/A`
                                }
                            </p>
                        </LcDataWrap>
                    </Col> */}
                    {/* Temporarily hiding rate until my-loads is at the user/driver level */}
                </Col>

                <Col style={{ width: "15%" }}>
                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        <LcDataWrap>
                            {!props.onInvoicesScreen &&
                                <ReloadsLink
                                    key={key + "_reloads_button"}
                                    load={load}
                                    delivery={delivery}
                                    pushReloads={pushReloads}
                                />
                            }
                        </LcDataWrap>
                    </Col>
                </Col>

                <Col style={{ width: "11%" }}>

                    <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        <LcDataWrap>
                            <p className={classes.secondaryText}>{load.truckTypes}</p>
                        </LcDataWrap>
                    </Col>

                </Col>
                <Col style={{ width: "10%" }}>

                    <Col className={classes.centerColContent} style={{ alignItems: 'flex-start' }}>
                        <LcDataWrap>
                            <p className={classes.mainText}>Driver: <span className={classes.secondaryText}> {load.driverAssigned && load.driverAssigned === 1 ? 'Assigned' : 'Unassigned'} </span></p>
                            <p className={classes.mainText}>Truck: <span className={classes.secondaryText}> {load.truckAssigned && load.truckAssigned === 1 ? 'Assigned' : 'Unassigned'} </span></p>
                        </LcDataWrap>
                    </Col>

                </Col>
                <Col style={{ width: "12%" }}>

                    {/* <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                        <p className={classes.secondaryText}>{load.current_status}</p>
                    </Col> */}

                </Col>
                <Col style={{ width: "5%", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    {/* <div>
                        <IconButton aria-describedby={id} onClick={handleClick}>
                            <SimpleLineIcon name={'options'} color={'black'} style={{ fontSize: 30 }} />
                        </IconButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Box p={1}>
                                <Button
                                    onClick={() => {
                                        // setShowEditModal(true)
                                        handleClose()
                                        // useGtagHook(
                                        //     "open_edit_saved_search",
                                        //     {
                                        //         location: item.pickupCity + ", " + item.pickupState,
                                        //         isRecurring: checkValueExists(item, "pickupWeekDay"),
                                        //         pickupDate: checkValueExists(item, "pickupWeekDay") && Object.keys(item.pickupWeekDay).length > 0 ? item.pickupWeekDay : item.availableTime,
                                        //         pickupTime: item.availableTime,
                                        //         destinations: item.destinations,
                                        //         equipments: item.truckTypes
                                        //     }
                                        // )                                   
                                    }}
                                    // startIcon={<FeatherIcon.Edit color={"black"} size={25} />}
                                    style={{ width: '100%', display: "flex", justifyContent: "left" }}
                                >
                                    <p className={classes.buttonText} style={{ textTransform: 'none' }}> START TRACKING </p>
                                </Button>
                                <Button
                                    onClick={() => {
                                        // handleDeleteLanePreference()
                                        handleClose()
                                        // useGtagHook(
                                        //     "delete_saved_search",
                                        //     {
                                        //         location: item.pickupCity + ", " + item.pickupState,
                                        //         isRecurring: checkValueExists(item, "pickupWeekDay"),
                                        //         pickupDate: checkValueExists(item, "pickupWeekDay") && Object.keys(item.pickupWeekDay).length > 0 ? item.pickupWeekDay : item.availableTime,
                                        //         pickupTime: item.availableTime,
                                        //         destinations: item.destinations,
                                        //         equipments: item.truckTypes
                                        //     }
                                        // )
                                    }}
                                    // startIcon={<FeatherIcon.Trash2 color={muiTheme.ttRed} size={25} />}
                                    style={{ width: '100%', display: "flex", justifyContent: "left" }}
                                >
                                    <p className={classes.buttonText} style={{ textTransform: 'none' }}> UPLOAD DOCUMENT </p>
                                </Button>
                            </Box>

                        </Popover>
                    </div> */}
                </Col>

            </Row>

            {isExpanded &&
                <LoadCardExpandedContent
                    load={load}
                    index={index}
        
                />
            }


        </div>

    );
}


export default compose(
    connect(),
    muiThemeable()
)(LoadCard)

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        margin: 0,
        fontWeight: "bold"
    },
    buttonText: {
        fontSize: '16px',
        color: 'black',
        margin: 0,
        textAlign: 'center',
        width: 'inherit'
    },
    secondaryText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'grey'
    },
    valueText: {
        wordBreak: 'break-word',
        fontSize: '16px',
        color: 'black'
    },
    missing: {
        wordBreak: 'break-word',
        fontSize: '14px',
        color: 'grey'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    },
    showInterestBtn: {
        height: 'auto',
        width: '100%',
        // color: muiTheme.secondary, 
        backgroundColor: '#ad2228 !important',
        // borderColor: muiTheme.primary
    },
    descriptionList: {
        width: '200px',
        overflow: 'hidden',
        padding: 0,
        margin: 0
    },
    descriptorTag: {
        float: 'left',
        width: '40%;',
        padding: 0,
        margin: 0,
    },
    descriptorDetail: {
        float: 'left',
        width: '60%;',
        padding: 0,
        margin: 0,
        display: 'inline-block'
    }
});