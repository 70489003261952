
import {  BACKEND_URL } from "../../../api/constants"
import {useAuthenticationHook} from "../../hooks/authentication-hook"
export function GeneralServices() {
    const myAuthService = useAuthenticationHook()
    const BASE_URL = BACKEND_URL


    const    createPostRequestOptions = (params)=>{

        params.advantageId = process.env.REACT_APP_ADVANTAGE_ID

        let requestObject = {
            method: 'POST',
            headers:{'Content-Type':'application/json'},
            body: JSON.stringify(params)
        }
        return requestObject
    }

    return {
        async getLocations() {
            let promise = new Promise((resolve, reject) => {
                try {
                    let user = myAuthService.getUser()
                    let externalId = user.carrier.externalId
                    // console.log("externalId: ", externalId) // 100010828

                    const GET_LOCATIONS_URL = "/account/getLocations?advantagId=" + process.env.REACT_APP_ADVANTAGE_ID + "&carrierExternalId=" + externalId
                    let url = BASE_URL + GET_LOCATIONS_URL
                    let requestObject = {
                        method: 'GET',
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LOCATIONS: ", jsondata)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LOCATIONS ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LOCATIONS E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },


        async getTruckCapacity() {
            let promise = new Promise((resolve, reject) => {
                try {
                    const API_URL = "/api/carrier/truckCapacity" 
                    let url = BASE_URL + API_URL
                    let requestObject = {
                        method: 'GET',
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("AVAILABLE_CAPACITY: ", jsondata)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("AVAILABLE_CAPACITY ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("AVAILABLE_CAPACITY E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async addTruckCapacity(params) {
            let promise = new Promise((resolve, reject) => {
                try {
                    const API_URL = "/api/carrier/saveTruckCapacity"
                    let url = BASE_URL + API_URL
                    let  requestObject = createPostRequestOptions(params);

                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("AVAILABLE_CAPACITY: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("AVAILABLE_CAPACITY ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("AVAILABLE_CAPACITY E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async editTruckCapacity(params) {
            let promise = new Promise((resolve, reject) => {
                try {
                    const API_URL = "/api/carrier/saveTruckCapacity"
                    let url = BASE_URL + API_URL
                    
                    let  requestObject = createPostRequestOptions(params);

                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("AVAILABLE_CAPACITY: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("AVAILABLE_CAPACITY ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("AVAILABLE_CAPACITY E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async enableNotificationsTruckCapacity(params,id) {
            let promise = new Promise((resolve, reject) => {
                try {

                    const API_URL = "/api/carrier/truckCapacity/"+id+"/enableNotifications"
                    let url = BASE_URL + API_URL
                    let  requestObject = createPostRequestOptions(params);
                    
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCE: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCE ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCE E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async disableNotificationsTruckCapacity(params,id) {
            let promise = new Promise((resolve, reject) => {
                try {

                    const API_URL = "/api/carrier/truckCapacity/"+id+"/disableNotifications"
                    let url = BASE_URL + API_URL
                    let  requestObject = createPostRequestOptions(params);
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCE: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCE ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCE E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async onHoldTruckCapacity(params,id) {
            let promise = new Promise((resolve, reject) => {
                try {
                    
                    params.id = id
                    const API_URL = "/api/carrier/truckCapacity/onHold"
                    let url = BASE_URL + API_URL
                    
                    let  requestObject = createPostRequestOptions(params);
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCE: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCE ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCE E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async deleteTruckCapacity(params,id) {
            let promise = new Promise((resolve, reject) => {
                try {

                    const API_URL = "/api/carrier/truckCapacity/"+id+"/delete"
                    let url = BASE_URL + API_URL
                    let  requestObject = createPostRequestOptions(params);

                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCE: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCE ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCE E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },


        async getLanePreferences() {
            let promise = new Promise((resolve, reject) => {
                try {
                    const GET_LANE_PREFERENCES_URL = "/api/carrier/lanePreferences"
                    let url = BASE_URL + GET_LANE_PREFERENCES_URL

                    let requestObject = {
                        method: 'GET',
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCES: ", jsondata)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCES ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCES E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async addLanePreference(params) {
            let promise = new Promise((resolve, reject) => {
                try {

                    const API_URL = "/api/carrier/saveLanePreference"
                    let url = BASE_URL + API_URL
                    
                    let  requestObject = createPostRequestOptions(params);

                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCE: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCE ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCE E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async editLanePreference(params) {
            let promise = new Promise((resolve, reject) => {
                try {
                    

                    const API_URL = "/api/carrier/saveLanePreference"
                    let url = BASE_URL + API_URL
                    let  requestObject = createPostRequestOptions(params);

                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCE: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCE ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCE E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async enableNotificationsLanePreference(params,id) {
            let promise = new Promise((resolve, reject) => {
                try {

                    const API_URL = "/api/carrier/lanePreference/"+id+"/enableNotifications"
                    let url = BASE_URL + API_URL
                    
                    let  requestObject = createPostRequestOptions(params);

                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCE: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCE ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCE E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async disableNotificationsLanePreference(params,id) {
            let promise = new Promise((resolve, reject) => {
                try {
                   

                    const API_URL = "/api/carrier/lanePreference/"+id+"/disableNotifications"
                    let url = BASE_URL + API_URL
                    let requestObject = {
                        method: 'POST',
                        body: JSON.stringify(params)
                    }
                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCE: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCE ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCE E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async publishLanePreference(params,id) {
            let promise = new Promise((resolve, reject) => {
                try {
                    params.advantageId = process.env.REACT_APP_ADVANTAGE_ID

                    const API_URL = "/api/carrier/lanePreference/"+id+"/publish"
                    let url = BASE_URL + API_URL
                    
                    let  requestObject = createPostRequestOptions(params);

                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCE: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCE ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCE E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async unpublishLanePreference(params,id) {
            let promise = new Promise((resolve, reject) => {
                try {

                    const API_URL = "/api/carrier/lanePreference/"+id+"/unpublish"
                    let url = BASE_URL + API_URL
                    
                    let  requestObject = createPostRequestOptions(params);

                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCE: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCE ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCE E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async onHoldLanePreference(params,id) {
            let promise = new Promise((resolve, reject) => {
                try {
                    
                    params.id = id
                    const API_URL = "/api/carrier/lanePreference/onHold"
                    let url = BASE_URL + API_URL
                    
                    let  requestObject = createPostRequestOptions(params);

                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCE: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCE ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCE E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
        async deleteLanePreference(params,id) {
            let promise = new Promise((resolve, reject) => {
                try {
                    
                    const API_URL = "/api/carrier/lanePreference/"+id+"/delete"
                    let url = BASE_URL + API_URL
                    
                    let  requestObject = createPostRequestOptions(params);


                    let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
                    // console.log("newRequestObject: ", newRequestObject)
                    fetch(url, newRequestObject)
                        .then(response => response.json())
                        .then(jsondata => {
                            // console.log("LANE_PREFERENCE: ", response)
                            resolve(jsondata)
                        })
                        .catch(error => {
                            // console.log("LANE_PREFERENCE ERROR: ", error)
                            let errorObj = {
                                status: false,
                                message: "Error"
                            }
                            reject(errorObj)
                        })
                } catch (e) {
                    // error reading value
                    // console.log("LANE_PREFERENCE E: ", e)
                    let errorObj = {
                        status: false,
                        message: "Error"
                    }
                    reject(errorObj)
                }
            })

            let returnPromise = await promise
            return returnPromise
        },
    }
}