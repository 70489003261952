import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { useAuth0 } from "@auth0/auth0-react";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Modal from '@material-ui/core/Modal';
import { Row, Col, Grid } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import FA from 'react-fontawesome'
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import LinearProgress from '@material-ui/core/LinearProgress';
import queryString from 'query-string';

import Mobile from '../../HOC/Mobile'
import DefaultSignupModalContent from "../authentication/signup/signup-modal-content"
import WernerSignupModalContent from "../customers/a98db973/authentication/signup/signup-modal-content"

import { getUserByEmail } from "../apis/api-signup"
import { checkValueExists } from '../../lib/check-value-exists';

import {
    isTablet
} from "react-device-detect"

const SignupScreen = (props) => {

    const { isMobile, muiTheme, location } = props

    const { loginWithRedirect } = useAuth0();

    const useStyles = makeStyles((theme) => ({
        container: {
            height: '100%',
            width: "100%",
            backgroundColor: "white",
            // maxHeight: '100%',
            // overflow: 'auto'
        },
        root: {
            height: '100%',
            flexGrow: 1,
            minWidth: 300,
            maxWidth: window.width - 300,
            transform: 'translateZ(0)',
            // The position fixed scoping doesn't work in IE 11.
            // Disable this demo to preserve the others.
            '@media all and (-ms-high-contrast: none)': {
                display: 'none',
            },
        },
        modal: {
            display: 'flex',
            padding: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            width: 664,
            height: 600,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            overflowY: "scroll"
        },
        formRoot: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        row: {
            display: "flex",
            justifyContent: "center"
        },
        colMiddle: {
            width: "60%",
            marginBottom: 20
        },
        mainText: {
            fontSize: '16px',
            lineHeight: '24px',
            color: 'black',
            fontWeight: 'bold'
        },
        secondaryText: {
            fontSize: '14px',
            lineHeight: '21px',
            color: 'grey',
        },
        progressBar: {
            width: '80%',
        },
    }));

    useEffect(() => {
        let url = location.search;
        let params = queryString.parse(url);
        if (params.email) {
            setUserEmail(params.email)
            setExistingUserName({
                firstName: params.firstName,
                lastName: params.lastName
            })
            setShowForm(true)
            setIsExistingUser(true)
        }
    }, [location])

    const classes = useStyles()
    const rootRef = React.useRef(null);

    const [isOpen, setIsOpen] = useState(true)
    const [userEmail, setUserEmail] = useState("")
    const [showForm, setShowForm] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)
    const [showRedirectLoading, setShowRedirectLoading] = useState(false)
    const [progress, setProgress] = useState(0)
    const [isExistingUser, setIsExistingUser] = useState(false)
    const [existingUserName, setExistingUserName] = useState(false)


    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 5,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: muiTheme.ttRed,
        },
    }))(LinearProgress);


    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const checkForExistingUser = () => {
        setErrorMsg(null)
        if (userEmail == null || userEmail === "" || !validateEmail(userEmail)) {
            toast.error("Please enter a valid email address")
            // setErrorMsg("Please enter a valid email address")
            return
        }

        getUserByEmail(userEmail, true).then(response => {
            // console.log("response: ", response)
            if (Array.isArray(response) && response.length == 0) {
                // empty array if no user found
                setShowForm(true)
            } else if (Array.isArray(response) && response.length == 1) {
                let toastMsg = "An account has already been created for you. " + "\n" +
                "If you have forgotten your password, click the link at the bottom of the login form."
                if (response[0].passwordLinkSent && response[0].passwordLinkSent === true) {
                    toastMsg = "An account has already been created for you. " + "\n" +
                    "Please check your email account at "+ userEmail + " for a link to set your account password."
                }
                setErrorMsg(toastMsg)
                setShowRedirectLoading(true)
                setProgress(6000)
                setTimeout(() => {
                    if (process.env.REACT_APP_USE_AUTH0 == "true") {
                        loginWithRedirect()
                    } else {
                        window.location = "/login"
                    }
                }, 6000);
            } else {
                if (!response.status && checkValueExists(response, "message") && response.message !== "") {
                    // example
                    // {
                    //     "status": false,
                    //     "message": "Invalid email"
                    // }
                    // toast.error(response.message)
                    setErrorMsg("Error: " + response.message)
                } else {
                    setErrorMsg("There was a problem. Please refresh the page and try again.")
                }
            }

        }).catch(error => {
            // console.log("error: ", error)
            setErrorMsg("There was a problem. Please refresh the page and try again.")
        })
    }

    const getBody = () => {
        if (muiTheme.customerUniqueId && muiTheme.customerUniqueId == "a98db973") {
            return <WernerSignupModalContent {...props} onBack={() => closeModal()} />
        }
        else {
            return (
                <div style={{ height: '100%' }}>
                    {showForm && userEmail && userEmail != "" ? (
                        <DefaultSignupModalContent {...props} onBack={() => { setUserEmail(""); setShowForm(false) }} userEmail={userEmail} isExistingUser={isExistingUser} existingData={existingUserName} />
                    ) : (
                        <Grid style={{ height: '100%', width: '100%', display: "flex", justifyContent: "center" }}>
                            <Col style={{ width: "100%" }}>
                                <Row style={{ width: "100%", height: 25, marginBottom: 15 }}>
                                    <Button
                                        style={{
                                            backgroundColor: "transparent",
                                            color: muiTheme.actionColor,
                                            fontSize: 14,
                                            textTransform: 'none',
                                            display: 'block'
                                        }}
                                        onClick={() => closeModal()}
                                    >
                                        <FA name={"chevron-left"} color={muiTheme.actionColor} style={{ marginRight: 5 }} />
                                        Back
                                    </Button>
                                </Row>
                                {!showRedirectLoading && (
                                    <h3 style={{ textAlign: "center" }}>Please enter your email adress</h3>
                                )}
                                <Row style={{ display: "flex", }}>
                                    <Col style={{ width: "100%" }}>
                                        <form className={classes.formRoot}>
                                            <Row className={classes.row}>
                                                <div className={classes.colMiddle}>
                                                    <p className={classes.secondaryText}>Email</p>
                                                    <TextField
                                                        inputProps={{ style: { fontSize: 16 } }}
                                                        fullWidth
                                                        value={userEmail}
                                                        // placeholder={"jess@truckertools.com"}
                                                        onChange={(e) => setUserEmail(e.target.value)}
                                                        variant={"filled"}
                                                    />
                                                </div>
                                            </Row>
                                        </form>
                                    </Col>
                                </Row>
                                <Row style={{}}>
                                    <Col style={{ width: "100%" }}>
                                        {errorMsg && (
                                            <p style={{ fontSize: 16, color: muiTheme.ttRed, textAlign: "center" }}>{errorMsg}</p>
                                        )}
                                        {showRedirectLoading && (
                                            <div style={{ width: "100%", margin: 10, display: "flex", justifyContent: "center" }}>
                                                <div className={classes.progressBar}>
                                                    <BorderLinearProgress color="secondary" />
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row style={{ display: "flex", justifyContent: "center" }}>
                                    <Button
                                        variant={"contained"}
                                        size={"small"}
                                        disabled={userEmail && userEmail != "" ? false : true}
                                        style={{
                                            visibility: !showRedirectLoading ? "visible" : "hidden",
                                            fontSize: 14,
                                            color: "white",
                                            backgroundColor: userEmail && userEmail != "" ? muiTheme.actionColor : "grey",
                                            borderColor: muiTheme.actionColor
                                        }}
                                        onClick={() => checkForExistingUser()}
                                    >
                                        CONTINUE
                                    </Button>
                                </Row>
                            </Col>
                        </Grid>
                    )
                    }
                </div>
            )
        }

    }

    const closeModal = () => {
        setIsOpen(false)
        window.location = "/loads"

    }

    return (
        <div className={classes.container}>
            {!isMobile || isTablet ? (
                <div className={classes.root} ref={rootRef} style={{ backgroundColor: "transparent" }}>
                    <Modal
                        disablePortal
                        disableEnforceFocus
                        disableAutoFocus
                        open={isOpen}
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                        className={classes.modal}
                        style={{ backgroundColor: "transparent" }}
                        container={() => rootRef.current}
                    >
                        <div className={ !showForm ? classes.paper : ''}>
                            {getBody()}
                        </div>
                    </Modal>
                </div>
            ) : (
                <div style={{ height: '100%', backgroundColor: "white", overflowX: "hidden", overflowY: "auto" }}>
                    {getBody()}
                </div>
            )}

        </div>
    )
}


export default compose(
    Mobile(),
    muiThemeable(),
)(SignupScreen)