import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { numberWithCommas } from '../../../../../lib/number-with-commas';
import { defaultDisableCrstActions, responsibilityHideRate, responsibilityShowInterest } from '../../../../../lib/responsibility-code-functions';
import { validLoadDetails } from "../../../../../lib/valid-load-details";
import { useAuthenticationHook } from '../../../../hooks/authentication-hook';
import BinButton from "../../bin/binButton";
import CounterOfferForm from "./counterOfferForm";

const QuoteActionsDesktop = (props) => {

    const {
        load,
        pickup,
        delivery,
        offerThread,
        availability,
        autoBinForm,
        submitCounterOffer = () => { },
        //state variables
        showBinBtn,
        showRatePer,
        binAmount,
        useShowInterest,
        //other
        cardStyle = null,
        userProfile = false
    } = props
    const myAuthService = useAuthenticationHook()
    const user = myAuthService.getUser()
    const useStyles = makeStyles({
        binNotesContainer: {
            ...cardStyle,
            display: (!user || (user && user.role !== "" && user.role.toLowerCase() !== "finance" && user.role.toLowerCase() !== "loadviewer")) ? "flex" : "none",
            flexDirection: 'row',
            padding: 12,
            justifyContent: 'space-evenly',
            alignItems: 'center'
        },
        quoteFormContainer: {
            ...cardStyle,
            display: 'flex',
            flexDirection: 'column'
        },
    });
    const classes = useStyles()

    const binButtonStyle = {
        width: '100%',
        borderRadius: 15,
        fontSize: 16,
        margin: 0
    }

    return (
        <div>
            {showBinBtn && binAmount && parseFloat(binAmount) > 0 && responsibilityShowInterest(load, userProfile) == false &&
                <div data-cy="desktop-bin-button-container" style={{ display: 'flex', flexDirection: 'column', marginBottom: 16 }}>
                    <div key={"action_row"} className={classes.binNotesContainer}>
                        {(load.showBookItNowPrice === 1 || showBinBtn) && (responsibilityHideRate(load, userProfile) !== true) && defaultDisableCrstActions(userProfile) &&
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p className='labelText' style={{ fontSize: 16, fontWeight: 'bold' }}>
                                    Book It Now Rate
                                </p>
                                <p data-cy="desktop-bin-amount" className='titleText' >
                                    ${numberWithCommas(parseFloat(binAmount).toFixed(2))}
                                </p>
                            </div>
                        }

                        {(load.showBookItNowPrice === 1 || showBinBtn) && showRatePer && ((load.fullLoadDetails && load.fullLoadDetails.ratePer) || load.ratePer) &&
                            (responsibilityHideRate(load, userProfile) !== true) && defaultDisableCrstActions(userProfile) &&

                            <p className='titleText' style={{ alignSelf: 'flex-end' }}>
                                ${parseFloat(load.ratePer || load.fullLoadDetails.ratePer).toFixed(2)}/mi
                            </p>
                        }

                        {showBinBtn && validLoadDetails(load, ["weight"]) && 
                            <div data-component="div-quote-bin-button">
                                <BinButton
                                    key={"BIN_button"}
                                    carrierId={availability?.carrier_id}
                                    availabilityId={availability?.id}
                                    load={load}
                                    index={0}
                                    pickup={pickup}
                                    delivery={delivery}
                                    quoteValue={binAmount}
                                    customStyle={binButtonStyle}
                                    autoBinForm={autoBinForm}
                                    {...props}
                                />
                            </div>
                        }


                    </div>
                    <div className='labelText' style={{ display: 'flex', paddingRight: cardStyle ? 56 : 0, paddingLeft: cardStyle ? 56 : 0 }}>
                        <hr style={{ marginTop: 8, marginBottom: 8, flex: 1, border: '1px dashed #525C76' }} />
                        <p style={{ paddingLeft: 4, paddingRight: 4, color: '#525C76' }}>OR</p>
                        <hr style={{ marginTop: 8, marginBottom: 8, flex: 1, border: '1px dashed #525C76' }} />
                    </div>
                </div>
            }
            <div key={"action_row"}>
                <div className={classes.quoteFormContainer}>
                    <div className='titleText' style={{ whiteSpace: 'nowrap', fontSize: 20, paddingBottom: 8 }}>
                        {responsibilityShowInterest(load, userProfile) ? 'Show Interest' : 'Make Offer'}
                    </div>
                    <CounterOfferForm
                        load={load}
                        offer={Array.isArray(offerThread) && offerThread[0]?.loadOffer ? offerThread[0]?.loadOffer : null} //latest offer
                        submitCounterOffer={submitCounterOffer}
                        _onClose={() => { }}
                        onTargetLoadScreen
                        useShowInterest={useShowInterest}
                    />
                </div>
            </div>
        </div>

    )

}

const mapStateToProps = state => ({
    userProfile: state.Profile.profileData,
})


export default connect(mapStateToProps)(QuoteActionsDesktop)