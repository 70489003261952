import { PostRemoteLog } from '../../apis/api-remote-log'
import { BACKEND_URL } from '../../../api/constants'
import { useAuthenticationHook } from '../../hooks/authentication-hook'

// const BACKEND_URL = "http://scapi-dev.truckertools.com" //TODO: make dynamic
const BEST_MATCHED_LOADS_URL = "/api/carrier/bestMatchesForCarrier"



export const GetBestMatchedLoads = (params) => {

    const ROOT_URL = process.env.REACT_APP_ENVIRONMENT === 'staging' ? "https://scapi-staging.truckertools.com" : BACKEND_URL

    const url = ROOT_URL + BEST_MATCHED_LOADS_URL

    const myAuthService = useAuthenticationHook()

    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
        // console.log("newRequestObject: ", newRequestObject)
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + BEST_MATCHED_LOADS_URL,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data: JSON.stringify(params)
                }
                PostRemoteLog(payload).then(response => {
                    // console.log("REMOTE LOG RESPONSE: ", response)
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + BEST_MATCHED_LOADS_URL,
            message: {
                error: JSON.stringify(error)
            },
            data: JSON.stringify(params)
        }
        PostRemoteLog(payload).then(response => {
            // console.log("REMOTE LOG RESPONSE: ", response)
        }).catch((error) => {
        })
    }
}

