import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import { Fade } from "@material-ui/core";
import ContentCopy from 'material-ui/svg-icons/content/content-copy'
import Check from 'material-ui/svg-icons/navigation/check'


const CopyContentsButton = (props) => {

    const {
        content = '',
        isMobile = false,
        muiTheme
    } = props

    const [isCopied, setIsCopied] = useState(false)

    const useStyles = makeStyles({

    });

    const classes = useStyles();


    const handleClick = () => {
        if(!isCopied){
            setIsCopied(true)
            setTimeout(() => setIsCopied(false), 10000); 
        }
    }

    // {<ContentCopy color={muiTheme.actionColor} size={'small'} />}

    return (
        <Button
            startIcon = {
                isCopied ?
                <Check color={muiTheme.actionColor}/>
                :
                <Fade in={!isCopied}>{<ContentCopy color={muiTheme.actionColor}/>}</Fade>
            }
            variant="outlined"
            style={{
                width: isMobile ? 92 : 150,
                height: 36,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: muiTheme.actionColor,
                borderColor: muiTheme.actionColor
            }}
            onClick={() => { navigator.clipboard.writeText(content); handleClick() } }
        >
            <div style={{ fontSize: 14 }}> {isCopied ?  `copied` : <Fade in={!isCopied}><span>{isMobile ? 'copy' : 'copy email'}</span></Fade>} </div>
    </Button>
    )
}

export default compose(
  muiThemeable()
)(CopyContentsButton)