import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
//COMPONENTS//
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import HelpWrapper from '../../HelpWrapper'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const TruckTypeSelectWerner = ({
  //PROPS FROM PARENT//
  handleSetTruckType,
  existingTruckType = null,
  //OTHER
  muiTheme,
  mobileView = false
}) => {

  const useStyles = makeStyles({
    root: {
      fontSize: '16px',
    },
    wrapper: {
      display: 'contents',
      fontSize: '16px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    placeholder: {
      color: '#757575',
    },
    menuItem: {
      fontSize: '16px'
    },
    selectfieldStyle: {
      //border: '1px solid #ccc',
      height: '45px',
      width: '100%',
      maxWidth: mobileView ? null : '200px',
      minWidth: '200px',
      margin: '0px',
      overflow: 'visible',
      //backgroundColor: 'white',
      // outline: '1px solid black',
      paddingLeft: '10px',
      fontSize: '16px',
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.1,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    icon: {
      position: 'absolute',
      fontSize: '2.5rem',
      textAlign: 'right'
    },
    chips: {
      fontSize: '14px'
    },
    removePadding: {
      paddingRight: '0 !important'
    }
  });
  const classes = useStyles();

  const [truckTypes, setTruckTypes] = useState([]);
  const [finalSelectedType, setFinalSelectedType] = useState(null); // Array of selected truck types
  const [menuOpen, setMenuOpen] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => {
    getTruckTypes();

    if (existingTruckType) {
      setFinalSelectedType(existingTruckType)
    }
    return () => {
      setTruckTypes([]); // NOTE: Issue when using this clean up function
    }
  }, []);

  async function getTruckTypes() {
    let condensedTruckTypeList = ["VAN", "FLATBED", "TCU"]
    setTruckTypes(condensedTruckTypeList)
  }


  function handleChange(event) {

    // 'value' here represents the truck type ids selected from dropdown as in the value of the event target
    const { value } = event.target;
    let chosenTruckType = value

    setFinalSelectedType(chosenTruckType);
    handleSetTruckType(chosenTruckType)

  };


  function renderSelectedType(selected) {
    if (finalSelectedType === null) {
      return (
        <div className={classes.wrapper} style={{ fontSize: 14, color: "#aaa" }}>
          {
            'Select Equipment Type'
          }
        </div>
      )
    }
    else {
      return (
        <div className={classes.wrapper}>
          {
            finalSelectedType
          }
        </div>
      )
    }
  }

  return (
    <HelpWrapper text='Select your equipment types so we can find loads that match. (Click anywhere outside once finished)' location='bottom' zIndex={1350} enabled={!menuOpen}>
      { truckTypes &&
        <FormControl variant="filled" className={classes.formControl} fullWidth required >
          <InputLabel shrink={finalSelectedType} style={{fontSize:16}} id="location">Select Equipment</InputLabel>
          <Select
                //className={classes.selectfieldStyle}
                labelId="select-label"
                //disableUnderline={true}
                //autoWidth={false}
                //displayEmpty
               //input={<Input id="truck-type" />}
                value={finalSelectedType}
                onChange={(event) => handleChange(event)}
                // renderValue={(selected) => (                                     
                //   <Chip variant="outlined" key={selected} label={selected} className={classes.chips}/>
                // )}
                inputProps={{
                  classes: {
                    // icon: classes.icon,
                    root: classes.root
                  },
                }}
                // MenuProps={{
                //   disableScrollLock: true,
                //   getContentAnchorEl: null,
                //   anchorOrigin: {
                //     vertical: "bottom",
                //     horizontal: "left",
                //   }
                // }}
                onOpen={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
              >
              {/* <MenuItem
              className={classes.menuItem}
              style={{ color: "#aaa" }}
              value=""
              disabled>
              Select Equipment
            </MenuItem> */}
            {truckTypes && truckTypes.map((type) => {
              return (
                <MenuItem
                  key={type}
                  className={classes.menuItem}
                  value={type}
                >
                  {/* <Checkbox checked={finalSelectedType === type} />
                  <ListItemText classes={{primary:classes.menuItem}} primary={type} /> */}
                  {type}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      }
      </HelpWrapper>
  );
}

export default compose(
  muiThemeable()
)(TruckTypeSelectWerner)