import {useAuthenticationHook} from '../CarrierPortal/hooks/authentication-hook.js';
import { BACKEND_URL } from './constants.js'


export const GetPreferences = (email) => {
  //console.log('...',email);
  const myAuthService = useAuthenticationHook()
  var requestUrl = BACKEND_URL + '/getPreferences?email=' + email;
  //console.log('...',requestUrl);
  let requestObject = {
    method: 'GET',
  }
  let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
  return fetch(requestUrl, newRequestObject)
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      //console.log('json...', response.json());
      //console.log('json...', response.size());

      return response.json();
    });

  /*
  Example Response : {
            "receiveEmails":0,
            "invitationEmails":0,
            "publishCapacity":0,
            "allowDrivers":0
            "userEmail":sample@dfdsf.com,
  }
  */
}

export const UpdatePreferences = (data) => {
  const myAuthService = useAuthenticationHook()
  //console.log("data....", data);
  var requestUrl = BACKEND_URL + '/updatePreferences';
  //Sample Payload send to db
  //data={"receiveEmails":1,"invitationEmails":0,"publishCapacity":1,"allowDrivers":1,"userEmail":sample@dfdsf.com,}

  var postBody = {
    receiveEmails: data.receiveEmails,
    invitationEmails: data.invitationEmails,
    publishCapacity: data.publishCapacity,
    allowDrivers: data.allowDrivers,
    allowDriversBookItNow: data.allowDriversBookItNow,
    allowDriversOffer: data.allowDriversOffer,
    userEmail: data.userEmail,
    isPrimary: data.isPrimary,
  };
  let requestParams = {
    method: 'PUT',
    body: JSON.stringify(postBody),
  }
  let newRequestParams = myAuthService.onBeforeApiCall(requestParams)
  return fetch(requestUrl, newRequestParams)
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        if(response.status === 503){ //Temporary workaround for strange error returning but actually succeeding
          console.log('Update Preferences - 503 case')
          return {"status":true,"message":"We have updated your Preferences"}
        }else{
          return {"status":false,"message":`We had a problem updating your Preferences: ${response.status}`}
        }
      }
      return response.json();
    });
}
