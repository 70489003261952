
/* getOfferBy is either broker, carrier, driver, or BOOKITNOW */
const getOfferBy = (offer) => {
    if (offer.message_type?.toUpperCase() === 'QUOTE_B' || offer.offerBy?.toUpperCase() === 'BROKER' || offer.offer_by?.toUpperCase() === 'BROKER') {
        return 'BROKER'
    } else if (offer.message_type?.toUpperCase() === 'QUOTE_C' || offer.offerBy?.toUpperCase() === 'CARRIER' || offer.offer_by?.toUpperCase() === 'CARRIER') {
        return 'CARRIER'
    } else if (offer.message_type?.toUpperCase() === 'QUOTE_D' || offer.offerBy?.toUpperCase() === 'DRIVER' || offer.offer_by?.toUpperCase() === 'DRIVER') {
        return 'DRIVER'
    } else if (offer.message_type?.toUpperCase() === 'BOOKITNOW') {
        return 'BOOKITNOW'
    }
}


/* Offer type is accept or reject */
const getOfferType = (offer) => {
    if (offer.type?.toUpperCase() === 'ACCEPT' || offer.offer_type?.toUpperCase() === 'OFFER_ACCEPT' || offer.accepted) {
        return 'accept'
    } else if (offer.type?.toUpperCase() === 'REJECT' || offer.offer_type?.toUpperCase() === 'OFFER_REJECT' || offer.rejected) {
        return 'reject'
    } else if (offer.type?.toUpperCase() === 'OFFER' || offer.offer_type?.toUpperCase() === 'OFFER') {
        return 'offer'
    } else if (offer.type?.toUpperCase() === 'BOOKITNOW' || offer.offer_type?.toUpperCase() === 'BOOKITNOW') {
        return 'bookItNow'
    }
}

/*
* This function is to create a single offer object for us to handle comparisons.
* Offer objects vary depending on where they come from so this is a way for us to have it at a single location
*/
export const loadOfferMappings = (offer) => {
    if (offer) {
        let negotiationOfferObject = {
            'id': offer.id,
            'amount': offer.amount ?? offer.quote_value ?? null,
            'offer_type': getOfferType(offer),
            'offer_by': getOfferBy(offer),

        }
        return negotiationOfferObject
    }
}