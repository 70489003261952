import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { LogActionsActivity } from '../../apis/index'
import muiThemeable from 'material-ui/styles/muiThemeable';
import LoadListDesktop from './desktop'
import LoadListMobile from './mobile'
import useGtagHook from '../../../gtag-hook';
import {useAuthenticationHook} from '../../hooks/authentication-hook';
import { toast } from 'react-toastify';
import { showBIN } from '../../../lib/show-bin';

const CarrierLoadList = (props) => {

  const {
    unfilterLoadList = [],
    availability,
    setCurrentLoadListCount = null,
    loadFilters,
    pushReloads,
    isBookedLoads = false,
    isMyLoadsScreen = false,
    isRecentActivity = false,
    isOldRecentActivity = false,
    isHome = false,
    reloadsOpen = false,
    isMobile = false,
    onReloadsTab = false,
    myLoadsRenderedTab = 0,
    recentActivityActionType = null,
    muiTheme,
    isPaginationEnabled = false,
    pageNumber,
    setPageNumber,
    itemsPerPage,
    
  } = props
  const [filteredLoadList, setFilteredLoadList] = useState(unfilterLoadList)

  //these refs will halt the two other useEffects from running on mount
  const isMountedOne = useRef(false);
  const isMountedTwo = useRef(false);

  const myAuthService = useAuthenticationHook()
  const isAuthenticated = myAuthService.isAuthenticated()
  const [sendGtagEvent] = useGtagHook()

  useEffect(() => {
    makeActivityLogCall(0)

  }, [])

  useEffect(() => {
    if (isMountedOne.current && unfilterLoadList.length > 0) {
      filterLoads(unfilterLoadList)
        makeActivityLogCall(0)
    } else {
      isMountedOne.current = true;
    }

  }, [unfilterLoadList])

  useEffect(() => {
    if (isMountedTwo.current && unfilterLoadList.length > 0 && loadFilters.lastAction != null) { 
      filterLoads(unfilterLoadList)
        makeActivityLogCall(0)
    } else {
      isMountedTwo.current = true;
    }
    if(isPaginationEnabled)
      setPageNumber(1);
  }, [loadFilters])

  const filterLoads = (list) => {
    if(list == []) return
    if ((isRecentActivity || isMyLoadsScreen || isBookedLoads) && !reloadsOpen) {
      setFilteredLoadList(list)
      return
    }
    let retData = []
    if (loadFilters.lastAction != null) {

      list.forEach(load => {
        let canShowItem = canShowLoad(load)
        if (canShowItem) {
          retData.push(load)
        }
      })

    } else {
      retData = list
    }
    if (setCurrentLoadListCount != null) {
      setCurrentLoadListCount(retData.length)
    }
    setFilteredLoadList(retData)

    if (retData.length > 0) {
      sendGtagEvent(
        "search_results_returned",
        {
          screen: isRecentActivity ? "recent_activity" : isBookedLoads ? "booked_loads": isMyLoadsScreen ? "my_loads" : isHome ? "home" : "find_and_book_loads",
          listLength: retData.length,
          type: "loads",
          after_filter: true
        }
      )
    }

  }

  const makeActivityLogCall = (index) => {

    if(!isAuthenticated) return

    let loadIds = []
    if (filteredLoadList && filteredLoadList.length > 0) {
      let startingIndex = index
      if (startingIndex < 0) {
        startingIndex = 0
      }

      let load_view_count = 4
      let finalIndex = startingIndex + load_view_count
      if ( finalIndex > filteredLoadList.length ) {
        finalIndex = filteredLoadList.length
      }

      for (let i = startingIndex; i < finalIndex; i++) {
        let loadObj = {
          matchIndex: i,
          loadId: filteredLoadList[i].loadId,
          brokerId: filteredLoadList[i].brokerId
        }
        loadIds.push(loadObj)
      }
      let params = {
        loadId: null,
        loadIdArr: loadIds,
        brokerId: null,
        actionName: "LOAD_IMPRESSION",
        callingPhoneNumber: null,
        notes: null,
        rate: null,
        matchIndex: 0,
        matchIndex: null,
        screenName: "LOADS",
      }
      LogActionsActivity(params, muiTheme.customerUniqueId)
    }
  }

  const canShowLoad = (load) => {
    
    if (loadFilters.showBinOnly && !showBIN(load)) {
      return false
    }

    if (loadFilters.deadheadThresholds.length > 0 && load['deadhead'] != null) {
      if (load['deadhead'] < loadFilters.deadheadThresholds[0] || load['deadhead'] > loadFilters.deadheadThresholds[1]) {
        return false
      }
    }
    //dead head is camel cased for reloads
    if (loadFilters.deadheadThresholds.length > 0 && load['deadHead'] != null) {
      if (load['deadHead'] < loadFilters.deadheadThresholds[0] || load['deadHead'] > loadFilters.deadheadThresholds[1]) {
        return false
      }
    }

    if (loadFilters.reloadsThresholds.length > 0 && load['reloads'] != null) {
      if (load['reloads'] < loadFilters.reloadsThresholds[0] || load['reloads'] > loadFilters.reloadsThresholds[1]) {
        return false
      }
    }

    if (loadFilters.weightThresholds.length > 0 && load['weight'] != null) {
      if (load['weight'] < loadFilters.weightThresholds[0] || load['weight'] > loadFilters.weightThresholds[1]) {
        return false
      }
    }
    
    if (loadFilters.ratePerThresholds.length > 0 && load['ratePer'] != null && load['ratePer'] != "" && load['ratePer'] != "NULL") {
      if (parseFloat(load['ratePer']).toFixed(2) < loadFilters.ratePerThresholds[0] || parseFloat(load['ratePer']).toFixed(2) > loadFilters.ratePerThresholds[1]) {
        return false
      }
    }

    if (loadFilters.filteredOutDestinations.length > 0) {
      if (loadFilters.filteredOutDestinations.indexOf(load.stops[load.stops.length - 1].state) > -1) {
        return false
      }
    }

    if (loadFilters.filteredOutOrigins.length > 0) {
      if (loadFilters.filteredOutOrigins.indexOf(load.stops[0].state) > -1) {
        return false
      }
    }

    if (loadFilters.filteredOutBrokers.length > 0) {
      if (loadFilters.filteredOutBrokers.indexOf(load.companyName) > -1) {
        return false
      }
    }

    if (loadFilters.filteredOutEquipments.length > 0) {
      if (loadFilters.filteredOutEquipments.indexOf(load.truckTypes) > -1) {
        return false
      }
    }

    if (loadFilters.filteredOutRevenueCodes.length > 0) {
      if (loadFilters.filteredOutRevenueCodes.indexOf(load.revenueCode) > -1) {
        return false
      }
    }
    
    return true
  }

  const removeLoadFromList = (loadNumber) => {
    let tmpList = filteredLoadList.filter( load => load.loadId !== loadNumber)
    setFilteredLoadList(tmpList)
  }

  const paginationSlice = (completeLoadList, selectedPageNumber) => {
    return completeLoadList.slice((selectedPageNumber - 1) * itemsPerPage, selectedPageNumber * itemsPerPage)
  }

  return (
    <div style={{height: '100%'}}>
      {
        isMobile ? 
          <LoadListMobile
            {...props}
            filteredLoadList={
              (isPaginationEnabled) ?
                paginationSlice(filteredLoadList, pageNumber) : filteredLoadList
            }
            key={'load_list_mobile'}
            availability={availability}
            pushReloads={pushReloads}
            isMyLoadsScreen={isMyLoadsScreen}
            isRecentActivity={isRecentActivity}
            reloadsOpen={reloadsOpen}
            onReloadsTab={onReloadsTab}
            myLoadsRenderedTab={myLoadsRenderedTab}
            recentActivityActionType={recentActivityActionType}
            removeLoadFromList={removeLoadFromList}
            isBookedLoads={isBookedLoads}
            refreshInvoices={props.refreshInvoices}
            isHome={isHome}

          />
          :
          <LoadListDesktop
            {...props}
            filteredLoadList={
              (isPaginationEnabled) ?
                paginationSlice(filteredLoadList, pageNumber) : filteredLoadList
            }
            key={'load_list_desktop'}
            availability={availability}
            pushReloads={pushReloads}
            isRecentActivity={isRecentActivity}
            reloadsOpen={reloadsOpen}
            isMyLoadsScreen={isMyLoadsScreen}
            myLoadsRenderedTab={myLoadsRenderedTab}
            recentActivityActionType={recentActivityActionType}
            removeLoadFromList={removeLoadFromList}
            isBookedLoads={isBookedLoads}
            isOldRecentActivity={isOldRecentActivity}
            refreshInvoices={props.refreshInvoices}
            isHome={isHome}

          />
      }
    </div>
  )

}

const mapStateToProps = state => ({
  loadFilters: state.CarrierPortal.loadFilters
})

export default compose(
  connect(mapStateToProps),
  muiThemeable()
)(CarrierLoadList)