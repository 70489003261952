import { 	
			CALL_PROFILE_DATA, 
			UPDATE_PROFILE_DATA, 
      CLEAR_PROFILE_DATA,
		} from './constants'

export function setUserProfileRedux (data=null) {
  return {
    type: CALL_PROFILE_DATA,
    data
  }
}
export function clearUserProfile () {
  return {
    type: CLEAR_PROFILE_DATA
  }
}
export function updateProfile (data,updateCallback = ()=>{} ) {
  return {
    type: UPDATE_PROFILE_DATA,
    data,
    updateCallback:updateCallback
  }
}