import React from 'react'
import { setUserProfileRedux, updateProfile } from './actions.js'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { Col, Row, Grid, Glyphicon } from "react-bootstrap"
import { Card } from 'material-ui/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
//import 'react-toastify/dist/ReactToastify.min.css';
import ProfileUpdateForm from './profileUpdateForm'

import PropTypes from 'prop-types';
import {
	Redirect
} from 'react-router-dom';
// import OutOfNetworkBroker from '../outOfNetworkBrokerFooter'

import {
	signout, isAuthenticated
} from '../lib/check-auth'

import Preferences from '../preferences/indexV2';//chnage to indexv2 here for new one

//HOC//
import Mobile from '../HOC/Mobile'

class profile extends React.Component {

	static propTypes = {
		profile: PropTypes.shape({
			// profileData: PropTypes.obj,
			updateResponse: PropTypes.array,
			requesting: PropTypes.bool,
			successful: PropTypes.bool,
			messages: PropTypes.array,
			errors: PropTypes.array,
		}).isRequired,
		setUserProfileRedux: PropTypes.func.isRequired, // <-- new
	}

	constructor(props) {
		super(props);
		this.calHeaderHeight = this.calHeaderHeight.bind(this);
		this.changeFooterHeight = this.changeFooterHeight.bind(this);
		this.showEditOption = this.showEditOption.bind(this);
		this.closePUDialog = this.closePUDialog.bind(this);
		this.submitPUDialog = this.submitPUDialog.bind(this);
		this.reloadPage = this.reloadPage.bind(this);
		this.hidingBrokerId = 0;
		this.state = {
			data: {},
			footerDynamicHeight: 0,
			headerDynamicHeight: 0,
			editField: false,
		};
	}

	clientHeight() {

	}
	componentDidMount() {
		this.fetchProfileData();

	}
	componentDidUpdate() {

	}

	fetchProfileData = () => {

		// if (isAuthenticated()) {
		this.props.setUserProfileRedux();
		// 	return true;
		// }
		// else {
		// 	signout(() => this.props.history.push('/login'));
		// }
		// return false;
	}

	showEditOption = (e) => {

		this.setState({ editField: true })

	}

	submitPUDialog = (updateData) => {
		this.setState({ editField: false })
		var data;
		data = {
			username: updateData.username,
			mc: updateData.mc,
			scac: updateData.scac,
			dotNumber: updateData.dotNumber,
			firstName: updateData.firstName,
			lastName: updateData.lastName,
			email: updateData.email,
			phoneNumber: updateData.phoneNumber,
			fax: updateData.fax,
			companyName: updateData.companyName,
			address: updateData.address,
			city: updateData.city,
			state: updateData.state,
			zip: updateData.zip,
		}
		// console.log('updateData', updateData);
		// console.log('updateData', data);

		this.props.updateProfile(data, this.reloadPage)
	}

	closePUDialog = (e) => {

		this.setState({ editField: false })

	}

	reloadPage = () => {

		window.location.reload();

	}
	didTokenTimeOut = (errors) => {
		var didTimeOut = false;
		errors.map(error => {
			if (error.body && error.body.indexOf('Unauthorized') !== -1) {
				didTimeOut = true;
				signout(() => { });
			}
		});
		return didTimeOut;
	}




	render() {
		// console.log('render_start');
		const {
			profile: {
				profileData,
				updateResponse,
				requesting,
				successful,
				messages,
				errors,
			},
		} = this.props

		var footerHeight = this.state.footerDynamicHeight

		if (this.props.profile.profileData) {
			this.state.data = this.props.profile.profileData;
		}

		var headerHeight = this.state.headerDynamicHeight


		var removedpadding = 0

		const compHeight = window.innerHeight + removedpadding - footerHeight;
		var mainBody = compHeight - headerHeight;

		var scrollHeight = mainBody - footerHeight - 20
		if (this.props.isMobile) {
			scrollHeight = mainBody - footerHeight * 1.3
		}
		const scrollStyle = {
			overflow: 'auto',
			height: scrollHeight + 'px',
			padding: 5,
		}
		var footerStyle = {
			height: footerHeight + 'px'
		};
		const cardStyle = {
			backgroundColor: "white",
			padding: 15,
			width: "35%"
		}

		const itemNameStyle = { textAlign: this.props.isMobile ? 'left' : 'right' }
		const hBottomMargin_10 = { marginBottom: '10px' }
		const hBottomMargin_5 = { marginBottom: '5px' }
		const itemTxtStyle = { marginBottom: '5px', paddingTop: '2.4px' }

		const mobileStyle = {}

		return (
			<div>

				{this.state.editField &&
					(<ProfileUpdateForm
						show={this.state.editField}
						onHide={this.closePUDialog}
						submitCallback={this.submitPUDialog.bind(this)}
						initialValues={this.state.data}
						state={this.state}
						client={this.props.client}
						companyName={this.state.data.companyName}
						email={this.state.data.email}
						mc={this.state.data.mc}
						dotNumber={this.state.data.dotNumber}
						scac={this.state.data.scac}
						address={this.state.data.address}
						city={this.state.data.city}
						state={this.state.data.state}
						zip={this.state.data.zip}
						username={this.state.data.username}
						// firstName={this.state.data.firstName}
						// lastName={this.state.data.lastName}
						fullname={this.state.data.fullname}
						phoneNumber={this.state.data.phoneNumber}
						fax={this.state.data.fax}
						saveBtnColor={this.props.muiTheme.actionColor}
						closeBtnColor={this.props.muiTheme.ttUnselectGrey}
					/>)
				}


				<div className=" panel-default" style={this.props.isMobile ? mobileStyle : {}}>
					<div className="custom-panel-body" >
						
							<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
								<Card style={cardStyle}>
									{!requesting && !!errors.length && this.didTokenTimeOut(errors) && (
										<Redirect to={{
											pathname: '/login',
											state: { from: this.props.location }
										}} />
									)
									}

									<div>
										<Row>
											<h3 style={{ marginBottom: 10, textTransform: "uppercase" }}>User Profile</h3>
											<Row>
												<Col xs={12} md={4} style={itemNameStyle} >

													<span>
														<h4 style={itemTxtStyle} className="small">
															User Name:
								          						</h4>
													</span>
												</Col>
												<Col uid="profile-user-name" xs={12} md={8} >
													<span>
														<h4 style={hBottomMargin_5}>
															{this.state.data.username}
														</h4>
													</span>
												</Col>
												{/* <TextField
													id="standard-read-only-input"
													label="User Name"
													defaultValue={this.state.data.username}
													InputProps={{
														readOnly: true,
													}}
												/> */}
											</Row>
											<Row>
												<Col xs={12} md={4} style={itemNameStyle} >

													<span>
														<h4 style={itemTxtStyle} className="small">
															Your Name:
								          						</h4>
													</span>
												</Col>
												<Col uid="profile-your-name" xs={12} md={8} >
													<span>
														<h4  style={hBottomMargin_5}>
															{this.state.data.fullname}
														</h4>
													</span>
												</Col>
												{/* <TextField
													id="standard-read-only-input"
													label="Your Name"
													defaultValue={this.state.data.firstName + " " + this.state.data.lastName}
													InputProps={{
														readOnly: true,
													}}
												/> */}
											</Row>
{/* 
											<Row>
												<Col xs={12} md={4} style={itemNameStyle} >

													<span>
														<h4 style={itemTxtStyle} className="small">
															Phone #:
								          						</h4>
													</span>
												</Col>
												<Col uid="profile-phone-number" xs={12} md={8} >
													<span>
														<h4  style={hBottomMargin_5}>
															{this.state.data.phoneNumber}
														</h4>
													</span>
												</Col>
											</Row> */}
											{/* <Row>
											<Col xs={12} md={4} style={itemNameStyle} >
												<span>
													<h4 style={itemTxtStyle} className="small">
														Fax #:
								          						</h4>
												</span>
											</Col>
											<Col xs={12} md={8} >
												<span>
													<h4 style={hBottomMargin_5}>
														{this.state.data.fax}
													</h4>
												</span>
											</Col>
										</Row> */}
											<Row>
												<Col xs={12} md={4} style={itemNameStyle} >
													<span>
														<h4 style={itemTxtStyle} className="small">
															Company Name:
								          						</h4>
													</span>
												</Col>
												<Col uid="profile-company-name" xs={12} md={8} >
													<span>
														<h4  style={hBottomMargin_5}>
															{this.state.data.companyName}
														</h4>
													</span>
												</Col>
											</Row>
											{/* <Row>
											<Col xs={12} md={4} style={itemNameStyle} >
												<span>
													<h4 style={itemTxtStyle} className="small">
														Email:
								          						</h4>
												</span>
											</Col>
											<Col xs={12} md={8} >
												<span>
													<h4 style={hBottomMargin_5}>
														{this.state.data.email}
													</h4>
												</span>
											</Col>
										</Row> */}
											<Row>
												<Col xs={12} md={4} style={itemNameStyle} >

													<span>
														<h4 style={itemTxtStyle} className="small">
															MC # :
								          						</h4>
													</span>
												</Col>
												<Col uid="profile-mc-number" xs={12} md={8} >
													<span>
														<h4  style={hBottomMargin_5}>
															{this.state.data.mc}
														</h4>
													</span>
												</Col>
											</Row>

											<Row>
												<Col xs={12} md={4} style={itemNameStyle} >
													<span>
														<h4 style={itemTxtStyle} className="small">
															DOT # :
								          						</h4>
													</span>
												</Col>
												<Col uid="profile-dot-number" xs={12} md={8} >
													<span>
														<h4  style={hBottomMargin_5}>
															{this.state.data.dotNumber}
														</h4>
													</span>
												</Col>
											</Row>

											<Row>
												<Col xs={12} md={4} style={itemNameStyle} >

													<span>
														<h4 style={itemTxtStyle} className="small">
															SCAC # :
								          						</h4>
													</span>
												</Col>
												<Col uid="profile-scac-number" xs={12} md={8} >
													<span>
														<h4 style={hBottomMargin_5}>
															{this.state.data.scac}
														</h4>
													</span>
												</Col>
											</Row>


											<Row>
												<Col xs={12} md={4} style={itemNameStyle} >

													<span>
														<h4 style={itemTxtStyle} className="small">
															Address :
								          						</h4>
													</span>
												</Col>
												<Col uid="profile-address" xs={12} md={8} >
													<span>
														<h4  style={hBottomMargin_5}>
															{this.state.data.address}
														</h4>
													</span>
												</Col>
											</Row>

											<Row>
												<Col xs={12} md={4} style={itemNameStyle} >

													<span>
														<h4 style={itemTxtStyle} className="small">
															City :
								          						</h4>
													</span>
												</Col>
												<Col uid="profile-city" xs={12} md={8} >
													<span>
														<h4  style={hBottomMargin_5}>
															{this.state.data.city}
														</h4>
													</span>
												</Col>
											</Row>

											<Row>
												<Col xs={12} md={4} style={itemNameStyle} >

													<span>
														<h4 style={itemTxtStyle} className="small">
															State & Postal Code:
								          						</h4>
													</span>
												</Col>
												<Col uid="profile-state-postal" xs={12} md={8} >
													<span>
														<h4  style={hBottomMargin_5}>
															{this.state.data.state} {this.state.data.zip}
														</h4>
													</span>
												</Col>
											</Row>

											<Row style={{ marginTop: 15 }}>
												<Button
													id='saveButton'
													size="small"
													variant="contained"
													style={{
														backgroundColor: this.props.muiTheme.actionColor,
														borderColor: this.props.muiTheme.actionColor,
														color: "white",
														fontSize: 14,
														margin: 5
													}}
													onClick={(event) => { this.showEditOption(event) }}
												>
													Edit Details
											</Button>
											</Row>

										</Row>
									</div>

								</Card>
							</div>
							<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
								<Card style={{ ...cardStyle, ...{ marginTop: 15 } }}>
									<Preferences {...this.props} />
								</Card>
							</div>
						
						{/* {!this.props.isMobile && this.props.muiTheme.customerUniqueId == "2zr5ys19" && (
							<div style={{ ...footerStyle, ...{ backgroundColor: "white", marginTop: 10, marginBottom: 10 } }}>
								<OutOfNetworkBroker clientHeight={this.changeFooterHeight} />
							</div>
						)} */}
					</div>
					{/* {this.props.isMobile && (
						<div className="custom-panel-footer  " >
							<div style={footerStyle}>
								<OutOfNetworkBroker clientHeight={this.changeFooterHeight} />
							</div>
						</div>
					)} */}
				</div>
			</div>
		)
	}


	calHeaderHeight(node) {
		if (node) {
			// console.log('offsetHeight' + node.offsetHeight + 68);
			this.setState({
				headerDynamicHeight: node.offsetHeight + 68
			});
		}
	}


	changeFooterHeight(calculatedHeight) {
		// console.log('calculatedHeight ' + calculatedHeight)
		this.setState({ footerDynamicHeight: calculatedHeight });
	}
}


const mapStateToProps = state => ({
	profile: state.Profile,
	client: state.client

})

const connected = connect(
	mapStateToProps,
	{ setUserProfileRedux, updateProfile, }
)(profile)

export default Mobile()(muiThemeable()(connected)) // TODO: check if this is okay