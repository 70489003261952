import { BACKEND_URL } from '../../../../api/constants'

export function getELDIntegration(MCNumber) {

  const url = BACKEND_URL + '/ELDIntegration/' + MCNumber;
  return fetch(url, {
    method: 'GET',
    headers: {
      //'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id,
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

export function authToken(code) {
  const url = BACKEND_URL + '/api/keeptruckin';
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({code: code})
  })
    .then(response => {
      return response.json();
    });
}

export function createELDIntegration(payload) {
  const url = BACKEND_URL + '/ELDIntegration'
  return fetch(url, {
    method: 'POST',
    headers: {
      //'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

export function updateELDIntegration(payload) {
  const url = BACKEND_URL + '/ELDIntegration/' + payload.carrierMCNumber
  return fetch(url, {
    method: 'POST',
    headers: {
      //'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      if (!response.ok) {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
      return response.json();
    });
}

export function exchangeKTAuthCode(authCode) {
  const url = 'https://api.keeptruckin.com/oauth/token'
  var params = {
    grant_type: 'authorization_code',
    code: authCode,
    redirect_uri: 'https://bookaload.truckertools.com/keeptruckin/configuration/',
    client_id: '89c090556c90f6c4a9a5c9432afcd9f21e7fde8789880f7bb0fe689751cba519',
    client_secret: '86335f0d79bf5d4f7af0d3f072678c5a33f78342c3a823357913b16fc83d7865'
  };
  var queryString = Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&');
  
  let requestObject = {
    method: 'POST',
    timeout: 0,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: queryString,
  }

  return fetch(url, requestObject)
    .then(response => {
      console.log('response: ', response)
    })
    .catch(error => {
      console.log('error: ', error)
    })

}