import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@material-ui/styles";
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';
import FilterCheckbox from './filter-checkbox';
import useGtagHook from '../../../gtag-hook';
import ProtectedCheckbox from '../../authentication/protected-checkbox';
import { useAuthenticationHook } from '../../hooks/authentication-hook';
import { canShowBinTradeMark } from '../../helpers/permissions';
import { FormControlLabel } from '@material-ui/core';

const FilterUI = (props) => {

    const {
        muiTheme,
        paddingTop,
        updateLoadFilters,
        mobileView,

        //default values
        allEquipments,
        allBrokers,
        allDestinations,
        revenueCodeList,
        allOrigins,
        deadHeadMin,
        deadHeadMax,
        reloadsMin,
        reloadsMax,
        weightMin,
        weightMax,
        ratePerMin,
        ratePerMax,

        //current states
        deadheadRange,
        reloadsRange,
        weightRange,
        ratePerRange,
        binChecked,
        selectedEquipments,
        selectedBrokers,
        selectedDests,
        selectedOrigins,
        selectedRevenueCodes,

        //change handlers
        resetFilter,
        deselectAllCheckboxes,
        handleSelectDest,
        handleSelectRevenueCode,
        handleSelectOrigin,
        handleSelectEquipment,
        handleSelectBroker,
        handleBINChange,
        resetAllFilters,
        handleWeightChange,
        handleReloadsChange,
        handleDeadheadChange,
        handleRatePerChange,

        resetCheckBoxStates

    } = props

    const { height, width } = useWindowDimensions();
    const [sendGtagEvent] = useGtagHook()
    const useStyles = makeStyles((theme) => ({
        filterHeader: {
            fontSize: 14,
            fontWeight: "bold",
            marginBottom: 10,
            display: 'inline-block',
            color: 'black'
        },
        filterLabel: {
            fontSize: 14,
            lineHeight: "24px",
        },
        filterSection: {
            display: "flex",
            marginBottom: 10,
            marginLeft: 10
        },
        resetFilter: {
            fontSize: 13,
            float: "right",
            // textDecoration: 'underline',
            textAlign: "right",
            color: muiTheme.actionColor,
            padding: 0,
            cursor: 'pointer',
            textTransform: "none",
            '&:hover': {
                color: muiTheme.actionColor,
                textDecoration: 'underline !important',
            }
        },
        selectDeselectAll: {
            fontSize: 13,
            textAlign: "right",
            color: muiTheme.actionColor,
            padding: 0,
            cursor: 'pointer',
            '&:hover': {
                color: muiTheme.actionColor,
                textDecoration: 'underline !important',
            }
            // textTransform: "none"
        }
    }));
    const classes = useStyles();
    const FILTER_HEADER_HEIGHT = 75

    const [resetEquipment, setResetEquipment] = useState(false)
    const [resetBroker, setResetBroker] = useState(false)
    const [resetDestination, setResetDestination] = useState(false)
    const [resetOrigin, setResetOrigin] = useState(false)
    const [resetRevenueCode, setResetRevenueCode] = useState(false)

    const [deselectEquipment, setDeselectEquipment] = useState(false)
    const [deselectBroker, setDeselectBroker] = useState(false)
    const [deselectOrigin, setDeselectOrigin] = useState(false)
    const [deselectDestination, setDeselectDestination] = useState(false)
    const [deselectRevenueCode, setDeselectRevenueCode] = useState(false)

    const myAuthService = useAuthenticationHook()

    useEffect(() => {
        resetLocally()
    }, [resetCheckBoxStates])


    const onSliderChangeCommit = (type) => {
        let newValue
        switch (type) {
            case 'ratePer':
                updateLoadFilters("ratePer", ratePerRange)
                newValue = ratePerRange
                break
            case 'weight':
                updateLoadFilters("weight", weightRange)
                newValue = weightRange
                break
            case 'reloads':
                updateLoadFilters("reloads", reloadsRange)
                newValue = reloadsRange
                break
            case 'deadhead':
                updateLoadFilters("deadhead", deadheadRange)
                newValue = deadheadRange
                break
        }
        sendGtagEvent(
            "filter_loads_" + type,
            {
                category: type,
                type: "range",
                value: newValue
            }
        )
    }


    const BrandedSlider = createMuiTheme({
        overrides: {
            MuiSlider: {
                root: {
                    color: muiTheme.secondary,
                },
                rail: {
                    backgroundColor: "#53565A"
                },
            }
        }
    });

    const _onCheckboxSelect = (type, isSelected, item) => {
        switch (type) {
            case "equipment":
                handleSelectEquipment(isSelected, item)
                break
            case "broker":
                handleSelectBroker(isSelected, item)
                break
            case "destination":
                handleSelectDest(isSelected, item)
                break
            case "origin":
                handleSelectOrigin(isSelected, item)
                break
            case "revenueCode":
                handleSelectRevenueCode(isSelected, item)
                break
            default:
                break
        }
    }

    const resetLocally = () => {
        setResetEquipment(!resetEquipment)
        setResetDestination(!resetDestination)
        setResetOrigin(!resetOrigin)
        setResetRevenueCode(!resetRevenueCode)
    }


    // TODO: allow slider values to be entered in text boxes
    // TODO: figure out how to increase font size on slider thumb

    return (
        <div style={{ width: "100%", paddingLeft: 10, paddingRight: 10 }}>

            <div style={{ paddingTop: mobileView ? null : 15, paddingBottom: 0, borderBottom: '1px solid lightgrey', position: "relative" }}>
                <p style={{ display: 'inline-block', marginBottom: 10, color: 'black', fontSize: 16, fontWeight: "bold" }}>Filters</p>
                <a data-component="reset-all-filters" className={classes.resetFilter} style={{ position: "absolute", bottom: 13, right: 0 }} onClick={() => { resetAllFilters() }}>Reset All</a>
            </div>

            <div
                style={{
                    paddingTop: 20,
                    paddingBottom: 20,
                    paddingRight: 10,
                    overflowX: "hidden",
                    overflowY: 'auto',
                    maxHeight: height - paddingTop - FILTER_HEADER_HEIGHT + (mobileView ? 100 : 0)
                }}>
                <p className={classes.filterHeader} >BOOK-IT-NOW{canShowBinTradeMark() ? '\xAE' : ''}</p>
                {/* <a className={classes.resetFilter} onClick={() => resetFilter('BIN')}>Reset</a> */}
                <div className={classes.filterSection} style={{ alignItems: 'flex-start' }}>

                    {myAuthService.isAuthenticated() ?
                        <div id='book-it-now-checkbox' style={{ display: "flex", alignItems: "center" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        key="bin_filter_checkbox"
                                        style={{ color: muiTheme.secondary, transform: "scale(1.5)" }}
                                        color="default"
                                        checked={binChecked}
                                        onChange={(event) => { handleBINChange(event.target.checked) }}
                                    />
                                }
                                label={<span className={classes.filterLabel}>BOOK-IT-NOW{canShowBinTradeMark() ? '\xAE' : ''} loads only</span>}
                            />
                        </div>

                        :

                        <ProtectedCheckbox
                            key={"bin_checkbox_protected"}
                            id='bin_checkbox_protected'
                            popoverHeaderText={""}
                            popoverBodyText={muiTheme.loginMsg}
                            buttonText={`BOOK-IT-NOW${canShowBinTradeMark() ? '\xAE' : ''} loads only`}
                        />

                    }

                </div>
                {(allEquipments.length > 1 || (allEquipments.length == 1 && allEquipments[0] != undefined)) && (
                    <div>
                        <p className={classes.filterHeader}>Equipment</p>
                        <div style={{ float: 'right' }}>
                            <a data-component="equipment-filters-deselect-all" varient='contained' className={classes.selectDeselectAll} onClick={() => { deselectAllCheckboxes('equipment'); setDeselectEquipment(!deselectEquipment) }}>Deselect All</a>
                            <span style={{ paddingLeft: 3, paddingRight: 3 }}>|</span>
                            <a data-component="equipment-filters-select-all" varient='contained' className={classes.selectDeselectAll} onClick={() => { resetFilter('equipment'); setResetEquipment(!resetEquipment) }}>Select All</a>
                        </div>
                        <div className={classes.filterSection} style={{ width: '100%', flexDirection: 'column' }}>
                            {allEquipments.map(equipmentType => (
                                <FilterCheckbox
                                    key={equipmentType}
                                    checked={selectedEquipments.indexOf(equipmentType) > -1 ? false : true}
                                    type={"equipment"}
                                    item={equipmentType}
                                    reset={resetEquipment}
                                    deselect={deselectEquipment}
                                    onCheckboxSelect={(type, isSelected, item) => _onCheckboxSelect(type, isSelected, item)}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" && (allBrokers.length > 1 || (allBrokers.length == 1 && allBrokers[0] != undefined)) && (
                    <div>
                        <p className={classes.filterHeader}>Broker</p>
                        <div style={{ float: 'right' }}>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { deselectAllCheckboxes('broker'); setDeselectBroker(!deselectBroker) }}>Deselect All</a>
                            <span style={{ paddingLeft: 3, paddingRight: 3 }}>|</span>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { resetFilter('broker'); setResetBroker(!resetBroker) }}>Select All</a>
                        </div>
                        <div className={classes.filterSection} style={{ width: '100%', flexDirection: 'column' }}>
                            {allBrokers.map(broker => (
                                <FilterCheckbox
                                    key={broker}
                                    checked={selectedBrokers.indexOf(broker) > -1 ? false : true}
                                    type={"broker"}
                                    item={broker}
                                    reset={resetBroker}
                                    deselect={deselectBroker}
                                    onCheckboxSelect={(type, isSelected, item) => _onCheckboxSelect(type, isSelected, item)}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {process.env.REACT_APP_ADVANTAGE_ID == "srukfk5e" && (revenueCodeList.length > 1 || (revenueCodeList.length == 1 && revenueCodeList[0] != null)) && (
                    <div>
                        <p className={classes.filterHeader} >Revenue Code</p>
                        <div style={{ float: 'right' }}>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { deselectAllCheckboxes('revenueCode'); setDeselectRevenueCode(!deselectRevenueCode) }}>Deselect All</a>
                            <span style={{ paddingLeft: 3, paddingRight: 3 }}>|</span>
                            <a varient='contained' className={classes.selectDeselectAll} onClick={() => { resetFilter('revenueCode'); setResetRevenueCode(!resetRevenueCode) }}>Select All</a>
                        </div>
                        <div className={classes.filterSection} style={{ width: '100%', flexWrap: "wrap", flexDirection: 'row' }}>
                            {revenueCodeList.map(revenueCode => (
                                <FilterCheckbox
                                    key={revenueCode}
                                    type={"revenueCode"}
                                    item={revenueCode}
                                    checked={selectedRevenueCodes.indexOf(revenueCode) > -1 ? false : true}
                                    reset={resetRevenueCode}
                                    deselect={deselectRevenueCode}
                                    onCheckboxSelect={(type, isSelected, item) => _onCheckboxSelect(type, isSelected, item)}
                                    style={{ padding: 5 }}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {props.countryWideSearch &&
                    <div>
                        <p className={classes.filterHeader} >Origin State</p>
                        <div style={{ float: 'right' }}>
                            <a data-component="origin-state-filters-deselect-all" varient='contained' className={classes.selectDeselectAll} onClick={() => { deselectAllCheckboxes('origin'); setDeselectOrigin(!deselectOrigin) }}>Deselect All</a>
                            <span style={{ paddingLeft: 3, paddingRight: 3 }}>|</span>
                            <a data-component="origin-state-filters-select-all" varient='contained' className={classes.selectDeselectAll} onClick={() => { resetFilter('origin'); setResetOrigin(!resetOrigin) }}>Select All</a>
                        </div>
                        <div className={classes.filterSection} style={{ width: '100%', flexWrap: "wrap", flexDirection: 'row' }}>
                            {allOrigins.map(origin => (
                                <FilterCheckbox
                                    key={origin}
                                    type={"origin"}
                                    item={origin}
                                    checked={selectedOrigins.indexOf(origin) > -1 ? false : true}
                                    reset={resetOrigin}
                                    deselect={deselectOrigin}
                                    onCheckboxSelect={(type, isSelected, item) => _onCheckboxSelect(type, isSelected, item)}
                                    style={{ padding: 5 }}
                                />
                            ))}
                        </div>
                    </div>
                }
                <div>
                    <p className={classes.filterHeader} >Destination State</p>
                    <div style={{ float: 'right' }}>
                        <a data-component="destination-state-filters-deselect-all" varient='contained' className={classes.selectDeselectAll} onClick={() => { deselectAllCheckboxes('destination'); setDeselectDestination(!deselectDestination) }}>Deselect All</a>
                        <span style={{ paddingLeft: 3, paddingRight: 3 }}>|</span>
                        <a data-component="destination-state-filters-select-all" varient='contained' className={classes.selectDeselectAll} onClick={() => { resetFilter('destination'); setResetDestination(!resetDestination) }}>Select All</a>
                    </div>
                    <div className={classes.filterSection} style={{ width: '100%', flexWrap: "wrap", flexDirection: 'row' }}>
                        {allDestinations.map(dest => (
                            <FilterCheckbox
                                key={dest}
                                type={"destination"}
                                item={dest}
                                checked={selectedDests.indexOf(dest) > -1 ? false : true}
                                reset={resetDestination}
                                deselect={deselectDestination}
                                onCheckboxSelect={(type, isSelected, item) => _onCheckboxSelect(type, isSelected, item)}
                                style={{ padding: 5 }}
                            />
                        ))}
                    </div>
                </div>
                {deadHeadMin !== deadHeadMax &&
                    <div id='distance-from-search-slider-section'>
                        <p className={classes.filterHeader}>Distance From Search</p>
                        <a varient='contained' className={classes.resetFilter} onClick={() => resetFilter('deadhead')}>Reset</a>
                        <p style={{ paddingLeft: 10, fontSize: 14 }}>{deadheadRange[0]} - {deadheadRange[1]} mi</p>
                        <div className={classes.filterSection} style={{ paddingLeft: 15, paddingRight: 10 }}>
                            <ThemeProvider theme={BrandedSlider}>
                                <Slider
                                    value={deadheadRange}
                                    min={Math.floor(deadHeadMin)}
                                    max={Math.ceil(deadHeadMax)}
                                    onChange={handleDeadheadChange}
                                    onChangeCommitted={() => onSliderChangeCommit('deadhead')}
                                    aria-labelledby="range-slider"
                                />
                            </ThemeProvider>
                        </div>
                    </div>
                }
                {reloadsMin !== reloadsMax && (
                    <div id='reloads-slider-section'>
                        <p className={classes.filterHeader}>Reloads</p>
                        <a varient='contained' className={classes.resetFilter} onClick={() => resetFilter('reloads')}>Reset</a>
                        <p style={{ paddingLeft: 10, fontSize: 14 }}>{reloadsRange[0]} - {reloadsRange[1]} reloads</p>
                        <div className={classes.filterSection} style={{ paddingLeft: 15, paddingRight: 10 }}>
                            <ThemeProvider theme={BrandedSlider}>
                                <Slider
                                    value={reloadsRange}
                                    min={reloadsMin}
                                    max={reloadsMax}
                                    onChange={handleReloadsChange}
                                    onChangeCommitted={() => onSliderChangeCommit('reloads')}
                                    aria-labelledby="range-slider"
                                />
                            </ThemeProvider>
                        </div>
                    </div>
                )}
                {weightMin !== weightMax &&
                    <div id='weight-slider-section'>
                        <p className={classes.filterHeader} >Weight</p>
                        <a varient='contained' className={classes.resetFilter} onClick={() => resetFilter('weight')}>Reset</a>
                        <p key={"weight_slider_header_" + weightMin + "_to_" + weightMax} style={{ paddingLeft: 10, fontSize: 14 }}>{weightRange[0]} - {weightRange[1]} lbs</p>
                        <div className={classes.filterSection} style={{ paddingLeft: 15, paddingRight: 10 }}>
                            <ThemeProvider theme={BrandedSlider}>
                                <Slider
                                    key={"weight_slider_" + weightMin + "_to_" + weightMax}
                                    value={weightRange}
                                    min={Math.floor(weightMin)}
                                    max={Math.ceil(weightMax)}
                                    onChange={handleWeightChange}
                                    onChangeCommitted={() => onSliderChangeCommit('weight')}
                                    aria-labelledby="range-slider"
                                />
                            </ThemeProvider>
                        </div>
                    </div>
                }
                {/* {ratePerMin !== ratePerMax &&
                    <div id='ratePer-slider-section'>
                        <p className={classes.filterHeader} >Rate Per Mile</p>
                        <a varient='contained' className={classes.resetFilter} onClick={() => resetFilter('ratePer')}>Reset</a>
                        <p key={"weight_slider_header_" + ratePerMin + "_to_" + ratePerMax} style={{ paddingLeft: 10, fontSize: 14 }}>${ratePerRange[0]} - ${ratePerRange[1]}</p>
                        <div className={classes.filterSection} style={{ paddingLeft: 15, paddingRight: 10 }}>
                            <ThemeProvider theme={BrandedSlider}>
                                <Slider
                                    key={"ratePer_slider_" + ratePerMin + "_to_" + ratePerMax}
                                    value={ratePerRange}
                                    min={Math.floor(ratePerMin)}
                                    max={Math.ceil(ratePerMax)}
                                    onChange={handleRatePerChange}
                                    onChangeCommitted={() => onSliderChangeCommit('ratePer')}
                                    aria-labelledby="range-slider"
                                />
                            </ThemeProvider>
                        </div>
                    </div>
                } */}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    countryWideSearch: state.CarrierPortal.search.countryWideSearch
})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(FilterUI)


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}