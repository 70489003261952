
import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import FA from 'react-fontawesome'
import { Col, Row, Grid } from "react-bootstrap"
import moment from 'moment'
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify'
import { checkValueExists } from "../../../../../../lib/check-value-exists"
import { titleCaseFormatter } from "../../../../../../lib/title-case-formatter"

import * as FeatherIcon from 'react-feather';
import Popover from '@material-ui/core/Popover';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBiohazard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPhoneNumberWithHyphens } from '../../../../../../components/formatPhoneNumber';

library.add(
    faBiohazard
);

const ActiveLoadCard = (props) => {
    const classes = useStyles();
    const {
        load,
        index,
        pickup,
        delivery,
        availability,
        muiTheme,
        key,
        carrierCommunications
    } = props

    const [isHazmat, setIsHazmat] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null);
    const openRateBreakdown = Boolean(anchorEl);
    const id = openRateBreakdown ? 'rate-info-popover' : undefined;

    useEffect(() => {

        if (load.truckTypes) {
            if (load.truckTypes.toUpperCase().indexOf('HAZMAT') > -1)
                setIsHazmat(true)
        }
    }, [])

    const handleTrackingHelperOpen = (event, tag) => {
        setAnchorEl(event.currentTarget)
    };

    const handleTrackingHelperClose = () => {
        if (anchorEl !== null)
            setAnchorEl(null);
    };

    const checkWhiteSpace = (textInput) => {
        let status = true;
        if(textInput){
            if(textInput.trim().length === 0)
                status = false
        };
        return status
    }

    const checkEmptyString = (textInput) => {
        let status = true;
        if(typeof(textInput)=== 'string'){
            if(textInput.length === 0)
                status = false
        };
        return status
    }

    const getTrackingHelperPopover = (id, openRateBreakdown, anchorEl) => {
        return (
            <Popover
                id={id}
                open={openRateBreakdown}
                anchorEl={anchorEl}
                onClose={ (e) => {
                    e.preventDefault();
                    handleTrackingHelperClose(e)
                }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                // style={{ pointerEvents: 'none' }}
                disableRestoreFocus
            >
                <div style={{ padding: 15, width: 220, maxHeight: 300 }}>
                    <p className={classes.valueText}> Contact <a href={"tel:1-800-000-0000"}style={{ color: muiTheme.actionColor }}>1-800-000-0000</a><br/>to update tracking details.</p> 
                </div>    
            </Popover>
        )
    }

    return (
        <div style={{ height: '100%' }}>
            {/* // <Animatable.div key={load.id} style={[CustomStyleSheet.card, rowStyle]} animation={animation} duration={duration} > */}
            <Link
                style={{ color: 'black' }}
                to={{
                    pathname: '/loadDetails',
                    state: {
                        load: load,
                        pickup: pickup,
                        delivery: delivery,
                        availability: availability,
                        isMyLoadsScreen: true,
                        myLoadsRenderedTab: 0, //zero for active loads
                        carrierCommunications: carrierCommunications,
                    }
                }}
            >
                <Col style={{ display: 'flex', width: '100%', padding: 5 }} >
                    <Row style={{ display: 'flex', width: '100%', height: '100%', margin: 0 }}>

                        <Col style={{ display: 'flex', width: 25 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '63%', paddingTop: 8 }} >
                                <div style={{ width: 10, height: 10, borderRadius: 10 / 2, backgroundColor: '#A4A4AC' }} >
                                </div>
                                <div style={{ height: '70%', width: 2, backgroundColor: 'lightgrey', marginVertical: 5 }} >
                                </div>
                                <div style={{ width: 10, height: 10, backgroundColor: 'lightgrey' }} >
                                </div>
                            </div>
                        </Col>
                        <Col style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                            <Row style={{ margin: 0 }}>
                                <div>
                                    {load.originStop.city && load.originStop.state && (
                                        <Row>
                                            <p className={classes.mainText} style={{ display: 'inline-block' }}>
                                                {titleCaseFormatter(load.originStop.city)},{' ' + load.originStop.state}
                                            </p>
                                            {isHazmat && <FontAwesomeIcon icon={['fas', 'biohazard']} style={{ color: '#000', display: 'inline-block', marginLeft: 5 }} />}
                                        </Row>
                                    )}
                                    {!load.originStop.earliestDate && (
                                        <Row style={{ margin: 0 }}>
                                            <p className={classes.secondaryText}>
                                                {moment(load.originStop.latestDate).format('ll')}
                                            </p>
                                            <p className={classes.secondaryText}>
                                                {moment(load.originStop.latestDate).format('HH:mm')}
                                            </p>
                                        </Row>
                                    )}
                                    {load.originStop.earliestDate && (
                                        <Row style={{ margin: 0 }}>
                                            <p className={classes.secondaryText}>
                                                {moment(load.originStop.earliestDate).format('ll')} {moment(load.originStop.earliestDate).format('HH:mm')} -
                                    </p>
                                            <p className={classes.secondaryText}>
                                                {moment(load.originStop.latestDate).format('ll')} {moment(load.originStop.latestDate).format('HH:mm')}
                                            </p>
                                        </Row>
                                    )}
                                </div>
                            </Row>

                            <Row style={{ margin: 0 }}>
                                <div>
                                    <Row style={{height: '40%' }}>
                                        <p className={classes.mainText}>
                                            {titleCaseFormatter(load.destStop.city)},{' ' + load.destStop.state}
                                        </p>
                                    </Row>
                                    {!load.destStop.earliestDate && (
                                        <Row style={{ margin: 0, height: '40%' }}>
                                            <p className={classes.secondaryText}>
                                                {moment(load.destStop.latestDate).format('ll')}
                                            </p>
                                            <p className={classes.secondaryText}>
                                                {moment(load.destStop.latestDate).format('HH:mm')}
                                            </p>
                                        </Row>
                                    )}
                                    {load.destStop.earliestDate && (
                                        <Row style={{ margin: 0, height: '40%' }}>
                                            <p className={classes.secondaryText}>
                                                {moment(load.destStop.earliestDate).format('ll')} {moment(load.destStop.earliestDate).format('HH:mm')} -
                                            </p>
                                            <p className={classes.secondaryText}>
                                                {moment(load.destStop.latestDate).format('ll')} {moment(load.destStop.latestDate).format('HH:mm')}
                                            </p>
                                        </Row>
                                    )}
                                </div>
                            </Row>
                        </Col>
                        <Col style={{ width: '45%' }}>
                            <Row>
                                {carrierCommunications &&
                                    checkValueExists(carrierCommunications, "carrierCommunication") &&
                                    checkValueExists(carrierCommunications.carrierCommunication, "vendorName") &&
                                    carrierCommunications.carrierCommunication.vendorName == "MACROPOINT" ? (
                                    <div>
                                        <dl className={classes.descriptionList}>
                                            <dt className={classes.descriptorTag}>
                                                <p className={classes.mainText}>Tractor #</p>
                                            </dt>
                                            <dd className={classes.descriptorDetail}>
                                                <p className={classes.secondaryText} style={{color:'black', fontSize: 14, position:'relative' }}>
                                                    {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "tractor") && checkWhiteSpace(load.carrierTrackingInfo.tractor) && load.carrierTrackingInfo.tractor != "" ? load.carrierTrackingInfo.tractor 
                                                    : <span className={classes.missing}> MISSING </span>}
                                                    <FeatherIcon.Info
                                                        style={{position:'absolute', left:65, top:7}}
                                                        color={"black"}
                                                        size={20}
                                                        onClick={ (e)=> {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            handleTrackingHelperOpen(e);
                                                        }}
                                                    />
                                                    {getTrackingHelperPopover(id, openRateBreakdown, anchorEl)}
                                                </p>
                                            </dd>
                                            <dt className={classes.descriptorTag}>
                                                <p className={classes.mainText}>Trailer #</p>
                                            </dt>
                                            <dd className={classes.descriptorDetail}>
                                                <p className={classes.secondaryText} style={{color:'black', fontSize: 14 }}>
                                                    {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "trailer") && checkWhiteSpace(load.carrierTrackingInfo.trailer) && load.carrierTrackingInfo.trailer != "" ? load.carrierTrackingInfo.trailer 
                                                    : <span className={classes.missing}> MISSING </span>}
                                                </p>
                                            </dd>
                                        </dl>
                                    </div>
                                ) : carrierCommunications &&
                                    checkValueExists(carrierCommunications, "carrierCommunication") &&
                                    checkValueExists(carrierCommunications.carrierCommunication, "vendorName") &&
                                    carrierCommunications.carrierCommunication.vendorName == "TRUCKER_TOOLS" ? (
                                    <div>
                                        <dl className={classes.descriptionList}>
                                            <dt className={classes.descriptorTag}>
                                                <p className={classes.mainText}>Driver Name</p>
                                            </dt>
                                            <dd className={classes.descriptorDetail}>
                                                <p className={classes.secondaryText}>
                                                    {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "driverName") ? load.carrierTrackingInfo.driverName : <span className={classes.missing}> MISSING </span>}
                                                </p>
                                            </dd>
                                            <dt className={classes.descriptorTag}>
                                                <p className={classes.mainText}>Driver Phone</p>
                                            </dt>
                                            <dd className={classes.descriptorDetail}>
                                                <p className={classes.secondaryText}>
                                                    {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "driverPhoneNumber") ? formatPhoneNumberWithHyphens(load.carrierTrackingInfo.driverPhoneNumber) : <span className={classes.missing}> MISSING </span>}
                                                </p>
                                            </dd>
                                        </dl>
                                    </div>
                                ) : null}
                            
                                {load.miles && (
                                    <div>
                                        <dl className={classes.descriptionList}>
                                            <dt className={classes.descriptorTag}>
                                                <p className={classes.mainText}> Distance:</p>
                                            </dt>
                                            <dd className={classes.descriptorDetail}>
                                                <p className={classes.secondaryText} style={{color:'black'}}> {load.miles}mi</p>
                                            </dd>
                                        </dl>
                                    </div>)                                  
                                }

                                {checkValueExists(load, "equipmentType") && (
                                    <div>
                                        <dl className={classes.descriptionList}>
                                            <dt className={classes.descriptorTag}>
                                                <p className={classes.mainText}>Equipment:</p>
                                            </dt>
                                            <dd className={classes.descriptorDetail}>
                                                <p className={classes.secondaryText} style={{color:'black'}}> {load.equipmentType}</p>
                                            </dd>
                                        </dl>
                                    </div>)       
                                }
                            </Row>
                        </Col>

                    </Row>



                </Col>
            </Link>
            <Row style={{
                display: 'flex',
                width: '100%',
                height: '28%',
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                backgroundColor: '#F6F6F6',
                margin: 0,
                justifyContent: 'space-between'
            }}>
                <div>
                    <Row key={key + "booked_action_row"} >
                        {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "trackingStatus") && (
                            <p className={classes.mainText} style={{textAlign:'center'}}>{load.carrierTrackingInfo.trackingStatus}</p>
                        )}
                    </Row>
                </div>
            </Row>
        </div>

    );
}


export default compose(
    connect(),
    muiThemeable()
)(ActiveLoadCard)

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    mainText: {
        fontSize: '14px',
        // lineHeight: '24px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '12px',
        // lineHeight: '21px',
        color: 'grey'
    },
    missing: {
        wordBreak: 'break-word',
        fontSize: '14px',
        color: 'grey'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    },
    showInterestBtn: {
        height: 'auto',
        width: '100%',
        // color: muiTheme.secondary, 
        backgroundColor: '#ad2228 !important',
        // borderColor: muiTheme.primary
    },
    descriptionList: {
        width: '200px',
        overflow: 'hidden',
        padding: 0,
        margin: 0
    },
    descriptorTag: {
        float: 'left',
        width: '45%;',
        padding: 0,
        margin: 0
    },
    descriptorDetail: {
        float: 'left',
        width: '55%;',
        padding: 0,
        margin: 0
    }
});