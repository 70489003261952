// TRUCKER TOOLS THEME FILE

import getMuiTheme from 'material-ui/styles/getMuiTheme';

const theme = getMuiTheme({
    primary: '#ad2228',
    secondary: '#ad2228',
    actionColor: "#ad2228",
    lightContrastText: '#fff',
    darkContrastText: '#000',
    quoteButton: { 
        background: '#F8FBFF',
        border: '#78BEFF',
        text: '#006DD3'
    },
    useDarkContrastText: false,
    menuHighlightColor: "rgba(0, 0, 0, 0.3)",
    menuLinkColor: "#fff",
    useWhiteBackgroundBehindLogo: true,
    tagLine:'',
    customerUniqueId: "2zr5ys19",
    ttRed: "#AD2228",
    ttUnselectGrey: "#53565A",
    ttSecondary: "#EAEAEA",
    forceDrawerAlwaysOpen: false,
    primaryFontFamily: "Roboto",
    loginMsg: "You've selected a feature that requires a secure login. Please login or sign up.",
    source: "Carrierportal" 
});
//console.log(theme);
export default theme