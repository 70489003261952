import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { Card } from 'material-ui/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import FA from 'react-fontawesome'

import LoadCard from './LoadCard'
import HistoricalLoadCard from './my-loads-card/HistoricalLoadCard'
import ActiveLoadCard from './my-loads-card/ActiveLoadCard'

import useGtagHook from '../../../../gtag-hook';
import { checkValueExists } from "../../../../lib/check-value-exists"
import { canShowBinTradeMark } from '../../../helpers/permissions';
import { numberWithCommas } from '../../../../lib/number-with-commas';



const useStyles = makeStyles({
    loadCard: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        marginTop: 10,
        marginLeft: 15,
        marginRight: 10,
        marginBottom: 0,
        '&:hover': {
            boxShadow: '0 2px 7px rgba(0,0,0,28%), 0 2px 5px rgba(0,0,0,28%) !important'
        },
        cursor: 'pointer'
    },
    myLoadActiveCard: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        marginBottom: 0,
        '&:hover': {
            boxShadow: '0 2px 7px rgba(0,0,0,28%), 0 2px 5px rgba(0,0,0,28%) !important'
        },
        cursor: 'pointer'
    },
    myLoadHistoricalCard: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        marginBottom: 0,
    },
    button: {
        backgroundColor: 'white',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5

    }
});

const LoadListItemDesktop = (props) => {

    const { 
        load, 
        availability, 
        index, 
        isRecentActivity = false, 
        isMyLoadsScreen = false, 
        isBookedLoads = false,
        myLoadsRenderedTab = 0,
        recentActivityActionType,
        pushReloads, 
        reloadsOpen, 
        muiTheme,
        removeLoadFromList,
        isHome=false,
    } = props

    const classes = useStyles();
    const [sendGtagEvent] = useGtagHook()

    const [isExpanded, setIsExpanded] = useState(false)
    const [pickup, setPickup] = useState(null)
    const [delivery, setDelivery] = useState(null)
    const [icon, setIcon] = useState('arrow-down')

    useEffect(() => {
        buildPickupDeliveryData()
    }, [])

    useEffect(() => {

        if (isExpanded) {
            setIcon('chevron-down')
            sendGtagEvent(
                "view_load",
                {
                    loadId: load.loadId,
                    brokerId: load.brokerId,
                    matchIndex: index
                }
            )
        } else
            setIcon('chevron-right')

    }, [isExpanded])

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded)
    }

    const buildPickupDeliveryData = () => {
        let tmpPickup = {}
        let tmpDelivery = {}

        if (load.stops) {

            tmpPickup = {
                ...load.stops[0],
                scheduledAt: load.stops[0].scheduledAt ? load.stops[0].scheduledAt : load.stops[0].scheduled_at
            }

            tmpDelivery = {
                ...load.stops[load.stops.length - 1],
                scheduledAt: load.stops[load.stops.length - 1].scheduledAt ? load.stops[load.stops.length - 1].scheduledAt : load.stops[load.stops.length - 1].scheduled_at
            }

        }
        else {

            tmpPickup = {
                city: load.start_city,
                state: load.start_state,
                scheduledAt: load.start_time
            }

            tmpDelivery = {
                city: load.end_city,
                state: load.end_state,
                scheduledAt: load.end_time
            }

        }

        setPickup(tmpPickup)
        setDelivery(tmpDelivery)
    }

    const getCardHeaderText = (activity) => {
        let localTimeFromNow = moment.tz(load.activities[0].created_at, "America/New_York").local().fromNow()

        // load.activities[0].action_name
        switch (activity.action_name) {
            case 'LOAD_VIEW':
                return "Viewed " + localTimeFromNow
            case 'LOAD_VIEW_SHOW_INTEREST':
                return "Showed interest " + localTimeFromNow
            case 'PHONE_CLICK':
                return "Contacted the broker " + localTimeFromNow
            case 'QUOTE':
                return "Quoted " + localTimeFromNow
            case 'BOOK_IT_NOW':
                if(canShowBinTradeMark()){
                    return "Opened Book-It-Now\xAE form " + localTimeFromNow
                }else{
                    return "Opened Book-It-Now form " + localTimeFromNow
                }
            case 'BookItNow':
                if (checkValueExists(load, "covered") && load.covered == 1) {
                    if (checkValueExists(load, "selfCovered") && load.selfCovered == 1) {
                        return "Covered by you " + localTimeFromNow
                    } else {
                        return "Covered by someone else " + localTimeFromNow
                    }
                } else {
                    return "Viewed " + localTimeFromNow
                }
            default:
                return "";
        }

    }

    const getCardHeader = () => {
        if (load.activities == null)
            return ""

        if (recentActivityActionType !== null) {
            let activityMatches = load.activities.filter(activity => {
                if (activity.action_name == recentActivityActionType) {
                    return true
                } else {
                    return false
                }
            })

            if (activityMatches.length > 0) {
                return getCardHeaderText(activityMatches[0])
            } else {
                return getCardHeaderText(load.activities[0])
            }
        } else {
            return getCardHeaderText(load.activities[0])
        }
    }

    return (
        <div>
            { pickup && delivery &&
                <div style={{ marginBottom: 15 }}>
                    {((isRecentActivity || isMyLoadsScreen || isBookedLoads) && !reloadsOpen) &&
                        <div style={{ paddingRight: 25, paddingTop: 10, textAlign: 'right' }}>
                            <p style={{ marginBottom: 0, fontWeight: 'bold', display: 'inline-block' }}>
                                {getCardHeader()}
                            </p>
                            { (load.targetPay && isMyLoadsScreen) || (load.targetPay && isBookedLoads) && (
                                <p style={{ fontWeight: 'bold', display: 'inline' }}>
                                    {" for "}
                                    <span style={{ color: 'green' }}>
                                        {"$" + numberWithCommas(parseFloat(load.targetPay).toFixed(2))}
                                    </span>
                                </p>
                            )}
                        </div>
                    }
                    <Card className={isMyLoadsScreen ? (myLoadsRenderedTab != 0 ? classes.myLoadHistoricalCard : classes.myLoadActiveCard) : classes.loadCard } onMouseDown={isMyLoadsScreen && myLoadsRenderedTab !== 0 ? null : toggleExpanded}>
                        <CardContent>
                        { (isMyLoadsScreen && !reloadsOpen) ? 
                                myLoadsRenderedTab == 0 ?
                                    <ActiveLoadCard
                                        {...props}
                                        load={load}
                                        index={index}
                                        pickup={pickup}
                                        delivery={delivery}
                                        availability={availability}
                                        pushReloads={pushReloads}
                                        reloadsOpen={reloadsOpen}
                                        isExpanded={isExpanded}
                                        dropDownIcon={icon}
                            
                                        // carrierCommunications={carrierCommunications}
                                    />
                                    :
                                    <HistoricalLoadCard
                                        {...props}
                                        load={load}
                                        index={index}
                                        pickup={pickup}
                                        delivery={delivery}
                                        availability={availability}
                                        pushReloads={pushReloads}
                                        reloadsOpen={reloadsOpen}
                            
                                    />
                            :
                            <LoadCard
                                {...props}
                                load={load}
                                index={index}
                                pickup={pickup}
                                delivery={delivery}
                                availability={availability}
                                isExpanded={isExpanded}
                                pushReloads={pushReloads}
                                dropDownIcon={icon}
                                reloadsOpen={reloadsOpen}
                                removeLoadFromList={removeLoadFromList}
                                isBookedLoads={isBookedLoads}
                                isHome={isHome}
                    
                            />

                        }
                        </CardContent>
                    </Card>
                </div>
            }
        </div>
    );
}


export default compose(
    connect(),
    muiThemeable()
)(LoadListItemDesktop)