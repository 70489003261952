
import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import FA from 'react-fontawesome'
import { Col, Row, Grid } from "react-bootstrap"
import moment from 'moment'
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify'
import { checkValueExists } from "../../../../../../lib/check-value-exists"
import { titleCaseFormatter } from "../../../../../../lib/title-case-formatter"

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBiohazard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPhoneNumberWithHyphens } from '../../../../../../components/formatPhoneNumber';

library.add(
    faBiohazard
);

const HistoricalLoadCard = (props) => {
    const classes = useStyles();
    const {
        load,
        index,
        pickup,
        delivery,
        availability,
        muiTheme,
        key,
        carrierCommunications
    } = props

    const [isHazmat, setIsHazmat] = useState(false)

    useEffect(() => {

        if (load.truckTypes) {
            if (load.truckTypes.toUpperCase().indexOf('HAZMAT') > -1)
                setIsHazmat(true)
        }
    }, [])


    const getExpenseRate = (breakDown) => {
        if(breakDown.length === 1){
            return breakDown[0].ledgerAmount
        }else{
            let total = 0;
            breakDown.forEach(element => {
                total += element.ledgerAmount
            });
            return total
        }
    }

    return (
        <div style={{ height: '100%' }}>
            {/* // <Animatable.div key={load.id} style={[CustomStyleSheet.card, rowStyle]} animation={animation} duration={duration} > */}
            <Link
                style={{ color: 'black' }}
                to={{
                    pathname: '/loadDetails',
                    state: {
                        load: load,
                        pickup: pickup,
                        delivery: delivery,
                        availability: availability,
                        isMyLoadsScreen: true,
                        myLoadsRenderedTab: 2, //zero for active loads
                        carrierCommunications: carrierCommunications,
                    }
                }}
            >
                <Col style={{ display: 'flex', width: '100%', padding: 5 }} >
                    <Row style={{ display: 'flex', width: '100%', height: '100%', margin: 0 }}>

                        <Col style={{ display: 'flex', width: 25 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '63%', paddingTop: 8 }} >
                                <div style={{ width: 10, height: 10, borderRadius: 10 / 2, backgroundColor: '#A4A4AC' }} >
                                </div>
                                <div style={{ height: '70%', width: 2, backgroundColor: 'lightgrey', marginVertical: 5 }} >
                                </div>
                                <div style={{ width: 10, height: 10, backgroundColor: 'lightgrey' }} >
                                </div>
                            </div>
                        </Col>
                        <Col style={{ display: 'flex', flexDirection: 'column', width: '55%' }}>
                            <Row style={{ margin: 0 }}>
                                <div>
                                    {load.origin && (
                                        <Row>
                                            <p className={classes.mainText} style={{ display: 'inline-block' }}>
                                                {titleCaseFormatter(load.origin.city)},{' ' + load.origin.state}
                                            </p>
                                            {isHazmat && <FontAwesomeIcon icon={['fas', 'biohazard']} style={{ color: '#000', display: 'inline-block', marginLeft: 5 }} />}
                                        </Row>
                                    )}
                                    {load.pickupDate && (
                                        <Row  style={{ margin: 0 }}>
                                            <p className={classes.secondaryText}>
                                                {moment(load.pickupDate).format('ll')}
                                            </p>
                                            <p className={classes.secondaryText}>
                                                {moment(load.pickupDate, "hh:mm a").format('HH:mm')}
                                            </p>
                                        </Row>
                                    )}
                                </div>
                            </Row>

                            <Row style={{ margin: 0 }}>
                                <div>
                                    {load.destination && (
                                        <Row style={{height: '40%' }}>
                                            <p className={classes.mainText}>
                                                {titleCaseFormatter(load.destination.city)},{' ' + load.destination.state}
                                            </p>
                                        </Row>
                                    )}
                                    {load.deliveryDate && (
                                        <Row style={{ margin: 0, height: '40%' }}>
                                            <p className={classes.secondaryText}>
                                                {moment(load.deliveryDate).format('ll')}
                                            </p>
                                            <p className={classes.secondaryText}>
                                                {moment(load.deliveryDate, "hh:mm a").format('HH:mm')}
                                            </p>
                                        </Row>
                                    )}
                                </div>
                            </Row>
                        </Col>
                        <dl className={classes.descriptionList}>
                            <dt className={classes.descriptorTag}>
                                <p className={classes.mainText}>Ref #</p>
                            </dt>
                            <dd className={classes.descriptorDetail}>
                                <span className={classes.secondaryText} style={{ display: "flex", alignItems: 'center', color:'black', fontSize: 14 }}> {load.loadNumber}</span>
                            </dd>
                            <dt className={classes.descriptorTag}>
                                <p className={classes.mainText} >Rate:</p>
                            </dt>
                            <dd className={classes.descriptorDetail}>
                                <span className={classes.secondaryText} style={{ display: "flex", alignItems: 'center', color:'black', fontSize: 14 }}>${(getExpenseRate(load.expenseLedgerBreakDown)).toFixed(2)}</span>
                            </dd>
                        </dl>
                        {/* <Col style={{ width: '35%' }}>
                            {checkValueExists(load, "loadNumber") &&
                                <Row style={{ alignItems: 'center' }}>
                                    <div style={{ float: 'right' }}>
                                        <Row style={{ display: "flex" }}>
                                            <p className={classes.mainText}>Ref #</p><span className={classes.secondaryText} style={{ display: "flex", alignItems: 'center', color:'black', fontSize: 14 }}> {load.loadNumber}</span>
                                        </Row>
                                    </div>
                                </Row>
                            }
                            {load.expenseLedgerBreakDown && load.expenseLedgerBreakDown.length > 0 &&
                                <Row style={{ alignItems: 'center' }}>
                                    <div style={{ float: 'right' }}>
                                        <Row style={{ display: "flex" }}>
                                            <p className={classes.mainText} >Rate:</p><span className={classes.secondaryText} style={{ display: "flex", alignItems: 'center', color:'black', fontSize: 14 }}>${(getExpenseRate(load.expenseLedgerBreakDown)).toFixed(2)}</span>
                                        </Row>
                                    </div>
                                </Row>
                            }
                        </Col> */}
                    </Row>
                </Col>
            </Link>
            <Row style={{
                display: 'flex',
                width: '100%',
                height: '28%',
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                backgroundColor: '#F6F6F6',
                margin: 0,
                justifyContent: 'space-between'
            }}>
                <div>
                    <Row key={key + "booked_action_row"} >
                        {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "trackingStatus") && (
                            <p className={classes.mainText} style={{ textAlign: 'center' }}>{load.carrierTrackingInfo.trackingStatus}</p>
                        )}
                    </Row>
                </div>
            </Row>
        </div>

    );
}


export default compose(
    connect(),
    muiThemeable()
)(HistoricalLoadCard)

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    mainText: {
        fontSize: '14px',
        // lineHeight: '24px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '12px',
        // lineHeight: '21px',
        color: 'grey'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    },
    showInterestBtn: {
        height: 'auto',
        width: '100%',
        // color: muiTheme.secondary, 
        backgroundColor: '#ad2228 !important',
        // borderColor: muiTheme.primary
    },
    descriptionList: {
        width: '200px',
        overflow: 'hidden',
        padding: 0,
        margin: 0
    },
    descriptorTag: {
        float: 'left',
        width: '35%;',
        padding: 0,
        margin: 0,
    },
    descriptorDetail: {
        float: 'left',
        width: '65%;',
        padding: 0,
        margin: 0,
        display:'inline-block'
    }
});