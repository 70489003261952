import React, { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withState, compose, lifecycle, withHandlers } from 'recompose'
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

//COMPONENTS//
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

//ACTIONS//
import { getPreferencesDetail, updatePreferences } from './actions.js'
//Fucntions
import queryString from 'query-parse';

const COMPONENT_NAME = ({
  //PROPS FROM PARENT//
  label = 'Test',
  userEmail,

  //REDUX//
  getPreferencesDetail, updatePreferences, preferences,

  //OTHER
  muiTheme, location, ...props
}) => {

  const useStyles = makeStyles({
    cancelButtonStyle: {
      backgroundColor: muiTheme.ttUnselectGrey,
      borderColor: muiTheme.ttUnselectGrey,
      color: "white",
      fontSize: 14,
      margin: 5,
      '&:hover': {
        background: `${muiTheme.ttUnselectGrey} !important`
      }
    },
    savePreferencesButton: {
      backgroundColor: muiTheme.actionColor,
      borderColor: muiTheme.actionColor,
      color: "white",
      fontSize: 14,
      margin: 5,
      '&:hover': {
        background: `${muiTheme.actionColor} !important`
      }
    }
  });

  const classes = useStyles()

  const [newpreferences, updateNewpreferences] = useState(false)
  const [receiveEmails, setReceiveEmails] = useState(false)
  const [invitationEmails, setInvitationEmails] = useState(false)
  const [allowDrivers, setAllowDrivers] = useState(false)
  const [allowDriversBookItNow, setAllowDriversBookItNow] = useState(false)
  const [allowDriversOffer, setAllowDriversOffer] = useState(false)
  const [initialState, setInitialState] = useState(null)

  const hasUnsubscribed = useRef(false)

  const willMount = useRef(true);
  if (willMount.current) {
    // console.log('JQ - This runs only once before rendering the component.');
    // console.log('JQ- Props', location);

    var parsed = queryString.toObject(location.search.substring(1, location.search.length));
    var unsubscribedEmail = parsed.unsubscribedEmail ? parsed.unsubscribedEmail : '';

    getPreferencesDetail(unsubscribedEmail);
    //console.log('Updating table component');
    willMount.current = false;
  }

  if (location) {
    var parsed = queryString.toObject(location.search.substring(1, location.search.length));
    var unsubscribedEmail = parsed.unsubscribedEmail;
  }

  if (preferences && preferences.payload && ('userEmail' in preferences.payload) && !newpreferences) {
    updateNewpreferences(preferences.payload);
    setAllowDrivers(preferences.payload.allowDrivers)
    setAllowDriversBookItNow(preferences.payload.allowDriversBookItNow)
    setAllowDriversOffer(preferences.payload.allowDriversOffer)
    setInvitationEmails(preferences.payload.invitationEmails)
    setReceiveEmails(preferences.payload.receiveEmails)

    setInitialState(
      {
        initPreferences: preferences.payload,
        initAllowDrivers: preferences.payload.allowDrivers,
        initAllowDriversBookItNow: preferences.payload.allowDriversBookItNow,
        initAllowDriversOffer: preferences.payload.allowDriversOffer,
        initAllowDriversInviteEmails: preferences.payload.invitationEmails,
        initAllowReceiveEmails: preferences.payload.receiveEmails
      })
  }


  const updateInitialValues = (unsubscribedEmail = null) => {
    let initialPreferences = {
      initAllowDrivers: preferences.payload.allowDrivers,
      initAllowDriversBookItNow: preferences.payload.allowDriversBookItNow,
      initAllowDriversOffer: preferences.payload.allowDriversOffer,
      initAllowDriversInviteEmails: unsubscribedEmail ? 0 : preferences.payload.invitationEmails,
      initAllowReceiveEmails: unsubscribedEmail ? 0 : preferences.payload.receiveEmails
    }
    setInitialState(initialPreferences)
    if (unsubscribedEmail) {
      // console.log("jq - unsubscribe update: ", initialPreferences)
      updateNewpreferences(preferences.payload);
      setAllowDrivers(preferences.payload.allowDrivers)
      setAllowDriversBookItNow(preferences.payload.allowDriversBookItNow)
      setAllowDriversOffer(preferences.payload.allowDriversOffer)
      setInvitationEmails(0)
      setReceiveEmails(0)
      handlePreferencesChange({ ...preferences.payload, invitationEmails: 0, receiveEmails: 0 })
    }
  }

  const checkInitState = () => {
    return (
      (initialState != null) &&
      (initialState.initAllowDrivers == allowDrivers) &&
      (initialState.initAllowDriversBookItNow == allowDriversBookItNow) &&
      (initialState.initAllowDriversOffer == allowDriversOffer) &&
      (initialState.initAllowDriversInviteEmails == invitationEmails) &&
      (initialState.initAllowReceiveEmails == receiveEmails)
    )
  }

  const resetToInitialValues = () => {
    updateNewpreferences(initialState.initPreferences)
    setAllowDrivers(initialState.initAllowDrivers)
    setAllowDriversBookItNow(initialState.initAllowDriversBookItNow)
    setAllowDriversOffer(initialState.initAllowDriversOffer)
    setInvitationEmails(initialState.initAllowDriversInviteEmails)
    setReceiveEmails(initialState.initAllowReceiveEmails)
  }

  const handlePreferencesChange = (newPreferences) => {
    var parsed = queryString.toObject(location.search.substring(1, location.search.length));
    var unsubscribedEmail = parsed.unsubscribedEmail ? parsed.unsubscribedEmail : '';

    updatePreferences(newPreferences, () => getPreferencesDetail(unsubscribedEmail));
  }

  useEffect(() => {
    if (preferences.requesting == false && preferences.successful == true) {
      var parsed = queryString.toObject(location.search.substring(1, location.search.length));
      var unsubscribedEmail = parsed.unsubscribedEmail ? parsed.unsubscribedEmail : '';
      // console.log("jq - ...", hasUnsubscribed, unsubscribedEmail === userEmail);
      if (!hasUnsubscribed.current && unsubscribedEmail === userEmail) {
        updateInitialValues(unsubscribedEmail)
        hasUnsubscribed.current = true
      } else {
        updateInitialValues()
      }
    }
  }, [preferences.requesting])

  return (
    <div>
      {/* <PaperStack> */}
      <div>
        <FormGroup>
          <FormControlLabel
            uid="profile-preferences-receive-emails"
            disabled={preferences.requesting}
            control={
              <Checkbox
                style={{ color: muiTheme.actionColor, transform: "scale(1.5)" }}
                checked={receiveEmails}
                disabled={preferences.requesting}
                onChange={(event) => {
                  updateNewpreferences({ ...newpreferences, receiveEmails: event.target.checked })
                  setReceiveEmails(event.target.checked)
                }}
              />
            }
            label={<p style={{ fontSize: 14 }}>{(newpreferences && newpreferences.userEmail) ? "Receive Emails of Matching Loads (" + newpreferences.userEmail + ")" : "Receive Emails of Matching Loads "}</p>}
          />
          {process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" &&
            <FormControlLabel
              disabled={preferences.requesting}
              control={
                <Checkbox
                  style={{ color: muiTheme.actionColor, transform: "scale(1.5)" }}
                  checked={invitationEmails}
                  disabled={preferences.requesting}
                  onChange={(event) => {
                    updateNewpreferences({ ...newpreferences, invitationEmails: event.target.checked })
                    setInvitationEmails(event.target.checked)
                  }}
                />
              }
              label={<p style={{ fontSize: 14 }}>{(newpreferences && newpreferences.userEmail) ? "Receive Invitation Emails from Brokers (" + newpreferences.userEmail + ")" : "Receive Invitation Emails from Brokers"}</p>}
            />
          }
          {/* <FormControlLabel
            control={
              <Checkbox
                style={{ color: muiTheme.actionColor, transform: "scale(1.5)" }}
                checked={newpreferences.isPrimary ? newpreferences.publishCapacity : true}
                disabled={newpreferences.isPrimary ? false : true}
                onChange={(event) => {
                  updateNewpreferences({ ...newpreferences, publishCapacity: event.target.checked })
                }}
              />
            }
            label={<p style={{ fontSize: 14 }}>Publish My Capacity</p>}
          /> */}


          {props.userRole && props.userRole.toLowerCase() == "admin" && (
            <div style={{ display: "contents" }}>
              <FormControlLabel
                disabled={preferences.requesting}
                control={
                  <Checkbox
                    style={{ color: muiTheme.actionColor, transform: "scale(1.5)" }}
                    checked={allowDrivers}
                    disabled={preferences.requesting}
                    onChange={(event) => {
                      updateNewpreferences({ ...newpreferences, allowDrivers: event.target.checked })
                      setAllowDrivers(event.target.checked)
                    }}
                  />
                }
                label={<p style={{ fontSize: 14 }}>Allow My Drivers to See Loads</p>}
              />
              <FormControlLabel
                disabled={preferences.requesting}
                control={
                  <Checkbox
                    style={{ color: muiTheme.actionColor, transform: "scale(1.5)" }}
                    checked={allowDriversOffer}
                    disabled={newpreferences && newpreferences.isPrimary ? false : true}
                    onChange={(event) => {
                      updateNewpreferences({ ...newpreferences, allowDriversOffer: event.target.checked })
                      setAllowDriversOffer(event.target.checked)
                    }}
                  />
                }
                label={<p style={{ fontSize: 14 }}>Allow My Drivers to Make Offers on Loads</p>}
              />
              <FormControlLabel
                disabled={preferences.requesting}
                control={
                  <Checkbox
                    style={{ color: muiTheme.actionColor, transform: "scale(1.5)" }}
                    checked={allowDriversBookItNow}
                    disabled={preferences.requesting}
                    onChange={(event) => {
                      updateNewpreferences({ ...newpreferences, allowDriversBookItNow: event.target.checked })
                      setAllowDriversBookItNow(event.target.checked)
                    }}
                  />
                }
                label={<p style={{ fontSize: 14 }}>Allow My Drivers to Book Loads</p>}
              />
            </div>
          )}
        </FormGroup>
      </div>
      <div
        style={{ marginTop: 15 }}
      >
        <Button
          id='cancelButton'
          size="small"
          variant="contained"
          disabled={checkInitState() || preferences.requesting}
          classes={{
            root: classes.cancelButtonStyle
          }}
          onClick={(e) => { resetToInitialValues() }}
        >
          CANCEL
        </Button>
        <Button
          id='cancelButton'
          size="small"
          variant="contained"
          disabled={checkInitState() || preferences.requesting}
          classes={{
            root: classes.savePreferencesButton
          }}
          onClick={(e) => { handlePreferencesChange(newpreferences) }}
        >
          SAVE PREFERENCES
        </Button>
      </div>
      {/* </PaperStack> */}
    </div>

  )
}

COMPONENT_NAME.propTypes = {
  //label:PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  preferences: state.preferencesReducer
})

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    getPreferencesDetail: getPreferencesDetail,
    updatePreferences: updatePreferences,
  }, dispatch)
}

export default compose(
  connect(mapStateToProps, matchDispatchToProps),
  muiThemeable()
)(COMPONENT_NAME)
