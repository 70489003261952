import dayjs from 'dayjs'
import utc from "dayjs/plugin/utc";
import timezonePlugin from "dayjs/plugin/timezone";
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezonePlugin);
dayjs.extend(customParseFormat)

export const getDateTimeFromNow = (dateTime, parseFormat) => {
    dateTime = dateTime.toUpperCase()
    dateTime = dayjs.tz(dateTime, parseFormat, "America/New_York").local().format("YYYY-MM-DD HH:mm")

    return dayjs(dateTime).fromNow()
}

export const getLocalDateTime = (dateTime, parseFormat) => {
    dateTime = dateTime.toUpperCase()
    dateTime = dayjs.tz(dateTime, parseFormat, "America/New_York").local().format("YYYY-MM-DD HH:mm")
    return dayjs(dateTime).local()
}


export const formatDateTime = (dateTime, timeZone = "") => {
    if (dateTime) {
        dateTime = dayjs(dateTime).format("MMM D HH:mm") + timeZone
        return dateTime
    }
    return null
}

export const joinOfferDateTime = (offer) => {
    if (offer && offer.date && offer.time)
        return offer.date.toString() + (offer.time ? ` ${offer.time.toString()}` : "")
    return null
}

