import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import HelpWrapper from '../../HelpWrapper'
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';

import { updateLoadSearchBar } from '../../../../CarrierPortal/ActionReducers/components';
import { bindActionCreators } from 'redux';

import { zonesData, anyWhereZone } from '../zones';


const ZoneSelect = ({
  updateLoadSearchBar,
  muiTheme,
  inputTransparentStyle,
  handleSetSelectedZone,
  existingZones,
  searchComponentType = 'destination',
  customWidth = false,
  mobileView = false,
  hasAnyLocation = false,
  clearData,
  ...props }) => {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: mobileView ? '100%' : '450px',
      '& .MuiSelect-select.MuiSelect-select': {
        height: 30,
        display: "flex",
        alignItems: "flex-end"
      }
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    wrapper: {
      width: '85%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      lineHeight: '24px',
    },
    selectfieldStyleOutline: {
      borderBottom: '1px solid #ccc',
      height: '56px',
      width: mobileView ? '100%' : '450px',
      maxWidth: mobileView ? null : '450px',
      margin: '0px',
      backgroundColor: 'white',
      paddingLeft: '10px',
      paddingRight: '0px',
      fontSize: '16px',
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.1,
    },
    menuItem: {
      fontSize: '14px'
    },
    icon: {
      position: 'absolute',
      fontSize: '2.5rem'
    },
    removePadding: {
      padding: '0 !important'
    },
    chips: {
      fontSize: '12px',
      height: '22px'
    },
    icon: {
      position: 'absolute',
      fontSize: '2.5rem',
    },
    labelText: {
      fontSize: 14,
      top: -5,
      left: -3,
      ' &.MuiInputLabel-filled.MuiInputLabel-shrink':{
        transform: 'translate(12px, 10px)'
      }
    },
    removePadding: {
      padding: '0 !important',
      marginTop: 14
    },
  }));
  const classes = useStyles();

  // const [hasError, setErrors] = useState(false);
  const [zones, setZones] = useState([]);
  const [selectedZones, setSelectedZones] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => {

    setZones(zonesData);

  }, [existingZones]);

  useEffect(() => {
    if (existingZones && existingZones.length !== 0) {
      let existingZoneObjs = zonesData.filter(zoneOption => existingZones.some(existingZone => zoneOption.abbr === existingZone.id));
      setSelectedZones(existingZoneObjs)
    }else{
      setSelectedZones([])
    }
  }, [existingZones]);

  useEffect(() => {
    if (isMounted.current) {
      if (searchComponentType === "destination") {
        updateLoadSearchBar([], 'lane_preferences')
      } else {
        updateLoadSearchBar([], 'lane_origins')
      }
      setSelectedZones([])
    } else {
      isMounted.current = true
    }

  }, [clearData])

  function handleChange(event, modifiedZones) {

    // 'value' here represents the truck type ids selected from dropdown as in the value of the event target
    //or it equals the modified list after deleting from the chip 'x' button
    let chosenZones = null
    if (modifiedZones)
      chosenZones = modifiedZones
    else
      chosenZones = event.target.value

    setSelectedZones(chosenZones);
    let selectedZoneObjs = chosenZones.map(zone => ({ id: zone.abbr, name: zone.name }))
    handleSetSelectedZone(selectedZoneObjs)
  };

  const handleChipDelete = (zoneToDelete) => () => {

    let modifiedZones = [...selectedZones]
    modifiedZones = modifiedZones.filter((zone) => zone !== zoneToDelete);

    handleChange(null, modifiedZones)

  };

  function renderSelectedZone(selected) {
    let chipMax = 2

    return (
      <div className={classes.wrapper}>
        {
          selected.map((value, index) => {
            if (mobileView & index >= chipMax)
              return index === chipMax ? <span style={{ margin: 5, fontSize: 18 }}>&hellip;</span> : null
            return (
              <Chip
                variant="outlined"
                key={value}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                label={value.abbr}
                className={classes.chips}
                onDelete={handleChipDelete(value)}
              />
            )
          })
        }
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <InputLabel
        variant={'filled'}
        id="selected-label"
        className={classes.labelText}
        shrink
      >
        {searchComponentType === "destination" ?
          <span>Destination</span>
          :
          <span>Origin</span>
        }
      </InputLabel>
      <Select
        // ref={selectBox}
        className={classes.selectfieldStyleOutline}
        labelId="select-label"
        displayEmpty
        disableUnderline={true}
        multiple
        value={selectedZones}
        onChange={(event) => handleChange(event)}
        renderValue={(selected) => renderSelectedZone(selected)}
        style={{ width: customWidth ? customWidth : mobileView ? '100%' : 450, marginTop: 0 }}
        inputProps={{
          classes: {
            icon: classes.icon,
            root: classes.removePadding
          },
        }}
        MenuProps={{
          autoFocus: false,
          disableScrollLock: true,
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          }
        }}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
      >
        <MenuItem
          className={classes.menuItem}
          value=""
          disabled>
          Select Zones
        </MenuItem>
        {hasAnyLocation && (
          <MenuItem
            key={anyWhereZone.id}
            className={classes.menuItem}
            value={anyWhereZone}
          >
            <b>{anyWhereZone.name}</b>
          </MenuItem>
        )}
        {zones.map((zone) => {
          return (
            <MenuItem
              key={zone.id}
              value={zone}
              className={classes.menuItem}
            >
              <Checkbox style={{ color: muiTheme.secondary }} checked={selectedZones.includes(zone)} />
              <ListItemText classes={{ primary: classes.menuItem }} primary={zone.name} />
            </MenuItem>)
        })}
      </Select>
    </div>
  )
}

const mapStateToProps = state => ({

})

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    updateLoadSearchBar: updateLoadSearchBar
  }, dispatch)
}

export default compose(
  muiThemeable(),
  connect(mapStateToProps, matchDispatchToProps),
)(ZoneSelect)