import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { Col, Row, Grid } from "react-bootstrap"
import CardContent from '@material-ui/core/CardContent';
import { Card } from 'material-ui/Card';
import { checkValueExists } from "../../../../../../../lib/check-value-exists"
import { titleCaseFormatter } from "../../../../../../../lib/title-case-formatter"
import muiThemeable from 'material-ui/styles/muiThemeable';
import Button from '@material-ui/core/Button';
import FA from 'react-fontawesome'
import {useAuthenticationHook} from '../../../../../../hooks/authentication-hook';
import { toast } from 'react-toastify'
import moment from 'moment'

const HistoricalLoadDetails = (props) => {

    const {
        load,
        delivery,
        pickup,
        availability,
        muiTheme,
        isMyLoadsScreen = false,
        myLoadsRenderedTab = 0,
        handleTabChange,
        callRefreshCard,
    } = props

    const classes = useStyles();
    const TT_LOGO_ASSET_URL = 'http://assets.truckertools.com/images/logos/'

    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()
    const user = myAuthService.getUser()

    const getExpenseRate = (breakDown) => {
        if(breakDown.length === 1){
            return breakDown[0].ledgerAmount
        }else{
            let total = 0;
            breakDown.forEach(element => {
                total += element.ledgerAmount
            });
            return total
        }
    }


    return (
        <div>
            <div style={{ padding: 10, paddingBottom: 70 }}>
                <p style={{ fontSize: 14, fontWeight: 'bold' }}>Load Information</p>
                <Card className={classes.card} style={{ borderRadius: 2, marginBottom: 10 }}>
                    <CardContent style={{ padding: 5, paddingLeft: 15, paddingRight: 15 }}>
                        <Row style={{ display: 'flex', width: '100%', height: '100%', margin: 0 }}>

                            <Col style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
                                <div style={{ marginBottom: 10 }}>
                                    {/* <Row className={classes.row}>
                                        <p className={classes.mainText}>
                                            Stop 1. 
                                        </p>
                                    </Row> */}
                                    <Row className={classes.row}>
                                        <p className={classes.mainText} style={{ fontSize: 14, margin:-5 }}>
                                            {titleCaseFormatter(load.origin.city)}, {load.origin.state}
                                        </p>

                                        {load.pickupDate && (
                                            <Row>
                                                <p className={classes.secondaryText}>
                                                    {moment(load.pickupDate).format('ll')}
                                                </p>
                                                <p className={classes.secondaryText}>
                                                    {moment(load.pickupDate, "hh:mm a").format('HH:mm')}
                                                </p>
                                            </Row>
                                        )}

                                    </Row>
                                </div>
                                <div>
                                    {/* <Row className={classes.row}>
                                        <p className={classes.mainText}>
                                            Stop 2. 
                                        </p>
                                    </Row> */}
                                    <Row className={classes.row}>
                                        <p className={classes.mainText} style={{ fontSize: 14, margin:-5 }}>
                                            {titleCaseFormatter(load.destination.city)}, {load.destination.state}
                                        </p>

                                        {load.deliveryDate && (
                                            <Row>
                                                <p className={classes.secondaryText}>
                                                    {moment(load.deliveryDate).format('ll')}
                                                </p>
                                                <p className={classes.secondaryText}>
                                                    {moment(load.deliveryDate, "hh:mm a").format('HH:mm')}
                                                </p>
                                            </Row>
                                        )}

                                    </Row>
                                </div>
                            </Col>
                            <Col style={{ width: '40%' }}>
                                {checkValueExists(load, "loadHash") &&
                                    <Row style={{ alignItems: 'center' }}>
                                        <div style={{ float: 'right' }}>
                                            <Row style={{ display: "flex" }}>
                                                <p className={classes.mainText}>Ref #</p><p className={classes.secondaryText} style={{ display: "flex", alignItems: 'center' }}> {load.loadHash}</p>
                                            </Row>
                                        </div>
                                    </Row>
                                }
                                <Row style={{ alignItems: 'center' }}>
                                    {load.rate && (load.rate != "NULL" && load.rate > 0) &&
                                        <div style={{ float: 'right' }}>
                                            <Row style={{ display: "flex" }}>
                                                <p className={classes.mainText}>Rate: </p><p className={classes.secondaryText} style={{ display: "flex", alignItems: 'center' }}>${parseFloat(load.rate).toFixed(2)}</p>
                                            </Row>
                                        </div>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </CardContent>
                </Card>

                {load.expenseLedgerBreakDown &&
                    <div>
                        <p style={{ fontSize: 14, fontWeight: 'bold' }}>Rate Breakdown</p>
                        <Card className={classes.card} style={{ borderRadius: 2, marginBottom: 10 }}>
                            <CardContent style={{ padding: 5, paddingLeft: 15, paddingRight: 15 }}>

                                {load.expenseLedgerBreakDown.length > 1 && (
                                    <Row style={{ width: '33%', marginBottom: 5, display: 'inline-flex' }}>
                                        <Col style={{ alignItems: 'flex-start', marginRight: 15 }}>
                                            <p className={classes.mainText}>
                                                Total 
                                            </p>
                                            <p className={classes.secondaryText}>
                                                ${(getExpenseRate(load.expenseLedgerBreakDown)).toFixed(2)}
                                            </p>
                                        </Col>
                                    </Row>
                                )}

                                <div style={{ width: '100%' }}>

                                    {Object.keys(load.expenseLedgerBreakDown).map((key, index) => {
                                        return (
                                            <div key={index}>
                                                {load.expenseLedgerBreakDown[key] &&
                                                    <div key={index} >

                                                        <Row style={{ width: '50%', marginBottom: 5, display: 'inline-flex' }}>
                                                            <Col style={{ alignItems: 'flex-start', marginRight: 15 }}>
                                                                <p className={classes.mainText}>
                                                                    {load.expenseLedgerBreakDown[key].ledgerType}
                                                                </p>
                                                                <p className={classes.secondaryText}>
                                                                    ${parseFloat(load.expenseLedgerBreakDown[key].ledgerAmount).toFixed(2)}
                                                                </p>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}

                                </div>

                            </CardContent>
                        </Card>
                    </div>
                }
            </div>



            {/* <div style={{
                backgroundColor: "white",
                position: 'absolute',
                bottom: 0,
                width: '100%',
                boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
                borderRadius: 2,
                zIndex: 1,
            }}>

                <Row style={{
                    display: 'flex',
                    width: '100%',
                    height: '28%',
                    // marginBottom: 30,
                    justifyContent: 'space-between'
                }}>


                    <div style={{ display: 'contents' }}>
                        <Row key={"invoice_button"} style={{ width: '100%', textAlign: 'center' }}>
                            <Button
                                aria-describedby={"upload_document"}
                                // variant={variantOutlined ? "outlined" : variantContained ? "contained" : null}
                                onClick={() => toast.info("UNDER CONTRUCTION")}
                                style={{
                                    fontSize: 14,
                                    color: "white",
                                    backgroundColor: muiTheme.actionColor,
                                    borderColor: muiTheme.actionColor,
                                    margin: 5
                                }}
                                size={"small"}
                                disabled={false}
                                disableRipple={false}
                            >
                                Upload Document
                                </Button>
                        </Row>
                    </div>

                </Row>
            </div> */}

        </div>
    );
}

export default compose(
    connect(),
    muiThemeable(),
)(HistoricalLoadDetails)

const useStyles = makeStyles({
    card: {
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08
    },
    button: {
        backgroundColor: 'white',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5

    },
    mainText: {
        fontSize: '14px',
        lineHeight: '24px',
        color: 'black',
        fontWeight: 'bold',
        wordBreak: 'break-word'
    },
    secondaryText: {
        fontSize: '14px',
        lineHeight: '21px',
        color: 'grey',
        wordBreak: 'break-word'
    },
    labelText: {
        fontSize: '16px',
        color: 'grey'
    },
    valueText: {
        fontSize: '16px',
        color: 'black'
    }
});