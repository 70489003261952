import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
//COMPONENTS//
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import HelpWrapper from '../../HelpWrapper'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import isMobile from '../../../../HOC/Mobile.js'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DaySelectWerner = ({
  //PROPS FROM PARENT//
  handleSetDays,
  existingDays = null,
  //OTHER
  muiTheme,
  mobileView = false
}) => {

  const useStyles = makeStyles({
    root: {
      fontSize: '16px',
    },
    placeholder: {
      color: '#757575',
    },
    menuItem: {
      fontSize: '16px'
    },
    icon: {
      position: 'absolute',
      fontSize: '2.5rem',
      textAlign: 'right'
    },
    chips: {
      fontSize: '12px'
    },
    removePadding: {
      paddingRight: '0 !important'
    }
  });

  const classes = useStyles();

  const weekdays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]
  const [finalSelectedDays, setFinalSelectedDays] = useState([]); // Array of selected weekdays
  const [menuOpen, setMenuOpen] = useState(false);
  const selectBox = useRef(null)

  useEffect(() => {
    if (existingDays) {
      setFinalSelectedDays(existingDays)
    }
  }, []);

  function handleChange(event) {

    // 'value' here represents the day ids selected from dropdown as in the value of the event target
    const { value } = event.target;
    let chosenDay = value

    setFinalSelectedDays(chosenDay);
    handleSetDays(chosenDay)

  };

  const handleDelete = (dayToDelete) => () => {
    let modifiedDays = [...finalSelectedDays]
    modifiedDays = modifiedDays.filter((day) => day !== dayToDelete);
    setFinalSelectedDays(modifiedDays);
    handleSetDays(modifiedDays);
  };

  function renderSelectedDay(selected) {
    let chipMax = 3
    if (selectBox.current)
      chipMax = selectBox.current.clientWidth <= 300 ? 3 : selectBox.current.clientWidth <= 400 ? 3 : 5

    return (
      selected.map((value, index) => {
        if (mobileView & index >= chipMax)
          return index === chipMax ? <span style={{ margin: 5, fontSize: 18 }}>&hellip;</span> : null
        return (
          <Chip
            variant="outlined"
            key={value}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            label={value}
            className={classes.chips}
            onDelete={handleDelete(value)}
          />
        )
      })
    )

  }

  return (
    <HelpWrapper text='Select your equipment types so we can find loads that match. (Click anywhere outside once finished)' location='bottom' zIndex={1350} enabled={!menuOpen}>
      {weekdays &&
        <FormControl variant="filled" size='small' className={classes.formControl} fullWidth required >
          <InputLabel style={{ fontSize: 16 }} id="location">Available Days</InputLabel>
          <Select
            ref={selectBox}
            // Menu props in place to avoid menu swashing around on each update
            MenuProps={{
              getContentAnchorEl: () => null,
            }}
            //className={classes.selectfieldStyle}
            labelId="select-label"
            //disableUnderline={true}
            //autoWidth={false}
            //displayEmpty
            //input={<Input id="day" />}
            multiple
            value={finalSelectedDays}
            onChange={(event) => handleChange(event)}
            renderValue={(selected) => renderSelectedDay(selected)}
            inputProps={{
              classes: {
            // icon: classes.icon,
            root: classes.root
              }
            }}
            // MenuProps={{
            //   disableScrollLock: true,
            //   getContentAnchorEl: null,
            //   anchorOrigin: {
            //     vertical: "bottom",
            //     horizontal: "left",
            //   }
            // }}
            onOpen={() => setMenuOpen(true)}
          onClose={() => setMenuOpen(false)}
          >
          {/* <MenuItem
              className={classes.menuItem}
              style={{ color: "#aaa" }}
              value=""
              disabled>
              Select Days
            </MenuItem> */}
          {weekdays && weekdays.map((day) => {
            return (
              <MenuItem
                key={day}
                className={classes.menuItem}
                value={day}
              >
                <Checkbox style={{color: muiTheme.secondary}} checked={finalSelectedDays.indexOf(day) > -1} />
                <ListItemText classes={{ primary: classes.menuItem }} primary={day} />
              </MenuItem>
            )
          })}
        </Select>
        </FormControl>
      }
    </HelpWrapper >
  );
}

export default compose(
  muiThemeable(),
  isMobile()
)(DaySelectWerner)