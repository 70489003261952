import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux'
import { compose } from 'recompose'
import LoadListItemDesktop from './LoadListItemDesktop'
import { Card } from 'material-ui/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import LoadCardHeader from './LoadCardHeader';
import HistoricalLoadCardHeader from './my-loads-card/HistoricalLoadCardHeader'
import ActiveLoadCardHeader from './my-loads-card/ActiveLoadCardHeader'
import muiThemeable from 'material-ui/styles/muiThemeable';
import NoLoadFoundCard from '../../invoices/no-load-found-card'
// import {useAuthenticationHook} from '../../../hooks/authentication-hook';


const LoadListDesktop = (props) => {

  const {
    filteredLoadList = [],
    availability,
    pushReloads,
    isMyLoadsScreen = false,
    myLoadsRenderedTab = 0,
    recentActivityActionType,
    isRecentActivity = false,
    isBookedLoads = false,
    isOldRecentActivity = false,
    reloadsOpen = false,
    removeLoadFromList,
    isHome=false,
    
  } = props
  const { height, width } = useWindowDimensions();
  const RELOAD_CRUMBTRAIL_HEIGHT = 75
  const OLD_RECENT_AND_BOOKED_HEADER_HEIGHT = 105
  const RECENT_AND_BOOKED_HEADER_HEIGHT = 150
  const MY_LOADS_SCREEN_HEADER_HEIGHT = 215
  const FIND_AND_BOOK_LOADS_HEADER_HEIGHT = 250
  const [pixelsFromTop, setPixelsFromTop] = useState(
    isOldRecentActivity || isBookedLoads ?
      OLD_RECENT_AND_BOOKED_HEADER_HEIGHT :
      isRecentActivity ?
        RECENT_AND_BOOKED_HEADER_HEIGHT :
        isMyLoadsScreen ?
          MY_LOADS_SCREEN_HEADER_HEIGHT : FIND_AND_BOOK_LOADS_HEADER_HEIGHT
  )

  const [isScrollBarPresent, setIsScrollBarPresent] = useState(false);

  const listContainer = useRef(null)

  useEffect(() => {
    let heightVal = 0

    if (reloadsOpen) {
      heightVal = pixelsFromTop + RELOAD_CRUMBTRAIL_HEIGHT
    }
    else if (isOldRecentActivity || isBookedLoads) {
      heightVal = OLD_RECENT_AND_BOOKED_HEADER_HEIGHT
    }
    else if (isRecentActivity) {
      heightVal = RECENT_AND_BOOKED_HEADER_HEIGHT
    }
    else if (isMyLoadsScreen) {
      heightVal = MY_LOADS_SCREEN_HEADER_HEIGHT
    }
    else {
      heightVal = FIND_AND_BOOK_LOADS_HEADER_HEIGHT
    }

    // let heightVal = reloadsOpen ? pixelsFromTop + RELOAD_CRUMBTRAIL_HEIGHT : (isRecentActivity || isMyLoadsScreen ? RECENT_AND_BOOKED_HEADER_HEIGHT : FIND_AND_BOOK_LOADS_HEADER_HEIGHT)

    setPixelsFromTop(heightVal)
  }, [reloadsOpen])

  

  useEffect(()=>{
    setTimeout(() => {
      if(listContainer !== null && listContainer.current !== null){
        if(listContainer.current.clientHeight < listContainer.current.scrollHeight){
          setIsScrollBarPresent(true);
        } 
      }
    }, 1000);     
  },[filteredLoadList])

  const getContainerHeight = () => {
    if(props.autoHeight) 
      return 'calc(100% - 45px)'
    else
      return (height - pixelsFromTop - 40 - (isRecentActivity || isOldRecentActivity ? 15 : 0))
  }

  return (
    <div style={{height: '100%'}}>
      <Card style={{ height: 45, backgroundColor: 'transparent', boxShadow: 'transparent', paddingLeft: 15, paddingRight: 10 }}>
        <CardContent style={{ paddingBottom: 5, borderBottom: '1px solid lightgrey', marginRight: isScrollBarPresent ? '25px' : '0px' }}>
          {(isMyLoadsScreen && !reloadsOpen) ?
            myLoadsRenderedTab == 0 ?
              <ActiveLoadCardHeader />
              :
              <HistoricalLoadCardHeader />
            :
            <LoadCardHeader {...props} />
          }
        </CardContent>
      </Card>
      <List ref={listContainer} style={{ maxHeight: getContainerHeight(), overflow: 'auto', paddingTop: 0 }} className="List">
        {filteredLoadList.length > 0 ?
          filteredLoadList.map((load, index) => {
            if ( load && load.stops && load.stops.length > 0 ) {
              return (
                <LoadListItemDesktop
                  {...props}
                  key={load.id + "_" + index}
                  load={load}
                  availability={availability}
                  index={index}
                  pushReloads={pushReloads}
                  isMyLoadsScreen={isMyLoadsScreen}
                  myLoadsRenderedTab={myLoadsRenderedTab}
                  recentActivityActionType={recentActivityActionType}
                  isRecentActivity={isRecentActivity}
                  reloadsOpen={reloadsOpen}
                  removeLoadFromList={removeLoadFromList}
                  isBookedLoads={isBookedLoads}
                  isHome={isHome}
      
                />
              )
            }
            else if( props.onInvoicesScreen && load.invoice ) {
              return (
                <NoLoadFoundCard load={load} {...props}/>
              )
            }
            else
              return null
          })
          :
          <div style={{ paddingTop: 25 }}>
            <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
              No Loads Found
              </p>
          </div>

        }

      </List>
    </div>
  )

}

export default compose(
  connect(),
  muiThemeable()
)(LoadListDesktop)

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}