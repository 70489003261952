// `src/index.js`
import React from 'react'
import ReactDOM from 'react-dom'
import { applyMiddleware, createStore, compose } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { BrowserRouter as Router } from 'react-router-dom'
import createHistory from 'history/createBrowserHistory'

// Import all of our components
import App from './App'

// Import the index reducer and sagas
import IndexReducer from './index-reducer'
import IndexSagas from './index-sagas'

import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from "redux-persist"

import TagManager from 'react-gtm-module'

if (process.env.REACT_APP_ADVANTAGE_ID == "a98db973") {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    // auth: process.env.REACT_APP_GTM_AUTH,
    // preview: process.env.REACT_APP_GTM_PREVIEW
  }
  TagManager.initialize(tagManagerArgs)
  new MutationObserver(() => {
    for( var element of document.head.querySelectorAll('[data-jss]').values()){
      element.classList.add("fs-css-in-js")
    }
  }).observe(document.head, {
    childList: true
  });
} else if (process.env.REACT_APP_ADVANTAGE_ID == "k6g3f58q") {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
  }
  TagManager.initialize(tagManagerArgs)
} else if (process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19") {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
  }
  TagManager.initialize(tagManagerArgs)
}

// Setup the middleware to watch between the Reducers and the Actions
const sagaMiddleware = createSagaMiddleware()

const history = createHistory();

// Redux DevTools - completely optional, but this is necessary for it to
// work properly with redux saga.  Otherwise you'd just do:
//
// const store = createStore(
//   IndexReducer,
//   applyMiddleware(sagaMiddleware)
// )

/*eslint-disable */
const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose
/*eslint-enable */

export const store = createStore(
  IndexReducer,
  composeSetup(applyMiddleware(sagaMiddleware)), // allows redux devtools to watch sagas
)

const persistor = persistStore(store);

// const getState = () => {
// 	return AppStore.getState();
// };

// Begin our Index Saga
sagaMiddleware.run(IndexSagas)

// Setup the top level router component for our React Router
ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      persistor={persistor}
    // loading={renderLoading()}
    // onBeforeLift={() => {}}
    >
      <Router history={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)