import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import FA from 'react-fontawesome'
import CarrierLoadList from '../../../index'


const ReloadsTab = (props) => {

    const { 
        load, 
        delivery, 
        pickup, 
        availability, 
        muiTheme,
        reloadsList,
        errorText,
        showError,
        isLoading,
        
    } = props

    return (
        <div style={{height: '100%'}}>
            { isLoading && 
                <div style={{ paddingTop: 45 }}>
                    <div style={{ width: '100%', textAlign: 'center'}}>
                        <FA name='spinner' size="3x" spin />
                    </div>
                </div>
            }
            { !isLoading &&
                <div style={{height: '100%'}}>
                    { showError ? 
                        <div style={{ paddingTop: 25 }}>
                            <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                {errorText}
                            </p>
                        </div>
                        :
                        <div style={{height: '100%'}}>
                        { reloadsList.length > 0 ?
                            <CarrierLoadList
                                {...props}
                                unfilterLoadList={reloadsList}
                                availability={availability}
                                onReloadsTab={true}
                                isMobile={true}
                    
                            />
                            :
                            <div style={{ paddingTop: 25 }}>
                                <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                    No Reloads Found
                                </p>
                            </div>
                        }
                        </div>
                    }
                </div>
            }
            
        </div>
    );
}

const mapStateToProps = state => ({
    values: state.CarrierPortal.components.loadSearchBar,
    availability: state.AvailabilityLoads.payload.availability,
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
)(ReloadsTab)
