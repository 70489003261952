import React from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { compose, withProps } from 'recompose'
import { Row } from 'react-bootstrap';
import FA from 'react-fontawesome'
import EldSetup from '../components/eldSetup'

const useStyles = makeStyles({
    container: {
        padding: 5,
        height: '100%',
        maxHeight: '100%',
        width: window.width,
        overflow:'auto'
    }
});

const EldSetupScreen = (props) => {

    const classes = useStyles()

    const { muiTheme } = props

    return (
        <div className={classes.container}>
            <Row style={{ width: "100%", marginTop: 15, marginBottom: 0 }}>
                
            </Row>
            <Row style={{ width: "100%", padding: 15 }}>
                <EldSetup {...props} />
            </Row>
        </div>
    )
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
    withProps(props => {

        return ({
            ...{

            },
            ...props
        })
    }),
)(EldSetupScreen)