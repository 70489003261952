
import React from 'react';
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { Col, Row } from "react-bootstrap"
import LcDataWrap from "./LoadCardDataWrapper"

const LoadCardHeader = (props) => {

    const classes = useStyles();

    return (

        <div>

            <Row style={{ display: 'flex' }}>

                <Col style={{ display: 'flex', width: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? '1.3%' : "4.3%" }} />

                {process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" && (
                    <Col style={{ width: 100, paddingLeft: 10, paddingRight: 10, display: 'flex', alignItems: "center" }} />
                )}

                <Col style={{ width: "40.7%" }}>
                    <Row style={{ display: 'inline-flex', width: '100%', justifyContent: 'center' }}>

                        <Col md={4} className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <LcDataWrap>
                                <Row>
                                    <p className={classes.headerText}>
                                        Origin Location
                                    </p>
                                </Row>
                            </LcDataWrap>
                        </Col>

                        <Col md={4} style={{ paddingLeft: 16, paddingRight: 16, display: "flex" }} />

                        <Col md={4} className={classes.centerColContent} style={{ alignItems: 'left' }}>
                            <LcDataWrap>
                                <Row>
                                    <p className={classes.headerText}>
                                        Destination Location
                                    </p>
                                </Row>
                            </LcDataWrap>
                        </Col>
                    </Row>
                </Col>

                <Col style={{ width: process.env.REACT_APP_ADVANTAGE_ID == "2zr5ys19" ? "50%" : "55%" }}>
                    <Col md={3} className={classes.centerColContent} style={{ alignItems: 'center' }}>
                        <LcDataWrap>
                            <p className={classes.headerText}>
                                Reloads
                            </p>
                        </LcDataWrap>
                    </Col>
                    <Col md={3} className={classes.centerColContent} style={{ textAlign: 'left' }}>
                        <LcDataWrap>
                            <Row style={{ width: '100%' }}>
                                <p className={classes.headerText}>
                                    Equipment / Weight
                                </p>
                            </Row>
                        </LcDataWrap>
                    </Col>
                    <Col md={3} className={classes.centerColContent} style={{ alignItems: 'flex-start', paddingLeft: 15 }}>
                        <p className={classes.headerText}>
                            Rate / Distance
                        </p>
                    </Col>

                    <Col md={3} className={classes.centerColContent} style={{ alignItems: 'center', textAlign: 'center' }} />
                </Col>
            </Row>
        </div>
    );
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(LoadCardHeader)

const useStyles = makeStyles({
    headerText: {
        // fontFamily: 'Arial !important',
        fontSize: '13px',
        color: 'black',
        margin: 0
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    }
});