import React, { useEffect, useState } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { Col, Row, Grid } from "react-bootstrap"
import CardContent from '@material-ui/core/CardContent';
import { Card } from 'material-ui/Card';
import { checkValueExists } from "../../../../../../../lib/check-value-exists"
import { titleCaseFormatter } from "../../../../../../../lib/title-case-formatter"
import * as FeatherIcon from 'react-feather';
import Popover from '@material-ui/core/Popover';
import muiThemeable from 'material-ui/styles/muiThemeable';
import {useAuthenticationHook} from '../../../../../../hooks/authentication-hook';
import moment from 'moment'
import { formatPhoneNumberWithHyphens } from '../../../../../../../components/formatPhoneNumber';

const ActiveLoadsDetails = (props) => {

    const {
        load,
        muiTheme,
    } = props

    const classes = useStyles();
    const TT_LOGO_ASSET_URL = 'http://assets.truckertools.com/images/logos/'

    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()
    const user = myAuthService.getUser()
    const stops = [load.originStop, load.destStop];

    const [anchorEl, setAnchorEl] = useState(null);
    const openRateBreakdown = Boolean(anchorEl);
    const id = openRateBreakdown ? 'rate-info-popover' : undefined;


    const checkWhiteSpace = (textInput) => {
        let status = true;
        if(textInput){
            if(textInput.trim().length === 0)
                status = false
        };
        return status
    }

    const checkEmptyString = (textInput) => {
        let status = true;
        if(typeof(textInput)=== 'string'){
            if(textInput.length === 0)
                status = false
        };
        return status
    }

    const handleTrackingHelperOpen = (event, tag) => {
        setAnchorEl(event.currentTarget)
    };

    const handleTrackingHelperClose = () => {
        if (anchorEl !== null)
            setAnchorEl(null);
    };

    const getTrackingHelperPopover = (id, openRateBreakdown, anchorEl) => {
        return (
            <Popover
                id={id}
                open={openRateBreakdown}
                anchorEl={anchorEl}
                onClose={handleTrackingHelperClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                // style={{ pointerEvents: 'none' }}
                disableRestoreFocus
            >
                <div style={{ padding: 15, width: 220, maxHeight: 300 }}>
                    <p className={classes.valueText}> Contact <a href={"tel:1-800-000-0000"}style={{ color: muiTheme.actionColor }}>1-800-000-0000</a><br/>to update tracking details.</p> 
                </div>    
            </Popover>
        )
    }

    return (
        <div>
            <div style={{ padding: 10, paddingBottom: 70 }}>
                <div style={{ display: 'contents' }}>
                    <p style={{ fontSize: 14, fontWeight: 'bold' }}>Trip Details</p>
                    <Card className={classes.card} style={{ borderRadius: 2, marginBottom: 10 }}>
                        <CardContent style={{ padding: 5, paddingLeft: 15, paddingRight: 15 }}>
                            <div>
                                {stops.map((stop, index) => (
                                    <div key={stop.stopId} style={{ marginBottom: 10 }}>

                                        <div>
                                            <Row className={classes.row}>
                                                <p className={classes.mainText} style={{ fontSize: 14 , marginLeft: -5}}>
                                                    {titleCaseFormatter(stop.city)}, {stop.state}
                                                </p>
                                                <dl className={classes.descriptionList}>
                                                    <dt className={classes.descriptorTag}>
                                                        <p className={classes.labelText}>Appointment:</p>
                                                    </dt>
                                                    <dd className={classes.descriptorDetail}>
                                                        <p className={classes.secondaryText}>
                                                            {moment(stop.latestDate).format('ll  HH:mm')} 
                                                        </p>
                                                    </dd>
                                                </dl>
                                                {/* {!stop.earliestDate && (
                                                    <Row>
                                                        <p className={classes.secondaryText}>
                                                            {moment(stop.latestDate).format('ll')}
                                                        </p>
                                                        <p className={classes.secondaryText}>
                                                            {moment(stop.latestDate).format('HH:mm')}
                                                        </p>
                                                    </Row>
                                                )}
                                                {stop.earliestDate && (
                                                    <Row>
                                                        <p className={classes.secondaryText}>
                                                            {moment(stop.earliestDate).format('ll')} {moment(stop.earliestDate).format('HH:mm')} -
                                                        </p>
                                                        <p className={classes.secondaryText}>
                                                            {moment(stop.latestDate).format('ll')} {moment(stop.latestDate).format('HH:mm')}
                                                        </p>
                                                    </Row>
                                                )} */}
                                            </Row>
                                            {isAuthenticated && process.env.REACT_APP_ADVANTAGE_ID === "a98db973" &&
                                                <Row className={classes.row}>
                                                    <p className={classes.secondaryText} style={{ fontSize: 14 }}>
                                                        {stop.stop_type}
                                                    </p>
                                                </Row>
                                            }
                                        </div>

                                    </div>
                                ))}

                                <Row style={{ width: '100%', marginBottom: 5, display: 'inline-flex' }}>
                                    {load.equipmentType &&
                                        <Col style={{ width: '50%', alignItems: 'flex-start', marginRight: 15 }}>
                                            <div><p className={classes.mainText} style={{marginLeft:-3}}>Equipment Type</p></div>
                                            <div><p className={classes.valueText}>{load.equipmentType}</p></div>
                                        </Col>
                                    }
                                    {load.miles &&
                                        <Col style={{ width: '50%', alignItems: 'flex-start', marginRight: 15 }}>
                                            <div><p className={classes.mainText} style={{marginLeft:-3}}>Distance</p></div>
                                            <div><p className={classes.valueText}>{load.miles}mi</p></div>
                                        </Col>
                                    }
                                </Row>

                            </div>
                        </CardContent>
                    </Card>
                </div>

                {/* {(  (checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "driverName")) ||
                    (checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "driverPhoneNumber")) ||
                    (checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "tractor")) ||
                    (checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "trailer"))
                ) && */}
                    <div style={{ display: 'contents' }}>
                        <p style={{ fontSize: 14, fontWeight: 'bold' }}>Tracking Details</p>
                        <Card className={classes.card} style={{ borderRadius: 2, marginBottom: 10 }}>
                            <CardContent style={{ padding: 5, paddingLeft: 15, paddingRight: 15 }}>
                                <div style={{ width: '100%' }}>
                                    <Row style={{marginBottom: 5, display: 'inline-flex' }}>
                                    <dl className={classes.descriptionList}>
                                        {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "driverName") && checkEmptyString(load.carrierTrackingInfo.driverName) && (
                                            <div>
                                                <dt className={classes.descriptorTag}>
                                                    <p className={classes.mainText}>Driver Name</p>
                                                </dt>
                                                <dd className={classes.descriptorDetail}>
                                                    <p className={classes.valueText}>{load.carrierTrackingInfo.driverName}</p>
                                                </dd>
                                            </div>
                                        )}
                                        {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "driverPhoneNumber") && checkEmptyString(load.carrierTrackingInfo.driverPhoneNumber) && (
                                            <div>
                                                <dt className={classes.descriptorTag}>
                                                    <p className={classes.mainText}>Driver Phone</p>
                                                </dt>
                                                <dd className={classes.descriptorDetail}>
                                                    <p className={classes.valueText}>{formatPhoneNumberWithHyphens(load.carrierTrackingInfo.driverPhoneNumber)}</p>
                                                </dd>
                                            </div>
                                        )}
                                            <div>
                                                <dt className={classes.descriptorTag}>
                                                    <p className={classes.mainText} style={{whiteSpace:'nowrap'}}>Tractor #</p>
                                                </dt>
                                                <dd className={classes.descriptorDetail}>
                                                    <p className={classes.valueText} style={{position:'relative'}}>
                                                        {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "tractor") && checkWhiteSpace(load.carrierTrackingInfo.tractor) && load.carrierTrackingInfo.tractor != "" ? load.carrierTrackingInfo.tractor 
                                                        : <span className={classes.missing}> MISSING </span>}
                                                        <FeatherIcon.Info
                                                            style={{position:'absolute', left:65, top:10}}
                                                            color={"black"}
                                                            size={20}
                                                            onClick={handleTrackingHelperOpen}
                                                        />
                                                        {getTrackingHelperPopover(id, openRateBreakdown, anchorEl)}
                                                    </p>
                                                </dd>
                                                <dt className={classes.descriptorTag}>
                                                    <p className={classes.mainText} style={{whiteSpace:'nowrap'}}>Trailer &nbsp;#</p>
                                                </dt>
                                                <dd className={classes.descriptorDetail}>
                                                    <p className={classes.valueText}>{checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "trailer") && checkWhiteSpace(load.carrierTrackingInfo.trailer) && load.carrierTrackingInfo.trailer != "" ? load.carrierTrackingInfo.trailer 
                                                    : <span className={classes.missing}> MISSING </span>}</p>
                                                </dd>
                                            </div>
                                    </dl>
                                    </Row>
                                </div>

                            </CardContent>
                        </Card>
                    </div>
                


                <p style={{ fontSize: 14, fontWeight: 'bold' }}>Contact Werner</p>
                <Card className={classes.card} style={{ borderRadius: 2, marginBottom: 10 }}>
                    <CardContent style={{ padding: 5, paddingLeft: 15, paddingRight: 15 }}>
                        <div style={{ width: '100%' }}>
                            {checkValueExists(load, "tourId") && (
                                <Row>
                                    <Col md={4}>
                                        <p className={classes.mainText}>Load ID</p>
                                    </Col>
                                    <Col md={8}>
                                        <p className={classes.valueText}>{load.tourId}</p>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col md={4}>
                                    <p className={classes.mainText}>Email</p>
                                </Col>
                                <Col md={8}>
                                    <a href={"mailto:TruckerToolsCapacity@Werner.com"} target="_blank" className={classes.valueText} style={{ color: muiTheme.actionColor }}>{"TruckerToolsCapacity@Werner.com" + "\n"}</a>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <p className={classes.mainText}>Phone</p>
                                </Col>
                                <Col md={8}>
                                    <a href={'tel:8003462820'} className={classes.valueText} style={{ color: muiTheme.actionColor, display: 'block' }}>800-346-2820</a>
                                </Col>
                            </Row>
                        </div>

                    </CardContent>
                </Card>
            </div>



            <div style={{
                backgroundColor: "white",
                position: 'absolute',
                bottom: 0,
                width: '100%',
                boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
                borderRadius: 2,
                zIndex: 1,
                padding: 10
            }}>

                <Row style={{
                    display: 'flex',
                    width: '100%',
                    height: '28%',
                    // marginBottom: 30,
                    justifyContent: 'space-between'
                }}>
                    <div style={{ display: 'contents' }}>
                        
                        <Row key={"tracking_info"} style={{ width: '100%', textAlign: 'center' }}>
                            {checkValueExists(load, "carrierTrackingInfo") && checkValueExists(load.carrierTrackingInfo, "trackingStatus") && (
                                <p className={classes.mainText} style={{ textAlign: 'center' }}>{load.carrierTrackingInfo.trackingStatus}</p>
                            )}
                        </Row>
                        
                    </div>
                </Row>
            </div>

        </div>
    );
}

export default compose(
    connect(),
    muiThemeable(),
)(ActiveLoadsDetails)

const useStyles = makeStyles({
    card: {
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08
    },
    button: {
        backgroundColor: 'white',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5

    },
    mainText: {
        fontSize: '14px',
        // lineHeight: '24px',
        color: 'black',
        fontWeight: 'bold',
        wordBreak: 'break-word'
    },
    secondaryText: {
        fontSize: '14px',
        // lineHeight: '21px',
        color: 'grey',
        wordBreak: 'break-word'
    },
    labelText: {
        fontSize: '14px',
        color: 'grey'
    },
    valueText: {
        fontSize: '16px',
        color: 'black'
    },
    missing: {
        wordBreak: 'break-word',
        fontSize: '14px',
        color: 'grey'
    },
    descriptionList: {
        width: '450px',
        overflow: 'hidden',
        padding: 0,
        margin: 0
    },
    descriptorTag: {
        float: 'left',
        width: '25%;',
        padding: 0,
        margin: 0,
    },
    descriptorDetail: {
        float: 'left',
        width: '75%;',
        padding: 0,
        margin: 0,
        display:'inline-block'
    }
});