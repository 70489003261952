import { PostRemoteLog } from './api-remote-log'
import { BACKEND_URL } from '../../api/constants'
import {useAuthenticationHook} from '../hooks/authentication-hook'


export const UpdateUserData = (payload) => {
    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + "/user"
    try {
        let requestObject = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "update Carrier Data",
                    message: {
                        error: JSON.stringify(error)
                    },
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "update Carrier Data",
            message: {
                error: JSON.stringify(error)
            },
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}
