import React from 'react';
import { compose } from 'recompose'

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import { Row } from 'react-bootstrap';

import isMobile from '../../../HOC/Mobile.js'


const WernerTutorialModal = (props) => {
    const { muiTheme, isMobile, onClose } = props
    const useStyles = makeStyles((theme) => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            width: isMobile ? '100%' : 460,
            height: isMobile ? '100%' : 'unset',
            outline: 'none',
            padding: isMobile ? 5 : theme.spacing(2, 4, 3),
        },
        header: {
            margin: 0
        },
        linkHeader: {
            marginBottom: 5,
            marginTop: 15
        },
        link: {
            color: muiTheme.actionColor,
            "&:visited": {
                color: muiTheme.actionColor
            },
            "&:hover": {
                color: muiTheme.actionColor,
                textDecoration: 'underline !important'
            },
        }
    }));
    const classes = useStyles()

    const handleClose = () => {
        onClose()
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={true}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            disableEscapeKeyDown
        >
            <div className={classes.paper}>
                <Row style={{ width: "100%", height: 25, display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        style={{
                            backgroundColor: "transparent",
                            color: muiTheme.actionColor,
                            fontSize: 14,
                            textTransform: 'none',
                            right: -16
                        }}
                        onClick={() => handleClose()}
                    >
                        {/* <FeatherIcon.X color={muiTheme.actionColor} size={20} /> */}
                       Close
                    </Button>
                </Row>
                <h2 id="transition-modal-title">Help Links</h2>
                <div style={{display:'flex', flexDirection:'column', gap:'10px'}}>
                    <a className={classes.link} href="https://vimeo.com/497981208/a7ba8828cf" target="_blank">Access from Werner.com</a>
                    <a className={classes.link} href="https://vimeo.com/497984048/d6c9979ac9" target="_blank">How to Login</a>
                    <a className={classes.link} href="https://vimeo.com/497981720/9542c7f7c1" target="_blank">Book-It-Now</a>
                    <a className={classes.link} href="https://www.werner.com/werner-edge-rewards/?utm_source=Modal_EDGE_Rewards&utm_medium=Website&utm_campaign=Modal_Rewards&utm_id=Website_Modal" target="_blank">Werner EDGE Rewards</a>
                </div>
            </div>
        </Modal>
    )
}

export default compose(
    muiThemeable(),
    isMobile()
)(WernerTutorialModal)