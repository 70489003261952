import { findLatestBinOffer } from '../CarrierPortal/components/load-list/quotes/actionButtons/quoteHelperFunctions'
import { checkValueExists } from './check-value-exists'
import { numberWithCommas } from './number-with-commas'
import { responsibilityHideRate } from './responsibility-code-functions'
import { showBIN } from './show-bin'

export const getDisplayRate = ( load, isMobile = false, userProfile) => {
    let tmpRate = null
    let quoteList = load.quotes ? JSON.parse(JSON.stringify(load.quotes)) : null
    let latestBinOffer = findLatestBinOffer(quoteList, load) 
    if(responsibilityHideRate(load, userProfile) == true){
        return null
    }

    if (latestBinOffer.amount && parseFloat(latestBinOffer.amount) > 0 && quoteList && quoteList.length > 0 && !latestBinOffer.isOriginalBinPrice) {
        tmpRate = latestBinOffer.amount
    }
    else if (checkValueExists(load,'targetPay') && load.targetPay > 0 && (load.showBookItNowPrice == 1 || showBIN(load)) ) {
        tmpRate = load.targetPay
    }

    if (tmpRate && tmpRate > 0) {
        return (
            <p style={{ fontSize: '16px', fontWeight: 'bold', textAlign: isMobile ? 'right' : 'left' }}>
                ${numberWithCommas(parseFloat(tmpRate).toFixed(2))}
            </p>
        )
    }

    return null
}