import React, {Component } from 'react';
export default function formatPhone(phone){
    return FormatPhoneNumber(phone)
}

function FormatPhoneNumber(num) {
    if(!num)return null
    var str = num.toString();

    var matched = str.match(/\d+\.?\d*/g);
    if(!matched) return null
    // 10 digit
    if (matched.length === 3) {
        return '(' + matched[0] + ') ' + matched[1] + '-' + matched[2];
    // 7 digit
    } else if (matched.length === 2) {
        return matched[0] + '-' + matched[1];
    }
    // no formatting attempted only found integers (i.e. 1234567890)
    else if (matched.length === 1) {
        // 10 digit
        if (matched[0].length === 10) {
            return '(' + matched[0].substr(0, 3) + ') ' + matched[0].substr(3, 3) + '-' + matched[0].substr(6);
        }
        // 7 digit
        if (matched[0].length === 7) {
            return matched[0].substr(0, 3) + '-' + matched[0].substr(3);
        }
    }

    // Format failed, return number back
    return num;
}

export function formatPhoneNumberWithHyphens(phoneNumberString, phoneExt) {
    // 5732964213x1006
    if(phoneNumberString == null || phoneNumberString === "") return null
    
    let extIndexStart = null
    extIndexStart = phoneNumberString.indexOf('x')
    
    if (extIndexStart > -1 ){
        phoneExt = phoneNumberString.slice(extIndexStart+1)
        phoneNumberString = phoneNumberString.slice(0,extIndexStart)
    }
        

    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      let phoneNumber = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      if (phoneExt) {
        phoneNumber = phoneNumber + " ext. " + phoneExt
      }
      return phoneNumber
    }
    return null;
  }
