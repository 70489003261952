
import React from 'react';
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { Col, Row } from "react-bootstrap"

const LoadCardHeader = (props) => {

    const classes = useStyles();

    return (

        <div>

            <Row style={{ display: 'flex', marginLeft: 0 }}>

                <Col style={{ width: '11%', display: 'flex' }}>
                  
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <p className={classes.headerText}>Reference #</p>
                        </Col>
                    
                </Col>

                <Col style={{ width: "15%", display: 'flex' }}>
                   
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <p className={classes.headerText}>Origin Location</p>
                        </Col>
                  
                </Col>

                <Col style={{ width: "12%", display: 'flex' }}>
                    
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <p className={classes.headerText}>Pickup Appointment</p>
                        </Col>
                   
                </Col>

                <Col style={{ width: "15%", display: 'flex' }}>
                    
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <p className={classes.headerText}>Destination Location</p>
                        </Col>
                   
                </Col>

                <Col style={{ width: "20%", display: 'flex' }}>
              
                        <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <p className={classes.headerText}>Delivery Appointment</p>
                        </Col>
                
                </Col>

                <Col style={{ width: "5%", display: 'flex' }}>
            
                        {/* <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left' }}>
                            <p className={classes.headerText}>Rate</p>
                        </Col> */}
                        {/* Temporarily hiding rate until my-loads is at the user/driver level */}
               
                </Col>

                <Col style={{ width: "18%", display: 'flex' }}>
               
                        {/* <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left', paddingLeft: 15 }}>
                            <p className={classes.headerText}>Settlement Status</p>
                        </Col> */}
              
                </Col>

                <Col style={{ width: "5%", display: 'flex' }}>
                   
                        {/* <Col className={classes.centerColContent} style={{ alignItems: 'left', textAlign: 'left', paddingLeft: 15 }}>
                            <p className={classes.headerText}>Actions</p>
                        </Col> */}
                  
                </Col>

            </Row>

        </div>
    );
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(LoadCardHeader)

const useStyles = makeStyles({
    headerText: {
        // fontFamily: 'Arial !important',
        fontSize: '13px',
        color: 'black',
        margin: 0
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    }
});