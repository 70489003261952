import SignupSaga from './signup/sagas'
import LoginSaga from './login/sagas'
import UloginSaga from './ulogin/sagas'
//import LoadsSaga from './loads/sagas'
//import MatchesSaga from './matches/sagas'
//import AmapsSaga from './availabilitymaps/sagas'
//import SalesmapSaga from './salesmap/sagas'
//import BrokermapSaga from './brokermap/sagas'
//import AloadsSaga from './availableloads/sagas'
import OutofnetworkbrokerfooterSaga from './outOfNetworkBrokerFooter/sagas'
import inNetworkBrokerReducerSaga from './inNetworkBrokers/sagas'
//import noteModalSaga from './notemodal/sagas'
import profileSaga from './profile/sagas'
import preferencesSaga from './preferences/sagas'
import TMSPartnerSaga from './TMSPartner/sagas'
import contractSaga from './contract/sagas'
import confirmregSaga from './confirmreg/sagas'
import reactUsStatesMapSaga from './components/react-us-state-map/sagas'
import lanePrefs from './components/lanePreferences/sagas'
//import brokerNotificationsSaga from './brokerNotifications/sagas'
import addCapacitySaga from './addCapacity/sagas'
import users from './users/sagas'
import contactUsSaga from './contactUs/sagas'
import CarrierPortalSaga from './CarrierPortal/sagas'
import AvailabilityLoadsSaga from './CarrierPortal/DirectLinking/AvailabilityLoads/sagas'
import CarrierSignupSaga from './CarrierPortal/DirectLinking/Signup/sagas'
import ConfirmContactInfo from './components/ConfirmContactInfo/sagas'
import QuoteSaga from './components/Quote/sagas'
import LaneHistorySearchSaga from './components/CarrieSearchByLaneAndHistory/sagas'
import CarrierHistorySage from './components/LaneHistory/sagas'
import ActionsSaga from './components/Actions/sagas'
import shipperIntegrationSaga from './shipperIntegration/sagas'
import shipperIntegrationLoadSaga from './shipperIntegration/load/sagas'
import shipperIntegrationLoadUpdateSaga from './shipperIntegration/loadupdate/sagas'
import credentialsSaga from './shipperIntegration/Credentials/sagas'
import offeredLoadsSaga from './components/OfferedLoads/sagas'


export default function* IndexSaga () {
  yield [SignupSaga(),
      CarrierPortalSaga(),
  		LoginSaga(),
  		//LoadsSaga(),
  		//MatchesSaga(),
  		//AmapsSaga(),
  		//AloadsSaga(),
  		UloginSaga(),
      contractSaga(),
      //SalesmapSagaV2(),
      //SalesmapSaga(),
      OutofnetworkbrokerfooterSaga(),
  		inNetworkBrokerReducerSaga(),
      //BrokermapSaga(),
      //noteModalSaga(),
      profileSaga(),
      preferencesSaga(),
      TMSPartnerSaga(),
      confirmregSaga(),
      reactUsStatesMapSaga(),
      lanePrefs(),
      //brokerNotificationsSaga(),
	    addCapacitySaga(),
      users(),
      contactUsSaga(),
      AvailabilityLoadsSaga(),
      ConfirmContactInfo(),
      CarrierSignupSaga(),
      QuoteSaga(),
      LaneHistorySearchSaga(),
      CarrierHistorySage(),
      ActionsSaga(),
      shipperIntegrationSaga(),      
      shipperIntegrationLoadSaga(),
      shipperIntegrationLoadUpdateSaga(),
      credentialsSaga(),
      offeredLoadsSaga()
  ]
}
