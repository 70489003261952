import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GoogleMapReact from 'google-map-react';
import numeral from 'numeral'

import "../../styleSheets/loadStyles.css"
import MapRoute from '../load-list/maproute.js'

const TargetLoadMap = (props) => {

    const {
        load,
        width = '56%',
        height = 200
    } = props

    const useStyles = makeStyles({
        mapContainer: {
            position: 'relative',
            height: height,
            width: width,
            border: '1px solid grey'
        },
    });
    const classes = useStyles()

    const [mapLoaded, setMapLoaded] = useState(false)
    const [map, updateMap] = useState(null)
    const [maps, updateMaps] = useState(null)
    const [processOnProgress, updateProcessOnProgress] = useState(false)
    const [notRendered, updateNotRendered] = useState(true)
    const [delay, updateForceDelay] = useState(false)
    const [googleMapsDuration, updateGoogleMapsDuration] = useState('')
    const [googleMapsDistance, updateGoogleMapsDistance] = useState('')
    const [showMapRouteError, setShowMapRouteError] = useState(false)

    const removeImportedFont = () => {
        var elements = document.querySelectorAll('link[href*="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Google+Sans:400,500,700"]')

        if (elements.length > 0)
            elements[0].parentNode.removeChild(elements[0]);
    }

    function contains(selector, text) {
        var elements = document.querySelectorAll(selector);
        return [].filter.call(elements, function (element) {
            return RegExp(text).test(element.innerHTML);
        });
    }

    const removeUnwantedSpan = () => {
        setTimeout(
            () => {
                let spanArray = contains('span', 'BESbswy')
                if (spanArray && spanArray.length > 0)
                    spanArray[0].parentNode.removeChild(spanArray[0]);
            }
            , 3000)
    }

    const showGoogleMapsInfo = (totalDistance, totalDuration) => {

        totalDistance = totalDistance * 0.000621371192;
        totalDistance = Math.round(totalDistance * 100) / 100
        var d = totalDuration
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        totalDuration = hDisplay + mDisplay //+ sDisplay;

        /*console.log('Directions totalDistance',totalDistance);
        console.log('Directions totalDuration',totalDuration);*/

        updateGoogleMapsDistance(totalDistance)
        updateGoogleMapsDuration(totalDuration)
        updateNotRendered(false)
        updateForceDelay(false)
        setShowMapRouteError(false)
    }

    return (
        <div className={classes.mapContainer} onMouseDown={(event) => event.stopPropagation()}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API }}
                center={{ lat: load.stops[0].lat, lng: load.stops[0].lon }}
                defaultZoom={8}
                onGoogleApiLoaded={({ map, maps }) => {
                    updateMap(map);
                    updateMaps(maps);
                    setMapLoaded(true);
                }}
                yesIWantToUseGoogleMapApiInternals={true}
                options={{ fullscreenControl: false }}
            >
            </GoogleMapReact>

            {mapLoaded && !processOnProgress && notRendered &&
                <MapRoute
                    delay={delay ? delay : false}
                    processStart={() => updateProcessOnProgress(true)}
                    processEnd={() => { updateProcessOnProgress(false); updateForceDelay(false); removeImportedFont(); removeUnwantedSpan() }}
                    showMapsInfo={(distance, duration) => showGoogleMapsInfo(distance, duration)}
                    map={map}
                    maps={maps}
                    origin={load.stops[0]}
                    destination={load.stops[load.stops.length - 1]}
                    stops={load.stops}
                    setShowMapRouteError={setShowMapRouteError}
                />
            }
            <div style={{ position: 'absolute', top: 1, right: 1, backgroundfontSize: 12, backgroundColor: 'whitesmoke', textAlign: 'right', padding: 4 }} >
                { googleMapsDistance && googleMapsDistance !== '' && (<div className='labelText' style={{margin: 0}}> {googleMapsDistance} mi</div>)}
                {googleMapsDuration && googleMapsDuration !== '' && (<div className='labelText' style={{margin: 0}}> {googleMapsDuration} </div>)}
                {processOnProgress && (<div style={{ padding: 1 }} > looking for direction ... </div>)}
            </div>
            <div style={{ position: 'absolute', top: 1, right: 1, fontWeight: 'bold', textAlign: 'center', backgroundColor: '#FFF', opacity: '75%', color: 'red' }} >
                {showMapRouteError && (<div style={{ padding: 1 }} >Failed to find the route for this load</div>)}
            </div>

        </div>
    )
}


export default TargetLoadMap