
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import React from 'react';
import { Row } from "react-bootstrap";
import FA from 'react-fontawesome';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { numberWithCommas } from '../../../../../../lib/number-with-commas';
import { responsibilityHideRate } from '../../../../../../lib/responsibility-code-functions';
import QuoteActions from '../../actionButtons';
import CounterOfferForm from '../../actionButtons/counterOfferForm';

import RateNegotiationsListItemMobile from './rateNegotiationsListItemMobile';


const RateNegotiationsListMobile = (props) => {

    const {
        offerThread,
        loadOfferNegotiations,
        latestBinAmount,
        submitCounterOffer,
        getStatusText,
        getStatusIcon,
        load,
        isLoading,
        priceLabel,
        useShowInterest,
        userProfile,
        setIsLoading = () => { },
    } = props
    const classes = useStyles();

    return (
        <div style={{ height: '100%' }}>

            <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                {isLoading &&
                    <div style={{ width: '100%', paddingTop: 25 }}>

                        <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                            <FA name='spinner' spin />
                        </p>

                    </div>
                }
                {!isLoading && loadOfferNegotiations.length <= 0 &&
                    <div style={{ display: 'flex', flex: 1, padding: 16, backgroundColor: 'white', flexDirection: 'column' }}>
                        <div className='titleText' style={{ whiteSpace: 'nowrap', fontSize: 20, paddingBottom: 8 }}>
                            {useShowInterest ? 'Show Interest' : 'Make Offer'}
                        </div>
                        <CounterOfferForm
                            load={load}
                            submitCounterOffer={submitCounterOffer}
                            noHistoryMobile
                            _onClose={() => { }}
                            useShowInterest={useShowInterest}
                        />

                    </div>
                }
                {!isLoading && loadOfferNegotiations.length > 0 && [
                    <div style={{ padding: 16, display: 'flex' }}>
                        <p className={classes.mainText} style={{ width: '100%', fontSize: 16 }}>Offer History - Load #{load.externalId}</p>
                    </div>,
                    <div style={{ display: 'flex', flex: 1, overflow: 'hidden', flexDirection: 'column' }}>
                        <div style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>

                            {
                                loadOfferNegotiations.map((quote, index) => {
                                    if (quote.loadOffer.amount != null || useShowInterest) {

                                        return (

                                            <Row key={index + "_rate_item"} style={{ display: 'flex', borderBottom: index !== loadOfferNegotiations.length - 1 ? '1px dashed black' : 'none', paddingTop: 5, paddingLeft: 3, paddingRight: 3 }}>
                                                <RateNegotiationsListItemMobile
                                                    quote={quote}
                                                    getStatusText={getStatusText}
                                                    getStatusIcon={getStatusIcon}
                                                    useShowInterest={useShowInterest}
                                                    {...props}
                                                />
                                            </Row>

                                        )
                                    } else
                                        return null

                                })
                            }

                        </div>
                        <div style={{ backgroundColor: 'white', justifyContent: 'center', padding: 16 }}>

                            {/* {(load.showBookItNowPrice === 1 || showBinBtn) && (responsibilityHideRate(load, userProfile) !== true) && defaultDisableCrstActions(userProfile) && */}


                            {latestBinAmount && latestBinAmount > 0 && (load.showBookItNowPrice === 1 || offerThread) && !responsibilityHideRate(load, userProfile) &&

                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 8 }}>

                                    <p className='labelText' style={{ marginRight: 8 }}>
                                        {priceLabel}
                                    </p>

                                    <p className='mainText' style={{ display: 'flex', alignItems: 'center', textAlign: 'right', fontWeight: 'bold' }}>
                                        ${numberWithCommas(parseFloat(latestBinAmount).toFixed(2))}
                                    </p>
                                </div>

                            }

                            <div style={{ display: 'flex', justifyContent: 'center' }}>

                                <QuoteActions
                                    load={load}
                                    offerThread={offerThread}
                                    setIsLoading={setIsLoading}
                                    latestBinAmount={latestBinAmount}
                                    mobileView
                                    {...props}
                                />


                            </div>
                        </div>
                    </div>]
                }

            </div>

        </div>
    );
}

export default compose(
    connect(),
    muiThemeable()
)(RateNegotiationsListMobile)

const useStyles = makeStyles({
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        divor: 'black',
        fontWeight: 'bold',
        margin: 0
    }
});