import { PostRemoteLog } from './api-remote-log'
// import { BACKEND_URL } from '../../api/constants'
const BACKEND_URL = "https://scapi-staging.truckertools.com" //TODO <-- add to gateway

export const FetchMarkets = () => {
    const url = BACKEND_URL + "/api/markets"
    try {
        let requestObject = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        return fetch(url, requestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "/api/markets",
                    message: {
                        error: JSON.stringify(error)
                    },
                }
                PostRemoteLog(payload).then(response => {
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "/api/markets",
            message: {
                error: JSON.stringify(error)
            },
        }
        PostRemoteLog(payload).then(response => {
        }).catch((error) => {
        })
    }

}