
import React from 'react';
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../../HOC/Mobile.js'
import UserItem from './user-item';
import UserListHeader from './user-list-header'
import InviteUserButton from './invite-user-btn'

import { useAuthenticationHook } from '../../hooks/authentication-hook';
import { canShowInviteNewUser } from '../../helpers/permissions.js';

const UserList = (props) => {

    const { contactList, refreshList, isMobile } = props

    const myAuthService = useAuthenticationHook()
    const user = myAuthService.getUser()
    const userCustomerAccountId = user["customerAccountId"]

    return (
        <div style={{ height: "100%", width: '100%', marginTop: 15, paddingLeft: isMobile ? 0 : 20, paddingRight: isMobile ? 0 : 20 }}>
            {contactList.length <= 0 ? (
                <div style={{ maxHeight: "97%", overflowX: "hidden", overflowY: "auto", display: "grid", justifyContent: "center", alignItems: "flex-start" }}>
                    <h3>There are no other users found</h3>
                    {canShowInviteNewUser() && <InviteUserButton {...props} customerAccountId={userCustomerAccountId} successCallback={refreshList} />}
                </div>
            ) : (
                <div style={{ height: "100%" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", paddingLeft: 10, paddingRight: 10 }}>
                        <h3>User Management</h3>
                        {canShowInviteNewUser() && <InviteUserButton {...props} customerAccountId={userCustomerAccountId} successCallback={refreshList} />}
                    </div>
                    {!isMobile && <UserListHeader />}
                    <div style={{ maxHeight: isMobile ? "100%" : "92%", overflowX: "hidden", overflowY: "auto", padding: isMobile ? 10 : 0 }}>
                        {contactList.map((contact) =>

                            <UserItem key={contact.userId} item={contact} successCallback={refreshList} />

                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default compose(
    muiThemeable(),
    isMobile()
)(UserList)