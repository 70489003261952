import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import HelpWrapper from '../../HelpWrapper'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ListItemText from '@material-ui/core/ListItemText';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import muiThemeable from 'material-ui/styles/muiThemeable';
import InputLabel from '@material-ui/core/InputLabel';

import { updateLoadSearchBar } from '../../../../CarrierPortal/ActionReducers/components';
import { bindActionCreators } from 'redux';

// id, market_area_name

const MarketSelect = ({
    muiTheme,
    updateLoadSearchBar,
    handleSetSelectedMarkets,
    existingMarkets,
    searchComponentType = 'destination',
    marketOptions = [],
    customWidth = false,
    mobileView = false,
    clearData,
    ...props }) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            width: mobileView ? '100%' : '450px',
            '& .MuiSelect-select.MuiSelect-select': {
                height: 30,
                display: "flex",
                alignItems: "flex-end"
            }
        },
        selectfieldStyleOutline: {
            borderBottom: '1px solid #ccc',
            height: '56px',
            width: mobileView ? '100%' : '450px',
            maxWidth: mobileView ? null : '450px',
            margin: '0px',
            backgroundColor: 'white',
            paddingLeft: '10px',
            paddingRight: '0px',
            fontSize: '16px',
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
        },
        wrapper: {
            width: '85%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            lineHeight: '24px',
          },
        icon: {
            position: 'absolute',
            fontSize: '2.5rem'
        },
        removePadding: {
            padding: '0 !important'
        },
        chips: {
            fontSize: '12px',
            height: '22px'
        },
        labelText: {
            fontSize: 14,
            top: -5,
            left: -3,
            ' &.MuiInputLabel-filled.MuiInputLabel-shrink':{
              transform: 'translate(12px, 10px)'
            }
          },
        removePadding: {
            padding: '0 !important',
            marginTop: 14
        },
        labelHeading: {
            fontWeight: 'bold'
        },
        menuItem: {
            fontSize: '14px'
        },
    }));
    const classes = useStyles();

    // const [hasError, setErrors] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isMounted = useRef(false);
    // const [marketOptions, setMarketOptions] = useState([]);

    const [selectedMarkets, setSelectedMarkets] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {

        if (existingMarkets && existingMarkets.length !== 0) {
            let existingMarketObjs = marketOptions.filter(marketOption => existingMarkets.some(existingMarket => marketOption.id === existingMarket.id));
            setSelectedMarkets(existingMarketObjs)
          }else{
            setSelectedMarkets([])
          }

    }, [marketOptions, existingMarkets]);

    useEffect(() => {
        if (isMounted.current) {
            if (searchComponentType === "destination") {
                updateLoadSearchBar([], 'lane_preferences')
            } else {
                updateLoadSearchBar([], 'lane_origins')
            }
            setSelectedMarkets([])
        } else {
            isMounted.current = true
        }

    }, [clearData])

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    function handleChange(event, modifiedMarkets) {

        // 'value' here represents the truck type ids selected from dropdown as in the value of the event target
        //or it equals the modified list after deleting from the chip 'x' button
        let chosenMarkets = null
        if (modifiedMarkets)
            chosenMarkets = modifiedMarkets
        else
            chosenMarkets = event.target.value

        setSelectedMarkets(chosenMarkets);
        let selectedMarketObjs = chosenMarkets.map(market => ({ id: market.id, name: market.market_area_name }))
        handleSetSelectedMarkets(selectedMarketObjs)
    };

    const handleChipDelete = (marketToDelete) => () => {

        let modifiedMarkets = [...selectedMarkets]
        modifiedMarkets = modifiedMarkets.filter((market) => market !== marketToDelete);

        handleChange(null, modifiedMarkets)

    };

    function renderSelectedMarkets(selected) {
        let chipMax = 2
        return (
            <div className={classes.wrapper}>
                {
                    selected.map((value, index) => {
                        if (mobileView & index >= chipMax)
                            return index === chipMax ? <span style={{ margin: 5, fontSize: 18 }}>&hellip;</span> : null
                        return (
                            <Chip
                                variant="outlined"
                                key={value}
                                onMouseDown={(event) => {
                                    event.stopPropagation();
                                }}
                                label={value.market_area_name}
                                className={classes.chips}
                                onDelete={handleChipDelete(value)}
                            />
                        )
                    })
                }
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <InputLabel
                variant={'filled'}
                id="selected-label"
                className={classes.labelText}
                shrink
            >
                {searchComponentType === "destination" ?
                    <span>Destination</span>
                    :
                    <span>Origin</span>
                }
            </InputLabel>
            <Select
                className={classes.selectfieldStyleOutline}
                labelId="select-label"
                autoWidth={true}
                displayEmpty
                disableUnderline={true}
                multiple
                value={selectedMarkets}
                onChange={(event) => handleChange(event)}
                renderValue={(selected) => renderSelectedMarkets(selected)}
                style={{ width: customWidth ? customWidth : mobileView ? '100%' : 450, marginTop: 0 }}
                inputProps={{
                    classes: {
                        icon: classes.icon,
                        root: classes.removePadding
                    },
                }}
                MenuProps={{
                    autoFocus: false,
                    disableScrollLock: true,
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    }
                }}
                onOpen={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
            >
                <MenuItem
                    className={classes.menuItem}
                    value=""
                    disabled>
                    Select Markets
                </MenuItem>
                {marketOptions.map((market) => {
                    return (
                        <MenuItem
                            key={market.id}
                            value={market}
                            className={classes.menuItem}
                        >
                            <Checkbox style={{ color: muiTheme.secondary }} checked={selectedMarkets.includes(market)} />
                            <ListItemText classes={{ primary: classes.menuItem }} primary={market.market_area_name} />
                        </MenuItem>)
                })}
            </Select>
        </div>
    )
}


const mapStateToProps = state => ({

})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        updateLoadSearchBar: updateLoadSearchBar
    }, dispatch)
}

export default compose(
    muiThemeable(),
    connect(mapStateToProps, matchDispatchToProps),
)(MarketSelect)