import React, {useEffect, useState} from 'react';

import muiThemeable from 'material-ui/styles/muiThemeable';

import List from '@material-ui/core/List';
import ListPagination from '../ListPagination';

import TireItem from './tire-cards/tire-item';

const TireList = (props) => {

    const { width } = useWindowDimensions();

    const {
        muiTheme,
        currentTireList,
        currentTireListLength,
        isCartVersion = false,
        isMobile = false
    } = props

    const [numOfPaginationPages, setNumOfPaginationPages] = useState(1)
    const [currentTirePageNumber, setCurrentTirePageNumber] = useState(1)
    const itemsPerPage = 20

    useEffect(()=>{
        setCurrentTirePageNumber(1)
        setNumOfPaginationPages(Math.ceil(currentTireListLength / itemsPerPage))
    }, [currentTireList])

    // Only paginate the list if it's NOT in the cart
    const cartListView = () => {
        return isCartVersion ? currentTireList : currentTireList.slice((currentTirePageNumber - 1) * itemsPerPage, currentTirePageNumber * itemsPerPage)
    }

    return (
        <div style={{ height: '100%' }}>
            <List style={{ height: isCartVersion ? '100%' : 'calc(100% - 56px)', width: '100%', maxHeight: '100%', overflowX: 'hidden', overflowY: "scroll", paddingTop: 0 }}>
                {currentTireList.length > 0 ?
                    cartListView().map((item, index) => {
                        return (
                            <TireItem
                                key={item.serial}
                                item={item}
                                itemIndex={index}
                                isCartVersion={isCartVersion}
                            />
                        )

                    })
                    :
                    <div style={{ paddingTop: !isCartVersion ? 25 : 5 }}>
                        <div style={{ width: '100%', textAlign: !isCartVersion ? 'center' : 'left' }}>
                            {isCartVersion ? (
                                <p style={{ fontSize: 16, fontWeight: "bold", color: muiTheme.ttRed }}>No tires selected. If you wish to receive a quote, please use the add button on items you are interested in.</p>
                            ) : (
                                <h3>No Tires Found</h3>
                            )}
                        </div>
                    </div>
                }
            </List>
            {isCartVersion != true && 
                <div style={{paddingTop:10, paddingBottom:10}}>
                    <ListPagination
                        numberOfPages={numOfPaginationPages}
                        pageNumber={currentTirePageNumber}
                        setPageNumber={setCurrentTirePageNumber}
                        isMobile={isMobile}
                        screenWidth={width}
                    />
                </div>
            }
        </div>
    )

}

export default muiThemeable()(TireList)

function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
        width,
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}