
import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"

import muiThemeable from 'material-ui/styles/muiThemeable';
import { makeStyles } from '@material-ui/core/styles';

import FA from 'react-fontawesome'
import { Col, Row, Grid } from "react-bootstrap"
import moment from 'moment'
import { checkValueExists } from "../../../../lib/check-value-exists"
import { validLoadDetails } from "../../../../lib/valid-load-details"
import { getDisplayRate } from "../../../../lib/get-display-rate.js"
import { showBIN } from '../../../../lib/show-bin';
import { defaultDisableCrstActions, responsibilityHideQuote } from '../../../../lib/responsibility-code-functions';
import { validateUserRoleBinPermissions } from '../../../helpers/permissions';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify'
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBiohazard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimpleLineIcon from 'react-simple-line-icons';

import QuoteButton from "../quotes/quoteButton"
import BinButton from "../bin/binButton"
import BookedActionButton from "../../booked-loads/action-button"
import { useAuthenticationHook } from '../../../hooks/authentication-hook';
import InvoiceActionContainer from '../../invoices/invoice-action-container';
import { titleCaseFormatter } from "../../../../lib/title-case-formatter"
import { numberWithCommas } from '../../../../lib/number-with-commas';
import { findLatestBinOffer, getLatestQuoteStatusMsg } from "../quotes/actionButtons/quoteHelperFunctions"
import { formatPhoneNumberWithHyphens } from '../../../../components/formatPhoneNumber';



library.add(
    faBiohazard
);

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08,
        margin: 10
    },
    mainText: {
        fontSize: '14px',
        lineHeight: '24px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '12px',
        lineHeight: '21px',
        color: 'grey'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'center',
        textAlign: 'left'
    },
    showInterestBtn: {
        height: 'auto',
        width: '100%',
        // color: muiTheme.secondary, 
        backgroundColor: '#ad2228 !important',
        // borderColor: muiTheme.primary
    },
    chipContainer: {
        marginBottom: 8,
        '& .MuiChip-root': {
            padding: 8,
            height: 26,
            width: 120,
            backgroundColor: "rgba(241,156,27,0.15)"
        },
        '& .MuiChip-root .MuiChip-avatar': {
            width: 12,
            height: 18
        },
        '& .MuiChip-label': {
            fontSize: 12,
            marginLeft: 8,
            fontWeight: "bold",
            color: "#F19C1B"
        }
    }
});

const LoadCardMobile = (props) => {
    const classes = useStyles();
    const {
        load,
        index,
        pickup,
        delivery,
        availability,
        muiTheme,
        key,
        removeLoadFromList,
        isBookedLoads,
        callRefreshCard,
        isHome = false,
        userProfile = false
    } = props
    const TT_LOGO_ASSET_URL = 'http://assets.truckertools.com/images/logos/'
    const [isHazmat, setIsHazmat] = useState(false)
    const [isHotLoad, setIsHotLoad] = useState(false)
    const [hotLoadComment, setHotLoadComment] = useState('This is a priority load!')
    const TT_ID = '2zr5ys19'

    const [failToLoadImage, setFailToLoadImage] = useState(false)
    const [errorMsg, setErrorMsg] = useState(null)

    const myAuthService = useAuthenticationHook()
    const isAuthenticated = myAuthService.isAuthenticated()
    const user = myAuthService.getUser()

    useEffect(() => {

        if (load.truckTypes) {
            if (load.truckTypes.toUpperCase().indexOf('HAZMAT') > -1)
                setIsHazmat(true)
        }
        if (load.extras) {
            let hotLoadStatus = false //TODO default this to false
            load.extras.forEach((extraItem) => {
                if (extraItem.name == "Priority Load") {
                    if (extraItem.value == "Yes") {
                        hotLoadStatus = true
                    }
                }
                if (extraItem.name == 'Priority Comment' && extraItem.value.length != 0) {
                    setHotLoadComment(extraItem.value)
                }
            })
            setIsHotLoad(hotLoadStatus)
        }
    }, [])

    const getIcon = (market, type) => {
        const SQUARE_SIZE = 6
        let icon = null
        let key = "originSignal"
        if (type == "origin") {
            icon = <div
                style={{
                    width: SQUARE_SIZE + 3,
                    height: SQUARE_SIZE + 3,
                    borderRadius: (SQUARE_SIZE + 6) / 2,
                    backgroundColor: '#A4A4AC',
                    width: 12,
                    height: 12,
                }}
            />
        } else {
            key = "destinationSignal"
            icon = <div
                style={{
                    width: SQUARE_SIZE + 3,
                    height: SQUARE_SIZE + 3,
                    backgroundColor: muiTheme.ttUnselectGrey,
                    width: 12,
                    height: 12,
                }}
            />
        }

        // if (checkValueExists(market, key)) {
        //     if (market[key] >= 80) {
        //         return (<FA name='fire' color='#9A1F25' />)
        //     } else if (market[key] >= 65 && market[key] < 80) {
        //         return (<FA name='fire' color='#D96C1E' />)
        //     } else if (market[key] >= 50 && market[key] < 65) {
        //         return (<FA name='fire' color='#FFB400' />)
        //     } else if (market[key] >= 35 && market[key] < 50) {
        //         return (<FA name='snowflake' color='#5DBFFF' />)
        //     } else if (market[key] > 20 && market[key] < 35) {
        //         return (<FA name='snowflake' color='#0086E1' />)
        //     } else if (market[key] <= 20) {
        //         return (<FA name="snowflake" color={'#002F81'} />)
        //     }
        // }

        return icon
    }


    return (
        <div data-cy="load-card">
            {/* // <Animatable.div key={load.id} style={[CustomStyleSheet.card, rowStyle]} animation={animation} duration={duration} > */}
            <Link
                style={{ color: 'black' }}
                to={{
                    pathname: '/loadDetails',
                    state: {
                        load: load,
                        // setOpenLoadDetails: setOpenLoadDetails,
                        pickup: pickup,
                        delivery: delivery,
                        availability: availability,
                        isMyLoadsScreen: false, // todo
                        myLoadsRenderedTab: 0, // todo
                        loadDetailsRenderedTab: 0,
                        // removeLoadFromList: removeLoadFromList, // todo
                        // callRefreshCard: callRefreshCard,
                        onInvoicesScreen: props.onInvoicesScreen,
                        isHome: isHome,
                        userProfile: userProfile,
                        isHotLoad: isHotLoad,
                        hotLoadComment: hotLoadComment
                    }
                }}
            >
                <Col style={{ display: 'flex', width: '100%', padding: 5 }} >
                    <Row style={{ display: 'flex', width: '100%', height: '100%', margin: 0 }}>

                        <Col style={{ display: 'flex', width: 25 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '75%', paddingTop: 8 }} >
                                <div style={{ width: 10, height: 10, borderRadius: 10 / 2, backgroundColor: '#A4A4AC' }} >
                                </div>
                                <div style={{ height: '70%', width: 2, backgroundColor: 'lightgrey', marginVertical: 5 }} >
                                </div>
                                <div style={{ width: 10, height: 10, backgroundColor: 'lightgrey' }} >
                                </div>
                            </div>
                        </Col>
                        <Col style={{ display: 'flex', flexDirection: 'column', width: '55%' }}>
                            <Row style={{ margin: 0 }}>
                                <div>
                                    {process.env.REACT_APP_ADVANTAGE_ID == "c0419cm1" && isHotLoad &&
                                        <Row className={classes.chipContainer} style={{ marginLeft: "3.9%" }}>
                                            <Tooltip
                                                title={<p style={{ fontSize: 12, lineHeight: "14px" }}>{hotLoadComment}</p>}
                                                placement="top"
                                                arrow
                                                enterTouchDelay={0} 
                                                leaveTouchDelay={2000}
                                                onClick={e=>e.preventDefault()}
                                                onTouchStart={e=>e.preventDefault()}
                                            >
                                                <Chip
                                                    avatar={<Avatar alt="Natacha" src={require('../../../customers/' + muiTheme.customerUniqueId + '/theme/hot-load.svg')} />}
                                                    label="Hot Load"
                                                    // variant="outlined"
                                                />
                                            </Tooltip>
                                        </Row>
                                    }
                                    <Row style={{ margin: 0 }}>
                                        <p className={classes.mainText} style={{ display: 'inline-block' }}>
                                            {titleCaseFormatter(load.originCity)},{' ' + load.originState}
                                        </p>
                                        {isHazmat && <FontAwesomeIcon icon={['fas', 'biohazard']} style={{ color: '#000', display: 'inline-block', marginLeft: 5 }} />}
                                    </Row>
                                    <Row style={{ margin: 0 }}>
                                        {!isHome &&
                                            <p className={classes.secondaryText}>
                                                Deadhead: {load.deadHead} mi
                                            </p>
                                        }
                                    </Row>
                                    <Row style={{ margin: 0 }}>
                                        <p className={classes.secondaryText}>
                                            {load.pickupDateTimeLatest ? moment(load.pickupDateTime).format('MMM D hh:mm A') + " - " + moment(load.pickupDateTimeLatest).format('MMM D hh:mm A') : moment(load.pickupDateTime).format('dddd MMM D hh:mm A')}
                                        </p>
                                    </Row>
                                    {isAuthenticated && process.env.REACT_APP_ADVANTAGE_ID === "a98db973" && load.stops &&
                                        load.stops[0].stop_type && load.stops[0].stop_type != "NULL" &&
                                        <Row>
                                            <p className={classes.secondaryText}>
                                                {load.stops[0].stop_type}
                                            </p>
                                        </Row>
                                    }
                                </div>
                            </Row>

                            <Row style={{ margin: 0 }}>
                                <div>
                                    <Row style={{ margin: 0, height: '40%' }}>
                                        <p className={classes.mainText}>
                                            {titleCaseFormatter(load.destinationCity)},{' ' + load.destinationState}
                                        </p>
                                    </Row>
                                    <Row style={{ margin: 0, height: '40%' }}>
                                        <p className={classes.secondaryText}>
                                            {moment(load.deliveryDateTime).format('dddd MMM D hh:mma')}
                                        </p>
                                    </Row>
                                    {isAuthenticated && process.env.REACT_APP_ADVANTAGE_ID === "a98db973" && load.stops &&
                                        load.stops[load.stops.length - 1].stop_type && load.stops[load.stops.length - 1].stop_type != "NULL" &&
                                        <Row>
                                            <p className={classes.secondaryText}>
                                                {load.stops[load.stops.length - 1].stop_type}
                                            </p>
                                        </Row>
                                    }
                                </div>
                            </Row>
                        </Col>
                        <Col style={{ width: '35%' }}>
                            {muiTheme.customerUniqueId === TT_ID && //only the defaut TT site will have loads from more than one broker
                                <Row style={{ height: '40%', justifyContent: "flex-end", alignItems: 'center', marginBottom: 8 }}>
                                    {!failToLoadImage ?
                                        <img
                                            style={{ width: '75%', maxWidth: 165, float: 'right' }}
                                            src={TT_LOGO_ASSET_URL + load.brokerId + '/default.png'}
                                            alt={load.companyName}
                                            onError={(error) => { setFailToLoadImage(true); setErrorMsg(error) }}
                                        /> :
                                        <p className={classes.mainText} style={{ textAlign: 'right' }}>{load.companyName}</p>
                                    }
                                </Row>
                            }
                            {checkValueExists(load, "numberOfStops") && ((load.numberOfStops - 2) > 0) && (
                                <Row style={{ alignItems: 'center' }}>
                                    <p style={{ textAlign: 'right', fontSize: 14, color: muiTheme.ttRed }}>
                                        {load.numberOfStops - 2} Interim Stops
                                    </p>
                                </Row>
                            )}
                            <Row style={{ alignItems: 'center' }}>
                                {isAuthenticated && (

                                    <div>
                                        <Row>
                                            {defaultDisableCrstActions(props.userProfile) ? getDisplayRate(load, true, userProfile) : ''}
                                        </Row>
                                        {(load.showBookItNowPrice === 1 || (showBIN(load, null, props.userProfile) && validLoadDetails(load, ["weight"]))) &&
                                            <Row>
                                                <div style={{ overflow: 'hidden' }}>
                                                    {checkValueExists(load, 'ratePer') && load.ratePer > 0 && defaultDisableCrstActions(userProfile) &&
                                                        <p style={{ textAlign: 'right', fontSize: 14 }}>${parseFloat(load.ratePer).toFixed(2)}/mi</p>}
                                                    {checkValueExists(load, 'distance') && load.distance > 0 &&
                                                        <p style={{ textAlign: 'right', fontSize: 14 }}>{parseFloat(load.distance).toFixed(0)}mi</p>}
                                                </div>
                                            </Row>
                                        }
                                    </div>
                                )}
                            </Row>
                            {/* {load.reloads &&
                                <Row style={{ alignItems: 'center' }}>
                                    <p style={{ textAlign: 'right', fontSize: 14 }}>{load.reloads > 0 && ('Reloads : ' + load.reloads)}</p>
                                </Row>
                            } */}

                            {load.truckTypes &&
                                <Row style={{ alignItems: 'center' }}>
                                    <p style={{ textAlign: 'right', fontSize: 14 }}>{load.truckTypes}</p>
                                </Row>
                            }
                        </Col>

                    </Row>



                </Col>
            </Link>
            <Row style={{
                display: 'flex',
                width: '100%',
                height: '28%',
                borderBottomLeftRadius: 15,
                borderBottomRightRadius: 15,
                backgroundColor: '#F6F6F6',
                margin: 0,
                justifyContent: 'space-between'
            }}>
                {props.onInvoicesScreen ?
                    <InvoiceActionContainer
                        load={load}
                        refreshInvoices={props.refreshInvoices}
                    />
                    :
                    <div style={{ display: 'contents' }}>
                        {checkValueExists(load, "covered") && load.covered == 1 && checkValueExists(load, "selfCovered") && load.selfCovered == 1 && (
                            <Col testID={"fullCard CR 1"} style={{ width: '90%', marginHorizontal: "5%", justifyContent: 'center' }}>
                                <p style={{ color: 'grey', textAlign: "right", fontSize: 14 }}>Covered by you.</p>
                            </Col>
                        )}
                        {checkValueExists(load, "covered") && load.covered == 1 && (!checkValueExists(load, "selfCovered") || (checkValueExists(load, "selfCovered") && load.selfCovered == 0)) && (
                            <Col testID={"fullCard CR 2"} style={{ width: '90%', marginHorizontal: "5%", justifyContent: 'center' }}>
                                <p style={{ color: 'grey', textAlign: "right", fontSize: 14 }}>Covered by someone else.</p>
                            </Col>
                        )}

                        {(!checkValueExists(load, "covered") || (checkValueExists(load, "covered") && load.covered == 0)) && (
                            <div style={{ textAlign: 'center', width: '100%', display: validateUserRoleBinPermissions(user ? user : null) ? "flex" : "none", flexDirection: 'column' }}>
                                <div style={{ paddingBottom: 4 }}>
                                    {getLatestQuoteStatusMsg(load, isAuthenticated)}
                                </div>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly', alignItems: 'flex-end' }}>
                                    {!responsibilityHideQuote(load, userProfile) && /* defaultDisableCrstActions(userProfile) && */
                                        <QuoteButton
                                            carriers={[availability.carrier_id]}
                                            brokers={[load.brokerId]}
                                            availabilities={[{ availability_id: availability.id, carrier_id: availability.carrier_id }]}
                                            removeLoadFromList={removeLoadFromList}
                                            isAuthenticated={isAuthenticated}
                                            callRefreshCard={callRefreshCard}
                                            userProfile={userProfile}
                                            {...props}
                                        />

                                    }
                                    {showBIN(load, null, props.userProfile) && validLoadDetails(load, ["weight"]) && /* defaultDisableCrstActions(userProfile) && */
                                        <BinButton
                                            key={key + "_BIN_button"}
                                            carrierId={availability.carrier_id}
                                            availabilityId={availability.id}
                                            load={load}
                                            index={index}
                                            pickup={pickup}
                                            delivery={delivery}
                                            quoteValue={findLatestBinOffer(load.quotes, load).amount}
                                            {...props}
                                        />
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                }
            </Row>
        </div>

    );
}


const mapStateToProps = state => ({
    userProfile: state.Profile.profileData,
})


export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(LoadCardMobile)