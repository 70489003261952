import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { useSpring, useTransition, animated } from 'react-spring'

import "../../styleSheets/loadStyles.css"
import CopyContentsIconButton from '../copy-contents-icon-button';
import { cardStyle } from "../../../lib/generalStyles"
import { checkValueExists } from "../../../lib/check-value-exists"
import { canShowCommodityId, canShowFullAddress } from "../../helpers/permissions"
import TargetLoadMap from './targetLoadMap';
import { numberWithCommas } from '../../../lib/number-with-commas';
import { formatDateTime } from '../../../lib/date-functions';


const TargetLoadDetails = (props) => {

    const {
        load,
        muiTheme
    } = props

    const stopsWrapperStartHeight = 240
    const stopsContentStartHeight = 170
    const stopsItemHeight = 80
    const dateTimeLineHeight = 22

    const countItemsWithNoLateArrivial = useRef(null)

    const useStyles = makeStyles({
        rootContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        primaryDetails: {
            ...cardStyle,
            display: "flex"
        },
        secondaryDetails: {
            ...cardStyle,
            display: "flex",
            flexWrap: "wrap"
        },
        stops: {
            ...cardStyle,
            overflow: 'hidden'
        },
        stopsLine: {
            display: 'flex',
            flex: 1
        },
        col1: {
            flex: 1
        },
        col2: {
            flex: 2
        },
        stopsContentContainer: {
            flex: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        primaryCol3: {
            flex: 3,
            whiteSpace: 'nowrap',
        },
        hoverShadow: {
            '&:hover': {
                boxShadow: '0px 2px 10px #999999',
            }
        }
    });
    const classes = useStyles()

    const TT_LOGO_ASSET_URL = 'http://assets.truckertools.com/images/logos/';

    const stopsRef = useRef(null)
    const [failToLoadImage, setFailToLoadImage] = useState(false)
    const [showInterimStops, setShowInterimStops] = useState(false)
    const [showSecondaryDetails, setShowSecondaryDetails] = useState(true)

    const showStopsBtnAnimatedStyle = useSpring({ transform: showInterimStops ? 'rotate(180deg)' : 'rotate(0deg)' })
    const stopsContainerAnimatedStyle = useSpring({ height: showInterimStops ? stopsWrapperStartHeight + (stopsItemHeight) * (load.fullLoadDetails.numberOfAdditionalStops) - (countItemsWithNoLateArrivial.current * dateTimeLineHeight) : stopsWrapperStartHeight })
    const stopsLabelAnimatedStyle = useSpring({ height: showInterimStops ? stopsContentStartHeight + (stopsItemHeight) * (load.fullLoadDetails.numberOfAdditionalStops) - (countItemsWithNoLateArrivial.current * dateTimeLineHeight) : stopsContentStartHeight })
    const fadeTransition = useTransition(showInterimStops, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { position: 'absolute', opacity: 0 },
        reverse: showInterimStops,
        delay: 100
    })

    useEffect(() => {
        setShowSecondaryDetails(
            checkValueExists(load.fullLoadDetails, "revenueCode") ||
            checkValueExists(load.fullLoadDetails, "quantity") ||
            (canShowCommodityId() && (checkValueExists(load.fullLoadDetails, "commodity") || checkValueExists(load.fullLoadDetails, "commodityId"))) ||
            checkValueExists(load.fullLoadDetails, "dimensions") ||
            checkValueExists(load.fullLoadDetails, "length") ||
            checkValueExists(load.fullLoadDetails, "comments") ||
            checkValueExists(load.fullLoadDetails, "bookItNowNotes")
        )

        countItemsWithNoLateArrivial.current = getNoLateArrivalCount()
    }, [load])

    const getNoLateArrivalCount = () => {
        let count = 0
        load.fullLoadDetails.additionalStops.forEach(stop => {
            if (stop.scheduledAtLateDateTime == null || stop.scheduledAtEarlyDateTime === "")
                ++count
        })

        if (!checkValueExists(load.fullLoadDetails.pickup, "scheduledAtLateDateTime"))
            ++count
        if (!checkValueExists(load.fullLoadDetails.delivery, "scheduledAtLateDateTime"))
            ++count

        return count
    }

    const getLocDisplay = (location) => {
        return (
            <div style={{ height: location.scheduledAtLateDateTime ? stopsItemHeight : stopsItemHeight - 18, paddingBottom: 8 }}>
                <p className='importantText' style={{ marginBottom: 4 }}>{location.city}, {location.state}</p>
                <p className='labelText' style={{ height: dateTimeLineHeight, marginBottom: 4 }}>{location.scheduledAtEarlyDateTime ? formatDateTime(location.scheduledAtEarlyDateTime, location.timeZone) : <span style={{ fontStyle: 'italic' }}>Contact broker for arrival time</span>}</p>
                {location.scheduledAtLateDateTime &&
                    <p className='labelText' style={{ height: dateTimeLineHeight, marginBottom: 4 }}>{formatDateTime(location.scheduledAtLateDateTime, location.timeZone)}</p>
                }
            </div>
        )
    }

    return (
        <div className={classes.rootContainer}>
            <div className={classes.primaryDetails}>
                <div className={classes.col1}>
                    <p className='labelText' style={{ fontSize: 14 }}>Load #</p>
                    <p className='labelText' style={{ fontSize: 14 }}>Status</p>
                </div>
                <div className={classes.col2}>
                    <p className='mainText'>{load.loadNumber}</p>
                    <p className='mainText'>{load.status}</p>
                </div>
                <div className={classes.primaryCol3}>
                    <div style={{ textAlign: "right", display: "flex", justifyContent: "flex-end", alignItems: "center", height: 40, paddingBottom: 16, paddingRight: 16 }}>
                        {!failToLoadImage ?
                            <img
                                style={{ height: 'inherit', width: 'inherit' }}
                                src={TT_LOGO_ASSET_URL + load.fullLoadDetails.broker.id + '/default.png'}
                                onError={() => setFailToLoadImage(true)}
                            /> :
                            <p className='mainText'>{load.fullLoadDetails.broker.companyName}</p>
                        }
                    </div>
                    {load && load.loadContact && load.loadContact.phone && (
                        <div style={{ textAlign: "right", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <a href={"tel:" + load.loadContact.phone} target="_blank" style={{ color: muiTheme.actionColor, overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 8 }}>
                                {load.loadContact.phone}
                            </a>
                            <CopyContentsIconButton isMobile={false} content={load.loadContact.phone} />
                        </div>
                    )}
                    {load && load.loadContact && load.loadContact.email && (
                        <div style={{ textAlign: "right", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <a href={"mailto:" + load.loadContact.email} target="_blank" style={{ color: muiTheme.actionColor, overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 8 }}>
                                {load.loadContact.email}
                            </a>
                            <CopyContentsIconButton isMobile={false} content={load.loadContact.email} />
                        </div>
                    )}
                </div>
            </div>
            <animated.div className={classes.stops} style={stopsContainerAnimatedStyle}>
                <div style={{ display: "flex", marginBottom: 16 }}>
                    {(checkValueExists(load.fullLoadDetails, "equipmentType") || checkValueExists(load.fullLoadDetails, "weight")) &&
                        <div className='detail-chip'>
                            <p className='detail-chip-text' style={{ marginRight: 8 }}>{load.fullLoadDetails.equipmentType}</p>
                            <p className='detail-chip-text'>{numberWithCommas(parseFloat(load.fullLoadDetails.weight).toFixed(0))} lbs</p>
                        </div>
                    }
                    {checkValueExists(load, "distance") && load.distance > 0 &&
                        <div className='detail-chip'>
                            <p className='detail-chip-text'>{numberWithCommas(parseFloat(load.distance).toFixed(0))}mi</p>
                        </div>
                    }
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <animated.div className={classes.stopsLine} style={stopsLabelAnimatedStyle}>
                        <div ref={stopsRef} style={{ display: 'flex', marginRight: 8, position: 'relative', flexDirection: 'row', justifyContent: 'space-between' }}>

                            <div className='start-end-line' style={{ position: 'relative', height: 'auto', flex: 1, margin: 0 }} />


                            <div style={{ position: 'relative', left: -6, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                                <div style={{ display: 'inline-flex', alignItems: 'flex-end', height: 22, backgroundColor: 'white' }}>
                                    <div className='origin-indicator' style={{ margin: 0, marginRight: 4 }} />

                                </div>

                                {checkValueExists(load.fullLoadDetails, "numberOfAdditionalStops") && load.fullLoadDetails.numberOfAdditionalStops > 0 &&
                                    <div>
                                        <div
                                            className={checkValueExists(load.fullLoadDetails, "additionalStops") && load.fullLoadDetails.additionalStops.length > 0 ? classes.hoverShadow : null}
                                            style={{ backgroundColor: 'white', display: 'inline-flex', alignItems: 'center', borderRadius: 10, padding: 4, cursor: 'pointer', marginLeft: -2, whiteSpace: 'nowrap' }}
                                            onClick={() => {
                                                if (checkValueExists(load.fullLoadDetails, "additionalStops") && load.fullLoadDetails.additionalStops.length > 0)
                                                    setShowInterimStops(!showInterimStops)
                                            }}
                                            
                                        >
                                            <animated.div style={showStopsBtnAnimatedStyle}>
                                                <FontAwesomeIcon icon={faAngleDoubleDown} />
                                            </animated.div>
                                            <p className='additionalStopsText' style={{ position: 'relative', margin: 0, marginLeft: 4 }}>
                                                +{load.fullLoadDetails.numberOfAdditionalStops} stops
                                            </p>
                                        </div>
                                    </div>
                                }

                                <div style={{ display: 'inline-flex', alignItems: 'center', paddingBottom: 40, backgroundColor: 'white' }}>
                                    <div className='destination-indicator' style={{ margin: 0, marginRight: 4 }} />
                                </div>

                            </div>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: 50, marginLeft: checkValueExists(load.fullLoadDetails, "numberOfAdditionalStops") && load.fullLoadDetails.numberOfAdditionalStops > 0 ? -50 : -8 }}>
                            <div className='start-btn' style={{ zIndex: 10 }}>
                                START
                            </div>

                            <div className='end-btn' style={{ zIndex: 10, marginBottom: 30 }}>
                                END
                            </div>
                        </div>
                    </animated.div>
                    <animated.div className={classes.stopsContentContainer} style={stopsLabelAnimatedStyle}>

                        {checkValueExists(load.fullLoadDetails, "pickup") &&
                            getLocDisplay(load.fullLoadDetails.pickup)
                        }

                        {checkValueExists(load.fullLoadDetails, "additionalStops") && load.fullLoadDetails.additionalStops.length > 0 &&
                            <div style={{ position: 'relative', height: load.fullLoadDetails.additionalStops * stopsItemHeight - (countItemsWithNoLateArrivial * dateTimeLineHeight) }}>
                                {
                                    fadeTransition(
                                        (styles, item) => item &&
                                            <animated.div style={styles}>
                                                {load.fullLoadDetails.additionalStops.map(stop => getLocDisplay(stop))}
                                            </animated.div>
                                    )

                                }
                            </div>
                        }

                        {checkValueExists(load.fullLoadDetails, "delivery") &&
                            getLocDisplay(load.fullLoadDetails.delivery)
                        }

                    </animated.div>
                </div>
            </animated.div>

            <div className={classes.secondaryDetails} style={{ display: showSecondaryDetails ? 'flex' : 'none' }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    {checkValueExists(load.fullLoadDetails, "revenueCode") &&
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <div className={classes.col1}>
                                <p className='labelText'>Revenue Code</p>
                            </div>
                            <div className={classes.col2}>
                                <p className='mainText'>{load.fullLoadDetails.revenueCode}</p>
                            </div>
                        </div>
                    }
                    {checkValueExists(load.fullLoadDetails, "quantity") &&
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <div className={classes.col1}>
                                <p className='labelText'>Quantity</p>
                            </div>
                            <div className={classes.col2}>
                                <p className='mainText'>{load.fullLoadDetails.quantity}</p>
                            </div>
                        </div>
                    }
                    {canShowCommodityId() && (checkValueExists(load.fullLoadDetails, "commodity") || checkValueExists(load.fullLoadDetails, "commodityId")) &&
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <div className={classes.col1}>
                                <p className='labelText'>Commodity</p>
                            </div>
                            <div className={classes.col2}>
                                <p className='mainText'>{load.fullLoadDetails.commodityId ? load.fullLoadDetails.commodityId : load.fullLoadDetails.commodity ? load.fullLoadDetails.commodity : null}</p>
                            </div>
                        </div>
                    }
                    {checkValueExists(load.fullLoadDetails, "comments") &&
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <div className={classes.col1}>
                                <p className='labelText'>Comment</p>
                            </div>
                            <div className={classes.col2}>
                                <p className='mainText'>{load.fullLoadDetails.comments}</p>
                            </div>
                        </div>
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    {checkValueExists(load.fullLoadDetails, "dimensions") &&
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <div className={classes.col1}>
                                <p className='labelText'>Dimensions</p>
                            </div>
                            <div className={classes.col2}>
                                <p className='mainText'>{load.fullLoadDetails.dimensions}</p>
                            </div>
                        </div>
                    }
                    {checkValueExists(load.fullLoadDetails, "length") &&
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <div className={classes.col1}>
                                <p className='labelText'>Length</p>
                            </div>
                            <div className={classes.col2}>
                                <p className='mainText'>{load.fullLoadDetails.length}</p>
                            </div>
                        </div>
                    }
                    {checkValueExists(load.fullLoadDetails, "bookItNowNotes") &&
                        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <div className={classes.col1}>
                                <p className='labelText'>Book It Now Notes</p>
                            </div>
                            <div className={classes.col2}>
                                <p className='mainText'>{load.fullLoadDetails.bookItNowNotes}</p>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <TargetLoadMap
                load={load}
                height={300}
                width='100%'
            />

        </div>
    )
}


export default TargetLoadDetails