import React, { Component } from 'react'
import { postNote, getNotes } from './actions.js'
import { connect } from 'react-redux'
import { Col, Row, Grid, Glyphicon } from "react-bootstrap"
import { AutoSizer, List } from 'react-virtualized'
import Button from '@material-ui/core/Button';
import { FormGroup, Modal, ListGroup, ListGroupItem } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, InsertButton } from 'react-bootstrap-table';
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.min.css';
import { reduxForm, Field, Form } from 'redux-form'
import BootstrapFieldInput from '../components/BootstrapFieldInput'
import moment from 'moment';
import PropTypes from 'prop-types';
import {
	timeSince
} from '../lib/time-since'
import {
	Redirect
} from 'react-router-dom';
import Messages from '../notifications/Messages'
import Errors from '../notifications/Errors'
import {
	signout, isAuthenticated
} from '../lib/check-auth'

import {
	timeSinceFormat
} from '../lib/time-since'

class profileUpdateForm extends React.Component {


	constructor(props) {
		super(props);

		this.onLoad = this.onLoad.bind(this);

		this.hidingBrokerId = 0;
		this.state = {
			footerDynamicHeight: 0,
			headerDynamicHeight: 0,
			initialValues: null,
			openPostForm: false,
			FormHeight: 80,
			ListHeight: 0,
			formTxtheight: 65,
		};
	}


	componentDidMount() {


	}

	componentWillReceiveProps(nextProps) {
		console.log('componentWillReceiveProps', nextProps);
		//this.props 		=	nextProps;

	}

	componentWillUpdate(nextProps, nextState) {
		console.log('componentWillUpdate');
	}

	componentDidUpdate() {
		console.log('componentDidUpdate');
	}

	setRefHeight(el) {

		// console.log('outOfHeight 1 = > '+el.clientHeight);

	}




	onLoad() {


	}



	showToast(message) {
		toast(message)
	}

	render() {
		console.log('render_start');

		const { handleSubmit } = this.props
		var companyName = this.props.companyName




		var marginTop = 30
		var ModalHeader = 70
		var ModalFooter = 100
		const compHeight = window.innerHeight - (marginTop + ModalHeader + ModalFooter)
		this.state.ListHeight = compHeight //- 	this.state.FormHeight;


		const itemNameStyle = { textAlign: 'left' }
		const hBottomMargin = { marginBottom: '0px' }
		const hBottomMargin_10 = { marginBottom: '10px' }
		const hBottomMargin_5 = { marginBottom: '5px' }
		const itemTxtStyle = { marginBottom: '5px', paddingTop: '2.4px' }
		return (
			<Modal show={this.props.show} onHide={this.props.onHide} bsSize="large" >
				<Form onSubmit={handleSubmit(this.props.submitCallback.bind(this))} >
					<Modal.Header style={{ padding: '5px' }}>
						<Modal.Title id="contained-modal-title-lg" >
							Update Profile
			          </Modal.Title>
					</Modal.Header>
					<Modal.Body style={{ padding: '0px' }}>

						<div className=" " ref={this.setRefHeight} >
							<div className="custom-panel-body" style={{ padding: '10px' }}>
								<Row className="show-grid" style={{ paddingBottom: '20px' }}>
									<Col xs={12} md={12}>
										<div style={{ overflow: 'auto', maxHeight: this.state.ListHeight + 'px', }}>


											<div>

												<Row style={{margin: 0}}>
													<Col xs={12} md={5}>
														{/* <h3 style={hBottomMargin_10}>User Profile</h3> */}
														<Row>
															<Col xs={12} md={4} style={itemNameStyle} >

																<span>
																	<h4 style={itemTxtStyle} className="small">
																		User Name:
								          						</h4>
																</span>
															</Col>
															<Col xs={12} md={8} >
																<span>
																	<Field
																		name="username"
																		type="text"
																		id="usernameTxt"
																		label="User Name"
																		placeholder="User Name"
																		style={{ fontSize: '15px' }}
																		component={BootstrapFieldInput}
																		disabled='true'
																		uid="profile-edit-user-name"
																	/>
																</span>
															</Col>
														</Row>

														<Row>
															<Col xs={12} md={4} style={itemNameStyle} >

																<span>
																	<h4 style={itemTxtStyle} className="small">
																		Your Name:
								          						</h4>
																</span>
															</Col>
															<Col xs={12} md={4} >
																<span>
																	<Field
																		name="fullname"
																		type="text"
																		id="fullNameTxt"
																		label="Full Name"
																		placeholder="Full Name"
																		style={{ fontSize: '15px' }}
																		component={BootstrapFieldInput}
																		uid="profile-edit-your-full-name"
																	/>
																</span>
															</Col>
															{/* <Col xs={12} md={4} >
																<span>
																	<Field
																		name="firstName"
																		type="text"
																		id="firstNameTxt"
																		label="First Name"
																		placeholder="First Name"
																		style={{ fontSize: '15px' }}
																		component={BootstrapFieldInput}
																		uid="profile-edit-your-first-name"
																	/>
																</span>
															</Col> */}
															{/* <Col xs={12} md={4} >
																<span>

																	<Field
																		name="lastName"
																		type="text"
																		id="lastNameTxt"
																		label="Last Name"
																		placeholder="Last Name"
																		style={{ fontSize: '15px' }}
																		component={BootstrapFieldInput}
																		uid="profile-edit-your-last-name"
																	/>
																</span>
															</Col> */}
														</Row>

														{/* <Row>
															<Col xs={12} md={4} style={itemNameStyle} >

																<span>
																	<h4 style={itemTxtStyle} className="small">
																		Phone #:
								          						</h4>
																</span>
															</Col>
															<Col xs={12} md={8} >
																<span>

																	<Field
																		name="phoneNumber"
																		type="text"
																		id="phoneNumberTxt"
																		label="Phone #"
																		placeholder="Phone Number"
																		style={{ fontSize: '15px' }}
																		component={BootstrapFieldInput}
																		uid="profile-edit-phone-number"
																	/>
																</span>
															</Col>
														</Row> */}

														{/* <Row>
								          						<Col xs={12} md={4} style={itemNameStyle} >
								          							
								          						<span>
								          						<h4 style={itemTxtStyle} className="small">
								          							Fax #: 
								          						</h4>
								          						</span>
								          						</Col>
								          						<Col xs={12} md={8} >
								          							<span>
									          							
									          							<Field
							                                              name="fax"
							                                              type="text"
							                                              id="faxTxt"
							                                              label="fax #"
							                                              placeholder="Fax"
							                                              style={{fontSize : '15px'}}
							                                              component={BootstrapFieldInput}
							                                            /> 
								          							</span>	
								          						</Col>
								          					</Row> */}

														<Row>
															<Col xs={12} md={4} style={itemNameStyle} >
																<span>
																	<h4 style={itemTxtStyle} className="small">
																		Company Name:
								          						</h4>
																</span>
															</Col>
															<Col xs={12} md={8} >
																<span>
																	<Field
																		name="companyName"
																		type="text"
																		id="companyNameTxt"
																		label="Company Name"
																		placeholder="Company Name"
																		style={{ fontSize: '15px' }}
																		component={BootstrapFieldInput}
																		max={160}
																		required
																		uid="profile-edit-company-name"
																	/>
																</span>
															</Col>
														</Row>


														{/* <Row>
								          						<Col xs={12} md={4} style={itemNameStyle} >
								          						<span>
								          						<h4 style={itemTxtStyle} className="small">
								          							Email: 
								          						</h4>
								          						</span>
								          						</Col>
								          						<Col xs={12} md={8} >
								          							<span>
								          							<Field
							                                              name="email"
							                                              type="email"
							                                              id="emailTxt"
							                                              label="Email"
							                                              placeholder="Company Email"
							                                              style={{fontSize : '15px'}}
							                                               component={BootstrapFieldInput}
							                                               required
							                                            />
								          							</span>
								          						</Col>
								          					</Row> */}

														<Row>
															<Col xs={12} md={4} style={itemNameStyle} >

																<span>
																	<h4 style={itemTxtStyle} className="small">
																		MC # :
								          						</h4>
																</span>
															</Col>
															<Col xs={12} md={8} >
																<span>
																	<Field
																		name="mc"
																		type="text"
																		id="mcTxt"
																		label="MC #"
																		placeholder="MC Number"
																		style={{ fontSize: '15px' }}
																		component={BootstrapFieldInput}
																		uid="profile-edit-mc-number"
																	/>
																</span>
															</Col>
														</Row>

														<Row>
															<Col xs={12} md={4} style={itemNameStyle} >
																<span>
																	<h4 style={itemTxtStyle} className="small">
																		DOT # :
								          						</h4>
																</span>
															</Col>
															<Col xs={12} md={8} >
																<span>
																	<Field
																		name="dotNumber"
																		type="text"
																		id="dotNumberTxt"
																		label="DOT #"
																		placeholder="DOT Number"
																		style={{ fontSize: '15px' }}
																		component={BootstrapFieldInput}
																		uid="profile-edit-dot-number"
																	/>
																</span>
															</Col>
														</Row>

														<Row>
															<Col xs={12} md={4} style={itemNameStyle} >

																<span>
																	<h4 style={itemTxtStyle} className="small">
																		SCAC # :
								          						</h4>
																</span>
															</Col>
															<Col xs={12} md={8} >
																<span>
																	<Field
																		name="scas"
																		type="text"
																		id="scasTxt"
																		label="SCAC"
																		placeholder="SCAC"
																		style={{ fontSize: '15px' }}
																		component={BootstrapFieldInput}
																		uid="profile-edit-scac-number"
																	/>
																</span>
															</Col>
														</Row>


														<Row>
															<Col xs={12} md={4} style={itemNameStyle} >

																<span>
																	<h4 style={itemTxtStyle} className="small">
																		Address :
								          						</h4>
																</span>
															</Col>
															<Col xs={12} md={8} >
																<span>
																	<Field
																		name="address"
																		type="text"
																		id="addressTxt"
																		label="Address"
																		placeholder="Address"
																		style={{ fontSize: '15px' }}
																		component={BootstrapFieldInput}
																		uid="profile-edit-address"
																	/>
																</span>
															</Col>
														</Row>

														<Row>
															<Col xs={12} md={4} style={itemNameStyle} >

																<span>
																	<h4 style={itemTxtStyle} className="small">
																		City :
								          						</h4>
																</span>
															</Col>
															<Col xs={12} md={8} >
																<span>

																	<Field
																		name="city"
																		type="text"
																		id="cityTxt"
																		label="City"
																		placeholder="City"
																		style={{ fontSize: '15px' }}
																		component={BootstrapFieldInput}
																		uid="profile-edit-city"
																	/>
																</span>
															</Col>
														</Row>

														<Row>
															<Col xs={12} md={4} style={itemNameStyle} >

																<span>
																	<h4 style={itemTxtStyle} className="small">
																		State Code:
								          						</h4>
																</span>
															</Col>
															<Col xs={12} md={8} >
																<span>

																	<Field
																		name="state"
																		type="text"
																		id="stateTxt"
																		label="State"
																		placeholder="State"
																		style={{ fontSize: '15px' }}
																		component={BootstrapFieldInput}
																		uid="profile-edit-state-code"
																	/>

																</span>
															</Col>
														</Row>
														<Row>
															<Col xs={12} md={4} style={itemNameStyle} >

																<span>
																	<h4 style={itemTxtStyle} className="small">
																		Postal Code:
								          						</h4>
																</span>
															</Col>
															<Col xs={12} md={8} >
																<span>
																	<Field
																		name="zip"
																		type="text"
																		id="zipTxt"
																		label="Zip"
																		placeholder="Zip"
																		style={{ fontSize: '15px' }}
																		component={BootstrapFieldInput}
																		uid="profile-edit-postal-code"
																	/>
																</span>
															</Col>
														</Row>

													</Col>

												</Row>

											</div>


										</div>
									</Col>
								</Row>


							</div>
						</div>

					</Modal.Body>

					<Modal.Footer style={{ padding: '5px' }}>
						<div>
							<Button
								id='closeBtn'
								size="small"
								variant="contained"
								style={{
									backgroundColor: this.props.closeBtnColor,
									borderColor: this.props.closeBtnColor,
									color: "white",
									fontSize: 14,
									margin: 5
								}}
								onClick={() => this.props.onHide()}
							>
								Close
							</Button>
							<Button
								id='saveButton'
								type="submit"
								size="small"
								variant="contained"
								style={{
									backgroundColor: this.props.saveBtnColor,
									borderColor: this.props.saveBtnColor,
									color: "white",
									fontSize: 14,
									margin: 5
								}}
							>
								Save Changes
							</Button>
						</div>
						{/*<Button type="submit" bsStyle="warning" onClick={()=>{this.props.submit();this.props.onHide()}}>Save changes</Button>*/}
					</Modal.Footer>
				</Form>
			</Modal>


		)
	}

}



function mapStateToProps(state) {
	return {
	};
}



const myReduxForm = reduxForm({
	form: 'updateProfileForm', // required by reduxForm()
	enableReinitialize: true,
	destroyOnUnmount: true
})(profileUpdateForm)

export default connect(mapStateToProps)(myReduxForm);

