/* global $ */
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'

import Messages from '../../../../notifications/Messages'
import Errors from '../../../../notifications/Errors'

import { loginRequest } from '../../../../login/actions'
import FA from 'react-fontawesome'

import '../../../../login/assets/css/custom.css'
import '../../../../login/assets/css/style-new.css'
import '../../../../login/assets/css/google-font.css'
import '../../../../login/assets/css/js_composer_front_custom.css'

import muiThemeable from 'material-ui/styles/muiThemeable';

//HOC//
import Mobile from '../../../../HOC/Mobile'
import {useAuthenticationHook} from '../../../hooks/authentication-hook';
import GoogleAnalyticsManager from '../../../../ga-manager';

import loginLogo from '../theme/logo_dark.png'

const isMobile = window.innerWidth <= 500;


// If you were testing, you'd want to export this component
// so that you can test your custom made component and not
// test whether or not Redux and Redux Form are doing their jobs
class WorldwideExpressLogin extends Component {
    // Pass the correct proptypes in for validation
    static propTypes = {
        handleSubmit: PropTypes.func,
        isAuthenticated: PropTypes.func,
        loginRequest: PropTypes.func,
        login: PropTypes.shape({
            requesting: PropTypes.bool,
            successful: PropTypes.bool,
            messages: PropTypes.array,
            errors: PropTypes.array,
        }),

    }
    constructor(props) {
        super(props);
        this.state = {
            showMobileNav: false,
            headerClass: "",
            searchOpened: "",
            brokersClass: "",
            partnersClass: "",
            resourcesClass: "",
            aboutClass: "",
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.showSearch = this.showSearch.bind(this);
        this.timeoutId = 0;
        this.headerClass = "vc_row wpb_row vc_row-fluid vc_row-has-fill sc_layouts_row sc_layouts_row_type_normal sc_layouts_row_delimiter sc_layouts_row_fixed pad-5";
        this.searchOpenedPrimaryClass = "search_wrap search_style_fullscreen search_ajax layouts_search";
    }
    componentDidUpdate() {
        const myGoogleAnalyticsManager = new GoogleAnalyticsManager()
        const userObj = this.props.getUser()


        // first check if there is an existing session
        // get customerId from muiTheme
        let customerId = this.props.muiTheme.customerUniqueId
        if (this.props.isAuthenticated()) {
            let location = this.props.location.state ? JSON.parse(this.props.location.state) : false
            const from = location || { pathname: '/' }
            this.props.history.replace(from)
            // call google analytics to set user properties
            myGoogleAnalyticsManager.setUserProperties(userObj)
        }

        // if (!this.props.login.requesting && this.props.login.successful) {
        //     // user has already successfully logged in
        //     // get from prop off of props.location or default to dashboard
        //     const from = JSON.parse(this.props.location.state) || { pathname: '/carrierPortal/customer/' + customerId }
        //     this.props.history.push(from.pathname)
        //     // call google analytics to set user properties
        //     myGoogleAnalyticsManager.setUserProperties(userObj)
        // }
    }

    componentDidMount() {

        this.setState({ headerClass: this.headerClass })
        this.setState({ searchOpened: this.searchOpenedPrimaryClass });
        if (!isMobile) {
            window.addEventListener('scroll', this.handleScroll);
        }
    }
    handleScroll() {
        var scrollTop = window.pageYOffset;
        console.log(scrollTop);
        if (scrollTop > 60) {
            this.setState({ headerClass: this.headerClass + ' sc_layouts_row_fixed_on top-0' })
        }
        else {
            this.setState({ headerClass: this.headerClass })
        }

    }
    showHideNav(flag) {

        if (flag == true) {
            this.setState({ showMobileNav: false })
        }
        else {
            this.setState({ showMobileNav: true })
        }
        console.log(this.state.showMobileNav)
    }
    showSearch() {
        this.setState({ searchOpened: this.searchOpenedPrimaryClass + ' search_opened' });
    }
    handleMouseEnter(pmenuName) {
        console.log(pmenuName);
        this.setState({ [pmenuName + "UlClass"]: "sub-menu animated fast fadeInUpSmall toggleShow" })
    }
    handleMouseExit(pmenuName) {
        console.log(pmenuName);
        this.timeoutId = setTimeout(() => {
            this.setState({ [pmenuName + "UlClass"]: "sub-menu animated fast fadeOutDownSmall toggleHide" })
        }, 1000)

    }
    handleMouseEnterSubUl(pmenuName) {
        console.log(pmenuName);
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
        this.setState({ [pmenuName + "UlClass"]: "sub-menu animated fast fadeInUpSmall toggleShow" })
    }
    handleMouseExitSubUl(pmenuName) {
        console.log(pmenuName);
        this.setState({ [pmenuName + "UlClass"]: "sub-menu animated fast fadeOutDownSmall toggleHide" })
        this.timeoutId = null
    }

    submit = (values) => {
        this.props.submitGtagEvent()
        this.props.loginRequest(values)
        setTimeout(() => {
            const myAuthService = useAuthenticationHook()
            myAuthService.login()
        }, 1000)
    }

    render() {
        const {
            handleSubmit, // remember, Redux Form injects this into our props
            isAuthenticated,
            login: {
                requesting,
                successful,
                messages,
                errors,
            },
        } = this.props

        return (
            <div className="new_login_page">
                <div className="body_wrap" onScroll={this.handleScroll}>
                    <div className="page_content_wrap scheme_default">
                        <div className="content_wrap">
                            <div className="text-center">
                                <article className="post_item_single post_type_page post-2749 page type-page status-publish hentry">
                                    <div className="post_content entry-content">
                                        <div className="login-form-tt">
                                            <form className="pb-25 loginFormSc" onSubmit={handleSubmit(this.submit)} style={{ backgroundColor: "#fff" }}>
                                                {/* <h2 className="text-center">Smart Capacity</h2> */}
                                                <div style={{ marginBottom: 30 }}>
                                                    <img src={loginLogo} width={166} />
                                                </div>

                                                {!this.props.login.requesting && !!this.props.login.errors.length && (
                                                    <div className="form-group"><span style={{ color: 'rgb(183, 34, 37)', fontWeight: 'bold', fontSize: '14px', marginBottom: '11px' }}><Errors message="Failure to login due to:" errors={errors} /></span></div>
                                                )}
                                                {!this.props.login.requesting && !!this.props.login.messages.length && (
                                                    <div className="form-group"><span style={{ color: 'rgb(183, 34, 37)', fontWeight: 'bold', fontSize: '14px', marginBottom: '11px' }}><Messages messages={messages} /></span></div>
                                                )}

                                                <div className="form-group">
                                                    <Field
                                                        name="email"
                                                        type="text"
                                                        id="email"
                                                        className="form-control text-field"
                                                        label="Email"
                                                        component="input"
                                                        placeholder="Username"
                                                        style={{fontSize: 16}}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <Field
                                                        name="password"
                                                        type="password"
                                                        id="password"
                                                        className="form-control text-field"
                                                        label="Password"
                                                        component="input"
                                                        placeholder="Password"
                                                        style={{fontSize: 16}}
                                                    />
                                                </div>
                                                <div className="form-group login-bt">
                                                    <button
                                                        id="loginBtn"
                                                        type="submit"
                                                        class="btn btn-block  sc_button_hover_slide_left"
                                                        style={{ backgroundColor: this.props.muiTheme.actionColor }}
                                                    >Log in</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

// Grab only the piece of state we need
const mapStateToProps = state => ({
    login: state.login,
    mcBroker: state.mcBroker,
    integrationId: state.integrationId,
})

// make Redux state piece of `login` and our action `loginRequest`
// available in this.props within our component
const connected = connect(mapStateToProps, { loginRequest })(WorldwideExpressLogin)

// in our Redux's state, this form will be available in 'form.login'
const formed = reduxForm({
    form: 'login',
})(connected)



// Export our well formed login component
export default Mobile()(muiThemeable()(formed))
