import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { compose, withProps } from 'recompose'
import isMobile from '../../../../HOC/Mobile.js'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const LocationSelector = (props) => {
    const { muiTheme, isMobile, locationsList, selectedLocationId, setSelectedLocationId } = props

    const useStyles = makeStyles({
        formControl: {
            margin: 1,
            minWidth: 200,
            maxWidth: isMobile ? 250 : 500
        },
        menuItem: {
            fontSize: '16px',
            // width: 300
        },
        selectfieldStyleOutline: {
            backgroundColor: 'white',
            paddingTop: 8,
            paddingLeft: '10px',
            paddingRight: '0px',
            paddingBottom: '0px',
            fontSize: '14px',
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        capFirstLetter: {
            textTransform: 'capitalize'
        }
    });

    const classes = useStyles()

    const handleChange = (event) => {
        setSelectedLocationId(event.target.value)
    };

    const handleCaps = (textInput) => {
        if(textInput)
            return textInput.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    }

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <FormControl variant="filled" className={classes.formControl}>
                <InputLabel shrink id="select-filled-label" style={{ fontSize: 20}}>Location</InputLabel>
                <Select
                    className={classes.selectfieldStyleOutline}
                    labelId="select-filled-label"
                    id="select-filled"
                    value={selectedLocationId}
                    onChange={handleChange}
                    style={{ minWidth: 200}}
                    MenuProps={{
                        getContentAnchorEl: () => null,
                }}
                >

                    {locationsList.map((location, index) => {
                        return (
                            <MenuItem
                                key={location.addressId}
                                value={location.addressId}
                                className={classes.menuItem}
                                style={{paddingTop: 8, paddingBottom: 8}}
                            >
                                <div>
                                    <p style={{ fontSize: 16, fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} uid="location-dropdown-header" > 
                                        {handleCaps(location.addressType) + ( location.name ? ' - ' + handleCaps(location.name) : '') }
                                    </p>
                                    <p style={{ fontSize: 14, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} uid="location-dropdown-city-state-zip">
                                        { handleCaps(location.street) + ", " + handleCaps(location.city) + ", " + location.state + " " + location.zip + " " + location.country}
                                    </p>
                                </div>
                            </MenuItem>)
                    })}
                </Select>
            </FormControl>
        </div>
    )
}

const mapStateToProps = state => ({
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
    isMobile(),
    withProps(props => {

        return ({
            ...{

            },
            ...props
        })
    }),
)(LocationSelector)

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}