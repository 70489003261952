/*
* load objects for v2 return quote array, v4 returns negotations array.
* Need to accomodate for both!
*/
export const loadNegotiationsArray = (load) => {

    if(load){
        if(load.negotiations){
            // There is logic in place the handle the legacy quotes array to rever. TEMPORARY FIX until we get everyone on searchNearbyLoadsV4
            return JSON.parse(JSON.stringify(load.negotiations))
        }else if (load.quotes){
            return load.quotes.reverse() 
        }
    }
}