import React from 'react';
import { compose } from 'recompose'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { Row, Col, Grid } from 'react-bootstrap';
import ExistingCarrierForm from "./existing-carrier-form"
import FA from 'react-fontawesome'
import Button from '@material-ui/core/Button';
import {useAuthenticationHook} from '../../../../hooks/authentication-hook';
import isMobile from '../../../../../HOC/Mobile'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { isTablet } from 'react-device-detect';

import signUpLogoSvg from '../../theme/logo_dark.svg'
import signUpLogoPng from '../../theme/logo_dark.png'

const WernerSignupModalContent = (props) => {

    const classes = useStyles()
    const myAuthService = useAuthenticationHook();
    
    const { isMobile, muiTheme, showBackButton = true, showCloseButton = false, onBack=()=>{} } = props

    return (
        <div style={{height:'100%'}}>       
            {(!isMobile || isTablet ) &&
                <div style={{ width: 600 }}>
                    <Grid style={{ width: 600 }}>
                        <Row style={{ width: 600, height: 18 }}>
                            <IconButton
                                className={classes.closeIconButton} 
                                aria-label="close" 
                                style={{display: showCloseButton ? 'block' : 'none'}}
                                onClick={()=>onBack(true)}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Button
                                style={{
                                    backgroundColor: "transparent",
                                    color: muiTheme.actionColor,
                                    fontSize: 18,
                                    marginTop: 5,
                                    textTransform: 'none',
                                    display: showBackButton ? 'block' : 'none'
                                }}
                                onClick={() => onBack()}
                            >
                                <FA name={"chevron-left"} size='sm' color={muiTheme.actionColor} style={{ marginRight: 5 }} />
                            Back
                            </Button>
                        </Row>
                        <Row style={{ display: "flex", justifyContent: "center", width: 600 }}>
                            <img src={signUpLogoSvg} height={60} />
                        </Row>
                        <Row style={{ marginTop: 40, width: 600, height: 248 }}>
                            <Grid style={{ width: "100%", display: "flex" }}>
                                <Col style={{ alignItems: "center", width: 284, height: "100%" }}>
                                    <p className={classes.colTitle}>Add User</p>
                                    <p style={{ textAlign: "center" }}>For existing carrier</p>
                                    <ExistingCarrierForm {...props} />
                                </Col>
                                <div style={{width: 1, height: 'auto', marginLeft: 15, marginRight: 15, backgroundColor: muiTheme.ttUnselectGrey }}/>
                                <Col style={{ alignItems: "center", width: 284, height: "100%" }}>
                                    <p className={classes.colTitle}>New Carrier</p>
                                    <div style={{ display: "flex", justifyContent: "center", marginTop: 30, marginBottom: 15 }}>
                                        <a href={myAuthService.getSignupPath()} style={{ color: "#fff" }}>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                style={{
                                                    color: "white",
                                                    backgroundColor: muiTheme.actionColor,
                                                    borderColor: muiTheme.actionColor,
                                                    fontSize: 14,
                                                    textTransform: 'none'
                                                }}
                                                onClick={() => {
                                                }}
                                            >ENROLL with WERNER</Button>
                                        </a>
                                    </div>
                                    <p style={{ textAlign: "center", fontSize: 14, lineHeight: "16px" }}>You will be redirected to the RMIS website </p>
                                </Col>
                            </Grid>
                        </Row>
                        <Row style={{ display: "flex", justifyContent: "center", marginTop: 80, width: 600 }}>
                            <Grid style={{ justifyContent: "center", width: 600 }}>
                                <Row style={{ justifyContent: "center", width: 600 }}>
                                    <p style={{ textAlign: "center" }}>Please contact Werner</p>
                                </Row>
                                <Row style={{ justifyContent: "center", width: 600 }}>
                                    <a href={'tel:+8002282240,1004357'} style={{ color: muiTheme.actionColor, display: 'block', textAlign: "center" }}>800-228-2240 ext.1004357</a>
                                </Row>
                            </Grid>
                        </Row>
                    </Grid>
                </div>
            }

            { (isMobile && !isTablet) &&
                <div style={{height: '100%', width:'100%'}}>
                    <Button
                        style={{
                            backgroundColor: "transparent",
                            color: muiTheme.actionColor,
                            fontSize: 14,
                            textTransform: 'none',
                            display: showBackButton ? 'block' : 'none',
                            paddingTop: '15px',
                            position: 'inherit',
                            top: 0,
                            zIndex: 5
                            // position: 'absolute',
                            // left: -30,
                            // top:16
                        }}
                        onClick={() => onBack()}
                    >
                        <FA name={"chevron-left"} size='sm' color={muiTheme.actionColor} style={{ marginRight: 5 }} />
                        Back
                    </Button>   
                    <Grid>                               
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <img src={signUpLogoPng} style={{height:60}} />
                        </div>                 
                    </Grid>                      
                    <Grid style={{width:'95% !important', paddingTop: 10,marginTop:16, marginBottom:16, paddingBottom: 16}}>
                        <Row>        
                            <div> 
                                <p className={classes.colTitle} >For Existing Carrier</p>
                                <p style={{ textAlign: "center" }}>Add User</p>
                                <ExistingCarrierForm {...props} />
                            </div>

                            <hr style={{borderTop: '1px solid rgba(0, 0, 0, 0.3)', width:'75%'}}/>

                            <div >
                                <p className={classes.colTitle}>For New Carrier</p>
                                <div style={{ display: "flex", justifyContent: "center",paddingBottom: 16, paddingTop: 11}}>
                                    <a href={myAuthService.getSignupPath()} style={{ color: "#fff" }}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            style={{
                                                color: "white",
                                                backgroundColor: muiTheme.actionColor,
                                                borderColor: muiTheme.actionColor,
                                                fontSize: 14,
                                                textTransform: 'none',
                                            }}
                                            onClick={() => {
                                            }}
                                        >ENROLL with WERNER</Button>
                                    </a>    
                                </div>
                                <p style={{ textAlign: "center", fontSize: 14}}>You will be redirected to the RMIS website </p>
                            </div>
                        </Row>  
                    </Grid>
                    <Grid style={{display:'flex', flexDirection:'column', justifyContent:'center', paddingBottom: 16}}>
                        <Row >
                            <p style={{ textAlign: "center" }}>Please contact Werner</p>
                        </Row>
                        <Row>
                            <a href={'tel:+8002282240,1004357'} style={{ color: muiTheme.actionColor, display: 'block', textAlign: "center" }}>800-228-2240 ext.1004357</a>
                        </Row>
                    </Grid>
                </div>
            }
        </div>
    )
}

export default compose(
    muiThemeable(),
    isMobile()
)(WernerSignupModalContent)

const useStyles = makeStyles((theme) => ({
    container: {
        height: window.innerHeight,
        width: window.width
    },
    colTitle: {
        textAlign: "center",
        color: "black",
        fontWeight: 'bold',
        fontSize: 16,
        marginBottom: '5px'
    },
    closeIconButton: {
        marginBottom: '-20px',
        float: 'right',
        '& .MuiSvgIcon-root':{
            fontSize: '2.5rem'
        }     
    },
}));