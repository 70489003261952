import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import "../../styleSheets/loadStyles.css"
import RateNegotiations from '../load-list/quotes/RateNegotiations';
import { cardStyle } from "../../../lib/generalStyles"
import TargetLoadDetails from './targetLoadDetails';
import { validateUserRoleBinPermissions } from '../../helpers/permissions';
import { useAuthenticationHook } from '../../hooks/authentication-hook';
import QuoteActions from '../load-list/quotes/actionButtons';

const TargetLoad = (props) => {

    const {
        load,
        broker,
        offerThread,
        pickup,
        delivery,
        availability,
        updateOfferHistoryList,
        isLoadingOfferHistory,
        autoBinForm,
        goToFindAndBook,
        muiTheme,
        
    } = props

    const useStyles = makeStyles({
        rootContainer: {
            display: 'flex',
            flex: 1,
            overflowY: 'auto',
            overflowX: 'hidden'
        },
        column: {
            display: 'flex',
            flex: 1,
            height: 'min-content',
            flexDirection: 'column',
            padding: 16,
        },
        binQuoteContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 16
        },
        quoteHistoryContainer: {
            flex: 1,
            ...cardStyle,
            padding: 0,
            marginBottom: 0
        }
    });
    const classes = useStyles()
    const myAuthService = useAuthenticationHook()
    const user = myAuthService.getUser()

  return (
        <div className={classes.rootContainer} style={{ flexDirection: 'column' }}>

            <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>

                <div className={classes.column} style={{ paddingRight: 8 }}>

                    <TargetLoadDetails load={load} muiTheme={muiTheme} />

                </div>

                {validateUserRoleBinPermissions(user) &&
                    <div className={classes.column} style={{ paddingLeft: 8 }}>
                        <div className={classes.binQuoteContainer} >
                            <QuoteActions
                                load={load}
                                pickup={pickup}
                                delivery={delivery}
                                offerThread={offerThread?.loadOfferNegotiations ?? null}
                                availability={availability}
                                updateOfferHistoryList={updateOfferHistoryList}
                                autoBinForm={autoBinForm}
                                muiTheme={muiTheme}
                                cardStyle={cardStyle}
                            />
                        </div>
                        {!isLoadingOfferHistory &&
                            <div className={classes.quoteHistoryContainer}>
                                <RateNegotiations
                                    load={load}
                                    pickup={pickup}
                                    delivery={delivery}
                                    availability={availability}
                                    broker={broker}
                                    offerThread={offerThread}
                                    onTargetLoadScreen
                                    removeLoadFromList={() => { }}
                                    callRefreshCard={() => { }}
                                    isLoadingOfferHistory={isLoadingOfferHistory}
                                    {...props}
                                />
                            </div>
                        }
                    </div>
                }
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 24, position: 'sticky', bottom: 0 }}>
                <Button
                    aria-describedby={"go_back"}
                    variant={"outlined"}
                    onClick={() => goToFindAndBook()}
                    style={{
                        fontSize: 16,
                        color: muiTheme.actionColor,
                        backgroundColor: 'white',
                        borderColor: muiTheme.actionColor,
                        border: '1px solid',
                        margin: 5,
                    }}
                    size={"medium"}
                    disableRipple={false}
                >
                    See Similar Loads
                </Button>
                {process.env.REACT_APP_ADVANTAGE_ID === "2zr5ys19" &&
                    <Button
                        aria-describedby={"go_back"}
                        variant={"outlined"}
                        onClick={() => goToFindAndBook(load.fullLoadDetails.broker.id, true)}
                        style={{
                            fontSize: 16,
                            color: muiTheme.lightContrastText,
                            backgroundColor: muiTheme.actionColor,
                            borderColor: muiTheme.actionColor,
                            border: '1px solid',
                            margin: 5,
                        }}
                        size={"medium"}
                        disableRipple={false}
                    >
                        See Loads From This Broker
                    </Button>
                }
            </div>
        </div>
    )
}


export default TargetLoad