import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { responsibilityHideBin } from '../../../../../lib/responsibility-code-functions';
import { validLoadDetails } from '../../../../../lib/valid-load-details';
import BinButton from '../../bin/binButton';

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';

import CounterOfferForm from './counterOfferForm';

const QuoteActionsMobile = (props) => {

    const {
        load,
        offerThread,
        latestBinAmount,
        pickup,
        delivery,
        availability,
        submitCounterOffer = () => { },
        //state variables
        showBinBtn,
        useShowInterest,
        //other
        isSaving,
        userProfile,
        muiTheme
    } = props

    const classes = useStyles();

    const [showPopover, setShowPopover] = useState(false)
    const [counterAnchorEl, setCounterAnchorEl] = useState(null)

    let customStyle = {
            color: showBinBtn ?  muiTheme.actionColor : "white",
            backgroundColor: showBinBtn ? 'transparent' : muiTheme.actionColor,
            borderColor: showBinBtn ? 'none' : muiTheme.actionColor,
            borderRadius: showBinBtn ? 'unset' : 15,
            textDecoration: showBinBtn ? 'underline' : 'none',
            fontSize: 14,
           
        }

    return (

        <div className={classes.buttonContainer}>

            <div onClick={(event) => event.stopPropagation()} style={{ display: 'flex', justifyContent:'center'}}>

                <Button
                    key={"counter_offer_btn"}
                    id='counterOfferBtn'
                    size="small"
                    style={customStyle}
                    disabled={isSaving}
                    onClick={(event) => {
                        event.stopPropagation()
                        setCounterAnchorEl(event.currentTarget)
                        setShowPopover(true)
                    }}
                >
                    Counter
                </Button>
                <Popover
                    id="counter_offer_popover"
                    open={showPopover}
                    anchorEl={counterAnchorEl}
                    onClose={() => { setCounterAnchorEl(null); setShowPopover(false) }}
                    PaperProps={{ style: { width: '100%', padding: 0 } }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                >
                    <div style={{ padding: 16, maxHeight: 800 }}>

                        <CounterOfferForm
                            load={load}
                            offer={Array.isArray(offerThread?.loadOfferNegotiations) ? offerThread?.loadOfferNegotiations[0]?.loadOffer : null} //latest offer
                            submitCounterOffer={submitCounterOffer}
                            useShowInterest={useShowInterest}
                            _onClose={() => { setCounterAnchorEl(null); setShowPopover(false) }}
                        />

                    </div>
                </Popover>
            </div>

            {showBinBtn && validLoadDetails(load, ["weight"]) &&
                <BinButton
                    key={"BIN_button"}
                    carrierId={availability?.carrier_id}
                    availabilityId={availability?.id}
                    load={load}
                    index={0}
                    pickup={pickup}
                    delivery={delivery}
                    quoteValue={latestBinAmount} //rename quoteValue to binVlaue
                    onQuotesTab
                    customStyle={{ borderRadius: 15 }}
                    {...props}
                />
            }

        </div>

    )

}

const mapStateToProps = state => ({
    availability: state.AvailabilityLoads.payload.availability,
    userProfile: state.Profile.profileData,
    isSaving: state.QuoteReducer.post.requesting,

})


export default compose(
    connect(mapStateToProps),
    muiThemeable()
)(QuoteActionsMobile)

const useStyles = makeStyles({
    button: {
        fontSize: 14,
        color: "white",
        margin: 5
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        height: '100%',
        justifyContent: 'center'
    }
});