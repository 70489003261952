import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'recompose';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
//COMPONENTS//
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import HelpWrapper from '../../HelpWrapper'
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
//ACTIONS//
import {useAuthenticationHook} from '../../../hooks/authentication-hook';


const BACKEND_URL = `${process.env.REACT_APP_SC_URL}`; // move this to call to sagas file

const TruckTypeSelect = ({
  //PROPS FROM PARENT//
  value = [], showErrorOnEmpty = true,
  hintText = 'Pick any truck types you are interested in ',
  floatingLabelText = 'Truck Types',
  needsRedux = true,
  handleSetTruckType,
  clearData,
  existingTruckTypes = null,
  //REDUX//
  selectedTruckTypes,
  updateSearchbox,
  //STATE
  //truckTypesList,
  //OTHER
  muiTheme,
  mobileView = false
}) => {

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.08,
      margin: 10
    },
    wrapper: {
      display: 'contents',
      fontSize: '16px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    placeholder: {
      color: '#757575',
    },
    menuItem: {
      fontSize: '16px'
    },
    selectfieldStyle: {
      border: '1px solid #ccc',
      height: '45px',
      width: '100%',
      maxWidth: mobileView ? null : '200px',
      minWidth: '200px',
      margin: '0px',
      overflow: 'visible',
      backgroundColor: 'white',
      // outline: '1px solid black',
      paddingLeft: '10px',
      fontSize: '16px',
      shadowRadius: 5,
      shadowColor: 'black',
      shadowOpacity: 0.1,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    icon: {
      position: 'absolute',
      fontSize: '2.5rem',
      textAlign: 'right'
    },
    chips: {
      fontSize: '12px'
    },
    removePadding: {
      paddingRight: '0 !important'
    }
  });
  const classes = useStyles();

  const [hasError, setErrors] = useState(false);
  const [truckTypes, setTruckTypes] = useState([]);
  const [finalSelectedTypesList, setFinalSelectedTypesList] = useState([]); // Array of selected truck types
  const [menuOpen, setMenuOpen] = useState(false);
  const isMounted = useRef(false);

  const myAuthService = useAuthenticationHook()

  useEffect(() => {
    getTruckTypes();

    if (existingTruckTypes) {
      let tmpList = existingTruckTypes.map(type => { return type.code })
      setFinalSelectedTypesList(tmpList)
    }
    return () => {
      setTruckTypes([]); // NOTE: Issue when using this clean up function
    }
  }, []);

  useEffect(() => {
    if (existingTruckTypes) {
      let tmpList = existingTruckTypes.map(type => { return type.code })
      setFinalSelectedTypesList(tmpList)
    }
  }, [existingTruckTypes]);

  useEffect(() => {
    if (isMounted.current) {
      setFinalSelectedTypesList([])
      handleSetTruckType([])
    } else {
      isMounted.current = true
    }

  }, [clearData])

  async function getTruckTypes() {
    // TODO move this to sagas
    /*
    let url = '/api/getTruckCodes'
    var requestUrl = BACKEND_URL + url
    let requestObject = {
      method: 'GET',
    }
    // let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

    const res = await fetch(requestUrl, requestObject);

    res.json().then(response => {
      if (response.status) {
        let truckTypesRes = response.truckCodes
        setTruckTypes(truckTypesRes);
      }
    }).catch(error => setErrors(error));
    */
    let condensedTruckTypeList = [
                                    {name: "Van", code: "V"},
                                    {name: "Reefer", code: "R"},
                                    {name: "Flatbed", code: "F"},
                                    {name: "Auto Carrier", code: "AC"},
                                    {name: "Power Only", code: "PO"},
                                    {name: "Other", code: "O"}
                                  ]
    setTruckTypes(condensedTruckTypeList);
  }


  function handleChange(event) {

    // 'value' here represents the truck type ids selected from dropdown as in the value of the event target
    const { value } = event.target;
    let chosenTruckTypes = value

    finalSelectedTypesList.forEach(type => {
      if (type.code != null)
        chosenTruckTypes.push(type.code)
    })

    // Below can be used in case truck type names are needed
    let truckTypesSelected = truckTypes.filter((type) => {
      return chosenTruckTypes.indexOf(type.code) >= 0
    })

    let finalSelection = []
    truckTypesSelected.forEach(type => {
      if (type.code != null)
        finalSelection.push(type.code)
    })
    setFinalSelectedTypesList(finalSelection);
    //if (!needsRedux) { handleSetTruckType(truckTypesSelected) }
    handleSetTruckType(truckTypesSelected)

  };

  function renderSelectedTruckTypes(selected) {

    let result = getTypeNames(selected)

    return (
      (selected.length === 0) ?
        <div className={classes.placeholder}>Select Equipment</div> :
        // <div className={classes.wrapper}>
        //   {result}
        // </div>
        result.split(", ").map((value) => (
          <Chip 
            variant="outlined" 
            key={value}
            label={value}
            className={classes.chips}
          />
        ))  
    )
  }

  // Takes in array of trucktype ids and returns comma separate string of trucktype names
  function idsToNames(ids) {
    let truckTypeArr = truckTypes.filter((type) => ids.includes(type.truckTypeId));

    let truckTypeNamesArr = truckTypeArr.map(type => type.name)

    if (truckTypeNamesArr) {
      return truckTypeNamesArr.join(",");
    }
    return null;
  }

  function getTypeNames(types) {

    let tmpList = []
    types.forEach(type => {
      if (type['code'] != null)
        tmpList.push(type.code)
      else if (typeof type === 'string')
        tmpList.push(type)
    })

    let truckTypeArr = truckTypes.filter((type) => tmpList.includes(type.code));

    let truckTypeNamesArr = truckTypeArr.map(type => type.name)
    if (truckTypeNamesArr) {
      return truckTypeNamesArr.join(", ");
    }
    return null;
  }
 
  return (
    <HelpWrapper text='Select your equipment types so we can find loads that match. (Click anywhere outside once finished)' location='bottom' zIndex={1350} enabled={!menuOpen} isMultiSelect={true}>
      { truckTypes &&
        <FormControl variant="filled" className={classes.formControl} fullWidth required >
        <InputLabel style={{fontSize:16}} id="location">Select Equipment</InputLabel>
        <Select
          //className={classes.selectfieldStyle}
          labelId="select-label"
          multiple
          //disableUnderline={true}
          //autoWidth={false}
          //displayEmpty
          //input={<Input id="truck-type" />}
          value={finalSelectedTypesList}
          onChange={(event) => handleChange(event)}
          renderValue={(selected) => renderSelectedTruckTypes(selected)}
          inputProps={{
            classes: {
              // icon: classes.icon,
              // root: classes.removePadding
              root: classes.menuItem
            }
          }}
          MenuProps={{
                    getContentAnchorEl: () => null,
          }}
          // MenuProps={{
          //   disableScrollLock: true,
          //   getContentAnchorEl: null,
          //   anchorOrigin: {
          //     vertical: "bottom",
          //     horizontal: "left",
          //   }
          // }}
          onOpen={() => setMenuOpen(true)}
          onClose={() => setMenuOpen(false)}
        >
          {truckTypes && truckTypes.map((type) => {
            return (
              <MenuItem
                key={type.code}
                className={classes.menuItem}
                value={type.code}
              >
                <Checkbox style={{color:muiTheme.secondary}} checked={finalSelectedTypesList.indexOf(type.code) > -1} />
                {type.name}
              </MenuItem>
            )
          })}
        </Select>
        </FormControl>
      }
    </HelpWrapper>
  );
}

// TruckTypeSelect.propTypes={
//   //label:PropTypes.string.isRequired
// }

const mapStateToProps = state => ({
  selectedTruckTypes: state.CarrierPortal.components.loadSearchBar.truckTypes,
})

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

export default compose(
  muiThemeable(),
  connect(mapStateToProps, matchDispatchToProps),
)(TruckTypeSelect)
