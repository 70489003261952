import React, { useState } from 'react';
import { compose } from 'recompose'
import {
    // isAndroid,
    isIOS
} from "react-device-detect"

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Button from '@material-ui/core/Button';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
// import ListSubheader from '@material-ui/core/ListSubheader';
// import IconButton from '@material-ui/core/IconButton';
// import InfoIcon from '@material-ui/icons/Info';

import MobileShortcutsScreen from './mobile-shorcuts-screen'
import LocationSettingsScreen from './location-settings-screen'

import useGtagHook from '../../../gtag-hook'

import mobileShortcutMenuImgIos from '../../assets/mobile-shortcuts/menu-cover-image.png'
import mobileShortcutMenuImgAndroid from '../../assets/mobile-shortcuts/menu-cover-image.png'
import locationSettingsMenuImgIos from '../../assets/location-settings/menu-cover-image.png'
import locationSettingsMenuImgAndroid from '../../assets/location-settings/menu-cover-image.png'

const HowToScreen = (props) => {

    const { muiTheme } = props

    const useStyles = makeStyles((theme) => ({
        container: {
            height: '100%',
            width: '100%',
            paddingTop: 4
        },
        title: {
            textAlign: "left",
            color: "black"
        },
        instruction: {
            textAlign: "left",
            marginTop: 25,
            color: "black"
        },
        textLink: {
            color: muiTheme.actionColor
        },
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
        },
        gridList: {
            width: "100%",
        },
        icon: {
            color: 'rgba(255, 255, 255, 0.54)',
        },
        title: {
            fontSize: 16,
        },
        titleBar: {
            background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
        }
    }));
    const classes = useStyles()

    const itemData = [
        { key: "mobile_shortcuts", img_ios: mobileShortcutMenuImgIos, img_android: mobileShortcutMenuImgAndroid, title: "Mobile Shortcuts", description: "" },
        { key: "location_settings", img_ios: locationSettingsMenuImgIos, img_android: locationSettingsMenuImgAndroid, title: "Location Settings", description: "" }
    ]

    const [showingTableOfContents, setShowingTableOfContents] = useState(true)
    const [showMobileShortcut, setShowMobileShortcut] = useState(false)
    const [showLocationSetup, setShowLocationSetup] = useState(false)

    const [sendGtagEvent] = useGtagHook()

    const handleBackClick = () => {
        setShowMobileShortcut(false);
        setShowLocationSetup(false);
        setShowingTableOfContents(true);
    }

    const handleItemClick = (key) => {
        switch (key) {
            case "mobile_shortcuts":
                setShowingTableOfContents(false);
                setShowMobileShortcut(true);
                sendGtagEvent("view_how_to_guide", {
                    guideType: "mobile_shortcuts"
                })
                break;
            case "location_settings":
                setShowingTableOfContents(false);
                setShowLocationSetup(true);
                sendGtagEvent("view_how_to_guide", {
                    guideType: "location_settings"
                })
                break;
            default:
                setShowMobileShortcut(false);
                setShowLocationSetup(false);
                setShowingTableOfContents(true);
                break;
        }
    }

    return (
        <div className={classes.container}>
            <div style={{ display: showingTableOfContents ? 'none' : 'block', height: showingTableOfContents ? '0%' : '5%' }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        left: 10,
                        backgroundColor: muiTheme.actionColor
                    }}
                    onClick={() => handleBackClick()}
                >
                    Back
                </Button>
            </div>
            <div style={{ height: showingTableOfContents ? '100%' : '95%' }}>
                {showingTableOfContents && (
                    <div className={classes.root}>
                        <GridList cellHeight={250} className={classes.gridList} cols={1}>
                            {/* <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                                <ListSubheader component="div">December</ListSubheader>
                            </GridListTile> */}
                            {itemData.map((item) => (
                                <GridListTile key={item.key}>
                                    {isIOS ? (
                                        <img src={item.img_ios} alt={item.title} onClick={() => handleItemClick(item.key)} />
                                    ) : (
                                        <img src={item.img_android} alt={item.title} onClick={() => handleItemClick(item.key)} />
                                    )}
                                    <GridListTileBar
                                        title={item.title}
                                        classes={{
                                            root: classes.titleBar,
                                            title: classes.title,
                                        }}
                                    // subtitle={<span>by: {item.description}</span>}
                                    // actionIcon={
                                    //     <IconButton aria-label={`info about ${item.title}`} className={classes.icon}>
                                    //         <InfoIcon />
                                    //     </IconButton>
                                    // }
                                    />
                                </GridListTile>
                            ))}
                        </GridList>
                    </div>
                )}
                {showMobileShortcut && (
                    <MobileShortcutsScreen />
                )}
                {showLocationSetup && (
                    <LocationSettingsScreen />
                )}
            </div>
        </div >
    )
}

export default compose(
    muiThemeable(),
)(HowToScreen)