import {  findLatestBinOffer } from '../CarrierPortal/components/load-list/quotes/actionButtons/quoteHelperFunctions'
import {responsibilityHideBin} from './responsibility-code-functions.js'

const validateLoadCarrierBrokerBinPermissions = (load) => {
    //Returns validation for carrier/load relation as well as broker/load relation for bookitnow
    // Also checks fullLoadDetails for target load page 
    return  (
        (load?.brokerHasBookItNow == 1 && !load.isPreviewLoad && load.hasBookItNow == 1 && load.showBookItNowButton == true) || 
        (load?.fullLoadDetails?.brokerHasBookItNow == 1 && load.fullLoadDetails.hasBookItNow == 1 && load.fullLoadDetails.showBookItNowButton == true) 
    )
}

const validateBrokerBinPermissions = (load) => {
    // Returns validation for broker/load relation ONLY
    // Also checks fullLoadDetails for target load page 
    return (
        (load?.brokerHasBookItNow == 1 || load?.fullLoadDetails?.brokerHasBookItNow == 1)
    )
}

export const showBIN = (load, offerThread, userProfile) => {
    if( load == null ) return false

    let quoteList = offerThread ? offerThread : JSON.parse(JSON.stringify(load.quotes ? load.quotes : []))
    let latestBinOffer = findLatestBinOffer(quoteList, load) 

    //CRST responsibility codd check - FLATBED does not want bin ever
    if(responsibilityHideBin(load, userProfile)){
        return false
    }
    // Case for rate negotioations, latest OFFER from BROKER or latest ACCEPT OFFER from BROKER will become new BIN price and it'll surpass Carrier BIN permissions. 
    // Broker Permissions still need to be accounted for
    if (validateBrokerBinPermissions(load) && latestBinOffer?.amount && parseFloat(latestBinOffer.amount) > 0 && !latestBinOffer.isOriginalBinPrice){
        return true
    }
    //Ryder MS wants to hide the default BIN button because they send strange target rates for GET
    else if (process.env.REACT_APP_ADVANTAGE_ID === 'srukfk5e' && (load.revenueCode === "GET" || load.fullLoadDetails?.revenueCode === "GET")){
        return false
    }

    //General case for loads/brokers on the site to display BIN or not
    else if (validateLoadCarrierBrokerBinPermissions(load)){
        return true
    }

    return false
}