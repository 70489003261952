import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { compose, withProps } from 'recompose'
import { Row, Col } from 'react-bootstrap';
import FA from 'react-fontawesome'
import CarrierLoadList from '../components/load-list'
import { toast } from 'react-toastify';
import moment from 'moment'
import FilterMenu from "../components/filter-menu/index"
import * as BookedLoadsApi from '../apis/api-booked-loads'
import LoadingOverlay from '../../components/LoadingOverlay'
import useGtagHook from '../../gtag-hook';
import isMobile from '../../HOC/Mobile.js'

const BookedLoadsScreen = ({ loads, availability, muiTheme, isMobile }) => {
    const { height, width } = useWindowDimensions();

    const useStyles = makeStyles({
        container: {
            padding: 5,
            height: height,
            width: '100%'
        },
        badge: {
            height: 20,
            width: 20,
            borderRadius: 50,
            display: "flex",
            justifyContent: "center",
            backgroundColor: muiTheme.secondary
        },
        breadcrumbLink: {
            cursor: 'pointer',
            marginRight: 15,
            color: "black", // TODO: change??
            "&:hover": {
                color: muiTheme.actionColor,
                textDecoration: "underline"
            }
        },
        activeBreadcrumbLink: {
            color: muiTheme.primary,
            fontWeight: 'bold',
            textDecoration: "underline",
            "&:hover": {
                color: muiTheme.primary,
            }
        },
        secondaryText: {
            fontFamily: 'Arial !important',
            fontSize: '14px',
            color: 'grey'
        },
    });

    const classes = useStyles()
    const [sendGtagEvent] = useGtagHook()

    const [loadsStack, setLoadsStack] = useState([])
    const [currentLoadList, setCurrentLoadList] = useState([])
    const [reloadCrumbs, setReloadCrumbs] = useState([])
    const [reloadsOpen, setReloadsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const FILTER_PADDING_TOP = 83

    useEffect(() => {

        let params = {
            advantageId: muiTheme.customerUniqueId
        }

        // make api call to fetch recent activity
        BookedLoadsApi.FetchBookedLoads(params).then(response => {
            if (response.status) {
                if (response.loads && response.loads.length > 0) {
                    let loadList = sortMostRecentBooked(response.loads)
                    setLoadsStack([loadList])
                    setCurrentLoadList(loadList)
                }
                setIsLoading(false)
                sendGtagEvent(
                    "search_results_returned",
                    {
                        screen: "booked_loads",
                        listLength: response.loads.length,
                        type: "loads",
                    }
                )
            } else {
                let errorMessage = "Error: There was a problem fetching your booked loads."
                // setLoadsStack([])
                // setCurrentLoadList([])
                setIsLoading(false)
                toast.error(response.message ? response.message : errorMessage)
            }
        }).catch((error) => {
            let errorMessage = "Error: There was a problem fetching your booked loads."
            // setLoadsStack([])
            // setCurrentLoadList([])
            setIsLoading(false)
            toast.error(errorMessage)
        })
    }, [])

    useEffect(() => {
        if (reloadCrumbs.length <= 0)
            setReloadsOpen(false)
        else
            setReloadsOpen(true)
    }, [reloadCrumbs])

    useEffect(() => {
        if (loadsStack[loadsStack.length - 1] != null)
            setCurrentLoadList(loadsStack[loadsStack.length - 1])
    }, [loadsStack])

    const pushReloads = (parentLoad, reloads) => {
        //todo: push another param for date besides relying on scheduledAt to be in stops
        let tmpLoadOrigin = {
            ...parentLoad.stops[0],
            date: parentLoad.pickupDateTimeLatest ? parentLoad.pickupDateTimeLatest : parentLoad.pickupDateTime
        }
        let tmpLoadDestination = {
            ...parentLoad.stops[parentLoad.stops.length - 1],
            date: parentLoad.deliveryDateTime
        }
        if (reloadCrumbs.length <= 0) {
            setReloadCrumbs([
                tmpLoadOrigin,
                tmpLoadDestination
            ])
        } else {
            setReloadCrumbs([
                ...reloadCrumbs,
                tmpLoadDestination
            ])
        }
        setLoadsStack([
            ...loadsStack,
            reloads
        ])
        sendGtagEvent(
            "search_results_returned",
            {
                screen: "booked_loads",
                listLength: reloads.length,
                type: "reloads"
            }
        )
    }

    const popToIndex = (index) => {
        let newLoadsStack = loadsStack.slice(0, index + 1)
        let newReloadCrumbs = reloadCrumbs.slice(0, index + 1)
        setLoadsStack(newLoadsStack)
        if (index === 0)
            setReloadCrumbs([])
        else
            setReloadCrumbs(newReloadCrumbs)
    }

    const sortMostRecentBooked = (loadList = []) => {
        loadList.sort((a, b) => {
            let aTime = moment(a.activities[0].created_at).valueOf()
            let bTime = moment(b.activities[0].created_at).valueOf()
            if (aTime < bTime)
                return 1
            else if (aTime > bTime)
                return -1
            else
                return 0
        })
        return loadList
    }

    return (
        <div style={{height: '100%'}}>
            {isLoading && <LoadingOverlay />}
            { !isMobile &&
                <div className={classes.container}>

                    <Row style={{ height: '100%' }}>
                        {reloadsOpen &&
                            <Col md={2} style={{ paddingTop: FILTER_PADDING_TOP, backgroundColor: 'white', height: '100%' }}>
                                <FilterMenu loadList={currentLoadList} paddingTop={FILTER_PADDING_TOP} />
                            </Col>
                        }
                        <Col md={reloadsOpen ? 10 : 12}>
                            <Row style={{ width: "100%", marginTop: 15, marginBottom: 0 }}>
                                <h2 style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 15, }}>Your Booked Loads</h2>
                            </Row>
                            {reloadsOpen &&
                                <Row>
                                    <div style={{ paddingLeft: 15, paddingTop: 25 }}>
                                        {
                                            reloadCrumbs.map((crumb, index) => {
                                                return (
                                                    <span>

                                                        { (index < reloadCrumbs.length - 1) &&
                                                            <span>
                                                                <div style={{ display: 'inline-block' }}>
                                                                    <span className={classes.breadcrumbLink} onClick={() => popToIndex(index)}>
                                                                        {crumb.city + ', ' + crumb.state}
                                                                    </span>
                                                                    <p className={classes.secondaryText}>
                                                                        {moment(crumb.date).format('MM/DD')}
                                                                    </p>
                                                                </div>
                                                                <FA name='chevron-right' size="lg" color={"black"} style={{ marginRight: 15, verticalAlign: 'top' }} />

                                                            </span>
                                                        }

                                                        { index === reloadCrumbs.length - 1 &&

                                                            <div style={{ display: 'inline-block' }}>
                                                                <span className={classes.activeBreadcrumbLink} onClick={() => popToIndex(index)}>
                                                                    {crumb.city + ', ' + crumb.state}
                                                                </span>
                                                                <p className={classes.secondaryText}>
                                                                    {moment(crumb.date).format('MM/DD')}
                                                                </p>
                                                            </div>


                                                        }
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                </Row>
                            }

                            {(!isLoading && currentLoadList && currentLoadList.length > 0) ?
                                <Row>
                                    <CarrierLoadList
                                        unfilterLoadList={currentLoadList}
                                        availability={availability}
                                        pushReloads={pushReloads}
                                        isBooked={true}
                                        reloadsOpen={reloadsOpen}
                                        isBookedLoads={true}
                                    />
                                </Row>
                                :
                                <div style={{ paddingTop: 50 }}>
                                    <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                        No Booked Loads Found
                                </p>
                                </div>
                            }

                        </Col>
                    </Row>

                </div>
            }


            {isMobile &&
                <div style={{height: '100%'}}>

                    {(!isLoading && currentLoadList && currentLoadList.length > 0) ?
                        <Row style={{height: '100%'}}>
                            <CarrierLoadList
                                unfilterLoadList={currentLoadList}
                                availability={availability}
                                isRecentActivity={false}
                                isMobile={true}
                                isBookedLoads={true}
                            />
                        </Row>
                        :
                        <div style={{ paddingTop: 50 }}>
                            <p style={{ width: '100%', textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
                                No Booked Loads
                      </p>
                        </div>
                    }
                </div>
            }

        </div>
    )
}

const mapStateToProps = state => ({
    loads: state.AvailabilityLoads.payload.loads,
    availability: state.AvailabilityLoads.payload.availability,
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
    isMobile(),
    withProps(props => {

        return ({
            ...{

            },
            ...props
        })
    }),
)(BookedLoadsScreen)

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}