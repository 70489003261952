
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import Button from '@material-ui/core/Button';
import FA from 'react-fontawesome'
import Popover from '@material-ui/core/Popover';
import GoogleMapReact from 'google-map-react';
import MapRoute from '../../../../../load-list/maproute'
import { Col, Row } from "react-bootstrap"
import numeral from 'numeral'
import ContentTabs from './content-tabs'

import TAMapPin from '../../../../fuel-stop-icons/ta-map-pin.svg'
import LovesMapPin from '../../../../fuel-stop-icons/loves-map-pin.svg'
import defaultMapPin from '../../../../fuel-stop-icons/default-map-pin.svg'

const useStyles = makeStyles({
    card: {
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08
    },
    button: {
        backgroundColor: 'white',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5

    }
});

const LoadDetailsMobileContent = (props) => {

    const {
        item,
        searchLocation,
        muiTheme,
    } = props

    const classes = useStyles();

    const [mapLoaded, setMapLoaded] = useState(false)
    const [map, updateMap] = useState(null)
    const [maps, updateMaps] = useState(null)
    const [processOnProgress, updateProcessOnProgress] = useState(false)
    const [notRendered, updateNotRendered] = useState(true)
    const [delay, updateForceDelay] = useState(false)
    const [googleMapsDuration, updateGoogleMapsDuration] = useState('')
    const [googleMapsDistance, updateGoogleMapsDistance] = useState('')
    const [showMapRouteError, setShowMapRouteError] = useState(false)

    const origin = {
        lat: parseFloat(searchLocation.lat),
        lon: parseFloat(searchLocation.lon ? searchLocation.lon : searchLocation.lng),
        city: searchLocation.city,
        state: searchLocation.state,
        label: 'stop0'
    }

    const dest = {
        lat: parseFloat(item.latitude),
        lon: parseFloat(item.longitude),
        city: item.city,
        state: item.state,
        label: 'stop1'
    }

    const getImgSrc = () => {
        switch (item.franchise_name) {
            case "TA":
                return TAMapPin
            case "Loves":
                return LovesMapPin
            default:
                return defaultMapPin
        }
    }

    const showGoogleMapsInfo = (totalDistance, totalDuration) => {

        totalDistance = totalDistance * 0.000621371192;
        totalDistance = Math.round(totalDistance * 100) / 100
        var d = totalDuration
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        totalDuration = hDisplay + mDisplay //+ sDisplay;

        /*console.log('Directions totalDistance',totalDistance);
        console.log('Directions totalDuration',totalDuration);*/

        updateGoogleMapsDistance(totalDistance)
        updateGoogleMapsDuration(totalDuration)
        updateNotRendered(false)
        updateForceDelay(false)
        setShowMapRouteError(false)
    }

    const removeImportedFont = () => {
        var elements = document.querySelectorAll('link[href*="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Google+Sans:400,500,700"]')

        if (elements.length > 0)
            elements[0].parentNode.removeChild(elements[0]);
    }

    function contains(selector, text) {
        var elements = document.querySelectorAll(selector);
        return [].filter.call(elements, function (element) {
            return RegExp(text).test(element.innerHTML);
        });
    }

    const removeUnwantedSpan = () => {
        setTimeout(
            () => {
                let spanArray = contains('span', 'BESbswy')
                if (spanArray && spanArray.length > 0)
                    spanArray[0].parentNode.removeChild(spanArray[0]);
            }
            , 3000)
    }

    const getCustomMarker = () => {
        
        map.markers = [];
            let markerDest = new maps.Marker({
                position: new maps.LatLng(dest.lat,dest.lon),
                map: map,
                icon: {
                    url: getImgSrc(),
                    scaledSize: new maps.Size(50, 50)
                },
                zIndex: 10
            });
            let markerOrigin = new maps.Marker({
                position: new maps.LatLng(origin.lat,origin.lon),
                map: map,
                zIndex: 5
            });
            map.markers.push(markerOrigin);
            map.markers.push(markerDest);

    }

    return (
        <div style={{ height: '100%' }}>
            <div style={{ position: 'relative', height: '25%', width: "100%", border: '1px solid grey' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API }}
                    center={{ lat: dest.lat, lng: dest.lon }}
                    defaultZoom={8}
                    onGoogleApiLoaded={({ map, maps }) => {
                        updateMap(map);
                        updateMaps(maps);
                        setMapLoaded(true);
                    }}
                    yesIWantToUseGoogleMapApiInternals={true}
                    options={{ fullscreenControl: false, zoomControl: false }}
                >
                </GoogleMapReact>

                {mapLoaded && !processOnProgress && notRendered &&
                    <div style={{ display: 'contents' }}>
                        <MapRoute
                            delay={delay ? delay : false}
                            processStart={() => updateProcessOnProgress(true)}
                            processEnd={() => { updateProcessOnProgress(false); updateForceDelay(false); removeImportedFont(); removeUnwantedSpan(); getCustomMarker() }}
                            showMapsInfo={(distance, duration) => showGoogleMapsInfo(distance, duration)}
                            map={map}
                            maps={maps}
                            origin={origin}
                            destination={dest}
                            stops={[origin, dest]}
                            setShowMapRouteError={setShowMapRouteError}
                            isFuelStopsScreen={true}
                        />
                    </div>
                }


                {/* <div style={{ position: 'absolute', top: 1, right: 1, backgroundfontSize: 12, backgroundColor: '#FFF', fontWeight: 200, textAlign: 'right' }} >
                    {googleMapsDistance && googleMapsDistance !== '' && (<div style={{ padding: 1 }} > {googleMapsDistance} mi</div>)}
                    {googleMapsDuration && googleMapsDuration !== '' && (<div style={{ padding: 1 }} > {googleMapsDuration} </div>)}
                    {processOnProgress && (<div style={{ padding: 1 }} > looking for direction ... </div>)}
                </div> */}
                <div style={{ position: 'absolute', top: 1, right: 1, fontWeight: 'bold', textAlign: 'center', backgroundColor: '#FFF', opacity: '75%', color: 'red' }} >
                    {showMapRouteError && (<div style={{ padding: 1 }} >Failed to find the route for this load</div>)}
                </div>
            </div>
            <div style={{height: '75%'}}>
            <ContentTabs
                item={item}
            />
            </div>
            

        </div>
    );
}


export default compose(
    connect(),
    muiThemeable(),
)(LoadDetailsMobileContent)
