import { isExpired, decodeToken } from "react-jwt";

export function BaseAuthService(){
    return {
        isAuthenticated() {
            // attempt to grab the token from localstorage
            const storedToken = localStorage.getItem('token')

            // if it exists
            if (storedToken) {
                // parse it down into an object
                const token = JSON.parse(storedToken)

                if (token.id) {
                    // const myDecodedToken = decodeToken(token.id);
                    const isMyTokenExpired = isExpired(token.id);
                    // console.log("myDecodedToken: ", myDecodedToken)
                    // console.log("isMyTokenExpired: ", isMyTokenExpired)
                    if (!isMyTokenExpired) {
                        return true
                    }
                }else {
                    localStorage.removeItem('token');
                }
            }

            return false
        },
        getUser() {
            // return {userId, role, userType, username, name, carrier { companyName, mc, dot, externalId, carrierId, contact: { email, phone, ext}}
            // get token from local storage
            // pull out fields from token
            // return user object
            let token = localStorage.getItem('token')
            const parsedToken = JSON.parse(token)
            let userObj = null
            if (parsedToken != null) {
                userObj = {
                    customerAccountId: parsedToken.customerAccountId,
                    userId: parsedToken.userId,
                    role: parsedToken.role,
                    userType: parsedToken.userType,
                    username: parsedToken.email,
                    fullName: parsedToken.name,
                    phoneNumber: parsedToken.phoneNumber,
                    carrier: {
                        companyName: parsedToken.companyName,
                        mc: parsedToken.mcNumber,
                        dot: parsedToken.dotNumber,
                        externalId: parsedToken.CarrierExternalId,
                        carrierId: parsedToken.carrierId,
                        contact: {
                            email: parsedToken.contactEmail,
                            phone: parsedToken.contactPhone,
                            // ext:
                        }
                    }
                }
            }
            return userObj
        },
        onBeforeApiCall(requestObj) {
            // check if authenticated
            // if yes
            //      add header to requestObj with bearer token
            if (this.isAuthenticated()) {
                let headers = requestObj.headers
                let newHeaders = {}
                if (headers !== null && typeof headers !== undefined) {
                    newHeaders = {
                        ...headers,
                        'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id
                    }
                } else {
                    newHeaders = {
                        'Authorization': 'bearer ' + JSON.parse(localStorage.getItem('token')).id
                    }
                }
                requestObj.headers = newHeaders
            }

            return requestObj
        },
        async login(token) {
            if(!token) {
                let storedToken = localStorage.getItem('token')
                if (storedToken) {
                    const parsedToken = JSON.parse(storedToken)
                    let promise = new Promise((resolve, reject) => {
                        parsedToken.createdTimestampSec = Math.round(new Date().getTime() / 1000);
                        localStorage.setItem('token', JSON.stringify(parsedToken));
                        let userObj = this.getUser()
                        var event = new CustomEvent('login')
                        window.dispatchEvent(event)
                        resolve(userObj)
                    })
                    let returnPromise = await promise
                    return returnPromise
                }
            } else {
                let promise = new Promise((resolve, reject) => {
                    token.createdTimestampSec = Math.round(new Date().getTime() / 1000);
                    localStorage.setItem('token', JSON.stringify(token));
                    let userObj = this.getUser()
                    var event = new CustomEvent('login')
                    window.dispatchEvent(event)
                    resolve(userObj)
                })
                let returnPromise = await promise
                return returnPromise
            }
        },
        logout(callback) {
            localStorage.removeItem('token');

            var event = new CustomEvent('logout')
            window.dispatchEvent(event)

            try {
                setTimeout(callback, 10);
            } catch (e) {
                console.log(e);
            }
        },
        getSignupPath() {
            return "https://www.truckertools.com"
        }
    }

}