/* global $ */
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import '../../../../login/assets/css/custom.css'
import '../../../../login/assets/css/style-new.css'
import '../../../../login/assets/css/google-font.css'
import '../../../../login/assets/css/js_composer_front_custom.css'

import { toast } from 'react-toastify';

// import { useOktaAuth } from '@okta/okta-react';
import * as OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import {useAuthenticationHook} from "../../../hooks/authentication-hook"
import GoogleAnalyticsManager from "../../../../ga-manager"
import MultiCarrierSelect from "./multi-carrier-select"

import loginLogo from '../theme/logo_dark.svg'
import loginBackgroundImg from '../theme/werner-zoom-america.jpg'

//HOC//
import isMobile from '../../../../HOC/Mobile'

const COLLAPSED_MENU_WIDTH = 60
const WIDGET_WIDTH = 398

// If you were testing, you'd want to export this component
// so that you can test your custom made component and not
// test whether or not Redux and Redux Form are doing their jobs
const WernerLogin = (props) => {

    let tokensRef = useRef(null)
    let widgetRef = useRef(null)

    const classes = useStyles();

    const { height, width } = useWindowDimensions();

    const [showCarrierSelect, setShowCarrierSelect] = useState(false)
    const [multiCarrierList, setMultiCarrierList] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const myAuthService = useAuthenticationHook();

    useEffect(() => {
       // Note: user propr noot being set for existing smart capacity
        const myGoogleAnalyticsManager = new GoogleAnalyticsManager()
        const userObj = myAuthService.getUser()

        // first check if there is an existing session
        // get customerId from muiTheme

        if (myAuthService.isAuthenticated()) {
            let location = props.location.state ? JSON.parse(props.location.state) : false
            const from = location || { pathname: '/' }
            props.history.replace(from)
            myGoogleAnalyticsManager.setUserProperties(userObj)
            window.location.reload(false)
        }
    })

    const renderWidget = (widget) => {
        if (widget && widget != null && typeof widget != "undefined" && widget.remove) {
            widget.remove()
        }
        widget.renderEl({
            el: '#widget-container'
        }, function success(res) {
            if (res.status === 'SUCCESS') {
                // oktaAuth.handleLoginRedirect(tokens);
                props.submitGtagEvent()
                tokensRef.current = res.tokens
                myAuthService.login(res.tokens)
                    .then((resp) => {
                        if (resp.hasMultiCarriers) {
                            setShowCarrierSelect(true)
                            setMultiCarrierList(resp.carriers)
                            widget.remove()
                            return
                        }
                        // console.log("login success: ", user)
                        // --- do not push to history until promise is resolved
                        if (widget && widget != null && typeof widget != "undefined" && widget.remove) {
                            widget.remove()
                        }
                        let location = props.location.state ? JSON.parse(props.location.state) : false
                        const from = location || { pathname: '/' }
                        props.history.replace(from)
                        // return value
                    }).catch((e) => {
                        // console.log("login error: ", e)
                        //handle error - maybe show a toast
                        toast.error(e.message)
                        widget.remove()
                        renderWidget(widget)
                    })
            } else {
                // The user can be in another authentication state that requires further action.
                // For more information about these states, see:
                //   https://github.com/okta/okta-signin-widget#rendereloptions-success-error
            }
        });

    }

    const multiCarrierLogin = (selectedCarrier) => {
        setIsLoading(true)
        if (tokensRef.current !== null) {
            let payload = {
                tokens: tokensRef.current,
                selectedCarrier: selectedCarrier
            }

            myAuthService.loginMultiCarrier(payload)
                .then((resp) => {

                    // console.log("login success: ", user)
                    // --- do not push to history until promise is resolved
                    setIsLoading(false)
                    if (widgetRef.current && widgetRef.current != null && typeof widgetRef.current != "undefined" && widgetRef.current.remove) {
                        widgetRef.current.remove()
                    }
                    let location = props.location.state ? JSON.parse(props.location.state) : false
                    const from = location || { pathname: '/' }
                    props.history.replace(from)
                }).catch((e) => {
                    setIsLoading(false)
                    toast.error(e.message)
                    closeMultiSelect()
                })

        } else {
            setIsLoading(false)
            toast.error("Error: Login unsuccessful, please try again...")
            closeMultiSelect()
        }

    }

    const closeMultiSelect = () => {
        setShowCarrierSelect(false)
        const widget = createWidget()
        renderWidget(widget)
    }

    const createWidget = () => {
        // console.log("oktaAuth: ", oktaAuth)
        const widget = new OktaSignIn({
            /**
             * Note: when using the Sign-In Widget for an OIDC flow, it still
             * needs to be configured with the base URL for your Okta Org. Here
             * we derive it from the given issuer for convenience.
             */
            baseUrl: process.env.REACT_APP_OKTA_BASE_URL,
            clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
            // baseUrl: "https://dev-6163546.okta.com",
            // clientId: "0oa18gh9aqn6Io7qY5d6",
            redirectUri: window.location.origin + "/login/callback",
            logo: loginLogo,
            i18n: {
                en: {
                    "primaryauth.title": "Sign in to Werner EDGE for Carriers",
                },
            },
            authParams: {
                // To avoid redirect do not set "pkce" or "display" here. OKTA-335945
                issuer: process.env.REACT_APP_OKTA_ISSUER,
                // issuer: "https://dev-6163546.okta.com/oauth2/aus2kqgha3IuZTbZi5d6", // "https://dev-6163546.okta.com/oauth2/default",
                scopes: ["openid","offline_access"],
                disableHttpsCheck: process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false,
                response_type: ["id_token"], // newly added
            },
        });
        widgetRef.current = widget
        return widget

    }

    useEffect(() => {

        const widget = createWidget()
        renderWidget(widget)

    }, [])

    const getLeftAlignment = () => {
        const leftValue = props.isMobile ? ((width - WIDGET_WIDTH) / 2) : ((width - WIDGET_WIDTH - COLLAPSED_MENU_WIDTH) / 2)

        return leftValue <= 15 ? 0 : leftValue
    }

    return (
        <div style={{ width: props.isMobile ? width : width - COLLAPSED_MENU_WIDTH , height: '100%', position: "relative" }}>
            <img src={loginBackgroundImg} width={ props.isMobile ? width : width - COLLAPSED_MENU_WIDTH} height={'100%'} style={{ position: "absolute" }} />
            {
                showCarrierSelect &&
                <MultiCarrierSelect onBack={closeMultiSelect} carriers={multiCarrierList} login={multiCarrierLogin} loading={isLoading} />
            }
            <div id="widget-container" className={classes.oktaWidget} style={{ position: getLeftAlignment() <= 15 ? "unset" : "absolute", zIndex: 3, left: getLeftAlignment(), top: 100 }} />
        </div>
    )
}


// Grab only the piece of state we need
const mapStateToProps = state => ({
})

// make Redux state piece of `login` and our action `loginRequest`
// available in props within our component
const connected = connect(mapStateToProps)(WernerLogin)

// Export our well formed login component
export default isMobile()(muiThemeable()(connected))




function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

const useStyles = makeStyles({
    oktaWidget: {
      '& #okta-sign-in .auth-org-logo' : {
        maxHeight: 66
      }
    }
  });
