
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import { Card } from 'material-ui/Card';
import CardContent from '@material-ui/core/CardContent';
import LoadCardMobile from './LoadCardMobile'
import moment from 'moment'
import { checkValueExists } from "../../../../lib/check-value-exists"
import isMobile from '../../../../HOC/Mobile.js'
import ActiveLoadCardMobile from './my-loads-card/ActiveLoadCardMobile'
import HistoricalLoadCardMobile from './my-loads-card/HistoricalLoadCardMobile';
import { canShowBinTradeMark } from '../../../helpers/permissions';




const useStyles = makeStyles({
    card: {
        shadowRadius: 5,
        shadowColor: 'black',
        shadowOpacity: 0.08
    },
    button: {
        backgroundColor: 'white',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5

    }
});

const LoadListItemMobile = (props) => {

    const {
        load,
        availability,
        index,
        isBookedLoads = false,
        isRecentActivity = false,
        isMyLoadsScreen = false,
        myLoadsRenderedTab = 0,
        pushReloads,
        reloadsOpen,
        isMobile = false,
        removeLoadFromList,
        isHome = false,
        
    } = props

    const classes = useStyles();

    const [pickup, setPickup] = useState(null)
    const [delivery, setDelivery] = useState(null)
    const [openLoadDetails, setOpenLoadDetails] = useState(false)
    const [loadDetailsRenderedTab, setloadDetailsRenderedTab] = useState(0)
    const [refreshCard, setRefreshCard] = useState(false)
    const [key, setKey] = useState(props.key)

    useEffect(() => {
        buildPickupDeliveryData()

    }, [])

    const callRefreshCard = () => {
        setRefreshCard(!refreshCard)
    }

    const buildPickupDeliveryData = () => {
        let tmpPickup = {}
        let tmpDelivery = {}

        if (load.stops) {

            tmpPickup = {
                ...load.stops[0],
                scheduledAt: load.stops[0].scheduledAt ? load.stops[0].scheduledAt : load.stops[0].scheduled_at
            }

            tmpDelivery = {
                ...load.stops[load.stops.length - 1],
                scheduledAt: load.stops[load.stops.length - 1].scheduledAt ? load.stops[load.stops.length - 1].scheduledAt : load.stops[load.stops.length - 1].scheduled_at
            }

        }
        else {

            tmpPickup = {
                city: load.start_city,
                state: load.start_state,
                scheduledAt: load.start_time
            }

            tmpDelivery = {
                city: load.end_city,
                state: load.end_state,
                scheduledAt: load.end_time
            }

        }

        setPickup(tmpPickup)
        setDelivery(tmpDelivery)
    }

    const getCardHeaderText = () => {

        let localTimeFromNow = moment.tz(load.activities[0].created_at, "America/New_York").local().fromNow()

        if (load.activities == null)
            return ""

        switch (load.activities[0].action_name) {
            case 'LOAD_VIEW':
                return "Viewed " + localTimeFromNow
            case 'LOAD_VIEW_SHOW_INTEREST':
                return "Showed interest " + localTimeFromNow
            case 'PHONE_CLICK':
                return "Contacted the broker " + localTimeFromNow
            case 'QUOTE':
                return "Quoted " + localTimeFromNow
            case 'BOOK_IT_NOW':
                if (canShowBinTradeMark()) {
                    return "Opened Book-It-Now\xAE form " + localTimeFromNow
                } else {
                    return "Opened Book-It-Now form " + localTimeFromNow
                }
            case 'BookItNow':
                if (checkValueExists(load, "covered") && load.covered == 1) {
                    if (checkValueExists(load, "selfCovered") && load.selfCovered == 1) {
                        return "Covered by you " + localTimeFromNow
                    } else {
                        return "Covered by someone else " + localTimeFromNow
                    }
                } else {
                    return "Viewed " + localTimeFromNow
                }
            default:
                return "";
        }

    }

    const showCardHeader = () => {
        if (isRecentActivity && !reloadsOpen)
            return true
        // if (isMyLoadsScreen && myLoadsRenderedTab === 0 && !reloadsOpen)
        //     return true
        if (isBookedLoads && !reloadsOpen)
            return true
        return false
    }

    return (
        <div>
            <div>
                {pickup && delivery &&

                    <div style={{ marginBottom: 15 }}>
                        {showCardHeader() &&
                            <div style={{ paddingRight: 25, paddingTop: 10, textAlign: 'right' }}>
                                <p style={{ marginBottom: 0, fontWeight: 'bold', display: 'inline-block' }}>
                                    {getCardHeaderText()}
                                </p>
                                {(load.targetPay && isMyLoadsScreen) || (load.targetPay && isBookedLoads) && (
                                    <p style={{ fontWeight: 'bold', display: 'inline' }}>
                                        {" for "}
                                        <span style={{ color: 'green' }}>
                                            {"$" + parseFloat(load.targetPay).toFixed(2)}
                                        </span>
                                    </p>
                                )}
                            </div>
                        }
                        <Card className={classes.card} style={{ borderRadius: 15 }}>
                            <CardContent style={{ padding: 0 }}>

                                {isMyLoadsScreen ?
                                    myLoadsRenderedTab == 0 ?
                                        <ActiveLoadCardMobile
                                            {...props}
                                            key={refreshCard}
                                            load={load}
                                            index={index}
                                            pickup={pickup}
                                            delivery={delivery}
                                            availability={availability}
                                            pushReloads={pushReloads}
                                            reloadsOpen={reloadsOpen}
                                            isExpanded={false}
                                
                                        // carrierCommunications={carrierCommunications}
                                        />
                                        :
                                        <HistoricalLoadCardMobile
                                            {...props}
                                            key={refreshCard}
                                            load={load}
                                            index={index}
                                            pickup={pickup}
                                            delivery={delivery}
                                            availability={availability}
                                            pushReloads={pushReloads}
                                            reloadsOpen={reloadsOpen}
                                
                                        />
                                    :
                                    <LoadCardMobile
                                        {...props}
                                        key={refreshCard}
                                        load={load}
                                        index={index}
                                        pickup={pickup}
                                        delivery={delivery}
                                        availability={availability}
                                        pushReloads={pushReloads}
                                        reloadsOpen={reloadsOpen}
                                        removeLoadFromList={removeLoadFromList}
                                        isBookedLoads={isBookedLoads}
                                        callRefreshCard={callRefreshCard}
                                        isHome={isHome}
                            
                                    />

                                }


                            </CardContent>

                        </Card>
                    </div>
                }
            </div>
        </div>
    );
}


export default compose(
    connect(),
    muiThemeable(),
    isMobile()
)(LoadListItemMobile)