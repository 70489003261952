import React, { useState } from 'react';
import { compose } from 'recompose'
import { useAuth0 } from "@auth0/auth0-react";

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import TextField from '@material-ui/core/TextField';
import FA from 'react-fontawesome'
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom'

import { newUserSignupSubmit } from "../../apis/api-signup"
import { receiveUserSignupNotification, canShowUserManagement } from "../../helpers/permissions"
import FooterText from '../modal-footer-text';
import { useAuthenticationHook } from '../../hooks/authentication-hook';


const SignupModalContent = (props) => {

    const { muiTheme, onBack, userEmail = "", onProfileScreen = false, existingData = {}, isExistingUser = false, isMobile = false } = props

    const { loginWithRedirect, } = useAuth0();

    const useStyles = makeStyles((theme) => ({
        modalContentStyling: {
            display: "flex",
            flexDirection: 'column',
            maxHeight: isMobile ? '100%' : 650,
            width: isMobile ? 'unset' : 550,
            backgroundColor: "#fff",
            border: '2px solid #000',
            padding: 20,
            overflow: "auto"
        },
        cardConent: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: 20
        },
        contentBlock: {
            width: 225
        },
        titleText: {
            fontSize: 24,
            fontWeight: 'bold'
        }
    }));

    const classes = useStyles()

    const myAuthService = useAuthenticationHook()
    const user = myAuthService.getUser()

    const [companyName, setCompanyName] = useState("")
    const [MC, setMC] = useState("")
    const [DOT, setDOT] = useState("")
    const [contactEmail, setContactEmail] = useState(userEmail)
    const [contactPhone, setContactPhone] = useState(((onProfileScreen || isExistingUser) && existingData.phoneNumber) ? existingData.phoneNumber : "")
    const [firstName, setFirstName] = useState(((onProfileScreen || isExistingUser) && existingData.firstName) ? existingData.firstName : "")
    const [lastName, setLastName] = useState(((onProfileScreen || isExistingUser) && existingData.lastName) ? existingData.lastName : "")
    const [numOfTrucks, setNumOfTrucks] = useState("")
    const [password, setPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const submitForm = () => {
        setIsLoading(true)
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        if (((MC == null || MC === "") || isNaN(MC)) && (DOT == null || DOT === "") || isNaN(DOT)) {
            toast.error("A Valid MC or DOT Number is required.")
            setIsLoading(false)
            return
        }
        else if (companyName == null || companyName === "") {
            toast.error("Company Name is required.")
            setIsLoading(false)
            return
        }
        else if (contactEmail == null || contactEmail === "" || !validateEmail(contactEmail)) {
            toast.error("Please enter a valid email address")
            setIsLoading(false)
            return
        }
        else if (!(onProfileScreen || isExistingUser) && (password == null || password === "")) {
            toast.error("Password is required.")
            setIsLoading(false)
            return
        }
        else if (!(onProfileScreen || isExistingUser) && (!password.match(strongRegex))) {
            toast.error("Password is too weak.")
            setIsLoading(false)
            return
        }
        let payload = {
            source: process.env.REACT_APP_ADVANTAGE_ID,
            // source: "testDispatcher",
            carrier: {
                companyName: companyName,
                truckCount: numOfTrucks === "" ? null : numOfTrucks,
            },
            user: {
                email: contactEmail,
                firstName: firstName,
                lastName: lastName,
                password: password
            }
        }

        if (contactPhone !== "") {
            payload.user.phoneNumber = contactPhone
        }
        if (MC !== "") {
            payload.carrier.mcNumber = "MC" + MC
        }
        if (DOT !== "") {
            payload.carrier.dotNumber = DOT
        }

        if (receiveUserSignupNotification()) {
            payload.advantageId = process.env.REACT_APP_ADVANTAGE_ID
        }

        // console.log("payload: ", payload)
        newUserSignupSubmit(payload).then(response => {
            // console.log("response: ", response)
            if (response.status) {
                // show toast and redirect to login
                if (!(onProfileScreen || isExistingUser)) {
                    toast.success(response.message + "Please remain on the page while we redirect you to login.")
                    setTimeout(() => {
                        if (process.env.REACT_APP_USE_AUTH0 === "true") {
                            loginWithRedirect()
                            setIsLoading(false)
                        } else {
                            window.location = "/login"
                            setIsLoading(false)
                        }
                    }, 6000);
                } else {
                    toast.success(response.message + "Please log out, then log back in and select new carrier.")
                    onBack()
                    setIsLoading(false)
                }

            } else {
                toast.error("Error: " + response.message)
                setIsLoading(false)
            }
        }).catch((error) => {
            // console.log("Error: ", error)
            toast.error("Error: ", error)
            setIsLoading(false)
        })
        //todo: json.stringify the payload before sending
        //todo
        //email the payload to JV, Jess, Murali, TT Support & Raymond from Reed
        // toast.success("You can expect an email containing your login credentials.")

    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    return (
        <div className={classes.modalContentStyling}>
            <Button
                style={{
                    backgroundColor: "transparent",
                    color: muiTheme.actionColor,
                    fontSize: 14,
                    display: 'flex',
                    alignSelf: 'flex-start'
                }}
                onClick={() => onBack()}
            >
                <FA name={"chevron-left"} size='sm' color={muiTheme.actionColor} style={{ marginRight: 5 }} />
                Back
            </Button>

            <div style={{ display: 'flex', alignSelf: 'center', paddingBottom: 24 }}>
                <p className={classes.titleText}>Sign Up</p>
            </div>

            <div style={{ alignSelf: 'center', paddingBottom: 24 }}>
                <p style={{ fontSize: 14, fontWeight: 600, paddingLeft: 10, paddingRight: 10 }}>
                    Complete this form to search for loads and manage digital transactions as a new carrier.
                </p>
                {onProfileScreen && user && user.role === "admin" && canShowUserManagement() &&
                    <p style={{ fontSize: 14, fontWeight: 600, paddingLeft: 10, paddingRight: 10 }}>
                        {/* If you are looking to invite a new user to your carrier network, <a style={{ color: muiTheme.actionColor, cursor: "pointer" }}>click here</a>. */}
                        If you are looking to invite a new user to your carrier network,{' '}
                        <Link
                            to={{
                                pathname: "/userManagement",
                                advantageId: muiTheme.customerUniqueId,
                                state: { pathname: "/profile", inviteUser: true }
                            }}
                            style={{ color: muiTheme.actionColor }}
                        >
                            click here
                        </Link>.
                    </p>
                }
                {/* <p style={{ fontStyle: 'italic', fontSize: 14, fontWeight: 600, paddingLeft: 10, paddingRight: 10 }}>
                    *Sign up <span style={{ fontWeight: 900 }}>or</span> speak to one of our dedicated support team members to learn more.
                </p> */}
            </div>

            <div className={classes.cardConent} style={{ paddingBottom: 32 }}>


                {!onProfileScreen && (
                    <div className={classes.contentBlock}>
                        <TextField
                            label={<p className={classes.secondaryText} style={{ color: companyName == "" ? muiTheme.ttRed : "grey" }}>First Name <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>}
                            inputProps={{
                                style: { fontSize: 16 }
                            }}
                            InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                            fullWidth
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                )}
                
                {!onProfileScreen && (
                    <div className={classes.contentBlock}>
                        <TextField
                            label={<p className={classes.secondaryText} style={{ color: companyName == "" ? muiTheme.ttRed : "grey" }}>Last Name <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>}
                            inputProps={{
                                style: { fontSize: 16 }
                            }}
                            InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                            fullWidth
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                )}

                <div className={classes.contentBlock}>
                    <TextField
                        label="Email"
                        inputProps={{
                            style: { fontSize: 16 }
                        }}
                        InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                        fullWidth
                        disabled={(onProfileScreen || isExistingUser)}
                        value={contactEmail}
                        onChange={(e) => setContactEmail(e.target.value)}
                        required
                    />
                </div>     

                {!onProfileScreen && (
                    <div className={classes.contentBlock}>
                        <TextField
                            label="Phone"
                            inputProps={{
                                style: { fontSize: 16 }
                            }}
                            InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                            fullWidth
                            value={contactPhone}
                            onChange={(e) => setContactPhone(e.target.value)}
                        />
                    </div> 
                )}

                <div className={classes.contentBlock}>
                    <TextField
                        label={<p className={classes.secondaryText} style={{ color: companyName == "" ? muiTheme.ttRed : "grey" }}>Company Name <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>}
                        inputProps={{
                            style: { fontSize: 16 }
                        }}
                        InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                        fullWidth
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                    />
                </div>


                <div className={classes.contentBlock}>
                    <TextField
                        label={<p className={classes.secondaryText} style={{ color: (MC == "" && DOT == "") ? muiTheme.ttRed : "grey" }}>MC Number</p>}
                        inputProps={{
                            style: { fontSize: 16 }
                        }}
                        InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                        fullWidth
                        value={MC}
                        onChange={(e) => setMC(e.target.value)}
                    />
                </div>
                <div className={classes.contentBlock}>
                    <TextField
                        label={<p className={classes.secondaryText} style={{ color: (MC == "" && DOT == "") ? muiTheme.ttRed : "grey" }}>DOT Number</p>}
                        inputProps={{
                            style: { fontSize: 16 }
                        }}
                        InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                        fullWidth
                        value={DOT}
                        onChange={(e) => setDOT(e.target.value)}
                    />
                </div>

                {!(onProfileScreen || isExistingUser) && (
                    <div className={classes.contentBlock}>
                        <TextField
                            label={<p className={classes.secondaryText} style={{ color: password == "" ? muiTheme.ttRed : "grey" }}>Password <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>}
                            inputProps={{
                                style: { fontSize: 16 }
                            }}
                            InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            FormHelperTextProps={{ style: { fontSize: 11 } }}
                            helperText={"Password must contain one lower case letter (a-z), " +
                                "one upper case letter (A-Z), " +
                                "one number (0-9), " +
                                "one special character (!@#$%^&*.) " +
                                "and must be at least 8 characters in length."}
                        />
                    </div>
                )}
                <div className={classes.contentBlock}>
                    <TextField
                        label="Number of Trucks"
                        inputProps={{
                            style: { fontSize: 16 }
                        }}
                        InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                        fullWidth
                        value={numOfTrucks}
                        onChange={(e) => setNumOfTrucks(e.target.value)}
                    />
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', gap: 16, paddingBottom: 24 }}>
                <Button
                    variant={"outlined"}
                    size={"small"}
                    style={{ fontSize: 14, color: muiTheme.actionColor, backgroundColor: "white", borderColor: muiTheme.actionColor }}
                    onClick={() => onBack()}
                >
                    Cancel
                </Button>


                <Button
                    variant={"contained"}
                    size={"small"}
                    style={{ fontSize: 14, color: "white", backgroundColor: isLoading ? muiTheme.ttGrey : muiTheme.actionColor, borderColor: isLoading ? muiTheme.ttGrey : muiTheme.actionColor }}
                    onClick={() => submitForm()}
                    disabled={isLoading}
                >
                    Submit Form
                </Button>
            </div>

            <FooterText
                advantageId={process.env.REACT_APP_ADVANTAGE_ID}
                providedText={"*Sign up or speak to one of our dedicated support team members to learn more."}
                textStyle={{ fontStyle: 'italic', fontSize: 12, marginBottom: 16 }}
            />

        </div>
    )

}


export default compose(
    muiThemeable(),
)(SignupModalContent)