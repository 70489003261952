export const checkValueExists = (data, key) => {
    if (Array.isArray(data)) {
        if (data.length > 0) {
            if (data.indexOf(key) > -1) {
                if (data[data.indexOf(key)] == null) {
                    return false
                } else if (data[data.indexOf(key)] == false) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        } else {
            return false
        }

    } else {
        // console.log("here", data[key])
        if (typeof data[key] == "undefined") {
            return false
        } else if (data[key] == null) {
            return false
        } else if (data[key] === false) {
            return false
        } else if (data[key] === "") {
            return false
        } else if (data[key] === "NULL") {
            return false 
        } else {
            return true
        }
    }


}