export const createLocationsPayloadV4 = (searchBarValues, target, useDefault = false) => {
    let locationArray = []
    if (target == 'origin') {
        if (useDefault) {
            // return locationArray
            return (
                {
                    type: "address",
                    city: "",
                    state: "",
                    country: "United States",
                    latitude: 37.09024,
                    longitude: -95.712891
                }
            )
        }
        switch (searchBarValues.originSearchType) {
            case 'default':
                locationArray.push(
                    {
                        type: "address",
                        city: searchBarValues.city,
                        state: searchBarValues.state,
                        country: searchBarValues.country,
                        latitude: searchBarValues.lat,
                        longitude: searchBarValues.lon
                    }
                )
                return locationArray
            case 'state':
                if (searchBarValues.lane_origins && searchBarValues.lane_origins.length !== 0) {
                    searchBarValues.lane_origins.forEach((originObject) => {
                        locationArray.push({
                            type: "state",
                            state: originObject.abbr,
                            country: "US"
                        })
                    })
                }
                return locationArray
            case 'zone':
                if (searchBarValues.lane_origins && searchBarValues.lane_origins.length !== 0) {
                    searchBarValues.lane_origins.forEach((originObject) => {
                        locationArray.push({
                            type: "zone",
                            code: originObject.id,
                        })
                    })
                }
                return locationArray
            case 'market':
                if (searchBarValues.lane_origins && searchBarValues.lane_origins.length !== 0) {
                    searchBarValues.lane_origins.forEach((originObject) => {
                        locationArray.push({
                            type: "market",
                            code: originObject.id,
                        })
                    })
                }
                return locationArray
            case 'anywhere':
                /* Return empty array for anywhere!*/
                return locationArray
            default:
                return false
        }
    } else if (target == 'destination') {
        if (useDefault) {
            return locationArray
        }
        switch (searchBarValues.destinationSearchType) {
            case 'default':
                locationArray.push(
                    {
                        type: "address",
                        city: searchBarValues.cityDestination,
                        state: searchBarValues.stateDestination,
                        country: searchBarValues.countryDestination,
                        latitude: searchBarValues.latDestination,
                        longitude: searchBarValues.lonDestination
                    }
                )
                return locationArray
            case 'state':
                if (searchBarValues.lane_preferences && searchBarValues.lane_preferences.length !== 0) {
                    searchBarValues.lane_preferences.forEach((destinationObject) => {
                        locationArray.push({
                            type: "state",
                            state: destinationObject.abbr,
                            country: "US"
                        })
                    })
                }
                return locationArray
            case 'zone':
                if (searchBarValues.lane_preferences && searchBarValues.lane_preferences.length !== 0) {
                    searchBarValues.lane_preferences.forEach((destinationObject) => {
                        locationArray.push({
                            type: "zone",
                            code: destinationObject.id
                        })
                    })
                }
                return locationArray
            case 'market':
                if (searchBarValues.lane_preferences && searchBarValues.lane_preferences.length !== 0) {
                    searchBarValues.lane_preferences.forEach((destinationObject) => {
                        locationArray.push({
                            type: "market",
                            code: destinationObject.id
                        })
                    })
                }
                return locationArray
            case 'anywhere':
                /* Return empty array for anywhere!*/
                return locationArray
            default:
                return false
        }
    }
}