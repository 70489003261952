export const validateSearchBarContents = (searchBarContents) => {
    let status = true

    if ((searchBarContents.originSearchType === 'default') && (!searchBarContents.city && !searchBarContents.state && !searchBarContents.country && !searchBarContents.lat && !searchBarContents.lon)) {
        status = false
    }

    if ((searchBarContents.originSearchType !== 'default' && searchBarContents.originSearchType !== 'anywhere') && (!searchBarContents.lane_origins || searchBarContents.lane_origins.length === 0)) {
        status = false
    }

    if ((searchBarContents.destinationSearchType === 'default') && (!searchBarContents.cityDestination && !searchBarContents.stateDestination && !searchBarContents.countryDestination && !searchBarContents.latDestination && !searchBarContents.lonDestination)) {
        status = false
    }

    if ((searchBarContents.destinatioSearchType !== 'default' && searchBarContents.destinatioSearchType !== 'anywhere') && (!searchBarContents.lane_preferences || searchBarContents.lane_preferences.length === 0)) {
        status = false
    }

    return status
}