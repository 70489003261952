import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { compose } from 'recompose';

import { Row, Col } from 'react-bootstrap';
import FA from 'react-fontawesome'
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { toast } from 'react-toastify';
import isMobile from '../../../../HOC/Mobile'

import loginLogo from '../theme/logo_dark.svg'


const MultiCarrierSelect = (props) => {
    const { muiTheme, onBack, carriers = [], login, loading = false, isMobile} = props

    const useStyles = makeStyles((theme) => ({
        container: {
            height: isMobile ? '100%' : window.innerHeight,
            width:  isMobile ? '100%' : window.width
        },
        root: {
            height:  isMobile ? '100%' : window.innerHeight,
            flexGrow: 1,
            minWidth: 300,
            maxWidth: window.width - 300,
            transform: 'translateZ(0)',
            // The position fixed scoping doesn't work in IE 11.
            // Disable this demo to preserve the others.
            '@media all and (-ms-high-contrast: none)': {
                display: 'none',
            },
        },
        modal: {
            display: 'flex',
            padding: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            width:  isMobile ? '100%' : 450,
            maxWidth: 450,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding:  isMobile ? 0 : theme.spacing(2, 4, 3),
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        colTitle: {
            textAlign: "center",
            color: "black",
            fontSize: 16
        },
        selectField: {
            fontSize: '16px'
        },
        menuItem: {
            fontSize: '16px'
          },
    }));

    const classes = useStyles()
    const rootRef = React.useRef(null);


    const [selectedCarrier, setSelectedCarrier] = useState(null);

    const handleChange = (event) => {
        setSelectedCarrier(event.target.value);
    };

    const handleSubmit = () => {
       if(selectedCarrier){
           login(selectedCarrier)
       } else {
           toast.error('Please Select A Carrier')
       }
        
        
    };

    const getBody = () => {
        if (muiTheme.customerUniqueId && muiTheme.customerUniqueId == "a98db973") {
            return (
                <div style={{ width: isMobile ? '100%' : 400 }}>
                    <div style={{ width: isMobile ? '100%' : 400 }}>
                        <Row style={{ width: isMobile ? '100%' : 400, height: 25 }}>
                            <Button
                                style={{
                                    backgroundColor: "transparent",
                                    color: muiTheme.actionColor,
                                    fontSize: 14,
                                    textTransform: 'none',
                                    display: 'block'
                                }}
                                onClick={() => onBack()}
                            >
                                <FA name={"chevron-left"} size='sm' color={muiTheme.actionColor} style={{ marginRight: 5 }} />
                            Back
                            </Button>
                        </Row>
                        <Row style={{ display: "flex", justifyContent: "center", width: isMobile ? '100%' : 400 }}>
                            <img src={loginLogo} height={66} />
                        </Row>
                        <Row style={{ marginTop: 40, width: isMobile ? '100%' : 400, height: 'auto' }}>
                            <div style={{ width: "100%", display: "flex", justifyContent: 'center' }}>
                                <Col style={{ alignItems: "center", width: 284, height: "100%" }}>
                                    <p className={classes.colTitle}>Select Carrier</p>
                                    <p style={{ textAlign: "center", fontSize: 14, lineHeight: "16px", marginTop: 5 }}>Select the carrier you want to log in to</p>
                                    <div style={{ display: "flex", justifyContent: "center", marginTop: 30, marginBottom: 15 }}>
                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel id="select-filled-label" style={{ fontSize: 12 }}>Carrier</InputLabel>
                                            <Select
                                                className={classes.selectField}
                                                labelId="select-filled-label"
                                                id="select-filled"
                                                value={selectedCarrier}
                                                onChange={handleChange}
                                                style={{ minWidth: 200 }}
                                                uid="carrier-select"
                                            >
                                                {carriers.map((carrier) => {
                                                    return (
                                                        <MenuItem
                                                            key={carrier.carrierIdent}
                                                            value={carrier}
                                                            className={classes.menuItem}
                                                            uid="carrier-select-item"
                                                        >
                                                            {carrier.carrierName}
                                                        </MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", marginTop: 30, marginBottom: 15 }}>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            style={{
                                                color: "white",
                                                backgroundColor: muiTheme.actionColor,
                                                borderColor: muiTheme.actionColor,
                                                fontSize: 14,
                                                textTransform: 'none'
                                            }}
                                            onClick={handleSubmit}
                                        >
                                            {loading ? 
                                                <div>
                                                    <FA name='spinner' spin style={{color: 'white'}}/> 
                                                </div>
                                            : 
                                                "SIGN IN"
                                            }
                                        </Button>
                                    </div>
                                </Col>
                            </div>
                        </Row>
                        <Row style={{ display: "flex", justifyContent: "center", marginTop: 15, width: isMobile ? '100%' : 400 }}>
                            <div style={{ justifyContent: "center", width: isMobile ? '100%' : 400 }}>
                                <Row style={{ justifyContent: "center", width: isMobile ? '100%' : 400 }}>
                                    <p style={{ textAlign: "center" }}>Please contact Werner</p>
                                </Row>
                                <Row style={{ justifyContent: "center", width: isMobile ? '100%' : 400 }}>
                                    <a href={'tel:+8002282240,1004357'} style={{ color: muiTheme.actionColor, display: 'block', textAlign: "center" }}>800-228-2240 ext.1004357</a>
                                </Row>
                            </div>
                        </Row>
                    </div>
                </div>
            )

        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.root} ref={rootRef} style={{ backgroundColor: "transparent" }}>
                <Modal
                    disablePortal
                    disableEnforceFocus
                    disableAutoFocus
                    open={true}
                    className={classes.modal}
                    style={{ backgroundColor: "transparent" }}
                    container={() => rootRef.current}
                >
                    <div className={classes.paper}>
                        {getBody()}
                    </div>
                </Modal>
            </div>
        </div>
    )
}


export default compose(
    muiThemeable(),
    isMobile()
)(MultiCarrierSelect)