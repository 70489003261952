
import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import React from 'react';
import { Col } from "react-bootstrap";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getLocalDateTime, joinOfferDateTime } from '../../../../../../lib/date-functions';
import { numberWithCommas } from '../../../../../../lib/number-with-commas';



const RateNegotiationsListItemMobile = (props) => {

    const {
        quote,
        getStatusText,
        getStatusIcon,
        useShowInterest
    } = props
    const classes = useStyles();



    return (
        <div style={{ display: 'flex', width: '100%' }}>

            <Col style={{ width: "12%", display: 'inline-flex', paddingRight: 15 }}>

                {quote.loadOffer.type &&
                    <Col style={{ paddingLeft: 15, justifyContent: 'flex-start', flexDirection: 'column', display: 'flex', marginTop: 3 }}>
                        {getStatusIcon(quote.loadOffer)}
                    </Col>
                }
            </Col>
            <Col style={{ width: "88%", paddingRight: 15 }}>
                {quote.loadOffer.amount ?
                    <p className={classes.mainText}>${numberWithCommas(parseFloat(quote.loadOffer.amount).toFixed(2))}<span className={classes.secondaryText}> - {getStatusText(quote.loadOffer)}</span></p>
                    :
                    <p className={classes.mainText}> <i>{useShowInterest && quote.loadOffer.amount == null ? "Shown Interest" : "No Offer Amount Given"}</i></p>
                }
                <p style={{ wordBreak: 'break-word' }}>
                    {quote.loadOffer.message}
                </p>

                <p className={classes.secondaryText} style={{ textAlign: 'right' }}>
                    {getLocalDateTime(joinOfferDateTime(quote.loadOffer), 'YYYY-MM-DD hh:mm A').format('MMM D HH:mm')}
                </p>

            </Col>

        </div>
    );
}

export default compose(
    connect(),
    muiThemeable()
)(RateNegotiationsListItemMobile)

const useStyles = makeStyles({
    mainText: {
        fontSize: '14px',
        lineHeight: '22px',
        color: 'black',
        fontWeight: 'bold',
        margin: 0
    },
    secondaryText: {
        fontSize: '13px',
        lineHeight: '20px',
        color: 'grey',
        wordBreak: 'break-word'
    },
    centerColContent: {
        height: '100%',
        display: "flex",
        justifyContent: 'center'
    },
    button: {
        fontSize: 14,
        color: "white",
        margin: 5
    }
});