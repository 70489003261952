import { PostRemoteLog } from '../CarrierPortal/apis/api-remote-log'
import { useAuthenticationHook } from '../CarrierPortal/hooks/authentication-hook'
import { BACKEND_URL } from './constants'


//new endpoints
const GET_LOAD_OFFERS_BY_ID = "/api/loadOffers/getByLoadId"
const CREATE_LOAD_OFFER = "/api/loadOffer/create"
const CREATE_LOAD_SHOW_INTEREST = "/api/loadInterest/create"

//final endpoint
const GET_LOAD_DETAILS_AND_OFFERS = "/api/carrier/loadOffersForLoad"
const NOTIFICATION_CLICK = "/notificationClick"


export function LogNotificationClick(payload) {
    const myAuthService = useAuthenticationHook()

    let url = BACKEND_URL + NOTIFICATION_CLICK


    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + NOTIFICATION_CLICK,
                    message: {
                        error: JSON.stringify(error)
                    }
                }
                PostRemoteLog(payload).then(response => {
                    // console.log("REMOTE LOG RESPONSE: ", response)
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + NOTIFICATION_CLICK,
            message: {
                error: JSON.stringify(error)
            }
        }
        PostRemoteLog(payload).then(response => {
            // console.log("REMOTE LOG RESPONSE: ", response)
        }).catch((error) => {
        })
    }
}

export function GetLoadDetails(loadNumber = null, loadId = null, advantageId = null) {
    const myAuthService = useAuthenticationHook()

    let url = BACKEND_URL + GET_LOAD_DETAILS_AND_OFFERS

    if (loadNumber && advantageId)
        url = url + `?loadNumber=${loadNumber}&advantageId=${advantageId}`
    else if (loadId)
        url = url + `?loadId=${loadId}&advantageId=${advantageId}`

    try {
        let requestObject = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'bearer ' + hardcode_token

            }
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)

        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + GET_LOAD_DETAILS_AND_OFFERS,
                    message: {
                        error: JSON.stringify(error)
                    }
                }
                PostRemoteLog(payload).then(response => {
                    // console.log("REMOTE LOG RESPONSE: ", response)
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + GET_LOAD_DETAILS_AND_OFFERS,
            message: {
                error: JSON.stringify(error)
            }
        }
        PostRemoteLog(payload).then(response => {
            // console.log("REMOTE LOG RESPONSE: ", response)
        }).catch((error) => {
        })
    }
}

export function GetLoadOffersByLoadId(loadId, includeLoadDetails = false) {
    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + GET_LOAD_OFFERS_BY_ID + `?loadId=${loadId}&loadDetail=${String(includeLoadDetails)}&advantageId=${process.env.REACT_APP_ADVANTAGE_ID}`

    try {
        let requestObject = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'bearer ' + hardcode_token,

            }
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + GET_LOAD_OFFERS_BY_ID,
                    message: {
                        error: JSON.stringify(error)
                    }
                }
                PostRemoteLog(payload).then(response => {
                    // console.log("REMOTE LOG RESPONSE: ", response)
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + GET_LOAD_OFFERS_BY_ID,
            message: {
                error: JSON.stringify(error)
            }
        }
        PostRemoteLog(payload).then(response => {
            // console.log("REMOTE LOG RESPONSE: ", response)
        }).catch((error) => {
        })
    }
}


export function CreateLoadOffer(params) {
    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + CREATE_LOAD_OFFER

    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'bearer ' + hardcode_token,

            },
            body: JSON.stringify(params)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + CREATE_LOAD_OFFER,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data: JSON.stringify(params)
                }
                PostRemoteLog(payload).then(response => {
                    // console.log("REMOTE LOG RESPONSE: ", response)
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + CREATE_LOAD_OFFER,
            message: {
                error: JSON.stringify(error)
            },
            data: JSON.stringify(params)
        }
        PostRemoteLog(payload).then(response => {
            // console.log("REMOTE LOG RESPONSE: ", response)
        }).catch((error) => {
        })
    }
}

export function CreateLoadShowInterest(params) {
    const myAuthService = useAuthenticationHook()

    const url = BACKEND_URL + CREATE_LOAD_SHOW_INTEREST

    try {
        let requestObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'bearer ' + hardcode_token,

            },
            body: JSON.stringify(params)
        }
        let newRequestObject = myAuthService.onBeforeApiCall(requestObject)
        return fetch(url, newRequestObject)
            .then(response => {
                if (!response.ok) {
                    const error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
                return response.json();
            })
            .catch(error => {
                let payload = {
                    source: "URL: " + CREATE_LOAD_SHOW_INTEREST,
                    message: {
                        error: JSON.stringify(error)
                    },
                    data: JSON.stringify(params)
                }
                PostRemoteLog(payload).then(response => {
                    // console.log("REMOTE LOG RESPONSE: ", response)
                }).catch((error) => {
                })
            })
    } catch (error) {
        let payload = {
            source: "URL: " + CREATE_LOAD_SHOW_INTEREST,
            message: {
                error: JSON.stringify(error)
            },
            data: JSON.stringify(params)
        }
        PostRemoteLog(payload).then(response => {
            // console.log("REMOTE LOG RESPONSE: ", response)
        }).catch((error) => {
        })
    }
}