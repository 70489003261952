import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const TTCloseButton = (props) => {
    const {
        positionAdjustments=null,
        handleClose=()=>{} // null function
    }
    =props

    return (
        <IconButton
            data-cy={'general-close-button'}
            style={{
                position: 'relative',
                bottom: '15px',
                float: 'right',
                right: '-5px',
                ...positionAdjustments
            }}
            aria-label="close"
            onClick={() => handleClose()
            }>
            <CloseIcon style={{width:24, height:24}}/>
        </IconButton>
    )

}

export default TTCloseButton