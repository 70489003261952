import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FlatButton from 'material-ui/FlatButton';
import muiThemeable from 'material-ui/styles/muiThemeable';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { saveQuoteAction } from '../../../../../components/Quote/actions';
import CurrencyInput from '../../../../../components/Quote/CurrencyInput';
import useGtagHook from "../../../../../gtag-hook";
import ProtectedButton from "../../../../authentication/protected-button";




const CounterOfferForm = (props) => {
    const {
        load,
        offer,
        muiTheme,
        isSaving,
        onTargetLoadScreen,
        noHistoryMobile = false,
        customStyle,
        submitCounterOffer = () => { },
        useShowInterest
    } = props


    const [counterOfferValue, updateCounterOfferValue] = useState(0) //
    const [quoteMessage, updateQuoteMessage] = useState("")
    const [sendGtagEvent] = useGtagHook()

    function onValueChange(value) {
        updateCounterOfferValue(value);
    }

    const IS_TT_SITE = process.env.REACT_APP_ADVANTAGE_ID === "2zr5ys19"

    const submitButtonStyle = {
        marginRight: 12,
        backgroundColor: onTargetLoadScreen ? IS_TT_SITE ? muiTheme.quoteButton.background : 'white' : muiTheme.actionColor,
        color: onTargetLoadScreen ? IS_TT_SITE ? muiTheme.quoteButton.text : muiTheme.actionColor : 'white',
        border: onTargetLoadScreen ? `1px solid ${IS_TT_SITE ? muiTheme.quoteButton.border : muiTheme.actionColor}` : 'unset',
        borderColor: onTargetLoadScreen ? IS_TT_SITE ? muiTheme.quoteButton.border : muiTheme.actionColor : 'unset',
        borderRadius: onTargetLoadScreen ? 15 : 'unset',
        margin: onTargetLoadScreen ? 5 : 'unset',
        cursor: 'pointer',
        fontSize: 14
    }

    const useStyles = makeStyles({
        input: {
            padding: 8,
            marginBottom: 0,
            '& .MuiOutlinedInput-multiline': {
                padding: 0
            }
        },
        submitBtnContainer: {
            flex: onTargetLoadScreen ? 'unset' : 1,
            display: 'inline-block',
            marginRight: 0,
            textAlign: noHistoryMobile ? 'right' : 'unset'
        }


    });
    const classes = useStyles()

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: noHistoryMobile ? 1 : null }}>
            <div style={{ paddingBottom: 16 }} key={'quoteInputFor' + (offer ? offer.id : 'NewQuote')}>
                {!useShowInterest && ( 
                    <div style={{ display: 'flex', flex: 1, paddingBottom: 16 }}>
                        <p className='mainText' style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', marginRight: 32, color: "0F1D40" }}>Offer Value:</p>
                        <CurrencyInput onValueChange={onValueChange} value={counterOfferValue} onTargetLoadScreen={onTargetLoadScreen} />
                    </div>
                )}
                <div style={{ flex: 1 }} key={'quoteMessageInputFor' + (offer ? offer.id : 'NewQuote')}>

                    <TextField
                        placeholder="Additional Message (Optional)"
                        inputProps={{ style: { fontSize: 16, padding: 5, lineHeight: 'normal' } }}
                        key="quote_message_input"
                        fullWidth={true}
                        value={quoteMessage}
                        multiline
                        rows={8}
                        variant="outlined"
                        onChange={(event) => updateQuoteMessage(event.target.value)}
                        className={onTargetLoadScreen ? classes.input : null}

                    />
                </div>
            </div>
            <div style={{ width: '100%', display: 'inline-flex', justifyContent: onTargetLoadScreen ? 'flex-end' : 'center', paddingRight: onTargetLoadScreen ? 4 : 0 }} key={'ActionsFor' + (offer ? offer.id : 'NewQuote')}>
                {!onTargetLoadScreen && !noHistoryMobile && 
                    <div style={{ flex: 1, display: 'inline-block' }}>
                        <FlatButton
                            fullWidth={true}
                            label={"Close"}
                            disableTouchRipple={true}
                            disableFocusRipple={true}
                            primary={true}
                            style={{ marginRight: 12, color: muiTheme.actionColor, borderColor: muiTheme.actionColor }}
                            onClick={() => {
                                props._onClose()
                            }}
                        />
                    </div>
                }
                <div className={classes.submitBtnContainer}>
                    <ProtectedButton
                        key={"quote_protected_submit_btn"}
                        id='submitQuoteButton'
                        popoverHeaderText={""}
                        popoverBodyText={muiTheme.loginMsg}
                        fromPath={"/loads"}
                        buttonText={ useShowInterest ? "Submit" : "Submit Offer" }
                        isSmall={false}
                        buttonStyle={{...submitButtonStyle}}
                        callback={() => {
                            submitCounterOffer(offer, (counterOfferValue / 100).toFixed(2), quoteMessage)
                            sendGtagEvent("submit_quote_counter", { loadId: load.loadId, brokerId: load.brokerId }) //todo add to GA & import
                            updateCounterOfferValue(0)
                            props._onClose()
                        }}
                        isDisabled={isSaving || (Boolean(!counterOfferValue) && !useShowInterest )}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    isSaving: state.QuoteReducer.post.requesting,
})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        saveQuoteAction: saveQuoteAction,
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    muiThemeable(),
)(CounterOfferForm)