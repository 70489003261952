import { numberWithCommas } from "../../../../../lib/number-with-commas"
import { loadOfferMappings } from "../../../../../lib/load-offer-mappings"


export const determineQuoteActions = (quote, setShowCounterButton = () => { }, setShowBinButton = () => { }) => {
    let offerMapping = loadOfferMappings(quote)
    switch (offerMapping.offer_type) {
        case 'accept':
            setShowCounterButton(true)
            setShowBinButton(true)
            break
        case 'reject':
            setShowCounterButton(true)
            setShowBinButton(false)
            break
        case 'offer':
            setShowCounterButton(true)
            if (offerMapping.offer_by === "BROKER" && parseFloat(offerMapping.amount) > 0) {

                setShowBinButton(true)
                setShowCounterButton(true)
            } else { //carrier show interest wouldn't send an amount
                setShowBinButton(false)
                setShowCounterButton(true)
            }
            break
        case 'bookItNow':
            setShowCounterButton(false)
            setShowBinButton(false)
            break
    }
}

export const findLatestBinOffer = (offerThread = null, load = null) => {

    let originalBinPrice = load?.fullLoadDetails?.targetPay ?? load?.targetPay ?? null


    let retAmount = originalBinPrice ? JSON.parse(JSON.stringify(parseFloat(originalBinPrice).toFixed(2))) : null
   
    let retObject = {
        amount: retAmount,
        isOriginalBinPrice: retAmount ? true : false
    }

    if (offerThread && offerThread.length > 0) {

        //the old offer structure won't have loadOffer obj and it's in reverse order with the newest item last. Need to have newest first for consistency 
        offerThread = offerThread[0] && offerThread[0].loadOffer ? offerThread : JSON.parse(JSON.stringify(offerThread))

        let breakLoop = false
        //find the latest offer that allows for a BIN
        offerThread.forEach(offer => {
            if(breakLoop) return null
            
            offer = offer.loadOffer ?? offer

            let offerMapping = loadOfferMappings(offer)
            if ( offer && (offerMapping.amount) && ((offerMapping.offer_type == 'accept') || (offerMapping.offer_by == 'BROKER' && offerMapping.offer_type !== 'reject'))) {

                breakLoop = true
                retObject.amount = offerMapping.amount
                retObject.isOriginalBinPrice = false
            }

        })
    }
    return retObject

}


export const binPriceIsOriginal = (offerAmount, offerThread, load) => {
    //target pay is being replaced by the latest valid offer from broker 
    let targetPay = load.fullLoadDetails ? load.fullLoadDetails.targetPay : load.targetPay
    if (offerThread.length > 0) {
        let hasOfferFromBroker = false
        offerThread.forEach(offer => {
            offer = offer.loadOffer
            let offerMapping = loadOfferMappings(offer)
            // console.log(offer)
            if (!hasOfferFromBroker && offer && (offerMapping.amount) && ((offerMapping.offer_type == 'accept') || (offerMapping.offer_by == 'BROKER' && offerMapping.offer_type !== 'reject'))) {
                hasOfferFromBroker = true
                // console.log('has offer: ', offer)
            }

        })
        return !hasOfferFromBroker
    }
    else
        return offerAmount === targetPay


}

export const canLatestQuoteShowBin = (quote) => {
    let offerMapping = loadOfferMappings(quote)
    if (offerMapping.offer_by === "CARRIER" || offerMapping.offer_by === "DRIVER") {
        return false
    }
    else if (offerMapping.offer_by === "BROKER" && offerMapping.offer_type === 'accept') {
        return true

    }
    else if (offerMapping.offer_by === "BROKER" && offerMapping.offer_type === 'reject') {
        return false

    }
    else if (offerMapping.offer_by === "BROKER") {
        return true

    }
    else if (offerMapping.offer_by === "BOOKITNOW") {
        return false
    }

}



export const getLatestQuoteStatusMsg = (load, isAuthenticated) => {

    if (!isAuthenticated)
        return null

    let quoteList = load && load.quotes ? JSON.parse(JSON.stringify(load.quotes)): []

    let offerMapping = loadOfferMappings(quoteList[0]) // grab latest communication

    if (offerMapping) {
        let amount = numberWithCommas(parseFloat(offerMapping.amount).toFixed(2))

        if (offerMapping.offer_by === "CARRIER" || offerMapping.offer_by === "DRIVER") {
            return (
                <p style={{ fontWeight: 'bold' }}>
                    {amount && parseFloat(amount) > 0 ? `You Offered $${amount}` : "You've Shown Interest"}
                </p>
            )

        }
        else if (offerMapping.offer_by === "BROKER" && offerMapping.offer_type === 'accept') {
            return (
                <p style={{ fontWeight: 'bold', color: 'green' }}>
                    Broker Accepted ${amount}
                </p>
            )
        }
        else if (offerMapping.offer_by === "BROKER" && offerMapping.offer_type === 'reject') {
            return (
                <p style={{ fontWeight: 'bold', color: "#9A1F25" }}>
                    Broker Rejected ${amount}
                </p>
            )
        }
        else if (offerMapping.offer_by === "BROKER") {
            return (
                <p style={{ fontWeight: 'bold' }}>
                    Broker Offered ${amount}
                </p>
            )
        }
        else
            return null
    }

}