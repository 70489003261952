import React, { useState, useEffect, useRef, useContext } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import isMobile from '../../HOC/Mobile.js';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import { Card } from 'material-ui/Card';
import CardContent from '@material-ui/core/CardContent';
import { Row, Col } from 'react-bootstrap';

import LoadingOverlay from '../components/LoadingOverlay';
import TireFilterMenu from '../components/tires/tire-filter-menu';
import TireListHeader from '../components/tires/tire-list-header';
import TireList from '../components/tires/tire-list';
import TireForm from '../components/tires/tire-form';
import TireSearchBar from '../components/tires/tire-search-bar';
import RequestQuotesButton from '../components/tires/request-quotes-button';

import {useAuthenticationHook} from '../hooks/authentication-hook';
import { resetAllTireFilters } from "../actions";
import { TireScreenContext } from '../components/tires/context/index';
import * as TireSearchApi from '../apis/api-tire-search';
import { camelize } from "../helpers/services"
import useGtagHook from '../../gtag-hook';

// import MockTireListMini from '../components/tires/mock-data/mock-tire-list-mini.json';


const TireScreen = (props) => {

    const {
        tireFilters,
        muiTheme,
        isMobile = false,
        userProfile
    } = props

    const useStyles = makeStyles({
        container: {
            height: '100%',
            width: '100%'
        },
        badge: {
            height: 20,
            width: 20,
            borderRadius: 50,
            display: "flex",
            justifyContent: "center",
            backgroundColor: muiTheme.secondary
        },
        breadcrumbLink: {
            cursor: 'pointer',
            marginRight: 15,
            color: "black", // TODO: change??
            "&:hover": {
                color: muiTheme.actionColor,
                textDecoration: "underline"
            }
        },
        activeBreadcrumbLink: {
            color: muiTheme.primary,
            fontWeight: 'bold',
            textDecoration: "underline",
            "&:hover": {
                color: muiTheme.primary,
            }
        },
        secondaryText: {
            fontFamily: 'Arial !important',
            fontSize: '14px',
            color: 'grey'
        },
        sortSelectItem: {
            border: '1px solid #ccc',
            backgroundColor: 'white',
            fontSize: 14,
            shadowRadius: 5,
            shadowColor: 'black',
            shadowOpacity: 0.1,
            whiteSpace: 'nowrap',
            paddingLeft: '10px',
        },
        icon: {
            position: 'unset',
            fontSize: '2.5rem',
            textAlign: 'right'
        },
        refreshButton: {
            fontSize: 16,
            color: "black",
            marginLeft: 5,
            paddingLeft: 5,
            paddingRight: 5,
            cursor: 'pointer',
            borderRadius: 10,
            '&:hover': {
                boxShadow: '0px 2px 10px #999999',
            }
        },
        signUpText: {
            fontSize: 18,
            fontWeight: "bold",
            color: "black"
        }
    });
    const FILTER_PADDING_TOP = 177
    const classes = useStyles();

    const context = useContext(TireScreenContext);

    const [isLoading, setIsLoading] = useState(false);
    const [tireListCount, setTireListCount] = useState(0);
    const [showSignUpForm, setShowSignUpForm] = useState(false);
    const [searchParams, setSearchParams] = useState({})
    const [baseListForFilter, setBaseListForFilter] = useState([])

    const isMountedOne = useRef(false);
    const isMountedTwo = useRef(false);

    let myAuthService = useAuthenticationHook()
    let user = myAuthService.getUser()

    const [sendGtagEvent] = useGtagHook()

    const handleBannerClick = () => {
        sendGtagEvent("banner_click", {
            bannerType: "tireNationalAccount"
        })
    }

    const filterTires = (list) => {
        if (list == []) return;
        let retData = [];
        if (tireFilters.lastAction != null) {

            list.forEach(tire => {
                let canShowItem = canShowTire(tire);
                if (canShowItem) {
                    retData.push(tire);
                }
            })

        } else {
            retData = list;
        }
        if (tireListCount != null) {
            setTireListCount(retData.length);
        }
        context.functions.handleFilteredData(retData);
        setTireListCount(retData.length)
    }

    const showSignupLink = () => {
        if (!myAuthService.isAuthenticated() || (myAuthService.isAuthenticated() && !(userProfile?.availableServices?.tire?.hasService))) {
            return true
        } else {
            return false
        }
    }

    const canShowTire = (tire) => {

        if (tireFilters.filteredOutBrands.length > 0) {
            if (tireFilters.filteredOutBrands.indexOf(tire.brand) > -1) {
                return false;
            }
        }
        if (tireFilters.filteredOutPositions.length > 0) {
            if (tireFilters.filteredOutPositions.indexOf(camelize(tire.position)) > -1) {
                return false;
            }
        }
        if (tireFilters.filteredOutModels.length > 0) {
            if (tireFilters.filteredOutModels.indexOf(tire.model) > -1) {
                return false;
            }
        }
        if (tireFilters.filteredOutSizes.length > 0) {
            if (tireFilters.filteredOutSizes.indexOf(tire.size) > -1) {
                return false;
            }
        }
        if (tireFilters.filteredOutPlys.length > 0) {
            if (tireFilters.filteredOutPlys.indexOf(tire.ply) > -1) {
                return false;
            }
        }

        return true;
    }

    const applyMultiSelectFilter = (data, filter, filters) => {
        return data.filter(item => {
            const value = filters[filter];
            let include = false
            if (Array.isArray(value)) {
                include = value.includes(item[filter] != null ? item[filter].toString() : item[filter])
            }
            return include
        })
    }

    const onSearchComplete = () => {
        let filtered = context.originalData
        Object.keys(searchParams).forEach(field => {
            filtered = applyMultiSelectFilter(filtered, field, searchParams)
        })
        // console.log("filtered: ", filtered)
        context.functions.handleFilteredData(filtered)
        setBaseListForFilter(filtered)
        setTireListCount(filtered.length)
        resetAllTireFilters()
        sendGtagEvent("find_tires", {
            brand: searchParams["brand"],
            position: searchParams["position"],
            product: searchParams["product"],
            ply: searchParams["ply"],
            listLength: filtered.length
        })
    }

    const updateSearchParams = (field, value) => {
        let tmpParams = searchParams
        // console.log("tmpParams: ", tmpParams, field, value)
        if (((value.length === 1 && value[0] === "All") || value.length === 0 || !value || typeof value === "undefined")) {
            // console.log("yooo", tmpParams)
            if (tmpParams[field]) {
                delete tmpParams[field]
            }
        } else {
            tmpParams[field] = value
        }
        setSearchParams(tmpParams)
        context.functions.handleSearchSelectionsUpdate(tmpParams)
    }

    const makeSearchApiCall = () => {
        setIsLoading(true)
        TireSearchApi.getTires(searchParams)
            .then(response => {
                // console.log("fetch tires response: ", response)
                resetAllTireFilters()
                context.functions.handleNewData(response)
                setTireListCount(response.length)
                setBaseListForFilter(response)
                setIsLoading(false)
                sendGtagEvent("find_tires", {
                    brand: searchParams["brand"],
                    position: searchParams["position"],
                    product: searchParams["product"],
                    ply: searchParams["ply"],
                    listLength: response.length
                })
            })
            .catch((error) => {
                // console.log("fetch tires error: ", error)
                let errorMessage = "Error: There was a problem fetching tires."
                toast.error(errorMessage)
                context.functions.handleNewData([])
                setTireListCount(0)
                setBaseListForFilter([])
                setIsLoading(false)
            })
    }

    useEffect(() => {
        makeSearchApiCall()

        TireSearchApi.getSearchFields()
            .then(response => {
                // console.log("fetch tires fields response: ", response)
                context.functions.handleNewFields(response)
                context.functions.handleMarkFieldMapComplete(true)
            })
            .catch((error) => {
                console.log("fetch tires fields error: ", error)
                context.functions.handleMarkFieldMapComplete(true)
                // let errorMessage = "Error: There was a problem fetching tires."
                // toast.error(errorMessage)
            })
        // context.functions.handleNewData(MockTireListMini.DummyData);
        // setTireListCount(MockTireListMini.DummyData.length);
    }, []);

    // useEffect(() => {
    //     if (isMountedOne.current && context.originalData.length > 0) {
    //         filterTires(context.originalData)
    //     } else {
    //         isMountedOne.current = true;
    //     }

    // }, [context.filteredData])

    useEffect(() => {
        if (isMountedTwo.current && baseListForFilter.length > 0 && tireFilters.lastAction != null) {
            filterTires(baseListForFilter)
        } else {
            isMountedTwo.current = true;
        }
    }, [tireFilters])

    return (
        <div style={{ height: '100%' }}>

            {isLoading && <LoadingOverlay />}

            {!isMobile &&
                <div className={classes.container}>

                    {!showSignUpForm &&
                        <Row style={{ height: '100%' }}>
                            <Col
                                md={2}
                                style={{
                                    paddingTop: FILTER_PADDING_TOP,
                                    backgroundColor: 'white',
                                    height: '100%'
                                }}
                            >
                                <TireFilterMenu currentTireList={baseListForFilter} paddingTop={FILTER_PADDING_TOP} />
                            </Col>
                            <Col md={context.originalData.length > 0 ? 10 : 12} style={{ height: '100%' }}>
                                <div style={{ marginLeft: 10, height: '5%', paddingTop: 10 }}>
                                    {showSignupLink() && (
                                        <span><a className={classes.signUpText} style={{ color: muiTheme.actionColor, cursor: "pointer" }} onClick={() => setShowSignUpForm(true)}>General Inquiries</a></span>
                                    )}
                                </div>
                                <div style={{ height: '90%' }}>
                                    <Row style={{ marginTop: 15, marginLeft: 10 }}>
                                        {context.canUseFieldMap &&
                                            <TireSearchBar searchData={context.fieldMap} onSearch={onSearchComplete} searchParams={searchParams} updateSearchParams={updateSearchParams} />
                                        }
                                    </Row>
                                    <Row style={{ marginLeft: 10, marginRight: 39, marginTop: 20 }}>
                                        <p style={{ color: 'black', fontSize: 25, fontWeight: 'bold' }}>{tireListCount} Results</p>
                                        <Card style={{ height: 45, backgroundColor: 'transparent', boxShadow: 'transparent' }}>
                                            <CardContent style={{ paddingRight: "calc(5% + 20px)", paddingBottom: 5, borderBottom: '1px solid lightgrey' }}>
                                                <TireListHeader {...props} />
                                            </CardContent>
                                        </Card>
                                    </Row>
                                    <Row style={{ height: '75%', marginLeft: 10, paddingBottom: 15, overflowY: "scroll" }}>
                                        <TireList currentTireList={context.filteredData} currentTireListLength={context.filteredData.length}/>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    }

                    {/* TODO-BG: Temporarily bypassing Sign-in form. Need to review authentication access requirements, these may not be needed */}
                    {showSignUpForm &&
                        <div style={{ height: "95%" }}>
                            <div style={{ height: isMobile ? "10%" : "25%" }}>
                                <a href="https://www.englandlogistics.com/tires/" target="_blank" onClick={() => handleBannerClick()}>
                                    <img src="https://assets.truckertools.com/website/banners/england-logistics/Free-Balancing-TTT-Banner_936_120.gif" alt="England Logistics Tire Banner" style={{ width: '100%' }} />
                                </a>
                            </div>
                            <div style={{ maxHeight: isMobile ? "90%" : "75%", overflowX: "hidden", overflowY: "auto", marginRight: isMobile ? 0 : 50, marginTop: isMobile ? 0 : 30 }}>
                                <TireForm onBack={() => { setShowSignUpForm(false) }} />
                            </div>
                        </div>
                    }
                </div>
            }

            {/* TODO-BG: Mobile Support*/}
            {isMobile &&
                <div style={{ height: "100%", width: "100%" }}>
                    {!isLoading && !showSignUpForm &&
                        <div style={{ height: "100%", paddingTop: 10 }}>
                            <div style={{ height: "8%", paddingLeft: 15, paddingRight: 10, display: "flex" }}>
                                <Col style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }}>
                                    {showSignupLink() && (
                                        <span><a style={{ color: muiTheme.actionColor, cursor: "pointer" }} onClick={() => setShowSignUpForm(true)}>General Inquiries</a></span>
                                    )}
                                </Col>
                                <Col style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center", paddingRight: 10 }}>
                                    <RequestQuotesButton btnStyle={{ fontSize: 14, color: "white", backgroundColor: muiTheme.actionColor }} isMobile={true} />
                                </Col>
                            </div>
                            <div style={{ height: "8%", paddingTop: 20, paddingLeft: 15, paddingRight: 10, display: "flex" }}>
                                <Col style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-end" }}>
                                    <p style={{ color: 'black', fontSize: 20, fontWeight: 'bold' }}>{tireListCount} Results</p>
                                </Col>
                                <Col style={{ width: "50%", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "flex-end" }}>
                                    <TireSearchBar searchData={context.fieldMap} onSearch={makeSearchApiCall} updateSearchParams={updateSearchParams} />
                                </Col>
                            </div>
                            <div style={{ height: '84%', paddingLeft: 15, paddingBottom: 15, overflowY: "scroll" }}>
                                <TireList currentTireList={context.filteredData} currentTireListLength={context.filteredData.length} isMobile={isMobile}/>
                            </div>
                        </div>
                    }
                    {showSignUpForm && (
                        <Row style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
                            <div style={{ maxHeight: "100%", overflowX: "hidden", overflowY: "auto" }}>
                                <div style={{ height: "10%" }}>
                                    <a href="https://www.englandlogistics.com/tires/" target="_blank" onClick={() => handleBannerClick()}>
                                        <img src="https://assets.truckertools.com/website/banners/england-logistics/Free-Balancing-TTT-Banner_936_120.gif" alt="England Logistics Tire Banner" style={{ width: '100%' }} />
                                    </a>
                                </div>
                                <div style={{ maxHeight: "90%", overflowX: "hidden", overflowY: "auto" }}>
                                    <TireForm onBack={() => { setShowSignUpForm(false) }} />
                                </div>
                            </div>
                        </Row>
                    )}
                </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    tireFilters: state.CarrierPortal.tireFilters,
    userProfile: state.Profile.profileData
})

export default compose(
    connect(mapStateToProps),
    muiThemeable(),
    isMobile()
)(TireScreen)