import React from 'react';
import { compose } from 'recompose'
import { connect } from 'react-redux'
import muiThemeable from 'material-ui/styles/muiThemeable';
import Button from '@material-ui/core/Button';
import FA from 'react-fontawesome'
import LoadDetailsMobileContent from './loadDetailsMobileContent'

const LoadDetailsMobile = (props) => {

    const { muiTheme } = props
    const {
        load,
        pickup,
        delivery,
        // setOpenLoadDetails,
        availability,
        isMyLoadsScreen = false,
        myLoadsRenderedTab = 0,
        loadDetailsRenderedTab = 0,
        // removeLoadFromList, // todo
        callRefreshCard = () => {},
        onInvoicesScreen = false,
        
    } = props.location.state
    return (


        // <Drawer width={"100%"} open={true} PaperProps={{ style: { overflow: 'hidden', width: '100%', height: '100%' } }}>
        <div style={{ width: '100%', height: '100%' }}>
            <Button
                onClick={() => {
                    // setOpenLoadDetails(false)
                    props.history.goBack()
                }}
                style={{
                    position: 'absolute',
                    top: 8,
                    left: 15,
                    zIndex: 10,
                    backgroundColor: muiTheme.actionColor,
                    minWidth: 'auto',
                    padding: '6px 16px'
                }}
                disableRipple={true}
            >

                <FA name="caret-left" size='2x' style={{ color: 'white' }} />
            </Button>

            <LoadDetailsMobileContent
                load={load}
                pickup={pickup}
                delivery={delivery}
                availability={availability}
                isMyLoadsScreen={isMyLoadsScreen}
                myLoadsRenderedTab={myLoadsRenderedTab}
                loadDetailsRenderedTab={loadDetailsRenderedTab}
                // removeLoadFromList={removeLoadFromList}
                callRefreshCard={callRefreshCard}
                onInvoicesScreen={onInvoicesScreen}
    
            />
        </div>
        // </Drawer>


    );
}


export default compose(
    connect(),
    muiThemeable(),
)(LoadDetailsMobile)