
import React, { useState, useEffect } from 'react';
import { compose, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import isMobile from '../../../../../../HOC/Mobile.js'
import muiThemeable from 'material-ui/styles/muiThemeable';

import { getQuoteHistory } from '../../../../../../components/Quote/actions'
import RateNegotiations from '../../../quotes/RateNegotiations';


const QuotesTab = (props) => {
    const {
        load,
        removeLoadFromList,
        callRefreshCard,
        
    } = props


    return (
        <div style={{ width: '100%', height: '100%' }}>

            <RateNegotiations
                load={load}
                removeLoadFromList={removeLoadFromList}
                callRefreshCard={callRefreshCard}
    
                {...props}
            />
        </div>

    );
}

const mapStateToProps = state => ({
    isSaving: state.QuoteReducer.post.requesting,
    quotesHistory: state.QuoteReducer.get.payload,
    isQuotesLoading: state.QuoteReducer.get.requesting,

})

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        getQuoteHistory: getQuoteHistory
    }, dispatch)
}

export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    withHandlers({
        updateQuoteHistory: props => () => {
            props.getQuoteHistory([props.load.id], props.carriers, false, props.brokers, props.availabilities) // dont have all of these props
        }
    }),
    isMobile(),
    muiThemeable(),
)(QuotesTab)