import React from 'react';
import { compose } from 'recompose'
import muiThemeable from 'material-ui/styles/muiThemeable';


const FooterText = (props) => {

    const { muiTheme, advantageId, providedText = "", textStyle = {} } = props

    const getSupportText = () => {
        switch (advantageId) {
            case "dd4udt1b":
                return { supportText: 'Please contact Atlas Now Support', email: 'elatlasnow@englandlogistics.com' }
            default:
                return { supportText: 'Please contact Trucker Tools Support', email: 'support@truckertools.com' }
        }
    }

    return (
        <div>
            {providedText !== "" && <p style={{...{ textAlign: "center" }, ...textStyle}}>{providedText}</p>}
            <p style={{ textAlign: "center" }}>{getSupportText().supportText}</p>
            <a href={`mailto:${getSupportText().email}`} target="_blank" style={{ color: muiTheme.actionColor, display: 'block', textAlign: "center" }}>{getSupportText().email}</a>
        </div>
    )

}

export default compose(
    muiThemeable(),
)(FooterText)

