import React, { useEffect, useState } from 'react';
import { compose } from 'recompose'
import { useAuth0 } from "@auth0/auth0-react";

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import TextField from '@material-ui/core/TextField';
import FA from 'react-fontawesome'
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';

import { UpdateUserData } from '../../src/CarrierPortal/apis/api-auth0-management'
import FooterText from '../CarrierPortal/authentication/modal-footer-text';

const EditMyProfile = (props) => {

    const { muiTheme, onBack, userEmail = "", onProfileScreen = false, existingData = {}, isExistingUser = false, isMobile = false} = props

    const { loginWithRedirect, getAccessTokenSilently } = useAuth0();

    const useStyles = makeStyles((theme) => ({
        modalContentStyling: {
            display: "flex",
            flexDirection: 'column',
            maxHeight: isMobile ? '100%' : 650,
            width: 550,
            backgroundColor: "#fff",
            border: '2px solid #000',
            padding: 20,
            overflow: "auto"
        },
        cardConent: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: 20
        },
        contentBlock: {
            width: 225
        },
        titleText: {
            fontSize: 24,
            fontWeight: 'bold'
        }
    }));

    const classes = useStyles()

    const [originalData, setOriginalData] = useState(
        {
            initFirstName: existingData.firstName ? existingData.firstName : "",
            initLastName: existingData.lastName ? existingData.lastName : "",
            initContactPhone: existingData.phoneNumber ? existingData.phoneNumber : ""
        }
    )

    const [firstName, setFirstName] = useState(existingData.firstName ? existingData.firstName : "")
    const [lastName, setLastName] = useState(existingData.lastName ? existingData.lastName : "")
    const [contactPhone, setContactPhone] = useState(existingData.phoneNumber ? existingData.phoneNumber : "")
    const [isLoading, setIsLoading] = useState(false)

    const submitForm = () => {
        setIsLoading(true)

        let payload = {}

        if (firstName !== "") {
            payload['firstName'] = firstName
        }
        if (lastName !== "") {
            payload['lastName'] = lastName
        }
        if (contactPhone !== "") {
            payload['phoneNumber'] = contactPhone
        }

        UpdateUserData(payload).then(async response => {

            if (response) {
                toast.success('Profile Data Updated')
                const RefreshAccessToken = await getAccessTokenSilently({
                    // audience: process.env.REACT_APP_AUTH0_AUDIENCE, // `https://${domain}/api/v2/`,
                    // scope: "read:current_user",
                    // responseType: "code",
                    ignoreCache:true
                });
                localStorage.setItem('token', JSON.stringify(RefreshAccessToken))
                window.location.reload(false);
            } else {
                toast.error("Error Updating Profile: " + response.message)
                setIsLoading(false)
            }
        }).catch((error) => {
            // console.log("Error: ", error)
            toast.error("Error Updating Profile: ", error)
            setIsLoading(false)
        })
        //todo: json.stringify the payload before sending
        //todo
        //email the payload to JV, Jess, Murali, TT Support & Raymond from Reed
        // toast.success("You can expect an email containing your login credentials.")

    }

    return (
        <div className={classes.modalContentStyling}>
            <Button
                style={{
                    backgroundColor: "transparent",
                    color: muiTheme.actionColor,
                    fontSize: 14,
                    display: 'flex',
                    alignSelf: 'flex-start'
                }}
                onClick={() => onBack()}
            >
                <FA name={"chevron-left"} size='sm' color={muiTheme.actionColor} style={{ marginRight: 5 }} />
                Back
            </Button>

            <div style={{ display: 'flex', alignSelf: 'center', paddingBottom:24}}>
                <p className={classes.titleText}>My Profile</p>
            </div>

            <div className={classes.cardConent} style={{ paddingBottom: 32 }}>
                <p style={{ fontStyle: 'italic', fontSize: 14, fontWeight:600, paddingLeft:10, paddingRight:10}}>
                    *If you need to update your role, reach out to a carrier admin to update this information.
                </p>
                <div className={classes.contentBlock}>
                    <TextField
                        label="First Name"
                        inputProps={{
                            style: { fontSize: 16 }
                        }}
                        InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                        fullWidth
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </div>

                <div className={classes.contentBlock}>
                    <TextField
                        label="Last Name"
                        inputProps={{
                            style: { fontSize: 16 }
                        }}
                        InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                        fullWidth
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    // onClick={(e) => handleContactPhoneClick(e.target.value)}
                    />
                </div>

                <div className={classes.contentBlock}>
                    <TextField
                        label="Phone"
                        inputProps={{
                            style: { fontSize: 16 }
                        }}
                        InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                        fullWidth
                        value={contactPhone}
                        onChange={(e) => setContactPhone(e.target.value)}
                    />
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', gap: 16, paddingBottom: 24 }}>
                <Button
                    variant={"outlined"}
                    size={"small"}
                    style={{ fontSize: 14, color: muiTheme.actionColor, backgroundColor: "white", borderColor: muiTheme.actionColor }}
                    onClick={() => onBack()}
                >
                    Cancel
                </Button>
                <Button
                    variant={"contained"}
                    size={"small"}
                    style={{ fontSize: 14, color: "white", backgroundColor: isLoading ? muiTheme.ttGrey : muiTheme.actionColor, borderColor: isLoading ? muiTheme.ttGrey : muiTheme.actionColor }}
                    onClick={() => submitForm()}
                    disabled={isLoading}
                >
                    Submit Form
                </Button>
            </div>
            <FooterText advantageId={process.env.REACT_APP_ADVANTAGE_ID}/>

        </div>
    )

}


export default compose(
    muiThemeable(),
)(EditMyProfile)