import React, { useState, useEffect } from 'react';
import { compose } from 'recompose'

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';
import isMobile from '../../HOC/Mobile.js'

import { Row } from 'react-bootstrap';
import { toast } from 'react-toastify'

import LoadingOverlay from '../../components/LoadingOverlay';
import UserList from '../components/user-management/user-list';

import * as UserManagementApi from '../apis/api-user-management';
import { useAuthenticationHook } from '../hooks/authentication-hook';

const UserManagementScreen = (props) => {

    const { height } = useWindowDimensions();

    const useStyles = makeStyles({
        container: {
            padding: 5,
            height: height,
            width: '100%'
        }
    });

    const classes = useStyles()

    const myAuthService = useAuthenticationHook()
    const user = myAuthService.getUser()

    const [isLoading, setIsLoading] = useState(false)
    const [contactList, setContactList] = useState([])

    const getUsers = () => {
        // setContactList(mockResp.results)
        setIsLoading(true)
        let carrierId = user.carrier.carrierId
        UserManagementApi.FetchCarrierUsers(carrierId).then(response => {
            // console.log("USERS: ", response)

            //need to group by email flag key
            //take the highest role
            if (response.status) {
                if (response.results && response.results.length > 0) {
                    setContactList(getHighestUserRole(response))
                    setIsLoading(false)
                } else {
                    setContactList([])
                    setIsLoading(false)
                }
            } else {
                setContactList([])
                setIsLoading(false)
            }
        }).catch((error) => {
            let errorMessage = "Error: There was a problem fetching users."
            setContactList([])
            setIsLoading(false)
            toast.error(errorMessage)
        })
    }

    useEffect(() => {
        getUsers()
    }, [])

    const getHighestUserRole = (response) => {
        let users = response.results
        let userRoleGroup = users.reduce((acc, value) => {
            // Group initialization
            if (!acc[value.email]) {
                acc[value.email] = [];
            }
            // Grouping
            acc[value.email].push(value);
            return acc;
        }, {});

        let precedenceArray = {
            admin: 1,
            dispatch: 2,
            finance: 3,
            loadviewer: 4
        }
        

        Object.keys(userRoleGroup).forEach(emailKey => {
            userRoleGroup[emailKey].sort((a, b) => precedenceArray[a.role] - precedenceArray[b.role])
        })

        //first element in the array is the highest role
        let results = Object.keys(userRoleGroup).map(emailKey => userRoleGroup[emailKey][0])
        return results
    }


    return (
        <div style={{ height: '100%' }}>
            {isLoading && <LoadingOverlay />}
            {!isLoading && (
                <div className={classes.container}>
                    <Row style={{ height: '84%', paddingTop: 15 }}>
                        <UserList {...props} contactList={contactList} refreshList={getUsers} />
                    </Row>
                </div>
            )}
        </div>
    )
}


export default compose(
    muiThemeable(),
    isMobile(),
)(UserManagementScreen)

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}