import React, { useEffect, useState } from 'react';
import { updateProfile } from './actions.js'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useAuth0 } from "@auth0/auth0-react";

import { makeStyles } from '@material-ui/core/styles';
import muiThemeable from 'material-ui/styles/muiThemeable';

import TextField from '@material-ui/core/TextField';
import FA from 'react-fontawesome'
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';

import { UpdateUserData } from '../../src/CarrierPortal/apis/api-auth0-management'
import FooterText from '../CarrierPortal/authentication/modal-footer-text';

const EditCarrierProfile = (props) => {

    const { 
        muiTheme, 
        onBack, 
        existingData = null, 
        isMobile = false, 
        
        //REDUX//
        updateProfile,
        profile //Reducer for checking api call statuses
    } = props

    const { getAccessTokenSilently } = useAuth0();

    const useStyles = makeStyles((theme) => ({
        modalContentStyling: {
            display: "flex",
            flexDirection: 'column',
            maxHeight: isMobile ? '100%' : 650,
            width: 550,
            backgroundColor: "#fff",
            border: '2px solid #000',
            padding: 20,
            overflow: "auto"
        },
        cardConent: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: 20
        },
        contentBlock: {
            width: 225
        },
        titleText: {
            fontSize: 24,
            fontWeight: 'bold'
        }
    }));

    const classes = useStyles()

    const [isLoading, setIsLoading] = useState(false)

    const [companyName, setCompanyName] = useState(existingData && existingData.companyName ? existingData.companyName : "")
    const [scac, setScac] = useState(existingData && existingData.scac ? existingData.scac : "")
    const [mc, setMc] = useState(existingData && existingData.mc ? existingData.mc : "")
    const [dotNumber, setDotNumber] = useState(existingData && existingData.dotNumber ? existingData.dotNumber : "")
    const [address, setAddress] = useState(existingData && existingData.address ? existingData.address : "")
    const [city, setCity] = useState(existingData && existingData.city ? existingData.city : "")
    const [state, setState] = useState(existingData && existingData.state ? existingData.state : "")
    const [zip, setZip] = useState(existingData && existingData.zip ? existingData.zip : "")


    const refreshToken = async () => {
        const RefreshAccessToken = await getAccessTokenSilently({
            // audience: process.env.REACT_APP_AUTH0_AUDIENCE, // `https://${domain}/api/v2/`,
            // scope: "read:current_user",
            // responseType: "code",
            ignoreCache:true
        });
        localStorage.setItem('token', JSON.stringify(RefreshAccessToken))
        window.location.reload(false);
    } 

    useEffect(() => {
        if (profile.requesting == false && profile.successful == true) {
            if(profile.updateSuccess){
                refreshToken()
            }
        }
      }, [profile.requesting])

    const submitForm = () => {
        setIsLoading(true)

        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        if (((mc == null || mc === "") || isNaN(mc)) && (dotNumber == null || dotNumber === "") || isNaN(dotNumber)) {
            toast.error("A Valid MC or DOT Number is required.")
            setIsLoading(false)
            return
        }
        else if (companyName == null || companyName === "") {
            toast.error("Company Name is required.")
            setIsLoading(false)
            return
        }

		var data;
		data = {
			mc: mc,
			scac: scac,
			dotNumber: dotNumber,
			companyName: companyName,
			address: address,
			city: city,
			state: state,
			zip: zip,
		}

		updateProfile(data)
        setIsLoading(false)
        
    }

    return (
        <div className={classes.modalContentStyling}>
            <Button
                style={{
                    backgroundColor: "transparent",
                    color: muiTheme.actionColor,
                    fontSize: 14,
                    display: 'flex',
                    alignSelf: 'flex-start'
                }}
                onClick={() => onBack()}
            >
                <FA name={"chevron-left"} size='sm' color={muiTheme.actionColor} style={{ marginRight: 5 }} />
                Back
            </Button>

            <div style={{ display: 'flex', alignSelf: 'center', paddingBottom:24}}>
                <p className={classes.titleText}>Carrier Profile</p>
            </div>

            <div className={classes.cardConent} style={{ paddingBottom: 32 }}>
                {/* <p style={{ fontStyle: 'italic', fontSize: 14, fontWeight:600, paddingLeft:10, paddingRight:10}}>
                    *If you need to update your role, reach out to a carrier admin to update this information.
                </p> */}
                <div className={classes.contentBlock}>
                    <TextField
                        label={<p className={classes.secondaryText} style={{ color: companyName == "" ? muiTheme.ttRed : "grey" }}>Company Name <span style={{ color: muiTheme.ttRed, fontWeight: "bold" }}>*</span></p>}
                        inputProps={{
                            style: { fontSize: 16 }
                        }}
                        InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                        fullWidth
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        disabled={isLoading || profile.requesting}
                    />
                </div>
                <div className={classes.contentBlock}>
                    <TextField
                        label="SCAC Number"
                        inputProps={{
                            style: { fontSize: 16 }
                        }}
                        InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                        fullWidth
                        value={scac}
                        onChange={(e) => setScac(e.target.value)}
                        disabled={isLoading || profile.requesting}
                    />
                </div>
                <div className={classes.contentBlock}>
                    <TextField
                        label={<p className={classes.secondaryText} style={{ color: (mc == "" && dotNumber == "") ? muiTheme.ttRed : "grey" }}>MC Number</p>}
                        inputProps={{
                            style: { fontSize: 16 }
                        }}
                        InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                        fullWidth
                        value={mc}
                        onChange={(e) => setMc(e.target.value)}
                        disabled={isLoading || profile.requesting}
                    />
                </div>
                <div className={classes.contentBlock}>
                    <TextField
                        label={<p className={classes.secondaryText} style={{ color: (mc == "" && dotNumber == "") ? muiTheme.ttRed : "grey" }}>DOT Number</p>}
                        inputProps={{
                            style: { fontSize: 16 }
                        }}
                        InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                        fullWidth
                        value={dotNumber}
                        onChange={(e) => setDotNumber(e.target.value)}
                        disabled={isLoading || profile.requesting}
                    />
                </div>
                <div className={classes.contentBlock}>
                    <TextField
                        label="Address"
                        inputProps={{
                            style: { fontSize: 16 }
                        }}
                        InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                        fullWidth
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        disabled={isLoading || profile.requesting}
                    />
                </div>
                <div className={classes.contentBlock}>
                    <TextField
                        label="City"
                        inputProps={{
                            style: { fontSize: 16 }
                        }}
                        InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                        fullWidth
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        disabled={isLoading || profile.requesting}
                    />
                </div>
                <div className={classes.contentBlock}>
                    <TextField
                        label="State"
                        inputProps={{
                            style: { fontSize: 16 }
                        }}
                        InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                        fullWidth
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        disabled={isLoading || profile.requesting}
                    />
                </div>
                <div className={classes.contentBlock}>
                    <TextField
                        label="Zip"
                        inputProps={{
                            style: { fontSize: 16 }
                        }}
                        InputLabelProps={{ shrink: true, style: { fontSize: 16 } }}
                        fullWidth
                        value={zip}
                        onChange={(e) => setZip(e.target.value)}
                        disabled={isLoading || profile.requesting}
                    />
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', gap: 16, paddingBottom: 24 }}>
                <Button
                    variant={"outlined"}
                    size={"small"}
                    style={{ fontSize: 14, color: muiTheme.actionColor, backgroundColor: "white", borderColor: muiTheme.actionColor }}
                    onClick={() => onBack()}
                    disabled={isLoading || profile.requesting}
                >
                    Cancel
                </Button>
                <Button
                    variant={"contained"}
                    size={"small"}
                    style={{ fontSize: 14, color: "white", backgroundColor: isLoading ? muiTheme.ttGrey : muiTheme.actionColor, borderColor: isLoading ? muiTheme.ttGrey : muiTheme.actionColor }}
                    onClick={(e) => submitForm()}
                    disabled={isLoading || profile.requesting}
                >
                    Submit Form
                </Button>
            </div>
            <FooterText advantageId={process.env.REACT_APP_ADVANTAGE_ID}/>

        </div>
    )

}

const mapStateToProps = state => ({
    profile: state.Profile
  })
  
  function matchDispatchToProps(dispatch) {
    return bindActionCreators({
      updateProfile: updateProfile,
    }, dispatch)
  }


export default compose(
    connect(mapStateToProps, matchDispatchToProps),
    muiThemeable()
)(EditCarrierProfile)