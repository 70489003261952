import React, { useEffect, useState } from 'react';
import { responsibilityShowInterest } from '../../../../../lib/responsibility-code-functions';
import { showBIN } from "../../../../../lib/show-bin";
import QuoteActionsDesktop from './desktop'
import QuoteActionsMobile from './mobile'
import * as RateNegotiationsApi from '../RateNegotiations/apiQuoteHelperFunctions';
import { determineQuoteActions, findLatestBinOffer } from './quoteHelperFunctions';

const QuoteActions = (props) => {

    const {
        load,
        pickup,
        delivery,
        latestBinAmount,
        offerThread,
        autoBinForm,
        updateOfferHistoryList = () => { },
        setIsLoading = () => { },
        availability,
        userProfile = false,
        cardStyle = null,
        mobileView,
        muiTheme
    } = props

    const [showRatePer, setShowRatePer] = useState(true)

    const [showBinBtn, setShowBinButton] = useState(false)
    const [binAmount, setBinAmount] = useState(false)

    const [useShowInterest, setAllowShowInterest] = useState(false)




    useEffect(() => {

        setAllowShowInterest(responsibilityShowInterest(load, userProfile)) 
        setBinAmount(findLatestBinOffer(offerThread, load)?.amount)

        if (showBIN(load, offerThread)) {
            setShowBinButton(true)
            setShowRatePer(true)
        }
        else {
            setShowBinButton(false)
            setShowRatePer(false)
        }

    }, [load, offerThread])

    const submitCounterOffer = (quote, counterOfferValue, message) => {
        RateNegotiationsApi.SubmitCounterOffer(
            load,
            quote,
            counterOfferValue,
            message,
            updateOfferHistoryList,
            setIsLoading
        )
    }


    return (
        <div>

            {mobileView ?
                <QuoteActionsMobile
                    load={load}
                    offerThread={offerThread}
                    setIsLoading={setIsLoading}
                    latestBinAmount={latestBinAmount}
                    submitCounterOffer={submitCounterOffer}
                    showRatePer={showRatePer}
                    showBinBtn={showBinBtn}
                    useShowInterest={useShowInterest}
                    availability={availability}
                />
                :
                <QuoteActionsDesktop
                    load={load}
                    pickup={pickup}
                    delivery={delivery}
                    submitCounterOffer={submitCounterOffer}
                    offerThread={offerThread}
                    availability={availability}
                    autoBinForm={autoBinForm}
                    showBinBtn={showBinBtn}
                    binAmount={binAmount}
                    useShowInterest={useShowInterest}
                    cardStyle={cardStyle}
                />
            }
        </div>

    )

}


export default QuoteActions